import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";

const CommonTable = ({
  headers,
  values,
  edittable,
  tableData,
  setTableData,
}) => {
  const [edittableRow, setEdittableRow] = useState(null);
  const [edittedData, setEdittedData] = useState(null);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEdittedData({ ...edittedData, [name]: value });
  };

  const handleEditClick = (index) => {
    setEdittableRow(index);
    setEdittedData(tableData[index]);
  };

  const handleSaveClick = (index) => {
    setTableData(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = edittedData;
      return updatedData;
    });
    setEdittableRow(null);
  };

  const handleCancelClick = () => {
    setEdittableRow(null);
  };

  return (
    <Box sx={{ overflowX: "auto", overflowY: "auto", maxHeight: "360px" }}>
      <Table sx={{ marginX: 2, minWidth: "100%" }}>
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 3,
          }}
        >
          <TableRow>
            {headers.map((eachHeader, index) => (
              <TableCell
                key={`head${index}`}
                sx={{
                  border: "1px solid #C6C6C6",
                  padding: "8px 16px 8px 15px",
                  ...(index === 0 && {
                    position: "sticky",
                    left: 0,
                    backgroundColor: "#fff",
                    zIndex: 2,
                    borderLeft: "1px solid #C6C6C6",
                  }),
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: 12,
                    fontWeight: "400",
                    letterSpacing: 0.4,
                    lineHeight: "16px",
                    wordWrap: "break-word",
                  }}
                >
                  {eachHeader}
                </Typography>
              </TableCell>
            ))}
            {edittable && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((eachRow, index) => (
            <TableRow key={`row${index}`}>
              {eachRow.map((eachCell, cellIndex) => (
                <TableCell
                  key={`${headers[cellIndex]}${cellIndex}`}
                  sx={{
                    border: "1px solid #C6C6C6",
                    padding: "8px 16px 8px 15px",
                    alignContent: "start",
                    ...(cellIndex === 0 && {
                      position: "sticky",
                      left: 0,
                      backgroundColor: "#fff",
                      zIndex: 1,
                      borderLeft: "1px solid #C6C6C6",
                    }),
                  }}
                >
                  {index === edittableRow ? (
                    <TextField
                      name={headers[cellIndex]}
                      value={edittedData[headers[cellIndex]]}
                      onChange={(e) => handleInputChange(e)}
                      multiline
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      style={{
                        color: "black",
                        ...(cellIndex === 0
                          ? { width: "100px" }
                          : { width: "206px" }),
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: eachCell.replace(/\n/g, "<br>"),
                      }}
                    />
                  )}
                </TableCell>
              ))}
              {edittable && (
                <TableCell>
                  {edittableRow === index ? (
                    <Box>
                      <IconButton color="primary" onClick={() => handleSaveClick(index)}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton color="secondary" onClick={handleCancelClick}>
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <IconButton onClick={() => handleEditClick(index)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );

};

export default CommonTable;
