import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MultipleTestCasesContext, TestCasesLoaderContext } from "../MultiTestCaseGenerationPage";
import MultipleEvaluationSummary from "../../output-components/testcase-generation/MultipleEvaluationSummary";
import MultipleUserStoriesComponent from "../../output-components/testcase-generation/MultipleUserStoriesComponent";
import MultipleRefinedUserStory from "../../output-components/testcase-generation/MutilpleRefinedUserStory";
import { Box, Typography } from "@mui/material";
import MultipleTestCases from "../../output-components/testcase-generation/MultipleTestCases";
import MultipleUseCase from "../../output-components/testcase-generation/MultipleUseCaseComponent";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import FullscreenToggle from "../../custom-components/FullScreenToggle";

const MultipleTestCasesOutputPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    multipleUserStories,
    setMultipleUserStories,
    setSelectedUserStories,
    multipleEvaluationSummary,
    setMultipleEvaluationSummary,
    multipleRefinedUserStory,
    setMultipleRefinedUserStory,
    setSelectedRefinedUserStories,
    multipleUseCases,
    setMultipleUseCases,
    setSelectedUseCases,
    multiTestCases,
    setMultiTestCases,
    setSelectedMultipleTestCases,
  } = useContext(MultipleTestCasesContext);

  useEffect(() => {
    // console.log(location.state);
    if (!location.state?.userStories) {
      return navigate("/dashboard/generate-testcases/input");
    }
    if (!location.state.userStories.length) {
      return navigate("/dashboard/generate-testcases/input");
    }
    setMultipleUserStories(location.state?.userStories || []);
    setSelectedUserStories(location.state?.selectedUserStories || []);
    setMultipleEvaluationSummary(location.state?.multiEvaluationSummary || []);
    setMultipleRefinedUserStory(location.state?.multipleRefinedUserStory || []);
    setSelectedRefinedUserStories(
      location.state?.selectedRefinedUserStories || []
    );
    setMultipleUseCases(location.state?.multipleUseCases || []);
    setSelectedUseCases(location.state?.selectedMultipleUseCases || []);
    setMultiTestCases(location.state?.multipleTestCases || []);
    setSelectedMultipleTestCases(
      location.state?.selectedMultipleTestCases || []
    );
  }, [
    location.state?.userStories,
    location.state?.selectedUserStories,
    location.state?.multiEvaluationSummary,
    location.state?.multipleRefinedUserStory,
    location.state?.selectedRefinedUserStories,
    location.state?.multipleUseCases,
    location.state?.selectedMultipleUseCases,
    location.state?.multipleTestCases,
    location.state?.selectedMultipleTestCases,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "601px",
        margin: "10px auto",
        "& > *:not(:last-child)": {
          marginBottom: "16px", // Adds margin between components but not after the last one
        },
        height: "100%",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position={"sticky"}
        top={0}
      >
        <Box display="flex">
          <AutomateIcon style={{ marginRight: 4 }} />
          {/* Informative text */}
          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{ mt: 0.5 }}
            fontSize={"12px"}
          >
            Automate and create comprehensive, high-quality test cases
            effortlessly
          </Typography>
        </Box>
        {/* <FullscreenIcon sx={{width: "24px"}}/> */}
        <FullscreenToggle />
      </Box>
      
        {!!multipleUserStories.length && <MultipleUserStoriesComponent />}
        {!!multipleEvaluationSummary.length && <MultipleEvaluationSummary />}
        {!!multipleRefinedUserStory.length && <MultipleRefinedUserStory />}
        {!!multipleUseCases.length && <MultipleUseCase />}
        {!!multiTestCases.length && <MultipleTestCases />}
      <Box
        component="footer"
        sx={{
          width: "100%",
          maxWidth: "601px",
          backgroundColor: "#f6f3f2",
          textAlign: "center",
          paddingX: "5px",
          position: "fixed",
          bottom: "0",
          zIndex: "99"
        }}
      >
        <Typography variant="caption" color="textSecondary">
        Use QMentisAI™ with human supervision, as with all generative AI tools.
        </Typography>
      </Box>
    </Box>
  );
};

export default MultipleTestCasesOutputPage;
