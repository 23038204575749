import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ReactComponent as QMentisImageTagline } from "../../images/QMentisAITagline.svg";
import QMentis from "../../images/QMentis.svg";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/webServices";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100vh",
  width: "100%",
  overflow: "hidden", // Prevent scrollbars
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Split = styled(Box)(({ theme, side }) => ({
  width: side === "left" ? "65%" : "25%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background:
    side === "left"
      ? `linear-gradient(-135deg, rgba(0, 116, 255, 0.2), rgba(226, 78, 94, 0.2))`
      : "#F6F3F2",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: side === "left" ? "30%" : "70%",
  },
}));

const LoginCard = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15)",
  display: "flex",
  flexDirection: "column",
  padding: "40px",
  width: "90%",
  maxWidth: "400px",
  height: "auto",
  maxHeight: "90%",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    maxHeight: "none",
    borderRadius: 0,
    boxShadow: "none",
  },
}));

const FooterSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "16px",
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px", // Add margin top for spacing
  width: "100%", // Make the button full width
  textTransform: "none",
  borderRadius: "4px",
  backgroundColor: "#0094FB",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#0077CC",
  },
}));

function ForgotPassword() {
  const [formValues, setFormValues] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("error");

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormValues({ ...formValues, [id]: value });
    setFormErrors({ ...formErrors, [id]: value.trim() ? "" : formErrors[id] });
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const email = document.getElementById("email").value;

    const errors = {};
    if (!email.trim()) {
      errors["email"] = "Please enter your email address.";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) return;

    try {
      const response = await forgotPassword({ username: email });
      // Simulating password reset request
      // await resetPassword({ email });
      setSeverity("success");
      setError("A password reset link has been sent to your email.");
    } catch (error) {
      setSeverity("error");
      setError("Password reset failed. Please try again.");
    }
  };

  return (
    <StyledBox sx={{ display: "flex", width: "100%" }}>
      <Split side="left" sx={{ width: "65%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "left",
            px: { xs: 2, sm: 4 },
            maxWidth: "500px", // Increased the maximum width of the text box
            width: "100%", // Ensures it takes full available width
            top: "-20px",
          }}
        >
          {/* Logo and Subheading */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <img
              src={QMentis}
              alt="QMentis Logo"
              style={{
                width: "55%",
                height: "55%",
                objectFit: "contain",
                marginBottom: "-20px",
                marginLeft: "-28px",
              }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontSize: { xs: "15px", sm: "20px" }, 
                fontWeight: "350",
                mt: 1, // Adjust margin for spacing
              }}
            >
              Revolutionising AI based QE
            </Typography>
          </Box>
 
          {/* Divider */}
          <Divider
            sx={{
              borderColor: "grey.400",
              borderWidth: 1,
              width: "100%",
              mb: 1, // Added margin for spacing
            }}
          />
 
          {/* Main Text */}
          <Typography
            variant="h4"
            color="textPrimary"
            sx={{
              fontSize: { xs: "20px", sm: "35px" }, // Increased font size for larger text
              fontWeight: "400",
              lineHeight: 1.5, // Maintains proper line spacing
              mt: 2, // Adds margin at the top
              textAlign: "left", // Ensures left alignment
            }}
          >
            Power your QE journey forward
            <br />
            through{" "}
            <span style={{ color: "#0094FB", fontWeight: "bold" }}>
              QMentisAI™
            </span>{" "}
            - your
            <br />
            gateway into smarter testing
          </Typography>
        </Box>
      </Split>


      <Split side="right" sx={{ width: "35%" }}>
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 3,
            height: "100vh",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <QMentisImageTagline />
          </Box>

          <Divider
            sx={{
              my: 2,
              borderColor: "grey.400",
              borderWidth: 1,
              width: "80%",
            }}
          />

          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: 2,
              mt: 4,
            }}
          >
            <Typography fontSize="15px" textAlign="left" color="black">
              Please enter the email address that you used to register, and we
              will send you a link to reset your password via mail.
            </Typography>
          </Box>

          <form onSubmit={handlePasswordReset} style={{ width: "80%" }}>
            {/* Email Field */}
            <TextField
              label="Email ID"
              id="email"
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              error={!!formErrors.email}
              helperText={formErrors.email}
              onChange={handleInputChange}
              sx={{ marginBottom: "16px" }} // Added margin bottom to create space
            />

            <Typography color={severity === "error" ? "error" : "primary"}>
              {error}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <StyledButton
                type="submit"
                variant="contained"
                startIcon={<ArrowForwardIcon />}
              >
                Submit
              </StyledButton>
            </Box>
          </form>

          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
              mt: 4,
            }}
          >
            <Typography>
              Return to&nbsp;
              <Link
                variant="body2"
                sx={{ mt: 1, fontSize: "14px", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                Login
              </Link>
            </Typography>
          </Box>
        </Box>
      </Split>
    </StyledBox>
  );
}

export default ForgotPassword;
