import React from "react";
import { Modal, Box } from "@mui/material";
import ProgressWithLogo from "../custom-components/ProgressWithLogo";

function LoadingModal({ open }) {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box>
        <ProgressWithLogo />
      </Box>
    </Modal>
  );
}

export default LoadingModal;
