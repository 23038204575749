import React, { useState, useContext } from 'react';
import {
    Box,
    CardContent,
    Input,
    Button
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ReactComponent as ExportIcon } from '../../../images/exportIcon.svg';
import{ReactComponent as ImportAuto} from "../../../images/importAuto.svg";
import {ReactComponent as ExportAuto} from "../../../images/exportAuto.svg";
import DownloadIcon from '@mui/icons-material/Download';
import { pageSourceService } from '../../../services/webServices';
import { useNavigate, useLocation } from 'react-router-dom';
import { TestCaseFlowDataContext } from '../../main-pages/TestAutomationPage';
// import Locators from './Locators';  // Import the Locators component
import CommonAccordion from "../../custom-components/CommonAccordion";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";

const InputForm = () => {
  const [pageSourceInput, setPageSourceInput] = useState("");
  const [locatorsData, setLocatorsData] = useState([]); // To store generated locators data
  const [showLocators, setShowLocators] = useState(false); // State to control visibility of Locators
  const navigate = useNavigate();
  const location = useLocation();
  const setLoading = useContext(LoadingModalContext);
  const { pseudoCode, locators } = useContext(TestCaseFlowDataContext);

  const defaultPseudoCodeExpanded =
    pseudoCode !== "" && !!locators && Object.keys(locators).length === 0;

  const handleUserStoryChange = (e) => {
    setPageSourceInput(e.target.value);
  };

  const handleGenerateLocators = async () => {
    setLoading(true);
    try {
      const requestData = {
        pageSource: pageSourceInput,
      };
      // Call the pageSourceService to generate locators based on the input
      const generatedLocatorsResponse = await pageSourceService(requestData);
      const generatedLocators = generatedLocatorsResponse.data; // Assuming response has 'locators' field
      if (typeof generatedLocators == "string") {
        setLoading(false);
        alert(generatedLocators);
        return;
      }

      navigate("/dashboard/test-automation/output", {
        state: {
          ...location.state,
          pageSource: pageSourceInput,
          generatedLocators: generatedLocators,
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error generating locators:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <CommonAccordion
        type={"output"}
        summary={"Page Source"}
        details={
          <>
            <Box sx={{ color: "#2b2f30", marginBottom: 2 }}>
              Include only relevant DOM elements to avoid inaccurate locators
              and unnecessary automation complexity.
            </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                // alignItems: 'center',
                                // width: '100%',
                                // marginTop: '50px',
                            }}
                        >
                            <form>
                                <CardContent
                                    sx={{
                                        // padding: 1,
                                        width: 500,
                                        bgcolor: '#E4E3E3',
                                        borderRadius: 2,
                                        marginBottom: 6
                                        // boxShadow: 3,
                                    }}
                                >
                                    <Input
                                        fullWidth
                                        disableUnderline
                                        multiline
                                        minRows={5}
                                        maxRows={10}
                                        margin="normal"
                                        placeholder="Enter Page Source"
                                        value={pageSourceInput}
                                        onChange={handleUserStoryChange}
                                        autoComplete="off"
                                        sx={{
                                            color: "black",
                                            bgcolor: "transparent",
                                            padding: "10px 10px 10px 33px",
                                        }}
                                    />
                                    <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-start' }}>
                                            <Button
                                                startIcon={<ImportAuto />}
                                                color="#80c3d8"
                                                sx={{ textTransform: "none", marginRight: '15px', color: '#49A3FF' }}
                                            >
                                                Upload
                                            </Button>
                                            <Button
                                                startIcon={<ExportAuto />}
                                                color="primary"
                                                sx={{ textTransform: "none", color: '#49A3FF' }}
                                            >
                                                Import from Jira
                                            </Button>
                                        </Box>

                    <Button
                      variant="contained"
                      startIcon={<GenerateButton />}
                      sx={{
                        mt: 0,
                        textTransform: "none",
                        backgroundColor: "#49A3FF",
                      }}
                      disabled={!pageSourceInput.trim()}
                      onClick={handleGenerateLocators} // Handle Generate Locators button click
                    >
                      Generate Locators
                    </Button>
                  </Box>
                </CardContent>
              </form>

              {/* Conditionally render the Locators accordion after the "Generate Locators" button is clicked */}
            </Box>
          </>
        }
        defaultExpanded={defaultPseudoCodeExpanded}
      />
      {/* {showLocators && <Locators locatorsData={locatorsData} />} */}
    </Box>
  );
};

export default InputForm;
