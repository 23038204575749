import { Button, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material'
import React from 'react'

const SelectionErrorPopUp = ({open, onClose}) => {
  return (
    <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        borderRadius: "8px",
        minWidth: "400px",
        padding: "10px 20px",
      },
    }}
  >
    <DialogContent sx={{ paddingBottom: "20px" }}>
      <Typography
        variant="body1"
        sx={{
          fontSize: "16px",
          fontFamily: "Roboto, sans-serif",
          color: "#333",
        }}
      >
        Select atleast one checkbox
      </Typography>
    </DialogContent>

    <Divider />

    <DialogActions sx={{ justifyContent: "flex-end", padding: "10px" }}>
      <Button
        onClick={onClose} // Use onClose prop to close the dialog
        sx={{
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
          color: "#49A3FF",
          marginRight: "5px",
        }}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default SelectionErrorPopUp