import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Typography,
  Box,
  Button,
  Pagination,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ReactComponent as ExportIcon } from "../../../images/exportIcon.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import CommonTable from "../../custom-components/CommonTestCasesTable";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from "../../custom-components/CommonDownload";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MultipleTestCasesContext,
  TestCasesLoaderContext,
} from "../../main-pages/MultiTestCaseGenerationPage";
import { ReactComponent as SendButton } from "../../../images/generateButton.svg";
import { TestCaseLoaderStateContext } from "../../main-pages/TestcaseGenerationPage";

function EachUserStoryAccordian({
  allTestCases,
  setAllTestCases,
  testCases,
  userStoryIndex,
  handleCheckboxChange,
  selectedTestCases,
  setSelectedMultipleTestCases,
  summary,
  expandAll,
}) {
  const [testcases, setTestCases] = useState(testCases);
  const [expanded, setExpanded] = useState(null);
  const { testCaseLoaderState } = useContext(TestCasesLoaderContext);

  useEffect(() => {
    if (expandAll) {
      setExpanded(userStoryIndex);
    } else {
      setExpanded(null);
    }
  }, [expandAll, userStoryIndex]);

  useEffect(() => {
    if (!testCaseLoaderState) {
      const updatingTestCases = [...allTestCases];
      updatingTestCases[userStoryIndex] = testcases;
      // navigate("/dashboard/generate-multi/testcases/output", {state: {...location.state, multipleTestCases: updatingTestCases}, replace: true})
      setAllTestCases(updatingTestCases);
      if (selectedTestCases[userStoryIndex] !== null) {
        setSelectedMultipleTestCases((prevState) => {
          const updatingSelections = [...prevState];
          updatingSelections[userStoryIndex] = testcases;
          return updatingSelections;
        });
      }
    }
  }, [testcases]);

  const handleSelectedCheck = () => {
    return (
      JSON.stringify(selectedTestCases[userStoryIndex]) ===
      JSON.stringify(testCases)
    );
  };

  const formatTestCase = (testCase) => {
    const formattedTestCasesAll = [];
    testCase.map((eachTestCase) => {
      const formattedTestCase = {};
      Object.entries(eachTestCase).map(([key, value]) => {
        if (Array.isArray(value)) {
          return (formattedTestCase[key] = flattenArray(value));
        } else if (typeof value === "object" && !!value) {
          return (formattedTestCase[key] = flattenObject(value));
        } else {
          return (formattedTestCase[key] = value);
        }
      });
      return formattedTestCasesAll.push(formattedTestCase);
    });
    return formattedTestCasesAll;
  };

  const tableDataConverter = (testCasesData) => {
    const headers = Object.keys(testCasesData[0]);
    const values = testCasesData.map((eachTestCase) => {
      return Object.values(eachTestCase);
    });
    return { headers, values };
  };

  return (
    <Box>
      <Accordion
        key={userStoryIndex} // Use index as key since testCase.id is not present
        expanded={expanded === userStoryIndex}
        onChange={(e, isExpanded) => {
          isExpanded ? setExpanded(userStoryIndex) : setExpanded(null);
        }}
      >
        <AccordionSummary
          sx={{
            backgroundColor:
              selectedTestCases[userStoryIndex] === null ? "white" : "#EAF1FF",
            height: "50px",
          }}
        >
          <Box display="flex" alignItems="center" width="100%">
            <Box width="34px">
              <ChevronRightIcon
                sx={{
                  transform:
                    expanded === userStoryIndex
                      ? "rotate(90deg)"
                      : "rotate(0deg)",
                  transition: "transform 0s ease",
                }}
              />
            </Box>
            <Box sx={{ padding: "11px" }}>
              <Checkbox
                checked={
                  JSON.stringify(selectedTestCases[userStoryIndex]) ===
                  JSON.stringify(allTestCases[userStoryIndex])
                }
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  handleCheckboxChange(e, userStoryIndex)}}
                sx={{
                  height: "24px",
                  width: "24px",
                  "&.Mui-checked": {
                    color: "#49A3FF",
                  },
                }}
              />
            </Box>
            <Box sx={{ flexBasis: "80%", overflow: "hidden", flexShrink: 2 }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  overflow: "hidden",
                  height: "20px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "400px",
                }}
              >
                {summary}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            maxHeight: "60vh",
            // overflow: "auto",
            // "&::-webkit-scrollbar": { width: "0px" },
            // scrollbarWidth: "none",
            padding: "0px",
            mt: "5px",
            paddingLeft: "56px",
          }}
        >
          <CommonTable
            {...tableDataConverter(formatTestCase(testCases))}
            edittable={true}
            tableData={testCases}
            setTableData={setTestCases}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default function MultipleTestCases() {
  const location = useLocation();
  const navigate = useNavigate();

  const userStories = useContext(MultipleTestCasesContext).multipleUserStories;
  const multiTestCases = useContext(MultipleTestCasesContext).multiTestCases;
  const setMultiTestCases = useContext(
    MultipleTestCasesContext
  ).setMultiTestCases;
  const selectedMultipleTestCases = useContext(
    MultipleTestCasesContext
  ).selectedMultipleTestCases;
  const setSelectedMultipleTestCases = useContext(
    MultipleTestCasesContext
  ).setSelectedMultipleTestCases;
  const { testCaseLoaderState } = useContext(TestCasesLoaderContext);

  const [expandAll, setExpandAll] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [multipleTestCases, setMultipleTestCases] = useState(multiTestCases);
  const [beginTestAutomation, setBeginTestAutomation] = useState(false);
  const [selectedTestCasesSet, setSelectedTestCasesSet] = useState(null);

  const [selectedAll, setSelectedAll] = useState(
    JSON.stringify(multipleTestCases) ===
      JSON.stringify(selectedMultipleTestCases)
  );
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const testCasesPerPage = 5;

  useEffect(() => {
    // console.log(multiTestCases);
    // console.log(selectedMultipleTestCases, "selected multiple testcases");
    setMultipleTestCases(multiTestCases);
  }, [multiTestCases]);

  useEffect(() => {
    // console.log("updating select all");
    setSelectedAll(
      JSON.stringify(multipleTestCases) ===
        JSON.stringify(selectedMultipleTestCases)
    );
  }, [multipleTestCases, selectedMultipleTestCases]);

  const activeTestCases = [];
  multipleTestCases.map((item, index) => {
    if (!!item) {
      return activeTestCases.push({ [index]: item });
    }
  });

  // Calculate the number of pages based on the total number of test cases
  const pageCount = Math.ceil(activeTestCases.length / testCasesPerPage);

  useEffect(() => {
    if (multipleTestCases.length === userStories.length) {
      if (
        JSON.stringify(multipleTestCases) !==
        JSON.stringify(location.state?.multipleTestCases)
      ) {
        navigate("/dashboard/generate-multi/testcases/output", {
          state: {
            ...location.state,
            multipleTestCases: multipleTestCases,
            selectedMultipleTestCases: selectedMultipleTestCases,
          },
          replace: true,
        });
      }
    }
  }, [multipleTestCases]);

  const handleDownload = () => {
    setOpenDownload(true);
  };

  const handleCheckboxChange = (event, userStoryIndex) => {
    const changingSelections = [...selectedMultipleTestCases];
    changingSelections[userStoryIndex] = event.target.checked
      ? multipleTestCases[userStoryIndex]
      : null;
    setSelectedMultipleTestCases([...changingSelections]);
  };

  const handleSelectAll = (event) => {
    setSelectedMultipleTestCases([...multipleTestCases]);
  };

  const handleUnselectAll = () => {
    setSelectedMultipleTestCases(selectedMultipleTestCases.map(() => null));
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleExport = () => {
    // console.log("Export Clicked");
  };

  const handleTestAutomation = (testCasesData) => {
    try {
      navigate("/dashboard/test-automation/output", {
        state: { testCaseDetails: testCasesData },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const tableDataConverter = (testCasesData) => {
    const headers = Object.keys(testCasesData[0]);
    const values = testCasesData.map((eachTestCase) => {
      return Object.values(eachTestCase);
    });
    return { headers, values };
  };

  const createWordData = (multipleTestCasesRawData) => {
    const wordData = [];
    multipleTestCasesRawData.map((testCasesData, index) => {
      if (!!testCasesData) {
        // console.log(testCasesData);
        const formattedTestCases = [];
        testCasesData.map((testCase) => {
          const flattenTestCase = {};
          Object.entries(testCase).map(([key, value]) => {
            if (Array.isArray(value)) {
              return (flattenTestCase[key] = flattenArray(value));
            } else if (typeof value === "object" && !!value) {
              return (flattenTestCase[key] = flattenObject(value));
            } else {
              return (flattenTestCase[key] = value);
            }
          });
          return formattedTestCases.push(flattenTestCase);
        });
        wordData.push({
          heading: "User Story",
          type: "text",
          data: userStories[index]["Title"],
        });
        wordData.push({
          heading: "Test Cases",
          type: "table",
          data: { ...tableDataConverter(formattedTestCases) },
        });
        wordData.push({ type: "pageBreak" });
      }
    });
    return wordData;
  };

  // Slice the test cases to display only the ones for the current page
  const currentTestCases = activeTestCases.slice(
    (currentPage - 1) * testCasesPerPage,
    currentPage * testCasesPerPage
  );

  const accordianSummary = `Test Cases (${activeTestCases.length})`;

  const accordionButtons = [
    {
      label: "Export",
      onClick: handleExport,
      variant: "text",
      startIcon: <ExportIcon />,
    },
    {
      label: "Download",
      onClick: handleDownload,
      variant: "text",
      startIcon: <FileDownloadOutlinedIcon />,
    },
    {
      label: "Begin Test Automation",
      onClick: () => setBeginTestAutomation(true),
      variant: "contained",
      startIcon: <SendSharpIcon />,
      disabled: !!testCaseLoaderState,
      sx: { float: "right" },
    },
  ];

  const accordianDetails = (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ height: "40px", padding: "10px" }}>
        {JSON.stringify(multipleTestCases) ===
        JSON.stringify(selectedMultipleTestCases) ? (
          <Button
            onClick={handleUnselectAll}
            sx={{ textTransform: "none", fontSize: "14px", color:"#49A3FF" }}
          >
            Unselect All
          </Button>
        ) : (
          <Button
            onClick={handleSelectAll}
            sx={{ textTransform: "none", fontSize: "14px", color:"#49A3FF" }}
          >
            Select All
          </Button>
        )}
        {expandAll ? (
          <Button
            onClick={() => setExpandAll(false)}
            sx={{ textTransform: "none", color:"#49A3FF" }}
          >
            Collapse All
          </Button>
        ) : (
          <Button
            onClick={() => setExpandAll(true)}
            sx={{ textTransform: "none", color:"#49A3FF"}}
          >
            Expand All
          </Button>
        )}
      </Box>
      <Box>
        {/* Accordions for each Test Case in the current page */}
        {currentTestCases.map((eachActiveTestCase) =>
          Object.entries(eachActiveTestCase).map(([index, testCase]) => {
            if (!testCase) {
              return;
            }
            return (
              <EachUserStoryAccordian
                allTestCases={multipleTestCases}
                setAllTestCases={setMultipleTestCases}
                testCases={testCase}
                userStoryIndex={index}
                handleCheckboxChange={handleCheckboxChange}
                selectedTestCases={selectedMultipleTestCases}
                setSelectedMultipleTestCases={setSelectedMultipleTestCases}
                summary={userStories[index]["Title"]}
                expandAll={expandAll}
                key={`eachUserStoryAccorianTestCases_${index}`}
              />
            );
          })
        )}
      </Box>
      {/* Pagination */}
      <Box mt={2} sx={{ alignSelf: "center" }}>
        <Pagination
          count={pageCount} // Total number of pages
          page={currentPage} // Current page
          onChange={handlePageChange} // Handle page change
          // color="primary"
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    <Box display="flex" justifyContent="center">
      <Box width="100%">
        <CommonAccordion
          summary={accordianSummary}
          details={accordianDetails}
          buttons={accordionButtons}
          fullScreenMode={true}
          type={"output"}
          defaultExpanded={true}
        />
        <CommonDownload
          open={openDownload}
          onClose={() => setOpenDownload(false)}
          serviceResponse={multipleTestCases}
          serviceName="TestCases_Report"
          wordData={createWordData(selectedMultipleTestCases)}
        />
      </Box>

      <Dialog
        open={beginTestAutomation}
        onClose={() => setBeginTestAutomation(false)}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            minWidth: "400px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            paddingBottom: "10px",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Select a user story
        </DialogTitle>

        <DialogContent sx={{ paddingBottom: "20px" }}>
          {multiTestCases.map((eachTestCaseSet, index) => {
            return (
              <RadioGroup
                value={selectedTestCasesSet}
                onChange={(e) => setSelectedTestCasesSet(e.currentTarget.value)}
              >
                {!!eachTestCaseSet && (
                  <FormControlLabel
                    value={index}
                    control={<Radio />}
                    label={userStories[index]["Title"]}
                  />
                )}
              </RadioGroup>
            );
          })}
        </DialogContent>

        <Divider />

        <DialogActions
          sx={{ justifyContent: "flex-end", padding: "10px 24px" }}
        >
          <Button
            onClick={() => setBeginTestAutomation(false)}
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Roboto, sans-serif",
              color: "#49A3FF",
              marginRight: "10px",
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() =>
              handleTestAutomation(multiTestCases[selectedTestCasesSet])
            }
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "14px",
              fontFamily: "Roboto, sans-serif",
              color: "white",
              backgroundColor: "#49A3FF",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "5px 15px",
              gap: "7px",
            }}
          >
            <SendButton sx={{ marginRight: "8px" }} />
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
