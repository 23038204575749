import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const QMentisBreadCrumb = ({ breadCrumbs, sx }) => {
  const [startIndex, setStartIndex] = useState(0);

  const componentsCount = breadCrumbs.length > 5 ? 5 : breadCrumbs.length;

  // Find the index of the last completed breadcrumb
  const lastCompletedIndex = useMemo(() => {
    return breadCrumbs.findIndex(
      (breadCrumb) => breadCrumb.status !== "Completed"
    ) - 1;
  }, [breadCrumbs]);

  // Auto-scroll to make the last completed breadcrumb visible
  useEffect(() => {
    if (lastCompletedIndex >= 0) {
      const scrollIndex = Math.max(
        0,
        Math.min(
          lastCompletedIndex - Math.floor(componentsCount / 2),
          breadCrumbs.length - componentsCount
        )
      );
      setStartIndex(scrollIndex);
    }
  }, [lastCompletedIndex, componentsCount, breadCrumbs.length]);

  const breadCrumbsComponents = breadCrumbs.map((breadCrumbData, index) => (
    <Box
      key={`breadcrumbcomponent_${index}`}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        marginY: "2px",
        height: "36px",
        color: "black",
        opacity: breadCrumbData.status === "Not Started" ? 0.5 : 1,
        maxWidth: "100%",
      }}
    >
      <Box>
        {breadCrumbData.status === "Completed" ? (
          <CheckCircleIcon sx={{ color: "#49A3FF" }} />
        ) : (
          <MoreHorizIcon />
        )}
      </Box>
      <Box>
        <Typography sx={{ fontSize: 14 }}>
          {index + 1}. {breadCrumbData.title}
        </Typography>
        <Typography sx={{ fontSize: 11, opacity: 0.5 }}>
          {breadCrumbData.status}
        </Typography>
      </Box>
    </Box>
  ));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
        bgcolor: "white",
        overflow: "auto",
      }}
    >
      {breadCrumbs.length > componentsCount && (
        <IconButton
          sx={{
            marginLeft: "20px",
            width: "12px",
            height: "12px",
            color: "black",
          }}
          disabled={startIndex === 0}
          onClick={() => setStartIndex((prevState) => prevState - 1)}
        >
          <NavigateBeforeIcon />
        </IconButton>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {breadCrumbsComponents
          .slice(startIndex, startIndex + componentsCount)
          .map((eachBreadCrumbComponent, componentIndex) => (
            <Box
              key={`breadcrumbitem_${componentIndex}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {eachBreadCrumbComponent}
              {componentIndex !== componentsCount - 1 && (
                <Box sx={{ marginX: "10px" }}>
                  <NavigateNextIcon sx={{ color: "#7F7F81" }} />
                </Box>
              )}
            </Box>
          ))}
      </Box>
      {breadCrumbs.length > componentsCount && (
        <IconButton
          sx={{
            marginRight: "20px",
            width: "12px",
            height: "12px",
            color: "black",
          }}
          disabled={breadCrumbs.length - startIndex <= componentsCount}
          onClick={() => setStartIndex((prevState) => prevState + 1)}
        >
          <NavigateNextIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default QMentisBreadCrumb;
