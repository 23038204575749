import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GuardedUserLogin, GuardedUserRoute } from "./services/authGuard";
import Login from "./components/main-pages/Login";
import LandingPage from "./components/main-pages/LandingPage";
import ServicePage from "./components/main-pages/ServicePage";
import BugReportPage from "./components/main-pages/BugReportPage";
import TestcaseGenerationPage from "./components/main-pages/TestcaseGenerationPage";
import BugReportInputPage from "./components/main-pages/input-pages/BugReportInputPage";
import BugReportOutputPage from "./components/main-pages/output-pages/BugReportOutputPage";
import TestCasesInputPage from "./components/main-pages/input-pages/TestCasesInputPage";
import TestCasesOutputPage from "./components/main-pages/output-pages/TestCasesOutputPage";
import ForgotPassword from "./components/common-components/ForgotPassword";
import JiraAccountSettingsPage from './components/common-components/JiraAccountsSettingPage';
import ResetPassword from "./components/common-components/ResetPassword";
import "./App.css";
import TestAutomationPage from "./components/main-pages/TestAutomationPage";
import TestAutomationInputPage from "./components/main-pages/input-pages/TestAutomationInputPage";
import TestAutomationOutputPage from "./components/main-pages/output-pages/TestAutomationOutputPage";
import FeatureFileCreated from "./components/output-components/test-automation/FeatureFilesCreated"
import MultiTestCaseGenerationPage from "./components/main-pages/MultiTestCaseGenerationPage";
import MultipleTestCasesOutputPage from "./components/main-pages/output-pages/MultipleTestCasesOutputPage";
import TestPlanInputForm from "./components/input-components/test-plan/TestPlanInputForm";
import TestPlanOuputComponent from "./components/output-components/test-plan/TestPlanOutputComponent";
import TestPlanPage from "./components/main-pages/TestPlanPage";
import BusinessProcessGuide from "./components/output-components/testcase-generation/BusinessProcessGuide";
import MultiBugReportPage from "./components/main-pages/MultiBugReportPage";
import MultiEnhancedBugOutputPage from "./components/main-pages/output-pages/MultiEnhancedBugOutputPage";
import MultiBugReportsInputPage from "./components/main-pages/input-pages/MultiBugReportsInputPage";
import TestPlanInputPage from "./components/main-pages/input-pages/TestPlanInputPage";
import TestPlanOutputPage from "./components/main-pages/output-pages/TestPlanOutputPage";
import MultiBusinessProcessGuide from "./components/output-components/testcase-generation/MultiBusinessProcessGuide";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<GuardedUserRoute />}>
          <Route path="/landingpage" element={<LandingPage />} />

          <Route path='/featurefilescreated' element={<FeatureFileCreated />} />
          <Route path="/dashboard" element={<ServicePage />}>
            <Route path="/dashboard/bugreportenhancer" element={<BugReportPage />}>
              <Route path="/dashboard/bugreportenhancer/input" element={<BugReportInputPage />} />
              <Route path="/dashboard/bugreportenhancer/output" element={<BugReportOutputPage />} />
            </Route>
            <Route path='/dashboard/multi-bugreports' element={<MultiBugReportPage />}>
              <Route path="/dashboard/multi-bugreports/input" element={<MultiBugReportsInputPage />} />
              <Route path='/dashboard/multi-bugreports/output' element={<MultiEnhancedBugOutputPage />} />
            </Route>

            <Route path="/dashboard/generate-testcases" element={<TestcaseGenerationPage />}>
              <Route path="/dashboard/generate-testcases/input" element={<TestCasesInputPage />} />
              <Route path="/dashboard/generate-testcases/output" element={<TestCasesOutputPage />} />
                <Route path="/dashboard/generate-testcases/output/businessProcessGuide" element={<BusinessProcessGuide />} />
            </Route>
            <Route path="/dashboard/generate-multi/testcases" element={<MultiTestCaseGenerationPage />} >
              <Route path="/dashboard/generate-multi/testcases/output" element={<MultipleTestCasesOutputPage />} />
                <Route path="/dashboard/generate-multi/testcases/output/multiBusinessProcessGuide" element={<MultiBusinessProcessGuide />} />
            </Route>
            <Route path="/dashboard/test-automation" element={<TestAutomationPage />}>
              <Route path="/dashboard/test-automation/input" element={<TestAutomationInputPage />} />
              <Route path="/dashboard/test-automation/output" element={<TestAutomationOutputPage />} />
            </Route>
            <Route path="/dashboard/jira-settings" element={<JiraAccountSettingsPage />} />

            <Route path='/dashboard/test-plan' element={<TestPlanPage />}>

              <Route path='/dashboard/test-plan/input' element={<TestPlanInputPage />} />
              <Route path='/dashboard/test-plan/output' element={<TestPlanOutputPage />} />
            </Route>

          </Route>
        </Route>

        <Route element={<GuardedUserLogin />}>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
