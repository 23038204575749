import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { ReactComponent as SendButton } from "../../images/generateButton.svg";

const SaveJIRAPopup = ({ jiraMessage, open, onClose, onConfirm }) => {
  const handleLogout = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClose(); // Close the dialog after confirming logout
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          minWidth: "400px",
          padding: "20px",
        },
      }}
    >
      {/* <DialogTitle
        sx={{
          fontWeight: "bold",
          fontSize: "20px",
          paddingBottom: "10px",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        Confirm Logout
      </DialogTitle> */}

      <DialogContent sx={{ paddingBottom: "20px" }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            fontFamily: "Roboto, sans-serif",
            color: "#333",
          }}
        >
          {jiraMessage}
        </Typography>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: "flex-end", padding: "10px 24px" }}>
        <Button
          onClick={onClose} // Use onClose prop to close the dialog
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            color: "#49A3FF",
            marginRight: "10px",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveJIRAPopup;
