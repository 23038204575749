import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Alert,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { resetPassword } from "../../services/webServices";
import { ReactComponent as QMentisImage } from '../../images/QMentisAIvertical.svg';
import Divider from "@mui/material/Divider";
import { ReactComponent as QMentisImageTagline } from "../../images/QMentisAITagline.svg";
import QMentis from '../../images/QMentis.svg';

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100vh",
  width: "100%",
  backgroundColor: "#FFFFFF",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Split = styled(Box)(({ theme, side }) => ({
  width: side === "left" ? "65%" : "35%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background:
    side === "left"
      ? `linear-gradient(-135deg, rgba(0, 116, 255, 0.2), rgba(226, 78, 94, 0.2))`
      : "#FFFFFF",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: side === "left" ? "30%" : "70%",
  },
}));

const CenterContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// const LoginCard = styled(Box)(({ theme }) => ({
//   borderRadius: "8px",
//   backgroundColor: "#FFFFFF",
//   boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15)",
//   display: "flex",
//   flexDirection: "column",
//   padding: "24px",
//   width: "90%",
//   maxWidth: "400px",
//   height: "auto",
//   maxHeight: "90%",
//   overflowY: "auto",
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//     maxHeight: "none",
//     borderRadius: 0,
//     boxShadow: "none",
//   },
// }));

const LogoSection = styled(Box)(() => ({
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
  minHeight: "40px",
  width: "100%",
  borderRadius: "4px",
  backgroundColor: "#0094FB",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#0077CC",
  },
}));

function ResetPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordActivated, setIsPasswordActivated] = useState(false);
  const [isConfirmPasswordActivated, setIsConfirmPasswordActivated] =
    useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const isButtonEnabled = () => {
    return password.length > 0 && confirmPassword.length > 0;
  };

  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      setMessage("Passwords do not match. Please try again.");
      setSeverity("error");
      return;
    }
    if (!/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)) {
      setMessage(
        "Password must be at least 8 characters long and include at least one number, one letter, and one special character."
      );
      setSeverity("error");
      return;
    }
    try {
      const url = window.location.href;
      const regex = /token=([^&]+)/;
      const match = url.match(regex);
      const token = match ? match[1] : null;

      const response = await resetPassword({ token, password });
      setMessage(<span style={{ color: '#49A3FF' }}>Password successfully updated.</span>); setSeverity("success");
    } catch (error) {
      setMessage(error.response.data.message);
      setSeverity("error");
    }
  };

  const handleLogin = () => {
    navigate("/");
  };

  return (
    <StyledBox sx={{ display: "flex", width: "100%" }}>
      <Split side="left" sx={{ width: "65%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "left",
            px: { xs: 2, sm: 4 },
            maxWidth: "500px", // Increased the maximum width of the text box
            width: "100%", // Ensures it takes full available width
            top: "-20px",
          }}
        >
          {/* Logo and Subheading */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <img
              src={QMentis}
              alt="QMentis Logo"
              style={{
                width: "55%",
                height: "55%",
                objectFit: "contain",
                marginBottom: "-20px",
                marginLeft: "-28px",
              }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontSize: { xs: "15px", sm: "20px" },
                fontWeight: "350",
                mt: 1, // Adjust margin for spacing
              }}
            >
              Revolutionising AI based QE
            </Typography>
          </Box>

          {/* Divider */}
          <Divider
            sx={{
              borderColor: "grey.400",
              borderWidth: 1,
              width: "100%",
              mb: 1, // Added margin for spacing
            }}
          />

          {/* Main Text */}
          <Typography
            variant="h4"
            color="textPrimary"
            sx={{
              fontSize: { xs: "20px", sm: "35px" }, // Increased font size for larger text
              fontWeight: "400",
              lineHeight: 1.5, // Maintains proper line spacing
              mt: 2, // Adds margin at the top
              textAlign: "left", // Ensures left alignment
            }}
          >
            Power your QE journey forward
            <br />
            through{" "}
            <span style={{ color: "#0094FB", fontWeight: "bold" }}>
              QMentisAI™
            </span>{" "}
            - your
            <br />
            gateway into smarter testing
          </Typography>
        </Box>
      </Split>
      <Split side="right" sx={{ width: "35%", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Box sx={{ width: "100%", padding: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <LogoSection>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <LogoSection />
              <QMentisImageTagline />
            </Box>

            <Divider
              sx={{
                my: 2,
                borderColor: "grey.400",
                borderWidth: 1,
                width: "140%",
              }}
            />
          </LogoSection>

          <Typography variant="body2" sx={{ mb: 2, textAlign: "center" }}>
            Set a new password to access your account. Make sure it's at least 8
            characters long and includes a mix of letters and numbers for security.
          </Typography>

          <form>
            <TextField
              fullWidth
              label="New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordActivated(true)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isPasswordActivated && (
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Re-enter Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={() => setIsConfirmPasswordActivated(true)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isConfirmPasswordActivated && (
                      <IconButton
                        onClick={handleToggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <Typography variant="body2" color="red" sx={{ marginTop: "0", textAlign: "center" }}>
              {message}
            </Typography>

            <StyledButton
              variant="contained"
              fullWidth
              disabled={!isButtonEnabled()}
              startIcon={
                <ArrowForwardIcon
                  sx={{ color: isButtonEnabled() ? "#FFFFFF" : "#B0B0B0" }}
                />
              }
              onClick={handlePasswordReset}
              sx={{
                mt: 3,
                mb: 3, // Adds space after the button
                py: 1.5,
                fontSize: { xs: "0.875rem", sm: "1rem" }, // Responsive font size
              }}
            >
              Submit
            </StyledButton>
          </form>

          {severity === "success" && (
            <Link
              sx={{
                mt: 0,
                textAlign: "center",
                display: "block",
                cursor: "pointer",
              }}
              onClick={handleLogin}
            >
              Back to Login
            </Link>
          )}
        </Box>
      </Split>

    </StyledBox>
  );
}

export default ResetPassword;
