import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import CommonAccordion from "../../custom-components/CommonAccordion";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TestCaseFlowDataContext } from '../../main-pages/TestAutomationPage';
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { featureFiles, pseudoCode } from '../../../services/webServices'



const SetPseudoCodePreferences = () => {
  const [expanded, setExpanded] = useState("panel4");

  // Dropdown state
  const [framework, setFramework] = useState("Selenium WebDriver");
  const [programmingLanguage, setProgrammingLanguage] = useState("Java");
  const [codeStyle, setCodeStyle] = useState("Page Object Model (POM)");
  const [libraries, setLibraries] = useState("Placeholder");
  const [testDataSource, setTestDataSource] = useState("Placeholder");
  const location = useLocation();
  const navigate = useNavigate();

  const { featureFiles, addPreferences } = useContext(TestCaseFlowDataContext);
  const pseudoCodeFromContext = useContext(TestCaseFlowDataContext).pseudoCode;
  const [featureFilesData, setFeatiuresFiles] = useState(featureFiles);
  // console.log("featureFilesData====>", featureFilesData)
  const [downloadOpen, setDownloadOpen] = useState(false);
  const setLoading = useContext(LoadingModalContext);

  const defaultAddPreferenceExpanded = addPreferences && pseudoCodeFromContext === "";

  const handlePseudoCode = async () => {
    setLoading(true);

    try {
      console.log(featureFilesData);
      const generatedPseudoCodeGenerated = await pseudoCode(featureFilesData);
      const generatedPseudoCode = generatedPseudoCodeGenerated.data;
      // console.log("generatedPseudoCode", generatedPseudoCode);
      // console.log("TYPE generatedPseudoCode", typeof generatedPseudoCode);

      navigate("/dashboard/test-automation/output", { state: { ...location.state, generatedPseudoCodeData: generatedPseudoCode }, replace: true });
      // setpsudoCodeGenerated(generatedPseudoCode); // Update with the fetched data
    } catch (error) {
      console.log(error);
      // setResponseError(error.response.data["Error"] || "An error occurred.");
    }
    finally {
      setLoading(false);
    }
  };

  const accordionContent = (
    <>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel sx={{ width: '90%', left: '6%' }}>Framework</InputLabel>
        <Select
          value={framework}
          onChange={(e) => setFramework(e.target.value)}
          label="Framework"
          sx={{ width: '90%', margin: 'auto' }} // Reduced width and centered
        >
          <MenuItem value="Selenium WebDriver">Selenium WebDriver</MenuItem>
          <MenuItem value="Robot Framework" disabled>Robot Framework</MenuItem>
          <MenuItem value="GT Framework" disabled>GT Framework</MenuItem>
          <MenuItem value="Cypress" disabled>Cypress</MenuItem>
          <MenuItem value="Playwright" disabled>Playwright</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} disabled>
        <InputLabel sx={{ width: '90%', left: '6%' }}>Programming Language</InputLabel>
        <Select
          value={programmingLanguage}
          onChange={(e) => setProgrammingLanguage(e.target.value)}
          label="Programming Language"
          sx={{ width: '90%', margin: 'auto' }}
        >
          <MenuItem value="Java">Java</MenuItem>
          <MenuItem value="Python">Python</MenuItem>
          <MenuItem value="JavaScript">JavaScript</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} disabled>
        <InputLabel sx={{ width: '90%', left: '6%' }}>Code Style Preference</InputLabel>
        <Select
          value={codeStyle}
          onChange={(e) => setCodeStyle(e.target.value)}
          label="Code Style Preference"
          sx={{ width: '90%', margin: 'auto' }}
        >
          <MenuItem value="Page Object Model (POM)">
            Page Object Model (POM)
          </MenuItem>
          <MenuItem value="Behavior Driven Development (BDD)">
            Behavior Driven Development (BDD)
          </MenuItem>
          <MenuItem value="Keyword Driven">Keyword Driven</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }} disabled>
        <InputLabel sx={{ width: '90%', left: '6%' }}>Predefined Libraries or Methods (Optional)</InputLabel>
        <Select
          value={libraries}
          onChange={(e) => setLibraries(e.target.value)}
          label="Predefined Libraries or Methods (Optional)"
          sx={{ width: '90%', margin: 'auto' }}
        >
          <MenuItem value="Placeholder">Select...</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth disabled sx={{ mb: 4 }}>
        <InputLabel sx={{ width: '90%', left: '6%' }}>Test Data Source (Optional)</InputLabel>
        <Select
          value={testDataSource}
          onChange={(e) => setTestDataSource(e.target.value)}
          label="Test Data Source (Optional)"
          sx={{ width: '90%', margin: 'auto' }}
        >
          <MenuItem value="Placeholder">Select...</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  return (
    <Box>
      <CommonAccordion
        summary={
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Typography variant="h7" sx={{ flexGrow: 1 }}>
              Preferences for Pseudo Code
            </Typography>
            <CheckCircleIcon
              sx={{ color: "#49A3FF", fontSize: "24px" }}
            />
          </Box>
        }
        details={accordionContent}
        buttons={[
          {
            label: "Generate Pseudo Code",
            variant: "contained",
            onClick: handlePseudoCode,
            sx: { float: "right", textTransform: "none", mb: 2 },
            startIcon: <SendSharpIcon />,
          },
        ]}
        defaultExpanded={defaultAddPreferenceExpanded}
      />
    </Box>
  );
};

export default SetPseudoCodePreferences;
