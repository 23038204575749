import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { display, styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { login } from "../../services/webServices";
import { useNavigate } from "react-router-dom";
import { ReactComponent as QMentisImageTagline } from "../../images/QMentisAITagline.svg";
import Divider from "@mui/material/Divider";
import QMentis from "../../images/QMentis.svg";
 
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: "100vh", // Full height of the viewport
  width: "100%", // Full width of the viewport
  backgroundColor: "#FFFFFF",
  overflow: "hidden", // Prevent scrollbars
  [theme.breakpoints.down("md")]: {
    flexDirection: "column", // Switch to column on smaller screens
  },
}));
 
const Split = styled(Box)(({ theme, side }) => ({
  width: side === "left" ? "65%" : "35%", // Adjusted width percentages
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background:
    side === "left"
      ? `linear-gradient(-135deg, rgba(0, 116, 255, 0.2), rgba(226, 78, 94, 0.2))`
      : "#F6F3F2",
  [theme.breakpoints.down("md")]: {
    width: "100%", // 100% width on small screens
    height: side === "left" ? "30%" : "70%", // Adjusted height ratios on smaller screens
  },
}));
 
const LoginCard = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px rgba(0, 0, 0, 0.15)",
  display: "flex",
  flexDirection: "column",
  padding: "40px",
  width: "90%",
  maxWidth: "400px",
  height: "auto",
  maxHeight: "90%",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    maxHeight: "none",
    borderRadius: 0,
    boxShadow: "none",
  },
}));
 
const LogoSection = styled(Box)(() => ({
  alignSelf: "left",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  marginBottom: "16px",
}));
 
const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  width: "100%", // Take full width on smaller screens
  maxWidth: "200px", // Limit max width
  borderRadius: "4px",
  backgroundColor: "#0094FB",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#0077CC",
  },
}));
 
const FooterSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "16px",
  marginBottom: "30px", // Space out the footer a bit more
}));
 
function Login() {
  const [formValues, setFormValues] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState("");
  const [severity, setSeverity] = useState("error");
 
  const navigate = useNavigate();
 
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  // Update input values and clear errors dynamically
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormValues({
      ...formValues,
      [id]: value,
    });
    // Clear errors for the current field if it has a value
    setFormErrors({
      ...formErrors,
      [id]: value.trim() ? "" : formErrors[id],
    });
  };
 
  const handleLogin = async (event) => {
    event.preventDefault();
    const username = document.getElementById("email").value;
    const password = document.getElementById("password").value;
 
    const errors = {};
    if (!username.trim()) {
      errors["username"] = "Please enter email address.";
    }
    if (!password.trim()) {
      errors["password"] = "Please enter password.";
    }
 
    setFormErrors(errors);
 
    if (Object.keys(errors).length > 0) {
      // console.log(formErrors);
      return;
    }
 
    try {
      await login({ username, password });
      setSeverity("success");
      navigate("/landingpage");
    } catch (error) {
      console.log("Error", error);
      setSeverity("error");
      setError("Login Failed. Please enter valid credentials.");
    }
  };
 
  return (
    <StyledBox sx={{ display: "flex", width: "100%" }}>
      <Split side="left" sx={{ width: "65%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "left",
            px: { xs: 2, sm: 4 },
            maxWidth: "500px", // Increased the maximum width of the text box
            width: "100%", // Ensures it takes full available width
            top: "-20px",
          }}
        >
          {/* Logo and Subheading */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <img
              src={QMentis}
              alt="QMentis Logo"
              style={{
                width: "55%",
                height: "55%",
                objectFit: "contain",
                marginBottom: "-20px",
                marginLeft: "-28px",
              }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontSize: { xs: "15px", sm: "20px" }, 
                fontWeight: "350",
                mt: 1, // Adjust margin for spacing
              }}
            >
              Revolutionising AI based QE
            </Typography>
          </Box>
 
          {/* Divider */}
          <Divider
            sx={{
              borderColor: "grey.400",
              borderWidth: 1,
              width: "100%",
              mb: 1, // Added margin for spacing
            }}
          />
 
          {/* Main Text */}
          <Typography
            variant="h4"
            color="textPrimary"
            sx={{
              fontSize: { xs: "20px", sm: "35px" }, // Increased font size for larger text
              fontWeight: "400",
              lineHeight: 1.5, // Maintains proper line spacing
              mt: 2, // Adds margin at the top
              textAlign: "left", // Ensures left alignment
            }}
          >
            Power your QE journey forward
            <br />
            through{" "}
            <span style={{ color: "#0094FB", fontWeight: "bold" }}>
              QMentisAI™
            </span>{" "}
            - your
            <br />
            gateway into smarter testing
          </Typography>
        </Box>
      </Split>
 
      <Split side="right" sx={{ width: "35%" }}>
        <Box
          sx={{
            backgroundColor: "white", // Set background color to white
            display: "flex", // Use flexbox layout
            flexDirection: "column", // Arrange content vertically
            justifyContent: "flex-start", // Align content to the top of the container
            alignItems: "center", // Horizontally center content
            padding: 3, // Add some padding around the content
            height: "100vh", // Keep full viewport height
            width: "100%", // Take full width of the Split section
            marginTop: "auto", // Push the box down
            paddingTop: 10, // Optional: add extra top padding if needed
          }}
        >
 
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative", // Enables positioning adjustments
              left: "-22px",
              // marginTop: 4, // Move the inner box down a little bit
            }}
          >
 
            <LogoSection />
            <QMentisImageTagline />
          </Box>
 
          <Divider
            sx={{
              my: 1,
              borderColor: "grey.400",
              borderWidth: 1,
              width: "80%",
            }}
          />
 
          {/* Container for the texts and form to ensure alignment */}
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Typography
              variant="h5"
              fontWeight={450}
              mt={0} // Remove margin to align with form
              fontSize={{ xs: "25px", sm: "25px" }}
              textAlign="left" // Align text to the left
            >
              Welcome back!
            </Typography>
 
            <Typography
              variant="body2"
              fontSize="12px"
              textAlign="left" // Align text to the left
              color="textSecondary"
            >
              Please enter your details
            </Typography>
          </Box>
 
          {/* Form Section */}
          <form onSubmit={handleLogin} style={{ width: "80%" }}>
            {/* Email Field */}
            <TextField
              label="Email ID"
              id="email"
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              error={!!formErrors.username}
              helperText={formErrors.username}
              onChange={handleInputChange}
            />
 
            <TextField
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
              error={!!formErrors.password}
              helperText={formErrors.password}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
 
            <Typography color={severity === "error" ? "error" : "primary"}>
              {error}
            </Typography>
 
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#49A3FF",
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="body2">Keep me signed in</Typography>
                }
                sx={{ mt: 1 }}
              />
 
              {/* Login Button */}
              {/* <Button sx={{ width: '12px', height: '40px' }}> */}
              <StyledButton
                type="submit"
                variant="contained"
                startIcon={<ArrowForwardIcon />}
                sx={{
                  width: "102px",
                  textTransform: "none",
                  height: "40px",
                  ml: 5,
                  backgroundColor: "#49A3FF",
                }}
              >
                Login
              </StyledButton>
              {/* </Button> */}

            </Box>
          </form>
 
          <Box
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Link
              variant="body2"
              sx={{ mt: 1, fontSize: "14px", cursor: "pointer" }}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot your password?
            </Link>
          </Box>
 
          <FooterSection sx={{ position: "relative" }}>
            <Typography
              variant="caption"
              fontSize="12px"
              textAlign="center"
              sx={{ position: "relative", bottom: "-60px" }}
            >
              By signing in, you agree to our{" "}
              <Link href="#" fontSize="12px">
                Terms of Use
              </Link>{" "}
              &{" "}
              <Link href="#" fontSize="12px">
                Privacy Policy
              </Link>
            </Typography>
          </FooterSection>
        </Box>
      </Split>
    </StyledBox>
  );
}
 
export default Login;
 
 