import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  Container,
  TextField,
  Divider,
  Grid2,
  Grid,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import { ReactComponent as PenIcon } from "../../../images/penIcon.svg";
import CommonDownload from "../../custom-components/CommonDownload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FullscreenToggle from "../../custom-components/FullScreenToggle";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ReactComponent as ExportButton } from "../../../images/exportButton.svg";

const itemsPerPage = 10;
const RenderMultipleInputBugDetils = ({
  items,
  page,
  expandedStates,
  handlers,
  setPage,
}) => {
  // State to hold updated titles
  const [titles, setTitles] = useState(items.map((item) => item.title || ""));

  const currentItems = items.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const isAnyExpanded = expandedStates.some((expanded) => expanded);

  return (
    <AccordionDetails>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Button
            size="small"
            sx={{ textTransform: "none" }}
            onClick={
              isAnyExpanded
                ? handlers.handleCollapseAll
                : handlers.handleExpandAll
            }
          >
            {isAnyExpanded ? "Collapse All" : "Expand All"}
          </Button>
        </Box>
      </Box>

      {currentItems.map((item, index) => {
        const globalIndex = (page - 1) * itemsPerPage + index;

        return (
          <Accordion
            key={globalIndex}
            expanded={expandedStates[globalIndex]}
            onChange={handlers.handleChange(globalIndex)}
            sx={{ width: "100%", mb: 0 }}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0 16px",
                backgroundColor: "white",
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {expandedStates[globalIndex] ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
              <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                <Typography variant="body2">{titles[globalIndex]}</Typography>{" "}
              </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ paddingLeft: "48px" }}>
              <Grid container spacing={2}>
                {item.title && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      Title
                    </Typography>
                    <Typography variant="body2">{item.title}</Typography>
                  </Grid>
                )}

                {item.description && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      Description
                    </Typography>
                    <Typography variant="body2">{item.description}</Typography>
                  </Grid>
                )}

                {item.environment && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      Environment Details
                    </Typography>
                    <Typography variant="body2">{item.environment}</Typography>
                  </Grid>
                )}

                {item.priority && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      Priority
                    </Typography>
                    <Typography variant="body2">{item.priority}</Typography>
                  </Grid>
                )}
                {/* 
                    {bugReport.stepsToReproduce && (
                      <Grid2 item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Steps to Reproduce
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.stepsToReproduce}
                        </Typography>
                      </Grid2>
                    )}

                    {bugReport.expectedResult && (
                      <Grid2 item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Expected Result
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.expectedResult}
                        </Typography>
                      </Grid2>
                    )}

                    {bugReport.actualResult && (
                      <Grid2 item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Actual Result
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.actualResult}
                        </Typography>
                      </Grid2>
                    )}

                    {bugReport.additionalInfo && (
                      <Grid2 item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Additional Information
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.additionalInfo}
                        </Typography>
                      </Grid2>
                    )}

                    {bugReport.impact && (
                      <Grid2 item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Impact
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.impact}
                        </Typography>
                      </Grid2>
                    )} */}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={Math.ceil(items.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </Box>
    </AccordionDetails>
  );
};

const EnhancedBugDetailsForm = ({
  bugDetails,
  editable,
  onSave,
  onCancel,
  index,
  accordianData,
  setAccordianData,
}) => {
  const [bugTitle, setBugTitle] = useState(accordianData[index]["Title"]);
  const [bugDescription, setBugDescription] = useState(
    accordianData[index]["Description"]
  );
  const [severityLevel, setSeverityLevel] = useState(
    accordianData[index]["Severity_Level"]
  );
  const [stepsToReproduce, setStepsToReproduce] = useState(
    accordianData[index]["Steps_To_Reproduce"]
  );
  const [expectedBehaviour, setExpectedBehaviour] = useState(
    accordianData[index]["Expected_Vs_Actual_Behavior"].Expected
  );
  const [actualBehaviour, setActualBehaviour] = useState(
    accordianData[index]["Expected_Vs_Actual_Behavior"].Actual
  );
  const [workaround, setWorkAround] = useState(
    accordianData[index]["Workaround"]
  );
  const [evidenceTBD, setEvidenceTBD] = useState(
    accordianData[index]["Evidences_TBD"]
  );
  const [businessImpact, setBusinessImpact] = useState(
    accordianData[index]["Business_Impact"]
  );
  const [technicalImpact, setTechnicalImpact] = useState(
    accordianData[index]["Technical_Impact"]
  );
  const [possibleCauses, setPossibleCauses] = useState(
    accordianData[index]["Possible_Causes_For_Defect_Leakage"]
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    accordianData[index]["Additional_Information"]
  );
  const [validationAndVerification, setValidationAndVerification] = useState(
    accordianData[index]["Validation_And_Verification_Retesting"]
  );
  const [contextUseCases, setContextUseCases] = useState(
    accordianData[index]["Contextual_Use_Cases_And_Scenarios"]
  );
  const [pcDevTeam, setPCDevTeam] = useState(
    accordianData[index]["Possible_Causes_For_Dev_Team"]
  );

  const [rcFixesDevTeam, setRCFixedDevTeam] = useState(
    accordianData[index]["Recommended_Fixes_For_Dev_Team"]
  );
  const [similarBugs, setSimilarBugs] = useState(
    accordianData[index]["Similar_Bugs"]
  );
  const [idealTestCase, setIdealTestCases] = useState(
    accordianData[index]["Ideal_Test_Case"]
  );
  const renderField = (key, value) => {
    // Helper function to format titles by removing underscores and capitalizing words
    const formatTitle = (title) =>
      title
        .replace(/_/g, " ") // Replace underscores with spaces
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" ");

    const formattedKey = formatTitle(key);

    if (Array.isArray(value)) {
      // Render arrays as a list of items
      return (
        <Box key={key} sx={{ mt: 1 }}>
          <Typography variant="body2">{formattedKey}:</Typography>
          {value.map((item, index) => (
            <Typography variant="body2" key={index} sx={{ ml: 0 }}>
              {item}
            </Typography>
          ))}
        </Box>
      );
    } else if (typeof value === "object" && value !== null) {
      // Recursively render nested objects
      return (
        <Box key={key} sx={{ mt: 1 }}>
          <Typography variant="body2">{formattedKey}</Typography>
          {Object.entries(value).map(([nestedKey, nestedValue]) =>
            renderField(nestedKey, nestedValue)
          )}
        </Box>
      );
    } else {
      // Render primitive values
      return (
        <Box key={key} sx={{ mt: 1 }}>
          <Typography variant="body2">
            {formattedKey}: {value}
          </Typography>
        </Box>
      );
    }
  };

  // Function to remove underscores and format titles
  const formatTitle = (title) =>
    title
      .replace(/_/g, " ") // Replace underscores with spaces
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ");

  // Function to convert the structured data to a formatted string
  const convertToString = (data) => {
    const result = [];
    for (const [key, value] of Object.entries(data)) {
      const formattedKey = formatTitle(key); // Format the title

      if (Array.isArray(value)) {
        // Handle arrays without adding indices
        const arrayContent = value
          .map(
            (item) =>
              typeof item === "object" && item !== null
                ? convertToString(item) // Recursively process objects in the array
                : item // Handle primitive values directly
          )
          .join("\n"); // Join array items with newlines for better readability
        result.push(`${formattedKey}:\n${arrayContent}`);
      } else if (typeof value === "object" && value !== null) {
        // Recursively handle nested objects
        result.push(`${formattedKey}: ${convertToString(value)}`);
      } else {
        // Append key-value pairs as a string
        result.push(`${formattedKey}: ${value}`);
      }
    }
    return result.join("\n"); // Join all lines with newlines
  };

  // Generate a single formatted string for the input field
  const formattedText = convertToString(idealTestCase);

  // console.log("formattedText", formattedText);

  const handleSave = () => {
    const updatedValues = {
      title: bugTitle,
      //   description: bugDescription,
      //   environment: bugEnvironment,
    };
    onSave(updatedValues);
    setAccordianData((prevState) => {
      const savedData = [...prevState];
      const savedBug = savedData[index];
      savedBug["Title"] = bugTitle;
      savedBug["Description"] = bugDescription;
      savedBug["Severity_Level"] = severityLevel;
      savedBug["Steps_To_Reproduce"] = stepsToReproduce;
      savedBug["Expected_Vs_Actual_Behavior"].Expected = expectedBehaviour;
      savedBug["Expected_Vs_Actual_Behavior"].Actual = actualBehaviour;
      savedBug["Workaround"] = workaround;
      savedBug["Evidences_TBD"] = evidenceTBD;
      savedBug["Business_Impact"] = businessImpact;
      savedBug["Technical_Impact"] = technicalImpact;
      savedBug["Possible_Causes_For_Defect_Leakage"] = possibleCauses;
      savedBug["Additional_Information"] = additionalInfo;
      savedBug["Validation_And_Verification_Retesting"] =
        validationAndVerification;
      savedBug["Contextual_Use_Cases_And_Scenarios"] = contextUseCases;
      savedBug["Possible_Causes_For_Dev_Team"] = pcDevTeam;
      savedBug["Recommended_Fixes_For_Dev_Team"] = rcFixesDevTeam;
      savedBug["Similar_Bugs"] = similarBugs;
      savedBug["Ideal_Test_Case"] = idealTestCase;
      savedData[index] = savedBug;
      return savedData;
    });
  };

  // Function to handle Cancel action
  const handleCancel = () => {
    setBugTitle(accordianData[index]["Title"]);
    setBugDescription(accordianData[index]["Description"]);
    setSeverityLevel(accordianData[index]["Severity_Level"]);
    setStepsToReproduce(accordianData[index]["Steps_To_Reproduce"]);
    setExpectedBehaviour(
      accordianData[index]["Expected_Vs_Actual_Behavior"].Expected
    );
    setActualBehaviour(
      accordianData[index]["Expected_Vs_Actual_Behavior"].Actual
    );
    setWorkAround(accordianData[index]["Workaround"]);
    setEvidenceTBD(accordianData[index]["Evidences_TBD"]);
    setBusinessImpact(accordianData[index]["Business_Impact"]);
    setTechnicalImpact(accordianData[index]["Technical_Impact"]);
    setPossibleCauses(
      accordianData[index]["Possible_Causes_For_Defect_Leakage"]
    );
    setAdditionalInfo(accordianData[index]["Additional_Information"]);
    setValidationAndVerification(
      accordianData[index]["Validation_And_Verification_Retesting"]
    );
    setContextUseCases(
      accordianData[index]["Contextual_Use_Cases_And_Scenarios"]
    );
    setPCDevTeam(accordianData[index]["Possible_Causes_For_Dev_Team"]);
    setRCFixedDevTeam(accordianData[index]["Recommended_Fixes_For_Dev_Team"]);
    setSimilarBugs(accordianData[index]["Similar_Bugs"]);
    setIdealTestCases(accordianData[index]["Ideal_Test_Case"]);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Box p={1}>
      {!editable && (
        <Grid container spacing={2}>
          {bugTitle && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Title
              </Typography>
              <Typography variant="body2">{bugTitle}</Typography>
            </Grid>
          )}

          {bugDescription && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Description
              </Typography>
              <Typography variant="body2">{bugDescription}</Typography>
            </Grid>
          )}

          {stepsToReproduce && stepsToReproduce.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Steps to Reproduce
              </Typography>
              <Box sx={{ mt: 1 }}>
                {stepsToReproduce.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          {expectedBehaviour && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Expected Result
              </Typography>
              <Typography variant="body2">{expectedBehaviour}</Typography>
            </Grid>
          )}

          {actualBehaviour && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Actual Result
              </Typography>
              <Typography variant="body2">{actualBehaviour}</Typography>
            </Grid>
          )}

          {severityLevel && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Severity Level
              </Typography>
              <Typography variant="body2">{severityLevel}</Typography>
            </Grid>
          )}

          {similarBugs && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Similar Bugs
              </Typography>
              <Typography variant="body2">{similarBugs}</Typography>
            </Grid>
          )}

          {/* Evidences_TBD */}
          {evidenceTBD && evidenceTBD.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Evidence
              </Typography>
              <Box sx={{ mt: 1 }}>
                {evidenceTBD.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          {/* Business Impact */}
          {businessImpact && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Business Impact
              </Typography>
              <Typography variant="body2">{businessImpact}</Typography>
            </Grid>
          )}

          {/* Technical Impact */}
          {technicalImpact && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Technical Impact
              </Typography>
              <Typography variant="body2">{technicalImpact}</Typography>
            </Grid>
          )}

          {/* Workaround */}
          {workaround && workaround.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Workaround
              </Typography>
              <Box sx={{ mt: 1 }}>
                {workaround.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          {/* Possible Causes for Defect Leakage */}
          {possibleCauses && possibleCauses.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Possible Causes for Defect Leakage
              </Typography>
              <Box sx={{ mt: 1 }}>
                {possibleCauses.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          {additionalInfo && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Additional Information
              </Typography>
              <Typography variant="body2">{additionalInfo}</Typography>
            </Grid>
          )}

          {validationAndVerification &&
            validationAndVerification.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  Validation and Verification Retesting
                </Typography>
                <Box sx={{ mt: 1 }}>
                  {validationAndVerification.map((step, index) => (
                    <Typography variant="body2" key={index}>
                      {step}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            )}

          {/* Contextual Use Cases and Scenarios */}
          {contextUseCases && contextUseCases.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Contextual Use Cases and Scenarios
              </Typography>
              <Box sx={{ mt: 1 }}>
                {contextUseCases.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}
          {pcDevTeam && pcDevTeam.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Possible Causes for Dev Team
              </Typography>
              <Box sx={{ mt: 1 }}>
                {pcDevTeam.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          {/* Recommended Fixes For Dev Team */}
          {rcFixesDevTeam && rcFixesDevTeam.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Recommended Fixes for Dev Team
              </Typography>
              <Box sx={{ mt: 1 }}>
                {rcFixesDevTeam.map((step, index) => (
                  <Typography variant="body2" key={index}>
                    {step}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          {/* Ideal Test Case */}
          {idealTestCase && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Ideal Test Case
              </Typography>
              {Object.entries(idealTestCase).map(([key, value]) =>
                renderField(key, value)
              )}
            </Grid>
          )}
        </Grid>
      )}
      {editable && (
        <Grid2 container spacing={2}>
          {/* Title */}
          <TextField
            fullWidth
            label="Title"
            value={bugTitle}
            onChange={(e) => setBugTitle(e.target.value)}
            placeholder="Enter the Bug Title"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Description */}
          <TextField
            fullWidth
            label="Description"
            value={bugDescription}
            onChange={(e) => setBugDescription(e.target.value)}
            placeholder="Enter the Bug Description"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Steps to Reproduce */}

          <TextField
            fullWidth
            label="Steps to Reproduce"
            value={stepsToReproduce.join("\n") || ""}
            onChange={(e) => setStepsToReproduce(e.target.value.split("\n"))}
            placeholder="Enter steps to reproduce the bug"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          <TextField
            fullWidth
            label="Expected Behavior"
            value={expectedBehaviour}
            onChange={(e) => setExpectedBehaviour(e.target.value)}
            placeholder="Describe the expected behavior"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          <TextField
            fullWidth
            label="Actual Behavior"
            value={actualBehaviour || ""}
            onChange={(e) => setActualBehaviour(e.target.value)}
            placeholder="Describe the actual behavior"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          <TextField
            fullWidth
            label="Severity Level"
            value={severityLevel}
            onChange={(e) => setSeverityLevel(e.target.value)}
            placeholder="Severity Level"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          <TextField
            fullWidth
            label="Similar Bugs"
            value={similarBugs}
            onChange={(e) => setSimilarBugs(e.target.value)}
            placeholder="Similar Bugs"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Evidence */}
          <TextField
            fullWidth
            label="Evidence"
            value={evidenceTBD.join("\n") || ""}
            onChange={(e) => setEvidenceTBD(e.target.value.split("\n"))}
            placeholder="Enter any evidence for the bug"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Business Impact */}
          <TextField
            fullWidth
            label="Business Impact"
            value={businessImpact || ""}
            onChange={(e) => setBusinessImpact(e.target.value)}
            placeholder="Describe the business impact"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Technical Impact */}
          <TextField
            fullWidth
            label="Technical Impact"
            value={technicalImpact || ""}
            onChange={(e) => setTechnicalImpact(e.target.value)}
            placeholder="Describe the technical impact"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Workaround */}
          <TextField
            fullWidth
            label="Workaround"
            value={workaround.join("\n") || ""}
            onChange={(e) => setWorkAround(e.target.value.split("\n"))}
            placeholder="Enter any workaround for the bug"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Possible Causes for Defect Leakage */}
          <TextField
            fullWidth
            label="Possible Causes for Defect Leakage"
            value={possibleCauses.join("\n") || ""}
            onChange={(e) => setPossibleCauses(e.target.value.split("\n"))}
            placeholder="Describe possible causes for defect leakage"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Additional Information */}
          <TextField
            fullWidth
            label="Additional Information"
            value={additionalInfo || ""}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Enter any additional information"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Validation and Verification Retesting */}
          <TextField
            fullWidth
            label="Validation and Verification Retesting"
            value={validationAndVerification.join("\n") || ""}
            onChange={(e) =>
              setValidationAndVerification(e.target.value.split("\n"))
            }
            placeholder="Enter validation and verification retesting steps"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Contextual Use Cases and Scenarios */}
          <TextField
            fullWidth
            label="Contextual Use Cases and Scenarios"
            value={contextUseCases.join("\n") || ""}
            onChange={(e) => setContextUseCases(e.target.value.split("\n"))}
            placeholder="Describe contextual use cases and scenarios"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />

          {/* Possible_Causes_For_Dev_Team */}
          <TextField
            fullWidth
            label="Possible Causes For Dev Team"
            value={pcDevTeam.join("\n") || ""}
            onChange={(e) => setPCDevTeam(e.target.value.split("\n"))}
            placeholder="Describe contextual use cases and scenarios"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />
          {/* Recommended_Fixes_For_Dev_Team */}
          <TextField
            fullWidth
            label="Recommended Fixes For Dev Team"
            value={rcFixesDevTeam.join("\n") || ""}
            onChange={(e) => setRCFixedDevTeam(e.target.value.split("\n"))}
            placeholder="Describe contextual use cases and scenarios"
            variant="outlined"
            multiline
            minRows={3}
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
          />
          {/* Ideal Test Case Input*/}

          <TextField
            fullWidth
            label="Ideal Test Case"
            value={formattedText}
            onChange={(e) => setIdealTestCases(e.target.value)}
            multiline
            minRows={8}
            placeholder="Enter the Ideal Test Case"
            variant="outlined"
            size="small"
            disabled={!editable}
          />
        </Grid2>
      )}
      {editable && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{ mr: 1, textTransform: "none" }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

const EnhancedMultipleOutputBugs = ({
  items,
  page,
  checkedStates,
  expandedStates,
  handlers,
  setPage,
  showEditIcon = false,
  isEnhanced = false,
  accordianData,
  setAccordianData,
  selectedAccordianData,
  setSelectedAccordianData,
}) => {
  // State to hold updated titles
  const [titles, setTitles] = useState(items.map((item) => item.Title || ""));
  const [editingStates, setEditingStates] = useState(
    Array(items.length).fill(false)
  );
  const currentItems = items.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const isAnySelected = checkedStates.some((checked) => checked);
  const isAnyExpanded = expandedStates.some((expanded) => expanded);

  const handleTitleUpdate = (index, newTitle) => {
    setTitles((prevTitles) => {
      const newTitles = [...prevTitles];
      newTitles[index] = newTitle;
      return newTitles;
    });
  };

  const handleThumbClick = (thumbType) => {
    // Toggle thumbs based on the thumbType (like or dislike)
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    // Handle copy action (you can implement actual copying logic here)
    console.log("Content copied!"); // Placeholder for copy logic
    setIsCopied(true);
    // Reset the copied status after 2 seconds (optional)
    setTimeout(() => setIsCopied(false), 2000);
  };

  const [activeThumb, setActiveThumb] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  return (
    <AccordionDetails>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Button
            size="small"
            sx={{ textTransform: "none", color: "#49A3FF" }}
            onClick={
              isAnySelected
                ? handlers.handleUnselectAll
                : handlers.handleSelectAll
            }
          >
            {isAnySelected ? "Unselect All" : "Select All"}
          </Button>
          <Button
            size="small"
            sx={{ textTransform: "none", color: "#49A3FF" }}
            onClick={
              isAnyExpanded
                ? handlers.handleCollapseAll
                : handlers.handleExpandAll
            }
          >
            {isAnyExpanded ? "Collapse All" : "Expand All"}
          </Button>
        </Box>
      </Box>

      {currentItems.map((item, index) => {
        const globalIndex = (page - 1) * itemsPerPage + index;
        return (
          <Accordion
            key={globalIndex}
            expanded={expandedStates[globalIndex]}
            onChange={handlers.handleChange(globalIndex)}
            sx={{ width: "100%", mb: 0 }}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0 16px",
                backgroundColor: checkedStates[globalIndex]
                  ? "#d6eaf8"
                  : "white",
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {expandedStates[globalIndex] ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
              <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                <Checkbox
                  size="small"
                  checked={checkedStates[globalIndex]}
                  onChange={(event) =>
                    handlers.handleCheckboxChange(globalIndex, event)
                  }
                  onClick={(event) => event.stopPropagation()}
                />
                <Typography variant="body2">{titles[globalIndex]}</Typography>{" "}
                {/* Use titles state here */}
                {showEditIcon && (
                  <Tooltip title="Click to edit" placement="right" arrow>
                    <Button
                      variant="text"
                      sx={{ ml: 1, minWidth: 0, padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingStates((prev) => {
                          const newEditingStates = [...prev];
                          newEditingStates[globalIndex] =
                            !newEditingStates[globalIndex];
                          return newEditingStates;
                        });
                      }}
                    >
                      <PenIcon sx={{ fontSize: 18, color: "grey.600" }} />
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: "48px" }}>
              <EnhancedBugDetailsForm
                bugDetails={item}
                editable={editingStates[globalIndex]}
                onSave={(updatedValues) => {
                  handleTitleUpdate(globalIndex, updatedValues.title); // Update title in state
                  setEditingStates((prev) => {
                    const newEditingStates = [...prev];
                    newEditingStates[globalIndex] = false;
                    return newEditingStates;
                  });
                }}
                onCancel={() => {
                  setEditingStates((prev) => {
                    const newEditingStates = [...prev];
                    newEditingStates[globalIndex] = false;
                    return newEditingStates;
                  });
                }}
                index={index}
                accordianData={accordianData}
                setAccordianData={setAccordianData}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={Math.ceil(items.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </Box>
    </AccordionDetails>
  );
};

const MultipleOutputBugReport = () => {
  const location = useLocation();
  const [bugDetails, setBugDetails] = useState(location.state); // This will contain the data passed via navigate from BugReport Input Page

  const [selectedBugDetails, setSelectedBugDetails] = useState([
    ...bugDetails.multipleOutputs,
  ]);

  const [enhancedBugDescriptions, setEnhancedBugDescriptions] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  const [bugDetailsExpanded, setBugDetailsExpanded] = useState(false);
  const [enhancedDescriptionExpanded, setEnhancedDescriptionExpanded] =
    useState(true);
  const [showEnhancedDescription, setShowEnhancedDescription] = useState(false);

  const [bugDetailsPage, setBugDetailsPage] = useState(1);
  const [enhancedPage, setEnhancedPage] = useState(1);

  const [bugDetailsCheckedStates, setBugDetailsCheckedStates] = useState();
  selectedBugDetails.map(
    (eachSelectedDetail, bugIndex) =>
      JSON.stringify(eachSelectedDetail) ===
      JSON.stringify(bugDetails[bugIndex])
  );
  const [enhancedCheckedStates, setEnhancedCheckedStates] = useState(
    Array(100).fill(false)
  );
  const [bugDetailsExpandedStates, setBugDetailsExpandedStates] = useState(
    Array(100).fill(false)
  );
  const [enhancedExpandedStates, setEnhancedExpandedStates] = useState(
    Array(100).fill(false)
  );

  // Map the entire object instead of just the title
  const bugDetailsItems = Array.from(
    { length: bugDetails.multipleInputs.length },
    (_, index) => bugDetails.multipleInputs[index] // This will include both title and description
  );

  const enhancedDescriptionItems = Array.from(
    { length: bugDetails.multipleOutputs.length },
    (_, index) => bugDetails.multipleOutputs[index] // This will include both title and description
  );

  const createAccordionHandlers = (
    setCheckedStates,
    setExpandedStates,
    checkedStates,
    expandedStates,
    accordianData,
    setAccordianData,
    selectedAccordianData,
    setSelectedAccordianData
  ) => ({
    handleChange: (index) => (event, isExpanded) => {
      setExpandedStates((prev) => {
        const newStates = [...prev];
        newStates[index] = isExpanded;
        return newStates;
      });
    },

    handleCheckboxChange: (index, event) => {
      event.stopPropagation();
      setCheckedStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = !newStates[index];
        return newStates;
      });
      const updatedSelection = [...selectedAccordianData];
      if (event.target.checked) {
        updatedSelection[index] = accordianData[index];
      } else {
        updatedSelection[index] = null;
      }
      setSelectedAccordianData(updatedSelection);
    },

    handleSelectAll: () => {
      setCheckedStates((prevStates) => {
        const isAnyUnchecked = prevStates.some((state) => !state);
        return Array(prevStates.length).fill(isAnyUnchecked);
      });
      setSelectedAccordianData([...accordianData]);
    },

    handleUnselectAll: () => {
      setCheckedStates((prev) => Array(prev.length).fill(false));
      setSelectedAccordianData(Array(accordianData.length).fill(null));
    },

    handleExpandAll: () => {
      setExpandedStates((prev) => Array(prev.length).fill(true));
    },

    handleCollapseAll: () => {
      setExpandedStates((prev) => Array(prev.length).fill(false));
    },
  });

  const bugDetailsHandlers = createAccordionHandlers(
    setBugDetailsCheckedStates,
    setBugDetailsExpandedStates,
    bugDetailsCheckedStates,
    bugDetailsExpandedStates,
    bugDetails,
    setBugDetails
    // selectedBugDetails,
    // setSelectedBugDetails
  );

  const enhancedHandlers = createAccordionHandlers(
    setEnhancedCheckedStates,
    setEnhancedExpandedStates,
    enhancedCheckedStates,
    enhancedExpandedStates,
    enhancedDescriptionItems,
    setBugDetails,
    selectedBugDetails,
    setSelectedBugDetails
  );

  return (
    <Box
      className="bug-report-container"
      sx={{ bgcolor: "#F6F3F2", minHeight: "100vh", p: 2 }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  width: "100%",
                  color: "var(--Schemes-Secondary, #000)",
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AutomateIcon sx={{ mr: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    Generate detailed and insightful bug reports with ease
                  </Typography>
                </Box>
                <FullscreenToggle />
              </Box>
            </Box>

            {/* //Multiple Input Bug Details Accordion */}
            <Accordion
              expanded={bugDetailsExpanded}
              onChange={() => setBugDetailsExpanded(!bugDetailsExpanded)}
              sx={{
                mb: 2,
                bgcolor: "white",
                borderRadius: 3,
                boxShadow: 1,
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 16px",
                  "& .MuiAccordionSummary-content": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {bugDetailsExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, ml: 1, fontSize: "0.95rem" }}
                >
                  Bug Details ({bugDetailsItems.length})
                </Typography>
                {bugDetailsExpanded ? (
                  ""
                ) : (
                  <CheckCircleIcon sx={{ color: "#49A3FF" }} />
                )}
              </AccordionSummary>
              {/* Multiple Inputs of Bug Details */}
              <RenderMultipleInputBugDetils
                items={bugDetailsItems}
                page={bugDetailsPage}
                expandedStates={bugDetailsExpandedStates}
                handlers={bugDetailsHandlers}
                setPage={setBugDetailsPage}
                isEnhanced={false}
              />
            </Accordion>

            {/* {showEnhancedDescription && ( */}
            <Accordion
              expanded={enhancedDescriptionExpanded}
              onChange={() =>
                setEnhancedDescriptionExpanded(!enhancedDescriptionExpanded)
              }
              sx={{
                mb: 2,
                bgcolor: "white",
                borderRadius: 3,
                boxShadow: 1,
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 16px",
                  "& .MuiAccordionSummary-content": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {enhancedDescriptionExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, ml: 1, fontSize: "0.95rem" }}
                >
                  Enhanced Bug Descriptions ({enhancedDescriptionItems.length})
                </Typography>
                {enhancedDescriptionExpanded ? (
                  ""
                ) : (
                  <CheckCircleIcon sx={{ color: "#49A3FF" }} />
                )}
              </AccordionSummary>

              <EnhancedMultipleOutputBugs
                items={enhancedDescriptionItems}
                page={enhancedPage}
                checkedStates={enhancedCheckedStates}
                expandedStates={enhancedExpandedStates}
                handlers={enhancedHandlers}
                setPage={setEnhancedPage}
                showEditIcon={true}
                isEnhanced={true}
                accordianData={enhancedDescriptionItems}
                setAccordianData={setSelectedBugDetails}
                selectedAccordianData={selectedBugDetails}
                setSelectedAccordianData={setSelectedBugDetails}
              />
              <Divider sx={{ mt: 1, mb: 1 }} />

              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="text"
                  startIcon={<ExportButton />}
                  sx={{ textTransform: "none", color: "#49A3FF" }}
                >
                  Export
                </Button>
                <Button
                  variant="contained"
                  startIcon={<GenerateButton />}
                  sx={{ textTransform: "none", backgroundColor: "#49A3FF" }}
                  onClick={() => setDownloadDialogOpen(true)} // Open the dialog when clicked
                >
                  Download
                </Button>
                <CommonDownload
                  open={downloadDialogOpen} // Pass the state to control dialog visibility
                  onClose={() => setDownloadDialogOpen(false)} // Pass the handler to close the dialog
                  serviceResponse={selectedBugDetails}
                  serviceName="Bug_Report"
                  wordData={[
                    {
                      heading: "Enhanced Bug Reports",
                      type: "subParagraphs",
                      data: [selectedBugDetails],
                    },
                  ]}
                />
              </Box>
            </Accordion>
            {/* )} */}
          </Box>
        </Box>
      </Container>
      {/* Centered Disclaimer */}
      <Box
        component="footer"
        sx={{
          width: "100%",
          maxWidth: "601px",
          backgroundColor: "#f6f3f2",
          textAlign: "right",
          paddingX: "5px",
          position: "fixed",
          bottom: "0",
          zIndex: "99",
          left: "46%", // Shift the Box a bit more to the right
          transform: "translateX(-50%)", // Adjust for centering
        }}
      >
        <Typography variant="caption" color="textSecondary">
          Use QMentisAI™ with human supervision, as with all generative AI
          tools.
        </Typography>
      </Box>
    </Box>
  );
};

export default MultipleOutputBugReport;
