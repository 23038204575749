import { Box, Container, Typography } from "@mui/material";
import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import NavigationRailIcons from "../common-components/NavigationRailIcons";
import LoadingSpinner from "../common-components/LoadingSpinner";
import LoadingModal from "../common-components/LoadingModal";

export const LoadingModalContext = createContext(null);

const ServicePage = () => {
  const [loading, setLoading] = useState(false);
  return (
    <Box
      disableGutters
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        backgroundColor: "#f6f3f2",
      }}
    >
      <Box sx={{ width: "80px" }}>
        <NavigationRailIcons />
      </Box>
      <Box
        sx={{
          flexBasis: "calc(100% - 80px)",
          height: "100% ",
          overflow: "hidden",
          scrollbarWidth: "none", // Hide scrollbar in Firefox
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar in Chrome, Safari, and Edge
          },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f6f3f2",
            padding: "18px",
            zIndex: 999,
          }}
        >
          <Typography sx={{ fontSize: "14px", lineHeight: "20px", marginTop: "17px" }}>
          QMentisAI™ - Intelligence for Next-Gen Quality
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            overflow: "auto",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar in Chrome, Safari, and Edge
            },
          }}
        >
          <LoadingModalContext.Provider value={setLoading}>
            <Outlet />
          </LoadingModalContext.Provider>
        </Box>
      </Box>
      {/* <LoadingSpinner open={loading} /> */}
      <LoadingModal open={loading} />
    </Box>
  );
};

export default ServicePage;
