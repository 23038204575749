import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Typography,
  IconButton,
  Dialog,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CommonAccordion = ({
  summary,
  details,
  buttons,
  fullScreenMode,
  type,
  defaultExpanded
}) => {
  const [expanded, setExpanded] = useState((type === "input") || defaultExpanded);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setExpanded(type === "input" || defaultExpanded);
  }, [type, defaultExpanded]);

  const handleAccordionChange = (isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleFullscreenClick = (event) => {
    event.stopPropagation();
    setExpanded(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderButtons = () =>
    buttons?.map((button, index) => (
      <Button
        key={index}
        onClick={button.onClick}
        variant={button.variant || "contained"}
        disabled={!!button.disabled ? button.disabled : false}
        sx={{
          marginRight: 1,
          fontSize: 14,
          backgroundColor:
            button.variant === "contained" ? "#49A3FF" : undefined,
          color:
            button.variant === "outlined" || button.variant === "text"
              ? "#49A3FF"
              : undefined,
          textTransform: "none",
          ...(index === buttons.length - 1 && { marginLeft: "auto" }),
          ...button.sx,
        }}
        startIcon={
          button.startIcon &&
          React.cloneElement(button.startIcon, { sx: { fontSize: 14 } })
        }
      >
        {button.label}
      </Button>
    ));

  return (
    <>
      <Accordion
        sx={{
          width: "100%",
          maxWidth: "600px",
          margin: "10px auto",
          borderRadius: "16px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          transition: "box-shadow 0.3s ease, background-color 0.3s ease",
          "&:before": { display: "none" },
        }}
        expanded={expanded}
        onChange={(e, isExpanded) => handleAccordionChange(isExpanded)}
      >
        <AccordionSummary sx={{ height: "56px" }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {expanded ? <ExpandMoreIcon sx={{ marginRight: 1 }} /> : <ChevronRightIcon sx={{ marginRight: 1 }} />}

            <Typography sx={{ flexGrow: 1, fontSize: 16, fontWeight: 500 }}>
              {summary}
            </Typography>
            {expanded
              ? fullScreenMode && (
                <IconButton onClick={handleFullscreenClick}>
                  <FullscreenIcon sx={{ color: "black", fontSize: 28 }} />
                </IconButton>
              )
              : type === "output" && (
                <CheckCircleIcon sx={{ color: "#49A3FF" }} />
              )}
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            maxHeight: "70vh",
            overflow: "auto",
            "&::-webkit-scrollbar": { width: "0px" },
            scrollbarWidth: "none",
            ml: "39px",
            padding: "0px",
          }}
        >
          {details}
        </AccordionDetails>

        {!!buttons && buttons.length > 0 && (
          <Box
            borderTop="1px solid #C6C6C6"
            padding={2}
            display="flex"
            justifyContent={buttons.length === 1 ? "flex-end" : "flex-start"}
            alignItems="center"
            flexWrap="wrap"
          >
            {renderButtons()}
          </Box>
        )}

      </Accordion>

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: "95%",
            maxWidth: "1200px",
            borderRadius: "16px",
            padding: 2,
            margin: 2,
          },
        }}
      >
        <Box padding={2}>
          <Accordion
            sx={{
              width: "100%",
              margin: "0 auto",
              borderRadius: "16px",
              boxShadow: "none",
            }}
            expanded
          >
            <AccordionSummary sx={{ height: "56px" }}>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <ExpandMoreIcon sx={{ marginRight: 1 }} />
                <Typography sx={{ flexGrow: 1, fontSize: 16, fontWeight: 500 }}>
                  {summary}
                </Typography>
                <Button
                  onClick={handleCloseModal}
                  variant="text"
                  sx={{ color: "#49A3FF", fontSize: 14 }}
                >
                  Exit Full Screen
                </Button>
              </Box>
            </AccordionSummary>

            <AccordionDetails
              sx={{
                maxHeight: "70vh",
                overflow: "auto",
                "&::-webkit-scrollbar": { width: "0px" },
                scrollbarWidth: "none",
                ml: "20px",
              }}
            >
              {details}
            </AccordionDetails>

            <Box borderTop={"1px solid #C6C6C6"} padding={2}>
              {renderButtons()}
            </Box>
          </Accordion>
        </Box>
      </Dialog>
    </>
  );
};

export default CommonAccordion;
