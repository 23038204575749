import React, { useContext, useEffect } from "react";
import MultipleOutputBugReport from "../../output-components/bug-report-enhancer/MultipleEnhancedOutputBugReport";
import { useLocation } from "react-router-dom";
import { MultipleBugReportsContext } from "../MultiBugReportPage";

const MultiEnhancedBugOutputPage = () => {
  const location = useLocation();
  const { multipleInputs, multipleOutputs } = location.state;
  const { setMultipleBugReports, setMultipleEnhancedBugReports } = useContext(
    MultipleBugReportsContext
  );
  
  useEffect(() => {
    setMultipleBugReports(multipleInputs);
    setMultipleEnhancedBugReports(multipleOutputs);
  }, [multipleInputs, multipleOutputs]);

  return (
    <div>
      <MultipleOutputBugReport />
    </div>
  );
};

export default MultiEnhancedBugOutputPage;
