import React from 'react'
import UserStoryFormInput from '../../input-components/testcase-generation/UserStoryFormInput'

const TestCasesInputPage = () => {
  return (
    <div>
        <UserStoryFormInput />
    </div>
  )
}

export default TestCasesInputPage