import React, { useContext, useEffect } from "react";
import TestPlanOuputComponent from "../../output-components/test-plan/TestPlanOutputComponent";
import { Box, Typography } from "@mui/material";
import { TestPlanContext } from "../TestPlanPage";
import { useLocation } from "react-router-dom";
import TestPlanFormDisplay from "../../output-components/test-plan/TestPlanFormDisplay";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";

const TestPlanOutputPage = () => {
  const location = useLocation();
  const testPlanData = location.state?.input || {};
  const testPlanOutput = location.state?.output || {};

  const { setTestPlanInputs, setTestPlanOutputs } = useContext(TestPlanContext);
  useEffect(() => {
    setTestPlanInputs(testPlanData);
    setTestPlanOutputs(testPlanOutput);
    // console.log(location.state);
  }, [location.state?.input,
  location.state?.output
  ])

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "10px auto",
        "& > *:not(:last-child)": {
          marginBottom: "16px", // Adds margin between components but not after the last one
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          width: "100%", // This can be removed if you want to avoid inner Box taking full width
          textAlign: "left",
          mb: 0.1,
        }}
      >
        <AutomateIcon style={{ marginRight: 6 }} />
        <Typography
          variant="body2"
          color="text.secondary"
          gutterBottom
          sx={{ mt: 0.5 }}
        >
          Generate a comprehensive test plan
        </Typography>
      </Box>
      <TestPlanFormDisplay />
      <Box sx={{ marginTop: -6 }}>
        <TestPlanOuputComponent
          testPlanInputs={testPlanData}
          testPlanOutputs={testPlanOutput}
        />
      </Box>
      <Box
        component="footer"
        sx={{
          width: "100%",
          maxWidth: "601px",
          backgroundColor: "#f6f3f2",
          textAlign: "center",
          paddingX: "5px",
          position: "fixed",
          bottom: "0",
          zIndex: "99"
        }}
      >
        <Typography variant="caption" color="textSecondary">
          Use QMentisAI™ with human supervision, as with all generative AI tools.
        </Typography>
      </Box>
    </Box>
  );
};

export default TestPlanOutputPage;
