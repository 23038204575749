import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Popover,
  MenuItem,
  Typography,
  ListItemIcon,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { fetchDisplayName, logout } from "../../services/webServices";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ConfirmLogout from "../popup-components/ConfirmLogout";
import QMentisImage from '../../images/QMentis.svg';


const navItems = [
  {
    src: QMentisImage,
    alt: "QMentis Icon",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4fa7e9a53a0b37a2cf751f8cc044de6188e2a7a534f07ab29fdb0b31d1ca99df?placeholderIfAbsent=true&apiKey=9a2f454d373e439aa0de2a182c9950a9",
    alt: "Profile icon",
  },
];

const NavigationRail = ({ }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [displayUserName, setUserDisplayName] = useState("");

  const handleHoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccountManagementClick = () => {
    navigate("/dashboard/jira-settings");
    handleHoverClose();
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
    handleHoverClose();
  };

  const handleSignOut = async () => {
    await logout();
    navigate("/");
    setOpenLogoutDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenLogoutDialog(false);
  };

  useEffect(() => {
    const fetchAndSetDisplayName = async () => {
      try {
        // const username = localStorage.getItem("email"); // Retrieve username from localStorage

        // if (!username) return; // If username is not found in localStorage, exit early

        // if (username) {
        const response = await fetchDisplayName(); // Fetch display name from server
        if (response && response.data && response.data.displayName) {
          setUserDisplayName(response.data.displayName);
        } else {
          console.error("Display name not found in response");
        }
        // }
      } catch (error) {
        console.error("Error fetching display name:", error);
      }
    };

    fetchAndSetDisplayName();
  }, []);

  return (
    <Box
      component="nav"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "80px", // Dynamic width based on screen size
        height: "100vh",
        backgroundColor: "white",
        padding: "19px 0",
        zIndex: 1000,
        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Top navigation icon */}
      <IconButton
        sx={{
          width: "56px",
          height: "58px",
          marginTop: -0.5,
        }}
      >
        <img
          src={navItems[0].src}
          alt={navItems[0].alt}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </IconButton>

      {/* Empty space for the rest of the page */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Profile icon at the bottom */}
      <Tooltip title="Profile" placement="right" arrow>
      <IconButton
        sx={{
          width: "40.7px",
          height: "54px",
          marginBottom: "25px",
        }}
        onClick={(e) => handleHoverOpen(e)}
      // onMouseLeave={handleHoverClose}
      >
        <img
          src={navItems[1].src}
          alt={navItems[1].alt}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </IconButton>
      </Tooltip>

      {/* Popover menu for profile */}
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleHoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          onMouseEnter: handleHoverOpen,
          onMouseLeave: handleHoverClose,
        }}
      >
        <MenuItem onClick={handleHoverClose}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <PersonOutlineOutlinedIcon sx={{ marginRight: 1 }} />{" "}
            {/* Add margin for spacing */}
            <span>{displayUserName}</span>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={handleAccountManagementClick}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <SettingsOutlinedIcon sx={{ marginRight: 1 }} />
            <Typography variant="body1">Settings</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">Logout</Typography>
        </MenuItem>
      </Popover>
      <ConfirmLogout
        open={openLogoutDialog}
        onClose={handleCloseDialog}
        onConfirm={handleSignOut}
      />
    </Box>
  );
};

export default NavigationRail;
