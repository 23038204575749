import React from "react";
import { Button } from "@mui/material";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";

const DownloadScriptLink = () => {
  const scriptContent = `
    const axios = require('axios');
    const fs = require('fs');

    // Jira instance details
    const JIRA_URL = "https://your-jira-instance.atlassian.net";
    const USERNAME = "your-email@example.com";
    const API_TOKEN = "your-api-token";
    const SCREEN_NAME = "your-screen-name"; // Add your screen name here to display custom field in specified screen

    // Base64 encode the username and API token for basic auth
    const auth = Buffer.from(\`\${USERNAME}:\${API_TOKEN}\`).toString('base64');

    // Headers for the API requests
    const headers = {
      "Content-Type": "application/json",
      "Authorization": \`Basic \${auth}\`
    };

    // List of custom fields to be created
    const customFields = [
      {
        "name": "QEBot_StepsToReproduce",
        "description": "Description of QEBot_StepsToReproduce",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_ExpectedVsActualBehavior",
        "description": "Description of QEBot_ExpectedVsActualBehavior",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_Evidences",
        "description": "Description of QEBot_Evidences",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_BusinessImpact",
        "description": "Description of QEBot_BusinessImpact",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_TechnicalImpact",
        "description": "Description of QEBot_TechnicalImpact",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_SeverityLevel",
        "description": "Description of QEBot_SeverityLevel",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_Workaround",
        "description": "Description of QEBot_Workaround",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_PossibleCausesForDefectLeakage",
        "description": "Description of QEBot_PossibleCausesForDefectLeakage",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_IdealTestCase",
        "description": "Description of QEBot_IdealTestCase",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_PossibleCausesForDevTeam",
        "description": "Description of QEBot_PossibleCausesForDevTeam",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_RecommendedFixesForDevTeam",
        "description": "Description of QEBot_RecommendedFixesForDevTeam",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_SimilarBugs",
        "description": "Description of QEBot_SimilarBugs",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_AdditionalInformation",
        "description": "Description of QEBot_AdditionalInformation",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_ValidationAndVerification",
        "description": "Description of QEBot_ValidationAndVerification",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      },
      {
        "name": "QEBot_ContextualUseCasesAndScenarios",
        "description": "Description of QEBot_ContextualUseCasesAndScenarios",
        "type": "com.atlassian.jira.plugin.system.customfieldtypes:textarea"
      }
    ];

    // Fetch the screen ID by name
    async function fetchScreenIdByName(screenName) {
      try {
        const response = await axios.get(\`\${JIRA_URL}/rest/api/2/screens\`, { headers });
        const screens = response.data.values;
        const screen = screens.find(screen => screen.name === screenName);
        if (!screen) {
          throw new Error(\`Screen with name "\${screenName}" not found.\`);
        }
        return screen.id;
      } catch (error) {
        console.error('Failed to fetch screen ID:', error.response?.data || error.message);
        throw error;
      }
    }

    // Fetch tabs for a screen
    async function fetchScreenTabs(screenId) {
      try {
        const response = await axios.get(\`\${JIRA_URL}/rest/api/2/screens/\${screenId}/tabs\`, { headers });
        return response.data;
      } catch (error) {
        console.error(\`Failed to fetch tabs for screen \${screenId}:\`, error.response?.data || error.message);
        throw error.response.data;
      }
    }

    // Add a field to a screen
    async function addFieldToScreen(screenId, fieldId, tabId) {
      try {
        const response = await axios.post(
          \`\${JIRA_URL}/rest/api/2/screens/\${screenId}/tabs/\${tabId}/fields\`,
          { fieldId },
          { headers }
        );
        // console.log(\`Field \${fieldId} added to screen \${screenId} on tab \${tabId} successfully.\`);
      } catch (error) {
        console.error(\`Failed to add field \${fieldId} to screen \${screenId} on tab \${tabId}:\`, error.response?.data || error.message);
        throw error.response.data;
      }
    }

    // Create a custom field
    async function createCustomField(field) {
      try {
        const response = await axios.post(\`\${JIRA_URL}/rest/api/2/field\`, field, { headers });
        // console.log(\`Custom field '\${field.name}' created successfully.\`);
        return response.data.id;
      } catch (error) {
        console.error(\`Failed to create custom field '\${field.name}':\`, error.response?.data || error.message);
        throw error.response.data;
      }
    }

    // Main function to create custom fields and associate them with a screen
    (async () => {
      try {
        const screenId = await fetchScreenIdByName(SCREEN_NAME);
        const tabs = await fetchScreenTabs(screenId);
        const firstTabId = tabs[0].id; // Use the first tab ID, or specify the correct tab ID

        for (const field of customFields) {
          const fieldId = await createCustomField(field);
          await addFieldToScreen(screenId, fieldId, firstTabId);
        }

        console.log('All custom fields created and added to the screen successfully.');
      } catch (error) {
        console.error('Failed to create custom fields and add them to the screen:', error.message);
      }
    })();
  `;

  const downloadScript = () => {
    const blob = new Blob([scriptContent], { type: "text/javascript" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "createJiraCustomFields.js";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      variant="text"
      startIcon={<ArrowCircleDownOutlinedIcon />}
      sx={{ textTransform: "none" }}
      onClick={downloadScript}
    >
      Download Script
    </Button>
  );
};

export default DownloadScriptLink;
