import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ReactComponent as ExportIcon } from '../../../images/exportIcon.svg';
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from '../../custom-components/CommonDownload';
import { pageClassAPI } from '../../../services/webServices';
import { useNavigate, useLocation } from 'react-router-dom';
import LocatorsTable from './LocatorsTable';
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { TestCaseFlowDataContext } from '../../main-pages/TestAutomationPage';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";


const IconComponent = () => (

    <Box sx={{ maxWidth: 14, display: 'flex', flexDirection: 'column' }}>
        <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/74001bbbe35d84ed7dd44adbf23d2273b030df4d38296f54b84768e53b777ae2?placeholderIfAbsent=true&apiKey=61dcb7c26aae4febb0f66efb27027a73"
            alt=""
            style={{ width: '100%', objectFit: 'contain' }}
        />
    </Box>
);


const Locators = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { locators, pageClass } = useContext(TestCaseFlowDataContext);
    const [pageClassData, setPageClassData] = useState(locators);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const setLoading = useContext(LoadingModalContext);

    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);

    const [activeThumb, setActiveThumb] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const defaultLocatorsExpanded = Object.keys(locators).length > 0 && pageClass === "";
    useEffect(() => {
        setPageClassData(locators)
    }, [locators])

    const handleDownloadClick = () => {
        setDownloadOpen(true);
    };

    const handleCloseDownload = () => {
        setDownloadOpen(false);
    };

    const handleCopyClick = () => {
        // Handle copy action (you can implement actual copying logic here)
        console.log("Content copied!"); // Placeholder for copy logic
        setIsCopied(true);
        // Reset the copied status after 2 seconds (optional)
        setTimeout(() => setIsCopied(false), 2000);
    };

    const handleCopy = () => {
        // Copy functionality for the editor content
        navigator.clipboard.writeText("Sample code to copy"); // Replace with actual code content
        alert("Code copied to clipboard");
    };
    // Function to extract primary fields from the response
    // function getPrimaryFields(response) {
    //     const primaryFields = {};
    //     for (const key in response) {
    //         if (response[key].primary) {
    //             primaryFields[key] = response[key].primary;
    //         }
    //     }
    //     return primaryFields;
    // }
    function getPrimaryFields(response) {
        const primaryFields = {};
        for (const key in response) {
            if (response[key].primary) {
                if (response[key].primary[0].startsWith("class")) {
                    primaryFields[key] = response[key].secondary;
                } else {
                primaryFields[key] = response[key].primary;
                }
            }
        }
        return primaryFields;
};

    // Using the function
    const primaryFieldsObject = getPrimaryFields(pageClassData);

    const handlePageClass = async () => {
        setLoading(true);
        try {
            const generatedPageClassGenerated = await pageClassAPI(primaryFieldsObject);
            const generatedPageClass = generatedPageClassGenerated.data;
            // setLocatorsGenerated(generatedPageClass)
            navigate("/dashboard/test-automation/output", { state: { ...location.state, generatedPageclassData: generatedPageClass }, replace: true });
        }
        catch (error) {
            console.error("Error generating PageClass:", error);
        } finally {
            setLoading(false);
        }

    }

    const buttonsgroup = [
        // {
        //     label: "Export",
        //     variant: "text",
        //     startIcon: <ExportIcon />,
        // },
        // {
        //     label: "Download",
        //     variant: "text",
        //     onClick: handleDownloadClick,
        //     startIcon: <SendIcon />,
        //     // sx: { float: "left" }
        // },
        { label: 'Export', variant: 'text', startIcon: <IconComponent />, sx: { textTransform: 'none' } },
        { label: 'Download', onClick: handleDownloadClick, variant: 'text', startIcon: <SaveAltRoundedIcon />, sx: { textTransform: 'none' } },
        {
            label: "Generate Page Class",
            variant: "contained",
            startIcon: <GenerateButton />,
            sx: { float: "right" },
            onClick: handlePageClass
        },
    ];
    const handleLike = () => {
        setLiked(!liked);
        if (disliked) setDisliked(false);
    };

    const handleDislike = () => {
        setDisliked(!disliked);
        if (liked) setLiked(false);
    };

    const handleThumbClick = (thumbType) => {
        // Toggle thumbs based on the thumbType (like or dislike)
        setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
    };

    const tableDataConverter = (locators) => {
        const headers = ["Parameters", ...(Object.keys(Object.values(locators)[0]))];
        const values = [];
        Object.entries(locators).map(([parameter, locatorValues]) => {
            const eachRow = [parameter, ...Object.values(locatorValues)];
            values.push(eachRow);
        })
        return { headers, values };
    }

    return (
        // <Box
        //     sx={{
        //         display: 'flex',
        //         flexDirection: 'column',
        //         justifyContent: 'flex-start',
        //         alignItems: 'center',
        //         width: '100%',
        //     }}
        // >

        // </Box>
        <Box>


            <CommonAccordion
                summary={"Locators"}
                details={
                    <Box>
                        <LocatorsTable jsonData={pageClassData} setJsonData={setPageClassData} />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2, marginTop: 2 }}>
                            <Tooltip title="Like" arrow>
                                <Box
                                    onClick={() => handleThumbClick("like")}
                                    sx={{
                                        cursor: "pointer",
                                        marginRight: 2, // Margin between the buttons
                                        "&:hover": {
                                            color: "#49A3FF", // Change color on hover
                                        },
                                    }}
                                >
                                    {activeThumb === "like" ? (
                                        <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                                    ) : (
                                        <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                                    )}
                                </Box>
                            </Tooltip>

                            {/* Thumbs Down Button with Tooltip */}
                            <Tooltip title="Dislike" arrow>
                                <Box
                                    onClick={() => handleThumbClick("dislike")}
                                    sx={{
                                        cursor: "pointer",
                                        marginRight: 2, // Margin between the buttons
                                        "&:hover": {
                                            color: "#FF4842", // Change color on hover
                                        },
                                    }}
                                >
                                    {activeThumb === "dislike" ? (
                                        <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                                    ) : (
                                        <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                                    )}
                                </Box>
                            </Tooltip>

                            {/* Copy Button with Tooltip */}
                            <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                                <Box
                                    onClick={handleCopyClick}
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            color: "#49A3FF", // Change color on hover
                                        },
                                    }}
                                >
                                    {isCopied ? (
                                        <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                                    ) : (
                                        <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                                    )}
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                }
                buttons={buttonsgroup}
                fullScreenMode={true}
                type={"output"}
                defaultExpanded={defaultLocatorsExpanded}
            />
            <CommonDownload open={downloadOpen} onClose={handleCloseDownload}
                serviceResponse={pageClassData}
                serviceName="Locators_File_data"
                wordData={[{ heading: "Locators", type: "table", data: { ...tableDataConverter(pageClassData) } }]}
            />

        </Box>
    );
};

export default Locators;

