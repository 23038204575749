import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Helper function to generate line numbers
const generateLineNumbers = (text) => {
    const lines = text.split("\n").length;
    return Array.from({ length: lines }, (_, i) => i + 1).join("\n");
};

// CodeEditor component
const CodeEditorNew = () => {
    const [codeContent, setCodeContent] = useState(`package utils;
import org.openqa.selenium.WebDriver;
import org.openqa.selenium.chrome.ChromeDriver;
import org.testng.annotations.AfterMethod;
import org.testng.annotations.BeforeMethod;
public class BaseTest {
  protected WebDriver driver;
  @BeforeMethod
  public void setUp() {
    // Initialize the WebDriver (e.g., ChromeDriver)
    System.setProperty("webdriver.chrome.driver", "path_to_chromedriver");
    driver = new ChromeDriver();
    driver.manage().window().maximize();
    driver.get("https://example.com/login"); // URL of the login page
  }
  @AfterMethod
  public void tearDown() {
    // Close the browser
    if (driver != null) {
      driver.quit();
    }
  }
}`);

    const [isEditing, setIsEditing] = useState(false);
    const [lineNumbers, setLineNumbers] = useState("");

    // Function to copy code to clipboard
    const handleCopyCode = () => {
        navigator.clipboard.writeText(codeContent);
        alert("Code copied to clipboard!");
    };

    const fileStructure = [];

    const handleCodeClick = () => {
        setIsEditing(true);
    };

    const handleContentChange = (event) => {
        setCodeContent(event.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    // Generate line numbers when content changes
    useEffect(() => {
        setLineNumbers(generateLineNumbers(codeContent));
    }, [codeContent]);

    return (
        <Box
            sx={{
                maxWidth: "554px",
                padding: "15px",
                bgcolor: "#3D3D3D",
                borderRadius: "4px",
            }}
        >
            <EditorHeader
                title="BaseTest.java – Setup for WebDriver"
                onCopyCode={handleCopyCode}
            />
            <Box sx={{ mt: 2, borderTop: "1px solid #FFFFFF" }} />
            <Box
                sx={{
                    display: "flex",
                    mt: 2,
                    color: "#FFFFFF",
                    fontSize: "14px",
                    lineHeight: "20px",
                }}
            >
                {/* <FileStructure structure={fileStructure} /> */}
                <LineNumberedCodeContent
                    content={codeContent}
                    isEditing={isEditing}
                    onCodeClick={handleCodeClick}
                    onContentChange={handleContentChange}
                    onBlur={handleBlur}
                    lineNumbers={lineNumbers}
                />
            </Box>
        </Box>
    );
};

// EditorHeader component with copy button
const EditorHeader = ({ title, onCopyCode }) => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
        }}
    >
        <Typography
            variant="subtitle1"
            sx={{ color: "#FFFFFF", letterSpacing: "0.1px", fontWeight: 500 }}
        >
            {title}
        </Typography>
        <Tooltip title="Copy code" placement="top">
            <IconButton
                aria-label="copy code"
                sx={{ color: "#FFFFFF" }}
                onClick={onCopyCode}
            >
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    </Box>
);

const FileStructure = ({ structure }) => (
    <Box
        sx={{ minWidth: "24px", borderRight: "1px solid #FFFFFF", mr: 2, pr: 1 }}
    >
        {structure.map((item, index) => (
            <Typography
                key={index}
                variant="body2"
                component="div"
                sx={{ whiteSpace: "nowrap" }}
            >
                {item.includes(".java") ? (
                    <span style={{ color: "#FD0A0A" }}>{item}</span>
                ) : item === "src/" ? (
                    <span style={{ color: "#1AC421" }}>{item}</span>
                ) : (
                    item
                )}
            </Typography>
        ))}
    </Box>
);

const LineNumberedCodeContent = ({
    content,
    isEditing,
    onCodeClick,
    onContentChange,
    onBlur,
    lineNumbers,
}) => (
    <Box sx={{ display: "flex", width: "100%", alignItems: "stretch" }}>
        {/* Line Numbers */}
        <Box
            sx={{
                textAlign: "right",
                userSelect: "none",
                backgroundColor: "#3D3D3D",
                color: "#FFFFFF",
                fontFamily: "monospace",
                fontSize: "14px",
                lineHeight: "20px",

                minWidth: "40px",

                paddingTop: "5px",
                paddingRight: "10px",
                borderRight: "1px solid #FFFFFF", // Move the vertical line here
                marginRight: "10px",
            }}
        >
            <pre style={{ margin: 0 }}>{lineNumbers}</pre>
        </Box>


        {/* Editable code area */}
        {isEditing ? (
            <textarea
                value={content}
                onChange={onContentChange}
                onBlur={onBlur}
                style={{
                    width: "100%",
                    //   height: "400px",
                    backgroundColor: "#2D2D2D",
                    color: "#FFFFFF",
                    fontFamily: "monospace",
                    fontSize: "14px",
                    border: "none",
                    outline: "none",
                }}
                autoFocus
            />
        ) : (
            <pre
                style={{
                    margin: 0,
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    cursor: "pointer",
                    paddingTop: "5px",
                }}
                onClick={onCodeClick}
            >
                {content.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                        {line.includes("import") ? (
                            <span style={{ color: "#03C3FF" }}>{line}</span>
                        ) : line.includes("public class") ||
                            line.includes("public void") ? (
                            <span>
                                <span style={{ color: "#03C3FF" }}>public </span>
                                {line.includes("class") ? (
                                    <span>
                                        class{" "}
                                        <span style={{ color: "#FD0A0A" }}>
                                            {line.split(" ")[2]}
                                        </span>
                                    </span>
                                ) : (
                                    <span>
                                        void{" "}
                                        <span style={{ color: "#FD0A0A" }}>
                                            {line.split(" ")[2].split("(")[0]}
                                        </span>
                                    </span>
                                )}
                                {line.substring(line.indexOf("("))}
                            </span>
                        ) : line.includes('"') ? (
                            <span>
                                {line.split('"').map((part, i) =>
                                    i % 2 === 0 ? (
                                        part
                                    ) : (
                                        <span key={i} style={{ color: "#1AC421" }}>
                                            "{part}"
                                        </span>
                                    )
                                )}
                            </span>
                        ) : (
                            line
                        )}
                        <br />
                    </React.Fragment>
                ))}
            </pre>
        )}
    </Box>
);

export default CodeEditorNew;
