import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Paper,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EditIcon from '@mui/icons-material/Edit';


const generateLineNumbers = (text) => {
  if (typeof text !== "string") {
    console.error("Expected a string but received:", typeof text);
    return "";  // or any appropriate fallback value
  }

  const lines = text.split("\n").length;
  return Array.from({ length: lines }, (_, i) => i + 1).join("\n");
};

const formatCodeContent = (content) => {
  if (typeof content === "string") {
    return content;
  } else if (typeof content === "object") {
    try {
      return JSON.stringify(content, null, 2); // Pretty-print JSON for readability
    } catch (error) {
      console.error("Failed to stringify content:", error);
      return "Error: Unable to display content";
    }
  }
  return String(content); // Fallback for other types, like numbers or booleans
};

const CodeEditor = ({ codeContent, setCodeContent }) => {
  const [rawCodeContent, setRawCodeContent] = useState(formatCodeContent(codeContent));
  const [lineNumbers, setLineNumbers] = useState(generateLineNumbers(rawCodeContent));

  // useEffect(() => {
  //   setCodeContent(rawCodeContent);
  // }, [rawCodeContent]);
  // Update line numbers and raw code content when codeContent changes
  useEffect(() => {
    const formattedContent = formatCodeContent(codeContent);
    setRawCodeContent(formattedContent);
    setLineNumbers(generateLineNumbers(formattedContent));
  }, [codeContent]);

  const handleContentChange = (e) => {
    const updatedContent = e.target.value;
    setRawCodeContent(updatedContent); // Update raw content as user types
    setCodeContent(updatedContent);
    setLineNumbers(generateLineNumbers(updatedContent)); // Dynamically update line numbers with content
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: "20px", backgroundColor: "#3D3D3D", borderRadius: "4px" }}
    >
      <Box>
        {/* Top section with title and buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <EditIcon style={{ color: 'white', fontSize: '18px' }} /> */}
            <Typography sx={{ color: 'white', ml: 0.4 }}>Click to Edit</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CopyToClipboard text={rawCodeContent}>
              <Tooltip title="Copy to clipboard">
                <IconButton aria-label="copy" sx={{ color: "#FFFFFF" }}>
                  {/* <ContentCopyIcon /> */}
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
        </Box>

        {/* Code editor area */}
        <Box sx={{ display: "flex", overflow: 'auto' }}>
          {/* Line Numbers */}
          <Box
            sx={{
              padding: "5px",
              textAlign: "right",
              userSelect: "none",
              backgroundColor: "#3D3D3D",
              color: "#FFFFFF",
              fontFamily: "monospace",
              fontSize: "14px",
              minWidth: "40px",
              marginRight: "10px",
              lineHeight: "20px", // Match line height to TextField
              display: "flex",
              flexDirection: "column",
            }}
          >
            <pre style={{ margin: 0, padding: 0, whiteSpace: 'pre-wrap' }}>{lineNumbers}</pre>
          </Box>

          {/* Editable TextField for code */}
          <TextField
            multiline
            fullWidth
            minRows={20}
            value={rawCodeContent}
            onChange={handleContentChange}
            InputProps={{
              style: {
                fontFamily: "monospace",
                fontSize: "14px",
                lineHeight: "20px", // Ensure consistent line height
                whiteSpace: "pre-wrap",
                color: "#FFFFFF",
                bgcolor: "#282828",
                padding: "5px", // Add padding to align the text better
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                color: "#FFFFFF",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FFFFFF",
              },
              "& textarea": {
                lineHeight: "20px", // Ensure line height matches the line number area
              },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default CodeEditor;
