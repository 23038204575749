import { Outlet } from 'react-router-dom'
import React, { createContext, useEffect, useState } from "react";
import { Box, Button, Container } from "@mui/material";
import QMentisBreadCrumb from '../custom-components/QMentisBreadCrumb';
import DownloadIcon from '@mui/icons-material/Download';

export const TestCaseFlowDataContext = createContext(null);


const TestAutomationPage = () => {
  const [testCaseDetails, setTestCaseDetails] = useState([]);
  const [featureFiles, setFeatureFiles] = useState("");
  const [addPreferences, setAddPreferences] = useState(false);
  const [pseudoCode, setPseudoCode] = useState("");
  const [pageSource, setPageSource] = useState("");
  const [locators, setLocators] = useState({});
  const [pageClass, setPageClass] = useState("");
  const [stepDefinition, setstepDefinition] = useState("");

  const breadCrumbs = [
    {
      title: "Test Case Details",
      status: testCaseDetails.length > 0 ? "Completed" : "In Progress",
    },
    {
      title: "Feature Files",
      status:
        featureFiles !== null && featureFiles !== ""
          ? "Completed"
          : testCaseDetails.length > 0
            ? "In Progress"
            : "Not Started",
    },

    {
      title: "Select Framework",
      status: addPreferences
        ? "Completed"
        : featureFiles !== null && featureFiles !== ""
          ? "In Progress"
          : "Not Started",
    },

    {
      title: "Pseudo Code",
      status:
        (!!pseudoCode && pseudoCode !== "")
          ? "Completed"
          : Object.keys(addPreferences).length > 0
            ? "In Progress"
            : "Not Started",
    },
    {
      title: "Page Source",
      status:
        pageSource !== null && pageSource !== ""
          ? "Completed"
          : (!!pseudoCode && pseudoCode !== "")
            ? "In Progress"
            : "Not Started",
    },
    {
      title: "Locators",
      status:
        Object.keys(locators).length > 0
          ? "Completed"
          : Object.keys(pageSource).length > 0
            ? "In Progress"
            : "Not Started",
    },
    {
      title: "Page Class",
      status:
        !!pageClass && pageClass !== ""
          ? "Completed"
          : Object.keys(locators).length > 0
            ? "In Progress"
            : "Not Started",
    },

    {
      title: "Step Definition",
      status:
        (!!stepDefinition && stepDefinition !== "")
          ? "Completed"
          : !!pageClass && pageClass !== ""
            ? "In Progress"
            : "Not Started",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Box sx={{ maxHeight: "100%", width: "100%" }}>
        {/* Fixed breadcrumb where you want it */}
        <Box
          sx={{
            position: "sticky",
            top: "0", // Adjust this value as needed to place the breadcrumb where you want it
            width: "100%",
            zIndex: 1000, // Ensure it stays above other content
            bgcolor: "white", // Background color to prevent transparency
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow for visual separation
          }}
        >
          <QMentisBreadCrumb breadCrumbs={breadCrumbs} sx={{ height: "56px" }} />
        </Box>

        {/* Add padding-top to avoid overlap with the fixed breadcrumb */}
        <Container
          sx={{
            // paddingTop: "50px", // Adjust this value based on the height of the breadcrumb
            width: "100%",
          }}
        >

          <TestCaseFlowDataContext.Provider
            value={{
              testCaseDetails,
              setTestCaseDetails,
              featureFiles,
              setFeatureFiles,
              addPreferences,
              setAddPreferences,
              pseudoCode,
              setPseudoCode,
              pageSource,
              setPageSource,
              locators,
              setLocators,
              pageClass,
              setPageClass,
              stepDefinition,
              setstepDefinition,

            }}
          >
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <Button
                startIcon={<DownloadIcon />}
                color="#80c3d8"
                sx={{ textTransform: "none", marginRight: '15px', color: '#53adcb', border: '2px solid #53adcb', }}
              >
                Upload
              </Button>
            </Box> */}
            <Outlet />
          </TestCaseFlowDataContext.Provider>
        </Container>
      </Box>
    </Box>
  );
};


export default TestAutomationPage