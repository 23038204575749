import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SuccessPopup({ open, handleClose }) {
    const navigate = useNavigate(); // For navigating to the landing page

    // Function to navigate to the landing page
    const handleNavigate = () => {
        navigate('/LandingPage');
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '8px',
                    minWidth: '400px',
                    padding: '20px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    paddingBottom: '10px',
                    fontFamily: 'Roboto, sans-serif',
                }}
            >
                Your Maven Project is ready
            </DialogTitle>

            <DialogContent sx={{ paddingBottom: '20px' }}>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: '16px',
                        fontFamily: 'Roboto, sans-serif',
                        color: '#333',
                    }}
                >
                    You may now proceed to tweak the script in downloads folder and execute.
                </Typography>
            </DialogContent>

            <Divider />

            <DialogActions sx={{ justifyContent: 'flex-end', padding: '10px 24px' }}>
                <Button
                    onClick={handleClose} // Use onClose prop to close the dialog
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        fontFamily: 'Roboto, sans-serif',
                        color: 'white',
                        backgroundColor: '#49A3FF',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px 15px',
                    }}
                >
                    OK
                </Button>

                {/* <Button
                    onClick={handleNavigate}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        fontFamily: 'Roboto, sans-serif',
                        color: 'white',
                        backgroundColor: '#49A3FF',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px 15px',
                    }}
                >
                    Navigate to Home Page
                </Button> */}
            </DialogActions>
        </Dialog>
    );
}

export default SuccessPopup;
