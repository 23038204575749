import React, { useEffect, useState, useMemo, useContext } from "react";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import CommonAccordion from "../../custom-components/CommonAccordion";
import MultiAccordian from "./MultiAccordian";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import {
  businessProcessGuide,
  genTestCases,
} from "../../../services/webServices";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MultipleTestCasesContext,
  TestCasesLoaderContext,
} from "../../main-pages/MultiTestCaseGenerationPage";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CommonDownload from "../../custom-components/CommonDownload";
import { ReactComponent as BusinessProcessGuideIcon } from "../../../images/businessProcessGuideIcon.svg";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import SelectionErrorPopUp from "../../popup-components/SelectionerrorPop";

const MultipleUseCase = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { testCaseLoaderState, setTestCaseLoaderState } = useContext(
    TestCasesLoaderContext
  );
  const multipleTestCasesContext = useContext(MultipleTestCasesContext);
  const multipleUseCasesFromContext = multipleTestCasesContext.multipleUseCases;
  const selectedUseCasesFromContext = multipleTestCasesContext.selectedUseCases;
  const { multiTestCases, setMultiTestCases, setSelectedMultipleTestCases } =
    multipleTestCasesContext;

  const [openDownload, setOpenDownload] = useState(false);

  const [multipleUseCase, setMultipleUseCase] = useState(
    multipleUseCasesFromContext
  );
  const [selectedUseCase, setSelectedUseCase] = useState(
    selectedUseCasesFromContext
  );
  const [businessProcessData, setBusinessProcessData] = useState(null);

  const [isEditing, setIsEditing] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [noneUseCaseSelected, setNoneUseCaseSelected] = useState(false);

  const defaultMultipleUseCasesExpanded = multipleUseCasesFromContext.length > 0 && multiTestCases.length === 0;

  const setLoading = useContext(LoadingModalContext);

  useEffect(() => {
    setMultipleUseCase(multipleUseCasesFromContext);
    setSelectedUseCase(selectedUseCasesFromContext);
  }, [multipleUseCasesFromContext, selectedUseCasesFromContext]);

  const activeUseCases = [];
  multipleUseCase.map((eachUseCase, index) => {
    if (!!eachUseCase) {
      activeUseCases.push({ [index]: eachUseCase });
    }
  });

  const summaries = useMemo(
    () =>
      multipleUseCase.map((eachUseCase) => {
        return eachUseCase?.["Title"];
      }),
    [multipleUseCase]
  );

  const useCaseComponents = multipleUseCase.map((eachUseCase, useCaseIndex) => {
    if (!eachUseCase) {
      return null;
    }
    return (
      <Box key={`eachUseCase_${useCaseIndex}`}>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Title
        </Typography>
        {isEditing === useCaseIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Title"
              value={editingValue["Title"]}
              onChange={(e) => handleEdittedValue(e, useCaseIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginBottom: 2 }}
            >
              {eachUseCase["Title"]}
            </Typography>
          </Box>
        )}

        {/* //Primary Actor */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Primary Actor
        </Typography>
        {isEditing === useCaseIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Primary Actor"
              value={editingValue["Primary Actor"]}
              onChange={(e) => handleEdittedValue(e, useCaseIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginBottom: 2 }}
            >
              {eachUseCase["Primary Actor"]}
            </Typography>
          </Box>
        )}

        {/* //Goal in Contenxt */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Goal in Context
        </Typography>
        {isEditing === useCaseIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Goal in Context"
              value={editingValue["Goal in Context"]}
              onChange={(e) => handleEdittedValue(e, useCaseIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginBottom: 2 }}
            >
              {eachUseCase["Goal in Context"]}
            </Typography>
          </Box>
        )}

        {/* //Scope */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Scope
        </Typography>
        {isEditing === useCaseIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Description"
              value={editingValue["Scope"]}
              onChange={(e) => handleEdittedValue(e, useCaseIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginBottom: 2 }}
            >
              {eachUseCase["Scope"]}
            </Typography>
          </Box>
        )}

        {/* //Stakeholders */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}

        >
          Stakeholders and Interests
        </Typography>
        {isEditing === useCaseIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Stakeholders and Interests"
              value={editingValue["Stakeholders and Interests"]}
              onChange={(e) => handleEdittedValue(e, useCaseIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginBottom: 2 }}
              dangerouslySetInnerHTML={{
                __html: eachUseCase["Stakeholders and Interests"].toString().replace(/\n/g, "<br>"),
              }}
            />
            {/* //   {eachUseCase["Stakeholders and Interests"]}
            // </Typography> */}
          </Box>
        )
        }

        {/* //PreConditions */}
        < Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Preconditions
        </Typography >
        {isEditing === useCaseIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Preconditions"
              value={editingValue["Preconditions"]}
              onChange={(e) => handleEdittedValue(e, useCaseIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px", marginBottom: 2 }}
              dangerouslySetInnerHTML={{
                __html: eachUseCase["Preconditions"].toString().replace(/\n/g, "<br>"),
              }} />

            {/* {eachUseCase["Preconditions"]}
            </Typography> */}
          </Box>
        )}

        {/* //Minimal Guarantees */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Minimal Guarantees
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Minimal Guarantees"
                value={editingValue["Minimal Guarantees"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Minimal Guarantees"].toString().replace(/\n/g, "<br>"),
                }} />

              {/* {eachUseCase["Minimal Guarantees"]}
              </Typography> */}
            </Box>
          )
        }

        {/* //Success Guarantees */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Success Guarantees
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Success Guarantees"
                value={editingValue["Success Guarantees"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Success Guarantees"].toString().replace(/\n/g, "<br>"),
                }} />

              {/* {eachUseCase["Success Guarantees"]}
              </Typography> */}
            </Box>
          )
        }

        {/* Trigger */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Trigger
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Trigger"
                value={editingValue["Trigger"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Trigger"].toString().replace(/\n/g, "<br>"),
                }} />
              {/* {eachUseCase["Trigger"]}
              </Typography> */}
            </Box>
          )
        }

        {/* //Main Success Scenario */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Main Success Scenario
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Main Success Scenario"
                value={editingValue["Main Success Scenario"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Main Success Scenario"].toString().replace(/\n/g, "<br>"),
                }} />

              {/* {eachUseCase["Main Success Scenario"]}
              </Typography> */}
            </Box>
          )
        }

        {/* //Extensions */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Extensions
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Extensions"
                value={editingValue["Extensions"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Extensions"].toString().replace(/\n/g, "<br>"),
                }} />
              {/* {eachUseCase["Extensions"]}
              </Typography> */}
            </Box>
          )
        }

        {/* Technology */}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Technology
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Technology"
                value={editingValue["Technology"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Technology"].toString().replace(/\n/g, "<br>"),
                }}
              />
              {/* {eachUseCase["Technology"]}
              </Typography> */}
            </Box>
          )
        }

        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Data Variations List
        </Typography>
        {
          isEditing === useCaseIndex ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                name="Data Variations List"
                value={editingValue["Data Variations List"]}
                onChange={(e) => handleEdittedValue(e, useCaseIndex)}
                sx={{ marginTop: 1, marginRight: 1 }}
                inputProps={{ style: { fontSize: "14px" } }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "&:hover": { backgroundColor: "white" },
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", marginBottom: 2 }}
                dangerouslySetInnerHTML={{
                  __html: eachUseCase["Data Variations List"].toString().replace(/\n/g, "<br>"),
                }} />

              {/* {eachUseCase["Data Variations List"]}
              </Typography> */}
            </Box>
          )
        }
      </Box >
    );
  });

  const handleBusinessProcessGuide = async () => {
    try {
      if (selectedUseCase.every((eachUseCase) => !eachUseCase)){
        setNoneUseCaseSelected(true);
        return;
      }
      setLoading(true);
      setBusinessProcessData([]);
      const updatedBusinessProcessGuide = [];

      for (const [index, eachUseCase] of selectedUseCase.entries()) {
        if (eachUseCase && Object.keys(eachUseCase).length > 0) {
          const requestData = { ...eachUseCase };
          const businessProcessGuideData = await businessProcessGuide(
            requestData
          );

          setBusinessProcessData((prevState) => {
            const updatingBusinessProcess = [...prevState];
            updatingBusinessProcess[index] = businessProcessGuideData.data;
            return updatingBusinessProcess;
          });
          updatedBusinessProcessGuide[index] = businessProcessGuideData.data;
        } else {
          updatedBusinessProcessGuide[index] = null;
        }
      }
      navigate(
        "/dashboard/generate-multi/testcases/output/multiBusinessProcessGuide",
        {
          state: {
            multipleUseCase,
            businessProcessData: updatedBusinessProcessGuide,
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdittedValue = (event) => {
    const updatedValue = {
      ...editingValue,
      [event.target.name]: event.target.value,
    };
    setEditingValue(updatedValue);
  };

  const handleMultipleTestCases = async () => {
    try {
      if (selectedUseCase.every((eachUseCase) => !eachUseCase)){
        setNoneUseCaseSelected(true);
        return;
      };
      setTestCaseLoaderState("Test Cases");
      setMultiTestCases([]);
      setSelectedMultipleTestCases([]);
      const updatedTestCases = [];

      for (const [index, eachUseCase] of selectedUseCase.entries()) {
        if (eachUseCase && Object.keys(eachUseCase).length > 0) {
          const requestData = { ...eachUseCase };
          const testCases = await genTestCases(requestData);

          setMultiTestCases((prevState) => {
            const updatingTestCases = [...prevState];
            updatingTestCases[index] = testCases.data;
            return updatingTestCases;
          });
          updatedTestCases[index] = testCases.data;
        } else {
          updatedTestCases[index] = null;
        }
      }
      navigate("/dashboard/generate-multi/testcases/output", {
        state: {
          ...location.state,
          multipleUseCases: multipleUseCase,
          selectedMultipleUseCases: selectedUseCase,
          multipleTestCases: updatedTestCases,
          selectedMultipleTestCases: Array(updatedTestCases.length).fill(null),
        },
        replace: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTestCaseLoaderState(null);
    }
  };

  const createWordData = (useCasesData) => {
    const wordData = [];
    useCasesData.map((eachUseCase) => {
      if (!!eachUseCase) {
        wordData.push({
          heading: "Use Case",
          type: "subParagraphs",
          data: eachUseCase,
        });
        wordData.push({ type: "pageBreak" });
        return;
      }
    });
    return wordData;
  };

  const buttons = [
    {
      label: "Download",
      variant: "text",
      startIcon: <FileDownloadOutlinedIcon />,
      onClick: () => setOpenDownload(true),
    },
    ,
    {
      label: "Business Process Guide",
      onClick: handleBusinessProcessGuide,
      startIcon: <BusinessProcessGuideIcon />,
      variant: "text",
      sx: { fontSize: "12px" },
    },
    {
      label: "Generate Test Cases",
      variant: "contained",
      startIcon:
        testCaseLoaderState === "Test Cases" ? (
          <CircularProgress size="14px" />
        ) : (
          <GenerateButton />
        ),
      onClick: handleMultipleTestCases,
      sx: { float: "right" },
      disabled: !!testCaseLoaderState,
    },
  ];

  const [selectedStories, setSelectedStories] = useState(
    new Array(multipleUseCase.length).fill(true)
  );

  return (
    <Box width={"601px"}>
      <CommonAccordion
        summary={`Use Cases (${activeUseCases.length})`}
        details={
          <Box>
            <Box sx={{ overflow: "hidden", width: "100%" }}>
              <MultiAccordian
                summaries={summaries}
                multiAccoridanDetails={useCaseComponents}
                multiAccoridanData={multipleUseCase}
                setMultiAccordianData={setMultipleUseCase}
                selectedAccordians={selectedUseCase}
                setSelectedAccordians={setSelectedUseCase}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                editingValue={editingValue}
                setEditingValue={setEditingValue}
                loaderCheck={!!testCaseLoaderState}
              />
            </Box>
          </Box>
        }
        buttons={buttons}
        type={"output"}
        defaultExpanded={defaultMultipleUseCasesExpanded}
      />
      <CommonDownload
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        serviceResponse={multipleUseCase}
        serviceName="UseCases_Report"
        wordData={createWordData(selectedUseCase)}
      />
      <SelectionErrorPopUp open={noneUseCaseSelected} onClose={() => setNoneUseCaseSelected(false)} />
    </Box>
  );
};

export default MultipleUseCase;
