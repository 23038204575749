import React, { act, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ReactComponent as PenIcon} from "../../../images/penIcon.svg"; // Pen icon for editing
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const SingleCheckedAccordian = React.memo(
  ({
    summary,
    details,
    index,
    selectedAccordians,
    setSelectedAccordians,
    multiAccoridanData,
    setMultiAccordianData,
    isEditing,
    setIsEditing,
    editingValue,
    setEditingValue,
    currentPage,
    rowsPerPage,
    expandAll,
    loaderCheck
  }) => {
    const [expanded, setExpanded] = useState(null);

    useEffect(() => {
      if (expandAll) {
        setExpanded(index);
      } else {
        setExpanded(null);
      }
    }, [expandAll, currentPage]);

    useEffect(() => {
      if (isEditing === index) {
        setExpanded(index);
      }
    }, [isEditing]);

    const handleSelection = useCallback(
      (event, index) => {
        const updatedData = [...selectedAccordians];
        if (event.target.checked) {
          updatedData[index] = multiAccoridanData[index];
        } else {
          updatedData[index] = null;
        }
        setSelectedAccordians(updatedData);
      },
      [selectedAccordians, multiAccoridanData]
    );

    const handleEditSave = useCallback(
      (index) => {
        const updatedData = [...multiAccoridanData];
        const selectedUpdatedData = [...selectedAccordians];
        updatedData[index] = editingValue;
        if (selectedUpdatedData[index] !== null) {
          selectedUpdatedData[index] = editingValue;
        }
        // navigate("/dashboard/generate-multi/testcases/output", {
        //   state: {
        //     ...location.state,
        //     userStories: updatedData,
        //     selectedUserStories: selectedUpdatedData,
        //   },
        //   replace: true,
        // });
        setMultiAccordianData([...updatedData]);
        setSelectedAccordians([...selectedUpdatedData]);
        setIsEditing(null);
      },
      [multiAccoridanData, selectedAccordians, editingValue]
    );

    const handleEditCancel = useCallback(() => {
      setIsEditing(null);
      setEditingValue({});
    }, [setIsEditing, setEditingValue]);
    return (
      <Accordion
        sx={{
          borderRadius: "0px !important",
          width: "100%",
          overflow: "hidden",
        }}
        expanded={expanded === index}
        onChange={(e, isExpanded) =>
          isExpanded ? setExpanded(index) : setExpanded(null)
        }
      >
        <AccordionSummary
          sx={{
            height: "50px",
            backgroundColor:
              selectedAccordians[index] === null ? "white" : "#EAF1FF",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Box width="34px">
              {expanded === index ? (
                <ExpandMoreIcon sx={{ marginRight: 1 }} />
              ) : (
                <ChevronRightIcon sx={{ marginRight: 1 }} />
              )}
            </Box>
            <Box>
              <Checkbox
                checked={
                  JSON.stringify(multiAccoridanData[index]) ===
                  JSON.stringify(selectedAccordians[index])
                }
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelection(e, index);
                }}
                sx={{
                  '&.Mui-checked': {
                    color: "#49A3FF",
                  },
                }}
              />
            </Box>
            <Box sx={{ flexBasis: "80%", overflow: "hidden", flexShrink: 2 }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  overflow: "hidden",
                  height: "20px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: (isEditing === index ? "300px" : "400px"),
                }}
              >
                {summary}
              </Typography>
            </Box>
            {isEditing === index ? (
              <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <Button
                  sx={{ color: "#49A3FF", fontSize: "14px", textTransform: "none" }}
                  onClick={() => handleEditSave(index)}
                >
                  Save
                </Button>
                <Button
                  sx={{ color: "#49A3FF", fontSize: "14px", textTransform: "none" }}
                  onClick={() => handleEditCancel()}
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <IconButton
                className="edit-icon"
                onClick={() => {
                  setEditingValue(multiAccoridanData[index]);
                  setIsEditing(index);
                }}
                disabled={loaderCheck}
              >
                <PenIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: "40px" }}>
          {details}
        </AccordionDetails>
      </Accordion>
    );
  }
);

const MultiAccordian = ({
  summaries,
  multiAccoridanDetails,
  selectedAccordians,
  setSelectedAccordians,
  multiAccoridanData,
  setMultiAccordianData,
  isEditing,
  setIsEditing,
  editingValue,
  setEditingValue,
  loaderCheck
}) => {
  const [expandAll, setExpandAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  // Select All/Unselect All logic is derived from selectedAccordians
  const selectedAll =
    JSON.stringify(selectedAccordians) === JSON.stringify(multiAccoridanData);

  const activeData = [];
  multiAccoridanDetails.map((eachAccordianData, index) => {
    if (!!eachAccordianData) {
      return activeData.push({ [index]: eachAccordianData });
    }
  });

  const paginatedData = useMemo(() => {
    return activeData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [currentPage, multiAccoridanDetails]);

  const handlePageChange = (event, value) => setCurrentPage(value);

  const handleUnselectAll = () => {
    setSelectedAccordians(selectedAccordians.map(() => null));
  };

  const handleSelectAll = () => {
    setSelectedAccordians([...multiAccoridanData]);
  };

  const handleExpandeAll = () => {
    setExpandAll(!expandAll);
  };

  return (
    <Box width={"100%"}>
      <Box sx={{ height: "40px", width: "100%", paddingX: "10px" }}>
        {selectedAll ? (
          <Button
            sx={{ color: "#49A3FF", fontSize: "14px", textTransform: "none" }}
            onClick={handleUnselectAll}
          >
            Unselect all
          </Button>
        ) : (
          <Button
            sx={{ color: "#49A3FF", fontSize: "14px", textTransform: "none" }}
            onClick={handleSelectAll}
          >
            Select all
          </Button>
        )}
        <Button
          sx={{ color: "#49A3FF", fontSize: "14px", textTransform: "none" }}
          onClick={handleExpandeAll}
        >
          {expandAll ? "Collapse all" : "Expand all"}
        </Button>
      </Box>

      <Box
        sx={{
          overflowY: "scroll",
          "&::-webkit-scrollbar": { width: "0px" },
          scrollbarWidth: "none",
        }}
      >
        {paginatedData.map((eachPaginatedData) =>
          Object.entries(eachPaginatedData).map(
            ([index, eachAccordianDetails]) => (
              <Box key={`eachAccordian_${index}`}>
                <SingleCheckedAccordian
                  summary={summaries[index]}
                  details={eachAccordianDetails}
                  index={Number(index)}
                  selectedAccordians={selectedAccordians}
                  setSelectedAccordians={setSelectedAccordians}
                  multiAccoridanData={multiAccoridanData}
                  setMultiAccordianData={setMultiAccordianData}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editingValue={editingValue}
                  setEditingValue={setEditingValue}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  expandAll={expandAll}
                  loaderCheck={loaderCheck}
                />
              </Box>
            )
          )
        )}
      </Box>

      <Pagination
        count={Math.ceil(activeData.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ display: "flex", justifyContent: "center", mt: "5px" }}
      />

<Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default MultiAccordian;
