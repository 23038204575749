// StepDefinition.js
import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ReactComponent as ExportIcon } from '../../../images/exportIcon.svg';
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from '../../custom-components/CommonDownload';
import CodeEditorNew from './BlackScreenNew';
import LikeDislikeComponent from './CommonLikeDislikeComponent';
import { TestCaseFlowDataContext } from '../../main-pages/TestAutomationPage';
import { useLocation, useNavigate } from "react-router-dom";
import CodeEditor from './CodeEditor';
import { LoadingModalContext } from "../../main-pages/ServicePage";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { mavenProject } from '../../../services/webServices';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import SuccessPopup from './SuccessPopup'; // Import SuccessPopup
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";

const IconComponent = () => (
    <Box sx={{ maxWidth: 14, display: 'flex', flexDirection: 'column' }}>
        <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/74001bbbe35d84ed7dd44adbf23d2273b030df4d38296f54b84768e53b777ae2?placeholderIfAbsent=true&apiKey=61dcb7c26aae4febb0f66efb27027a73"
            alt=""
            style={{ width: '100%', objectFit: 'contain' }}
        />
    </Box>
);

const StepDefinition = () => {
    const [downloadOpen, setDownloadOpen] = useState(false);
    const { pageClass } = useContext(TestCaseFlowDataContext);
    const { stepDefinition } = useContext(TestCaseFlowDataContext);
    const { featureFiles } = useContext(TestCaseFlowDataContext);

    const [stepDefinitionData, setstepDefinitionData] = useState(stepDefinition);

    const setLoading = useContext(LoadingModalContext);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);

    const [openSuccessPopup, setOpenSuccessPopup] = useState(false); // State to control the SuccessPopup
    const [activeThumb, setActiveThumb] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        setstepDefinitionData(stepDefinition);
    }, [stepDefinition]);

    const handleDownloadClick = () => {
        setDownloadOpen(true);
    };

    const handleCloseDownload = () => {
        setDownloadOpen(false);
    };

    const handleCopyClick = () => {
        // Handle copy action (you can implement actual copying logic here)
        console.log("Content copied!"); // Placeholder for copy logic
        setIsCopied(true);
        // Reset the copied status after 2 seconds (optional)
        setTimeout(() => setIsCopied(false), 2000);
    };

    const handleCopy = () => {
        // Copy functionality for the editor content
        navigator.clipboard.writeText(stepDefinition); // Replace with actual code content
        alert("Code copied to clipboard");
    };

    const handleMavenProject = async () => {
        setLoading(true);

        const pageClassData = { "pageClassGenerated": pageClass };
        const stepDefinitionData = { "stepDefinitionGenerated": stepDefinition };
        const featureFileGenerated = featureFiles;

        try {
            const response = await mavenProject({
                featureFileGenerated,
                pageClassData,
                stepDefinitionData
            });

            let { pomXmlContent, testRunnerClassContent, configProperties, loggingConfig, supportClasses } = response.data;

            const zip = new JSZip();

            // Append the profile content after </build> in pomXmlContent
            if (response.data.testProfileContent) {
                const profileContent = response.data.testProfileContent;
                pomXmlContent = pomXmlContent.replace(
                    /<\/build>/,
                    `</build>\n${profileContent}`
                );
            }

            // Add the modified `pom.xml` content
            zip.file("pom.xml", pomXmlContent);

            if (testRunnerClassContent) zip.file("src/test/java/tests/runner/TestRunner.java", testRunnerClassContent);
            if (configProperties) zip.file("src/test/resources/config.properties", configProperties);
            if (loggingConfig) zip.file("src/main/resources/log4j.properties", loggingConfig);

            // Add support classes
            if (Array.isArray(supportClasses)) {
                supportClasses.forEach((supportClass) => {
                    if (supportClass.content && supportClass.name) {
                        zip.file(`src/test/java/${supportClass.name}`, supportClass.content);
                    }
                });
            }

            zip.file("src/test/resources/features/featureFiles.feature", featureFileGenerated);
            zip.file("src/test/java/pages/PageClasses.java", pageClassData.pageClassGenerated);
            zip.file("src/test/java/gluecode/StepDefinitions.java", stepDefinitionData.stepDefinitionGenerated);

            const blob = await zip.generateAsync({ type: "blob" });
            saveAs(blob, "mavenProject.zip");

            // After the project is downloaded, show the success popup
            setTimeout(setOpenSuccessPopup(true), 3000);


        } catch (error) {
            console.error("Error during Maven project creation:", error);
        } finally {
            setLoading(false);
        }
    };

    const buttonsgroup = [
        {
            label: "Export",
            variant: "text",
            startIcon: <IconComponent />,
        },
        {
            label: "Download",
            variant: "text",
            onClick: handleDownloadClick,
            startIcon: <SaveAltRoundedIcon />
        },
        {
            label: "Generate Maven Project",
            variant: "contained",
            startIcon: <GenerateButton />,
            sx: { float: "right" },
            onClick: handleMavenProject
        },
    ];

    const handleLike = () => {
        setLiked(!liked);
        if (disliked) setDisliked(false);
    };

    const handleDislike = () => {
        setDisliked(!disliked);
        if (liked) setLiked(false);
    };

    const handleThumbClick = (thumbType) => {
        // Toggle thumbs based on the thumbType (like or dislike)
        setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
    };

    return (
        <>
        <Box>
      
            <CommonAccordion
                summary={"Step Definition"}
                details={
                    <Box>
                        <CodeEditor codeContent={stepDefinitionData} setCodeContent={setstepDefinitionData} />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2,marginTop:2 }}>
                                <Tooltip title="Like" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("like")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "like" ? (
                                            <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                                        ) : (
                                            <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Thumbs Down Button with Tooltip */}
                                <Tooltip title="Dislike" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("dislike")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#FF4842", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "dislike" ? (
                                            <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                                        ) : (
                                            <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Copy Button with Tooltip */}
                                <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                                    <Box
                                        onClick={handleCopyClick}
                                        sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {isCopied ? (
                                            <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                                        ) : (
                                            <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                                        )}
                                    </Box>
                                </Tooltip>
                        </Box>
                    </Box>
                }
                buttons={buttonsgroup}
                fullScreenMode={true}
                type={"output"}
                defaultExpanded={true}
            ></CommonAccordion>
            <CommonDownload open={downloadOpen} onClose={handleCloseDownload}
               serviceResponse={stepDefinitionData}
               serviceName="Step_Definition_data"
               wordData={[{heading: "Step Definition", type: "text", data: stepDefinitionData}]}
             />
     
        </Box>
        

            <CommonDownload
                open={downloadOpen}
                onClose={handleCloseDownload}
                serviceResponse={stepDefinitionData}
                serviceName="Step_Definition_data"
                wordData={[{ heading: "Step Definition", type: "text", data: stepDefinitionData }]}
            />

            <SuccessPopup open={openSuccessPopup} handleClose={() => setOpenSuccessPopup(false)} />
        </>
    );
};

export default StepDefinition;
