import { Box, Container } from "@mui/material";
import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import QMentisBreadCrumb from "../custom-components/QMentisBreadCrumb";

export const MultipleTestCasesContext = createContext(null);
export const TestCasesLoaderContext = createContext(null);

const MultiTestCaseGenerationPage = () => {
  const [multipleUserStories, setMultipleUserStories] = useState([]);
  const [selectedUserStories, setSelectedUserStories] = useState([
    ...multipleUserStories,
  ]);
  const [multipleEvaluationSummary, setMultipleEvaluationSummary] = useState(
    []
  );
  const [multipleRefinedUserStory, setMultipleRefinedUserStory] = useState([]);
  const [selectedRefinedUserStories, setSelectedRefinedUserStories] = useState([
    ...multipleRefinedUserStory,
  ]);
  const [multipleUseCases, setMultipleUseCases] = useState([]);
  const [selectedUseCases, setSelectedUseCases] = useState([
    ...multipleUseCases,
  ]);
  const [multiTestCases, setMultiTestCases] = useState([]);
  const [selectedMultipleTestCases, setSelectedMultipleTestCases] = useState([]);

  const [testCaseLoaderState, setTestCaseLoaderState] = useState(null);

  const breadCrumbs = [
    {
      title: "Input Story Details",
      status: multipleUserStories.length > 0 ? "Completed" : "In Progress",
    },
    {
      title: "Evaluate User Stories",
      status:
        multipleEvaluationSummary.length === multipleUserStories.length &&
        multipleUserStories.length > 0
          ? "Completed"
          : multipleUserStories.length > 0 && testCaseLoaderState === "Evaluation Summary"
          ? "In Progress"
          : "Not Started",
    },
    {
      title: "Refine User Stories",
      status:
        multipleRefinedUserStory.length === multipleUserStories.length &&
        multipleUserStories.length > 0
          ? "Completed"
          : multipleEvaluationSummary.length === multipleUserStories.length &&
            multipleUserStories.length > 0 && testCaseLoaderState === "Refined User Stories"
          ? "In Progress"
          : "Not Started",
    },
    {
      title: "Generate Use Cases",
      status:
        multipleUseCases.length === multipleUserStories.length &&
        multipleUserStories.length > 0
          ? "Completed"
          : multipleRefinedUserStory.length === multipleUserStories.length &&
            multipleUserStories.length > 0 && testCaseLoaderState === "Use Case"
          ? "In Progress"
          : "Not Started",
    },
    {
      title: "Generate Test Cases",
      status:
        multiTestCases.length === multipleUserStories.length &&
        multipleUserStories.length > 0
          ? "Completed"
          : multipleUseCases.length === multipleUserStories.length &&
            multipleUserStories.length > 0 && testCaseLoaderState === "Test Cases"
          ? "In Progress"
          : "Not Started",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Box sx={{ maxHeight: "100%", width: "100%" }}>
        {/* Fixed breadcrumb where you want it */}
        <Box
          sx={{
            position: "sticky",
            top: "0", // Adjust this value as needed to place the breadcrumb where you want it
            width: "100%",
            zIndex: 1000, // Ensure it stays above other content
            bgcolor: "white", // Background color to prevent transparency
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow for visual separation
          }}
        >
          <QMentisBreadCrumb breadCrumbs={breadCrumbs} sx={{height: "56px"}} />
        </Box>

        {/* Add padding-top to avoid overlap with the fixed breadcrumb */}
        <Container
          sx={{
            width: "100%",
          }}
        >
          <TestCasesLoaderContext.Provider
            value={{ testCaseLoaderState, setTestCaseLoaderState }}
          >
            <MultipleTestCasesContext.Provider
              value={{
                multipleUserStories,
                setMultipleUserStories,
                selectedUserStories,
                setSelectedUserStories,
                multipleEvaluationSummary,
                setMultipleEvaluationSummary,
                multipleRefinedUserStory,
                setMultipleRefinedUserStory,
                selectedRefinedUserStories,
                setSelectedRefinedUserStories,
                multipleUseCases,
                setMultipleUseCases,
                selectedUseCases,
                setSelectedUseCases,
                multiTestCases,
                setMultiTestCases,
                selectedMultipleTestCases,
                setSelectedMultipleTestCases,
              }}
            >
              <Outlet />
            </MultipleTestCasesContext.Provider>
          </TestCasesLoaderContext.Provider>
        </Container>
      </Box>
    </Box>
  );
};

export default MultiTestCaseGenerationPage;
