import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  TableSortLabel,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from "../../custom-components/CommonDownload";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import { refinedUserstory } from "../../../services/webServices";
import {
  MultipleTestCasesContext,
  TestCasesLoaderContext,
} from "../../main-pages/MultiTestCaseGenerationPage";

const headers = [
  "Title",
  "Independent",
  "Negotiable",
  "Valuable",
  "Estimable",
  "Small",
  "Testable",
];

const MultipleEvaluationSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { testCaseLoaderState, setTestCaseLoaderState } = useContext(
    TestCasesLoaderContext
  );
  const {
    multipleRefinedUserStory,
    setMultipleRefinedUserStory,
    setMultipleUseCases,
    setMultiTestCases,
  } = useContext(MultipleTestCasesContext);
  const [evaluationSummaryData, setEvaluationSummaryData] = useState([]);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const rowsPerPage = 5;

  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleDownloadClick = () => setDownloadOpen(true);
  const handleCloseDownload = () => setDownloadOpen(false);

  const handleRowToggle = (index) => {
    setExpandedRow((prev) => (prev === index ? null : index));
  };

  const userStoryDetails = useContext(MultipleTestCasesContext).multipleUserStories || [];
  const evaluationSummaryResponse = useContext(
    MultipleTestCasesContext
  ).multipleEvaluationSummary;

  const defaultMultipleEvaluationSummaryExpanded = evaluationSummaryResponse.length > 0 && multipleRefinedUserStory.length === 0;

  useEffect(() => {
    if (evaluationSummaryResponse.length > 0) {
      const combinedSummary = evaluationSummaryResponse.map(
        (evalSummary, index) => {
          const evalParametersArray = Array.isArray(evalSummary)
            ? evalSummary
            : [evalSummary];

          const title = userStoryDetails[index]?.Title || null;
          return {
            Title: title, //userStoryDetails[index]?.Title,
            EvaluationParameters: evalParametersArray.map((param) => ({
              Name: param?.Parameter,
              Rating: param?.Rating,
              Explanation: param?.Explanation,
              Recommendation: param?.Recommendation,
            })).filter(param => param.Rating || param.Explanation || param.Recommendation),
          };
        }
      ); //.filter(item => item.Title && item.EvaluationParameters.length > 0);

      const finalSummary =
        userStoryDetails.length === 0
          ? combinedSummary.fill({ Title: null, EvaluationParameters: [] }, 0)
          : combinedSummary.filter(
            (item) => item.Title && item.EvaluationParameters.length > 0
          );

      setEvaluationSummaryData(finalSummary);
    }
  }, [evaluationSummaryResponse]);

  // console.log("evaluationSummaryResponse", evaluationSummaryResponse);
  // Formatting or flattening the test cases data values(Array or Objects) into strings
  const formattedEvaluationSummary = [];
  evaluationSummaryData.map((evalSummary) => {
    const flattenEvaluationSummary = {};

    Object.entries(evalSummary).map(([key, value]) => {
      if (Array.isArray(value)) {
        flattenEvaluationSummary[key] = flattenArray(value);
      } else if (!!value && typeof value === "object") {
        flattenEvaluationSummary[key] = flattenObject(value);
      } else {
        flattenEvaluationSummary[key] = value;
      }
    });

    formattedEvaluationSummary.push(flattenEvaluationSummary);
  });

  const handleRefinedUserStory = async () => {
    setTestCaseLoaderState("Refined User Stories");
    const updatedRefinedUserStories = [];
    try {
      // setLoading(true);
      setMultipleRefinedUserStory([]);
      setMultipleUseCases([]);
      setMultiTestCases([]);
      for (const [
        index,
        eachRefiendUserStory,
      ] of evaluationSummaryResponse.entries()) {
        if (eachRefiendUserStory === null || !eachRefiendUserStory) {
          updatedRefinedUserStories[index] = null;
          continue;
        }
        const requestData = {
          EvaluationSummary: eachRefiendUserStory,
          UserStory: userStoryDetails[index],
        };

        const refinedUserStories = await refinedUserstory(requestData);
        const flattenedRefinedUserStory = {};
        Object.entries(refinedUserStories.data.refinedUserStory).map(
          ([key, value]) => {
            if (Array.isArray(value)) {
              return (flattenedRefinedUserStory[key] = flattenArray(value));
            }
            if (typeof value === "object" && !!value) {
              return (flattenedRefinedUserStory[key] = flattenObject(value));
            }
            return (flattenedRefinedUserStory[key] = value);
          }
        );

        setMultipleRefinedUserStory((prevState) => {
          const updatingRefinedUserStories = [...prevState];
          updatingRefinedUserStories[index] = flattenedRefinedUserStory;
          return updatingRefinedUserStories;
        });
        updatedRefinedUserStories[index] = flattenedRefinedUserStory;
      }

      navigate("/dashboard/generate-multi/testcases/output", {
        state: {
          ...location.state,
          multipleRefinedUserStory: [...updatedRefinedUserStories],
          selectedRefinedUserStories: Array(updatedRefinedUserStories.length).fill(null),
          multipleUseCases: [],
          selectedMultipleUseCases: [],
          multipleTestCases: [],
        },
        replace: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setTestCaseLoaderState(null);
    }
  };

  const handlePageChange = (event, value) => setCurrentPage(value);

  const handleSort = (headerIndex) => {
    const key = headers[headerIndex];
    const isAsc = sortConfig.key === key && sortConfig.direction === "asc";
    const direction = isAsc ? "desc" : "asc";

    const sortedData = [...evaluationSummaryData].sort((a, b) => {
      const ratingA = parseInt(a.EvaluationParameters[headerIndex - 1].Rating);
      const ratingB = parseInt(b.EvaluationParameters[headerIndex - 1].Rating);
      if (ratingA < ratingB) return direction === "asc" ? -1 : 1;
      if (ratingA > ratingB) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setEvaluationSummaryData(sortedData);
  };

  const paginatedData = evaluationSummaryData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  // const paginatedData = React.useMemo(() => {
  //   return evaluationSummaryData.slice(
  //     (currentPage - 1) * rowsPerPage,
  //     currentPage * rowsPerPage
  //   );
  // }, [evaluationSummaryData, currentPage]);

  const getRatingStyle = (rating) => {
    if (rating <= 2) {
      return { backgroundColor: " #FFDAD7" }; // Red
    } else if (rating === 3) {
      return { backgroundColor: "#fff176" }; // Yellow
    } else if (rating >= 4) {
      return { backgroundColor: "#D0FFAA" }; // Green
    }
    return {};
  };

  const tableRender = () => {
    return (
      <Box sx={{ overflowX: "auto" }}>
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      position: "sticky",
                      top: 0,
                      left: index === 0 ? 0 : "auto",
                      zIndex: index === 0 ? 3 : 1,
                      backgroundColor: "#fff",
                      textAlign: index === 0 ? "left" : "center",
                    }}
                  >
                    {index === 0 ? (
                      header
                    ) : (
                      <TableSortLabel
                        active={sortConfig.key === header}
                        direction={sortConfig.direction}
                        onClick={() => handleSort(index)}
                        IconComponent={SwapVertOutlinedIcon}
                      >
                        {header}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((evaluationGroup, groupIndex) => {
                const { Title, EvaluationParameters } = evaluationGroup;

                return (
                  <React.Fragment key={groupIndex}>
                    <TableRow
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowToggle(groupIndex)}
                    >
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 0,
                          zIndex: 2,
                          backgroundColor: "#fff",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowToggle(groupIndex);
                            }}
                          >
                            {expandedRow === groupIndex ? (
                              <ExpandMoreIcon />
                            ) : (
                              <KeyboardArrowRightTwoToneIcon />
                            )}
                          </IconButton>
                          <Box
                            sx={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "303px",
                              verticalAlign: "middle",
                              cursor: "pointer",
                              "&:hover": {
                                overflow: "visible",
                                whiteSpace: "normal",
                                backgroundColor: "#f0f0f0",
                                padding: "2px",
                              },
                            }}
                          >
                            {Title}
                          </Box>
                        </Box>
                      </TableCell>
                      {EvaluationParameters &&
                        EvaluationParameters.map(
                          ({ Name, Rating }, paramIndex) => (
                            <TableCell key={`${groupIndex}-${paramIndex}`}>
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "32px",
                                  height: "20px",
                                  borderRadius: "4px",
                                  ...getRatingStyle(parseInt(Rating)),
                                }}
                              >
                                {Rating}
                              </Box>
                            </TableCell>
                          )
                        )}
                    </TableRow>

                    {expandedRow === groupIndex && (
                      <>
                        {/* Explanation Row */}
                        <TableRow>
                          <TableCell
                            sx={{
                              position: "sticky",
                              left: 0,
                              zIndex: 2,
                              textAlign: "center",
                              verticalAlign: "middle",
                              backgroundColor: "#fff",
                            }}
                          >
                            Explanation
                          </TableCell>
                          {EvaluationParameters &&
                            EvaluationParameters.map(
                              ({ Name, Explanation }, paramIndex) => (
                                <TableCell
                                  key={`exp-${groupIndex}-${paramIndex}`}
                                >
                                  {Explanation}
                                </TableCell>
                              )
                            )}
                        </TableRow>

                        {/* Recommendation Row */}
                        <TableRow>
                          <TableCell
                            sx={{
                              position: "sticky",
                              left: 0,
                              zIndex: 2,
                              textAlign: "center",
                              verticalAlign: "middle",
                              backgroundColor: "#fff",
                            }}
                          >
                            Recommendation
                          </TableCell>
                          {EvaluationParameters &&
                            EvaluationParameters.map(
                              ({ Name, Recommendation }, paramIndex) => (
                                <TableCell
                                  key={`rec-${groupIndex}-${paramIndex}`}
                                >
                                  {Recommendation}
                                </TableCell>
                              )
                            )}
                        </TableRow>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(evaluationSummaryData.length / rowsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
        />

        <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
          {/* Thumbs Up Button with Tooltip */}
          <Tooltip title="Like" arrow>
            <Box
              onClick={() => handleThumbClick("like")}
              sx={{
                cursor: "pointer",
                marginRight: 2, // Margin between the buttons
                "&:hover": {
                  color: "#49A3FF", // Change color on hover
                },
              }}
            >
              {activeThumb === "like" ? (
                <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
              ) : (
                <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
              )}
            </Box>
          </Tooltip>

          {/* Thumbs Down Button with Tooltip */}
          <Tooltip title="Dislike" arrow>
            <Box
              onClick={() => handleThumbClick("dislike")}
              sx={{
                cursor: "pointer",
                marginRight: 2, // Margin between the buttons
                "&:hover": {
                  color: "#FF4842", // Change color on hover
                },
              }}
            >
              {activeThumb === "dislike" ? (
                <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
              ) : (
                <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
              )}
            </Box>
          </Tooltip>

          {/* Copy Button with Tooltip */}
          <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
            <Box
              onClick={handleCopyClick}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "#49A3FF", // Change color on hover
                },
              }}
            >
              {isCopied ? (
                <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
              ) : (
                <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
              )}
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const createWordData = (evaluationSummaryTableData) => {
    const wordData = [];
    evaluationSummaryTableData.map((eachSummary, index) => {
      if (!!eachSummary) {
        const headers = Object.keys(eachSummary[0]);
        const values = eachSummary.map((eachParameterSummary) =>
          Object.values(eachParameterSummary)
        );
        wordData.push({
          heading: "User Story", type: "text", data: userStoryDetails[index]["Title"]
        })
        wordData.push({
          heading: "Evaluation Summary",
          type: "table",
          data: { headers, values },
        });
        wordData.push({ type: "pageBreak" });
        return;
      }
    });
    return wordData;
  };

  return (
    <Box>
      <CommonAccordion
        summary={"Evaluation Summary"}
        details={tableRender()}
        type={"output"}
        buttons={[
          {
            label: "Download",
            onClick: handleDownloadClick,
            variant: "text",
            startIcon: <FileDownloadOutlinedIcon />,
          },
          {
            label: "Refine User Stories",
            onClick: handleRefinedUserStory,
            variant: "contained",
            startIcon:
              testCaseLoaderState === "Refined User Stories" ? (
                <CircularProgress size="14px" />
              ) : (
                <SendSharpIcon />
              ),
            sx: { float: "right" },
            disabled: !!testCaseLoaderState,
          },
        ]}
        fullScreenMode={true}
        defaultExpanded={defaultMultipleEvaluationSummaryExpanded}
      />
      <CommonDownload
        open={downloadOpen}
        onClose={handleCloseDownload}
        wordData={createWordData(evaluationSummaryResponse)}
        serviceName="Evaluation_Summary_Report"
        serviceResponse={evaluationSummaryResponse}
      />
    </Box>
  );
};

export default MultipleEvaluationSummary;
