export const serviceResponseTransformation = (
  data,
  serviceName,
  exportType
) => {
  switch (serviceName) {

    case "User_Story": {
      if (!data || typeof data !== "object") {
        return { headers: [], values: [] };
      }
      const isArray = Array.isArray(data);
      const headers = Object.keys(isArray ? data[0] : data);
      const values = isArray
        ? data.map((obj) => headers.map((header) => obj[header]))
        : [Object.values(data)];

      return { headers, values };
    }


    case "Bug_Report": {
      if (exportType === "csv" || exportType === "xls") {
        const headers = Object.keys(data);
        const values = [Object.values(data)];
        return { headers, values };
      }

      if (exportType === "pdf" || exportType === "docx") {
        const headers = ["Property", "Value"];
        const values = Object.entries(data).map(([key, value]) => [key, value]);
        return { headers, values };
      }
      break;
    }
    case "Evaluation_Summary_Report": {
      const headers = ["Parameter", "Rating", "Explanation", "Recommendations"];
      const values = Object.entries(data).map(([key, value]) => [
        ...Object.entries(value).map(([innerKey, innerValues]) => innerValues),
      ]);
      return { headers, values };
    }
    case "Refined_User_Story": {
      const headers = ["parameter", "Enhanced values"];
      const values = Object.entries(data).map(([key, value]) => [key, value]);
      return { headers, values };
    }
    case "TestCases_Report": {
      const headers = Object.keys(data[0]);
      const values = data.map((item) => Object.values(item));
      return { headers, values };
    }
    case "TestPlan_Report": {
      const headers = ["Parameter", "Values"];
      const values = Object.entries(data).map(([key, value]) => [key, value]);
      return { headers, values };
    }
    case "TestPlan_Input": {
      const headers = ["Parameter", "Values"];
      const values = Object.entries(data).map(([key, value]) => [key, value]);
      return { headers, values };
    }
    case "FeatureFiles_Report": {
      const headers = ["Feature File", "Details"];
      const values = Object.entries(data).map(([key, value]) => [key, value]);
      return { headers, values };
    }
    case "UseCases_Report": {
      const headers = ["Parameter", "Values"];
      const values = Object.entries(data).map(([key, value]) => [key, value]);
      return { headers, values };
    }
    default: {
      const headers = [];
      const values = [];
      return { headers, values };
    }
  }
};
