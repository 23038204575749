import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Box,
  Container,
  Divider,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import {ReactComponent as AutomateIcon} from '../../images/automateIcon.svg';
import { toast, ToastContainer } from "react-toastify";
import { getJiraConfig, saveJiraConfig } from "../../services/webServices";
import DownloadScriptButton from "../custom-components/CreateJiraCustomFields";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveJIRAPopup from "../popup-components/SaveJIRAPopup";

const AccountManagement = () => {
  // State variables to store the values of the text fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [user, setUser] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [screenName, setScreenName] = useState("");
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [jiraMessage, setJiraMessage] = useState("");

  // State variable to control the Save button's disabled state
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  // const userEmail = localStorage.getItem("email"); // Retrieve the email from localStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJiraConfig();
        // Set state with the response values directly
        setUrl(response.data.jira_url || ""); // Handle undefined or null
        setUser(response.data.jira_user || "");
        setApiToken(response.data.jira_api_token || "");
        setProjectKey(response.data.jira_project_key || "");
        setScreenName(response.data.jira_screen_name || "");
      } catch (error) {
        console.error("Error fetching Jira configuration:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect hook to enable/disable the Save button based on form validation
  useEffect(() => {
    // Check if all required fields are filled
    if (url && user && apiToken && projectKey && screenName) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [url, user, apiToken, projectKey, screenName]);

  useEffect(() => {
    // Set background color for the entire page
    document.body.style.backgroundColor = "#F6F3F2";
    document.body.style.margin = "0";
    document.body.style.minHeight = "100vh";

    return () => {
      // Reset styles on component unmount
      document.body.style.backgroundColor = "";
      document.body.style.margin = "";
      document.body.style.minHeight = "";
    };
  }, []);

  const handleSave = async () => {
    // const email = localStorage.getItem("email");
    // if (!email) {
    //   toast.error("Session has expired. Please log in again.");
    //   return; // Stop further execution if session is expired
    // }
    try {
      // Attempt to save Jira configuration
      const response = await saveJiraConfig({
        jiraUrl: url,
        jiraUser: user,
        jiraApiToken: apiToken,
        jiraProjectKey: projectKey,
        jiraScreenName: screenName,
      });

      if (response.data.message === "Details already saved") {
        toast.info("Details already saved.");
        setJiraMessage("JIRA Configuration Details already saved!!");
        setOpenLogoutDialog(true);
      } else {
        toast.success("Form data saved successfully.");
        setJiraMessage("JIRA Configuration Details Saved!!");
        setOpenLogoutDialog(true);
      }
    } catch (error) {
      console.log("Error object:", error); // Log the error to inspect its structure

      if (error.response?.status === 409) {
        setJiraMessage("JIRA Configuration Details already saved!!");
        toast.error("Details already saved.");
      } else if (error.response?.status === 401) {
        setJiraMessage("Invalid Jira API credentials. Please verify your Jira API key and URL.");
        toast.error("Invalid Jira API credentials. Please verify your Jira API key and URL.");
      } else if (
        (error.response?.status === 400 &&
          error.response.data.error === "Invalid Jira Project Key")
      ) {
        setJiraMessage("Invalid Jira Project Key. Please provide a valid project key.");
        toast.error(
          "Invalid Jira Project Key. Please provide a valid project key."
        );
      } else if (
        (error.response?.status === 400 &&
          error.response.data.error === "Invalid Jira Screen Name")
      ) {
        toast.error("Invalid Jira Screen Name. Please provide a valid screen name.");
        setJiraMessage("Invalid Jira Screen Name. Please provide a valid screen name.");
      } else if (error.response?.status === 502) {
        toast.error("Network failure: Unable to connect to the Jira server. Please check your network or Jira URL.");
        setJiraMessage("Network failure: Unable to connect to the Jira server. Please check your network or Jira URL.");
      } else if (error.response?.status === 504) {
        toast.error("Request timeout: The Jira server took too long to respond.");
        setJiraMessage("Request timeout: The Jira server took too long to respond.");
      } else if (
        error.response?.status === 404 &&
        error.response.statusText === "Jira configuration not found"
      ) {
        toast.error("Jira configuration is not set up for this email. Please configure it in the system.");
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      setOpenLogoutDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <Box
      className="bug-report-container"
      sx={{ bgcolor: "#F6F3F2", minHeight: "100vh", mb:3 }}
    >
      <Container maxWidth="lg">
        <Divider sx={{ mb: 3 }} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            mb={2}
          >
            <AutomateIcon sx={{ mr: 1 }} />
            <ChevronRightIcon />
            <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
              <Typography variant="subtitle1" sx={{ mr: 0.5 }}>
                Account Management
              </Typography>
            </Box>
          </Box>

          {/* <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{ mb: 2, alignSelf: "flex-start" }}
          >
            Generate detailed and insightful bug reports with ease
          </Typography> */}
          <Card elevation={3}>
            <CardContent>
              {/* <Typography variant="h6" gutterBottom>
                Global Configuration
              </Typography>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              /> */}
              <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                Jira Configuration
              </Typography>

              <form>
                <TextField
                  fullWidth
                  label="URL*"
                  variant="outlined"
                  margin="normal"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="User*"
                  variant="outlined"
                  margin="normal"
                  value={user}
                  onChange={(e) => setUser(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="API Token*"
                  variant="outlined"
                  margin="normal"
                  //type="password"
                  value={apiToken}
                  onChange={(e) => setApiToken(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Project Key*"
                  variant="outlined"
                  margin="normal"
                  value={projectKey}
                  onChange={(e) => setProjectKey(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Screen Name*"
                  variant="outlined"
                  margin="normal"
                  value={screenName}
                  onChange={(e) => setScreenName(e.target.value)}
                />
                <Divider sx={{ borderColor: "grey", mt: 2 }} />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={4}
                >
                  <Box>
                    <Button
                      variant="text"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      sx={{ mr: 2, textTransform: "none" }}
                    >
                      Add Custom Field
                    </Button>

                    {/* <Button
                      variant="text"
                      startIcon={<ArrowCircleDownOutlinedIcon />}
                      sx={{ textTransform: "none" }}
                    >
                      Download Script
                    </Button> */}
                    <DownloadScriptButton />
                  </Box>
                  <Button
                    variant="contained"
                    startIcon={<SendIcon />}
                    sx={{ textTransform: "none" }}
                    disabled={isSaveDisabled} // Disable the button based on form validation
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <SaveJIRAPopup
        jiraMessage={jiraMessage}
        open={openLogoutDialog}
        onClose={handleCloseDialog}
      />
    </Box>
  );
};

export default AccountManagement;
