import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Divider, // Import the Divider component
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send"; // Import Send icon from MUI icons
import { useNavigate } from "react-router-dom";

function SessionExpired() {
  const [open, setOpen] = useState(false); // Manage open state inside the component
  const navigate = useNavigate();

  // Automatically open the dialog when the component mounts
  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleLogout = () => {
    navigate("/");
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose} // Pass handleClose to handle dialog closing
      PaperProps={{
        sx: {
          borderRadius: "8px",
          minWidth: "400px",
          padding: "20px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "black",
          fontSize: "20px", // Adjust font size for title
          paddingBottom: "10px",
          fontFamily: "Roboto, sans-serif", // Match font family
        }}
      >
        Session expired
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: "20px" }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "16px", // Match the font size of the body text
            fontFamily: "Roboto, sans-serif", // Ensure font family is consistent
            color: "#333", // Match text color
          }}
        >
          Your session has expired. Please log in again to continue
        </Typography>
      </DialogContent>

      {/* Add Divider above the buttons */}
      <Divider />

      {/* Dialog Actions - Align both buttons to the right corner */}
      <DialogActions sx={{ justifyContent: "flex-end", padding: "10px 24px" }}>
        {/* Login Button (Blue) with Send Icon */}
        <Button
          onClick={handleLogout} // Confirm the logout action
          variant="contained"
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "14px", // Same font size for Yes button text
            fontFamily: "Roboto, sans-serif", // Same font family
            color: "white",
            backgroundColor: "#49A3FF",
            display: "flex",
            alignItems: "center",
            padding: "5px 15px",
          }}
        >
          <SendIcon sx={{ marginRight: "8px" }} />{" "}
          {/* Replace icon with Send icon */}
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionExpired;
