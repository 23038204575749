import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  PageBreak,
  Paragraph,
  TextRun,
} from "docx";
import { generateFilename } from "./download-utils/generateFilenameToExport";
import { saveAs } from "file-saver";
import { Table, TableCell, TableRow } from "docx";
import { flattenArray, flattenObject } from "../../utils/dataFlatteners";

function createTextSection(eachWordSectionData) {
  return new Paragraph({
    children: [
      new TextRun({
        text: eachWordSectionData.heading,
        heading: HeadingLevel.HEADING_2,
        font: "Arial",
        size: 32,
        color: "#1976d2",
        break: 1,
      }),
      ...eachWordSectionData.data
        .toString()
        .split("\n")
        .map(
          (eachLine) =>
            new TextRun({
              text: eachLine,
              font: "Arial",
              size: 24,
              break: 1,
            })
        ),
    ],
  });
}

function createSubParagraphsSection(eachWordSectionData) {
  const flattenedInputRows = {};

  Object.entries(eachWordSectionData["data"]).map(([key, value]) => {
    if (Array.isArray(value)) {
      return (flattenedInputRows[key] = flattenArray(value));
    } else if (typeof value === "object" && !!value) {
      return (flattenedInputRows[key] = flattenObject(value));
    } else {
      return (flattenedInputRows[key] = value);
    }
  });
  const subParaHeading = new Paragraph({
    children: [
      new TextRun({
        text: eachWordSectionData["heading"],
        heading: HeadingLevel.HEADING_2,
        font: "Arial",
        size: 30,
        color: "#1976d2",
      }),
    ],
  });
  const subParaData = Object.entries(flattenedInputRows).map(
    ([key, value]) =>
      new Paragraph({
        children: [
          new TextRun({
            text: key,
            heading: HeadingLevel.HEADING_3,
            font: "Arial",
            size: 28,
            color: "#1976d2",
            break: 1,
          }),
          ...value
            .toString()
            .split("\n")
            .map(
              (eachLine) =>
                new TextRun({
                  text: eachLine,
                  size: 24, // 12pt font
                  font: "Arial",
                  break: 1,
                })
            ),
        ],
      })
  );
  return [subParaHeading, ...subParaData];
}

async function createImageSection(eachWordSectionData) {
  try {
    const imagePngBlob = await convertSvgToPng(eachWordSectionData.data);
    const imageBuffer = await imagePngBlob.arrayBuffer();

    return new Paragraph({
      children: [
        new TextRun({
          text: eachWordSectionData.heading,
          heading: HeadingLevel.HEADING_2,
          font: "Arial",
          size: 32,
          color: "#1976d2",
          break: 1,
        }),
        new ImageRun({
          data: imageBuffer,
          transformation: {
            width: 500,
            height: 500,
            break: 1,
          },
          // break: 1,
        }),
      ],
    });
  } catch (error) {
    console.error("Error creating image section:", error);
    throw error;
  }
}

function createTableSection(eachWordSectionData) {
  const tableHeader = new TableRow({
    children: eachWordSectionData.data.headers.map(
      (eachCellData) =>
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: eachCellData,
                  bold: true,
                  size: 24, // 12pt font
                  font: "Arial",
                  color: "ffffff",
                  break: 1,
                }),
              ],
            }),
          ],
          shading: {
            fill: "0095ff", // Blue background for header
          },
        })
    ),
  });

  const tableBody = eachWordSectionData.data.values.map(
    (eachRow) =>
      new TableRow({
        children: eachRow.map(
          (eachCellData) =>
            new TableCell({
              children: [
                new Paragraph({
                  children: eachCellData
                    .toString()
                    .split("\n")
                    .map(
                      (eachLine) =>
                        new TextRun({
                          text: eachLine,
                          size: 24, // 12pt font
                          font: "Arial",
                          break: 1,
                        })
                    ),
                }),
              ],
            })
        ),
      })
  );
  const tableSection = new Table({
    rows: [tableHeader, ...tableBody],
    width: {
      size: 100,
      type: "pct",
    },
  });

  return [
    new Paragraph({
      children: [
        new TextRun({
          break: 1,
          text: eachWordSectionData.heading,
          heading: HeadingLevel.HEADING_2,
          font: "Arial",
          size: 32, // 16pt font (2 * 16)
          color: "#1976d2",
        }),
      ],
    }),
    tableSection,
  ];
}

function createPageBreak() {
  return [
    new Paragraph({
      children: [
        new PageBreak()
      ]
    })
  ]
};

export default async function wordGenerator(wordSectionsData, filename) {
  // const svgElement = document.getElementById("QEBotLogoApp");
  const svgString = `<svg width="125" height="125"  viewBox="0 0 254 254" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M183.779 179.011C196.337 165.31 204 147.05 204 127C204 84.4741 169.526 50 127 50C84.4741 50 50 84.4741 50 127C50 169.526 84.4741 204 127 204C147.318 204 165.797 196.131 179.557 183.274L143.811 148.054C142.631 146.891 142.617 144.992 143.78 143.811C144.943 142.631 146.842 142.617 148.022 143.78L183.779 179.011ZM127.1 102.36C127.569 109.83 133.322 125.24 152.36 127.117C144.262 128.196 127.829 134.755 127.1 152.36C127.238 144.566 122.526 128.606 102.36 127.117C110.044 126.794 125.707 121.389 127.1 102.36ZM169.993 96.2768C160.855 95.3759 158.381 87.9791 158.155 84.3934C157.486 93.5274 149.682 96.1215 145.993 96.2768C155.673 96.9914 158.221 104.652 158.155 108.393C158.505 99.9429 166.106 96.7946 169.993 96.2768Z" fill="#0096FF"/>
</svg>
`;

  const logoPngBlob = await convertSvgToPng(svgString);
  const logoPngBuffer = await logoPngBlob.arrayBuffer();

  const allSections = await Promise.all(
    wordSectionsData.map(async (eachWordSectionData, index) => {
      switch (eachWordSectionData.type) {
        case "text":
          return createTextSection(eachWordSectionData);
        case "subParagraphs":
          return createSubParagraphsSection(eachWordSectionData);
        case "image":
          return await createImageSection(eachWordSectionData);
        case "table":
          return createTableSection(eachWordSectionData);
        case "pageBreak":
          if (index === (wordSectionsData.length - 1)){
            return [];
          } else {
            return createPageBreak();
          }
      }
    })
  );

  const flattendAllSections = allSections.flat(Infinity);

  const doc = new Document({
    sections: [
      {
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new ImageRun({
                            data: logoPngBuffer,
                            transformation: {
                              width: 64,
                              height: 64,
                            },
                          }),
                        ],
                      }),
                    ],
                    verticalAlign: "center",
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: " QMentisAI™ - Intelligence for Next-Gen Quality",
                            font: "Arial",
                            size: 32,
                          }),
                          new TextRun({
                            text: " From AICoE, QualiZeal",
                            font: "Arial",
                            size: 27,
                            break: 1,
                          }),
                        ],
                        alignment: "left", // Horizontally center the text
                      }),
                    ],
                    verticalAlign: "center", // Vertically center the text
                  }),
                ],
              }),
            ],
            borders: {
              top: { size: 0, color: "FFFFFF" },
              bottom: { size: 0, color: "FFFFFF" },
              left: { size: 0, color: "FFFFFF" },
              right: { size: 0, color: "FFFFFF" },
              insideVertical: { size: 0, color: "FFFFFF" },
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "",
              }),
            ],
          }),
          ...flattendAllSections,
        ],
      },
    ],
  });

  const extendedFileName = generateFilename(filename, "docx");

  await Packer.toBlob(doc).then((blob) => {
    saveAs(blob, extendedFileName);
    // console.log("file saved");
  });
}

const convertSvgToPng = (svgElement) => {
  return new Promise((resolve, reject) => {
    let svgString;
    if (typeof svgElement !== "string") {
      svgString = new XMLSerializer().serializeToString(svgElement);
    } else {
      svgString = svgElement;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => resolve(blob), "image/png");
    };
    img.onerror = (error) => reject(error);

    img.src = `data:image/svg+xml;base64,${btoa(svgString)}`;
  });
};
