import React, { useContext, useState } from "react";
import { Box, Typography, IconButton, TextField, Button, Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {ReactComponent as PenIcon} from "../../../images/penIcon.svg";
import { ReactComponent as BusinessProcessGuideIcon } from "../../../images/businessProcessGuideIcon.svg";
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from "../../custom-components/CommonDownload";
import { businessProcessGuide, genTestCases } from "../../../services/webServices";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { TestCaseFlowDataContext, TestCaseLoaderStateContext } from "../../main-pages/TestcaseGenerationPage";

const UseCasesComponent = () => {
  const setLoading = useContext(LoadingModalContext);
  const { setTestCaseLoaderState } = useContext(TestCaseLoaderStateContext);
  const { testcases, setUseCase, setTestCases } = useContext(TestCaseFlowDataContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [downloadOpen, setDownloadOpen] = useState(false);
  const [businessProcessData, setBusinessProcessData] = useState(null);
  const editableFields = location.state.useCase || {};
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const defaultUseCaseExpanded = Object.keys(editableFields).length > 0 && testcases.length === 0;

  const useCaseData = {};
  Object.entries(editableFields).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      useCaseData[key] = flattenArray(value);
    } else if (!!value && typeof value === "object") {
      useCaseData[key] = flattenObject(value);
    } else {
      useCaseData[key] = value;
    }
  });

  const handleDownloadClick = () => {
    setDownloadOpen(true);
  };

  const handleCloseDownload = () => {
    setDownloadOpen(false);
  };

  const handleBusinessProcessGuide = async () => {
    try {
      setLoading(true);
      const response = await businessProcessGuide(useCaseData);
      setBusinessProcessData(response.data);
      navigate("/dashboard/generate-testcases/output/businessProcessGuide", {
        state: { useCaseData, businessProcessData: response.data },
      });
    } catch (error) {
      console.error("Error fetching business process data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateTestCases = async () => {
    try {
      setLoading(true);
      setTestCaseLoaderState("Test Cases");
      setTestCases([]);
      const testCases = await genTestCases(useCaseData);
      navigate("/dashboard/generate-testcases/output", {
        state: { ...location.state, testcases: testCases.data },
        replace: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const EditableField = ({ label, initialValue }) => {
    const [value, setValue] = useState(initialValue);
    const [tempValue, setTempValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = () => {
      setValue(tempValue);
      const updatedUseCase = { ...useCaseData, [label]: tempValue };
      navigate("/dashboard/generate-testcases/output", {
        state: { ...location.state, useCase: updatedUseCase },
        replace: true,
      });
      setIsEditing(false);
    };

    const handleCancel = () => {
      setTempValue(value);
      setIsEditing(false);
    };

    return (
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle1" sx={{ color: "gray", fontSize: '14px' }}>
          {label}:
        </Typography>
        {isEditing ? (
          <Box display="flex" alignItems="flex-start">
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              sx={{ marginRight: 1 }}
            />
            <Box display="flex" flexDirection="column">
              <Button onClick={handleSave} variant="text" color="primary" sx={{ marginBottom: 1, textTransform: "none" }}>
                Save
              </Button>
              <Button onClick={handleCancel} variant="text" sx={{ marginTop: -2, textTransform: "none" }}>
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: "relative",
              '&:hover .editIcon': {
                visibility: 'visible',
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                fontSize: '14px',
                flexGrow: 1,
              }}
            >
              {value}
            </Typography>
            <IconButton
              onClick={() => setIsEditing(true)}
              sx={{
                //visibility: 'hidden',
                position: 'relative',
                right: 0,
                marginLeft: '8px',
              }}
              className="editIcon"
            >
              <PenIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };


  return (
    <Box>
      <CommonAccordion
        summary={"Generated Use Case"}
        details={
          <Box>
            {Object.entries(useCaseData).map(([label, value]) => (
              <EditableField
                key={label}
                label={label}
                initialValue={value}
              />
            ))}
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
            >
              {/* Thumbs Up Button with Tooltip */}
              <Tooltip title="Like" arrow>
                <Box
                  onClick={() => handleThumbClick("like")}
                  sx={{
                    cursor: "pointer",
                    marginRight: 2, // Margin between the buttons
                    "&:hover": {
                      color: "#49A3FF", // Change color on hover
                    },
                  }}
                >
                  {activeThumb === "like" ? (
                    <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                  ) : (
                    <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                  )}
                </Box>
              </Tooltip>

              {/* Thumbs Down Button with Tooltip */}
              <Tooltip title="Dislike" arrow>
                <Box
                  onClick={() => handleThumbClick("dislike")}
                  sx={{
                    cursor: "pointer",
                    marginRight: 2, // Margin between the buttons
                    "&:hover": {
                      color: "#FF4842", // Change color on hover
                    },
                  }}
                >
                  {activeThumb === "dislike" ? (
                    <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                  ) : (
                    <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                  )}
                </Box>
              </Tooltip>

              {/* Copy Button with Tooltip */}
              <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                <Box
                  onClick={handleCopyClick}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#49A3FF", // Change color on hover
                    },
                  }}
                >
                  {isCopied ? (
                    <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                  ) : (
                    <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        }
        buttons={[
          {
            label: "Download",
            onClick: handleDownloadClick,
            variant: "text",
            startIcon: <FileDownloadOutlinedIcon />,
            sx: { fontSize: "14px", textTransform: 'none' },
          },
          {
            label: "Business Process Guide",
            onClick: handleBusinessProcessGuide,
            startIcon: <BusinessProcessGuideIcon />,
            variant: "text",
            sx: { fontSize: "14px", textTransform: 'none' },
          },
          {
            label: "Generate Test Cases",
            onClick: handleGenerateTestCases,
            startIcon: <GenerateButton />,
            variant: "contained",
            sx: { float: "right", fontSize: "14px", textTransform: 'none' },
          },
        ]}
        type={"output"}
        defaultExpanded={defaultUseCaseExpanded}
      />
      <CommonDownload
        open={downloadOpen}
        onClose={handleCloseDownload}
        serviceResponse={useCaseData}
        serviceName="UseCases_Report"
        wordData={[{ heading: "Use Case", type: "subParagraphs", data: useCaseData }]}
      />
    </Box>
  );
};

export default UseCasesComponent;
