import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState } from "react";

const CommonEvaluationTable = ({
    headers,
    values,
    edittable,
    tableData,
    setTableData,
}) => {
    const [edittableRow, setEdittableRow] = useState(null);
    const [edittedData, setEdittedData] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEdittedData({ ...edittedData, [name]: value });
    };

    const handleEditClick = (index) => {
        setEdittableRow(index);
        setEdittedData(tableData[index]);
    };

    const handleSaveClick = (index) => {
        setTableData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = edittedData;
            return updatedData;
        });
        setEdittableRow(null);
    };

    const handleCancelClick = () => {
        setEdittableRow(null);
    };

    return (
        <Box sx={{ overflowX: "auto" }}>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell
                                    key={`head${index}`}
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        left: index === 0 ? 0 : "auto",
                                        zIndex: index === 0 ? 3 : 1,
                                        backgroundColor: "#fff",
                                        textAlign: index === 0 ? "left" : "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "black",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            letterSpacing: 0.4,
                                            lineHeight: "16px",
                                            textAlign: "center"
                                        }}
                                    >
                                        {header}
                                    </Typography>
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {values.map((eachRow, rowIndex) => (
                            <TableRow key={`row${rowIndex}`}>
                                {eachRow.map((eachCell, cellIndex) => {
                                    const isRatingColumn = headers[cellIndex] === "Rating";
                                    const ratingValue = isRatingColumn ? parseFloat(eachCell) : null;

                                    return (
                                        <TableCell
                                            key={`${headers[cellIndex]}${cellIndex}`}
                                            sx={{
                                                width: "100%",
                                                position: cellIndex === 0 ? "sticky" : "static",
                                                left: cellIndex === 0 ? 0 : "auto",
                                                zIndex: cellIndex === 0 ? 2 : 1,
                                                backgroundColor: cellIndex === 0 ? "#fff" : "inherit",
                                                textAlign: "center"
                                            }}
                                        >
                                            {rowIndex === edittableRow ? (
                                                <TextField
                                                    name={headers[cellIndex]}
                                                    value={edittedData[headers[cellIndex]] || ""}
                                                    onChange={handleInputChange}
                                                    multiline
                                                    fullWidth
                                                />
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: isRatingColumn
                                                            ? ratingValue >= 3 ? "green" : "red"
                                                            : "black",
                                                        fontSize: 14,
                                                        fontWeight: 500,
                                                        width: "160px",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: eachCell.toString().replace(/\n/g, "<br>"),
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                    );
                                })}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CommonEvaluationTable;
