import { Box, Typography, Button, TextField, IconButton, Tooltip } from '@mui/material';
import CommonAccordion from '../../custom-components/CommonAccordion';
import { ReactComponent as GenerateButton } from '../../../images/generateButton.svg';
import {ReactComponent as PenIcon} from "../../../images/penIcon.svg";
import { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { testScape } from "../../../services/webServices";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import CommonDownload from '../../custom-components/CommonDownload';

const EditableField = ({ label, initialValue, onSave, error }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [tempValue, setTempValue] = useState(initialValue);

    const handleSave = () => {
        setValue(tempValue); 
        onSave(tempValue); 
        setIsEditing(false); 
    };

    const handleCancel = () => {
        setTempValue(value); 
        setIsEditing(false); 
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                overflow: "hidden",
                "&:hover .edit-icon": { visibility: "visible" },
            }}
        >
            <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ marginBottom: 1, fontSize: "14px" }}
            >
                {label}
            </Typography>
            {isEditing ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={tempValue}
                        onChange={(e) => setTempValue(e.target.value)}
                        sx={{ marginTop: 1, marginRight: 1 }}
                        inputProps={{ style: { fontSize: "14px" } }}
                    />
                    <Box>
                        {/* Save Button */}
                        <Button
                            onClick={handleSave}
                            variant="text"
                            size="small"
                            sx={{ marginRight: 1, color: "#49A3FF", textTransform:"none" }}
                        >
                            Save
                        </Button>
                        {/* Cancel Button */}
                        <Button
                            onClick={handleCancel}
                            variant="text"
                            size="small"
                            sx={{ marginRight: 1, color: "#49A3FF", textTransform:"none"  }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "&:hover": { backgroundColor: "white" },
                    }}
                >
                    <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: value.toString().replace(/\n/g, "<br>"),
                        }}
                    />
                    <Tooltip title="Click to edit" placement='right' arrow>
                    <IconButton
                        onClick={() => setIsEditing(true)}
                        className="edit-icon"
                       // sx={{ visibility: "hidden" }}
                    >
                        <PenIcon />
                    </IconButton>
                    </Tooltip>
                </Box>
            )}
              {error && <Typography variant="body2" color="error">{error}</Typography>}
        </Box>
    );
};

const TestPlanFormDisplay = () => {
    const location=useLocation();
    const navigate = useNavigate();
    const {
        Sprint_Objectives,
        Backlog_Items,
        User_Stories,
        Issue_Resolutions,
        Additional_Objectives,
    } = location.state?.input;
    const [sprintObjectivesState, setSprintObjectives] = useState(Sprint_Objectives);
    const [backlogItemState, setBacklogItem] = useState(Backlog_Items);
    const [userStoriesState, setUserStories] = useState(User_Stories);
    const [issueResolutionsState, setIssueResolutions] = useState(Issue_Resolutions);
    const [additionalObjectivesState, setAdditionalObjectives] = useState(Additional_Objectives);
    const [errors, setErrors] = useState({});
    const [openDownload, setOpenDownload]= useState(false);
    const setLoading = useContext(LoadingModalContext);

    const handleGenerateTestPlan = async () => {
        let newErrors = {};
        if (!sprintObjectivesState.trim()) {
          newErrors.sprintObjectivesState = "Sprint Objectives is required.";
        }
        if (!backlogItemState.trim()) {
          newErrors.backlogItemState = "Backlog Item is required.";
        }
        if (!userStoriesState.trim()) {
          newErrors.userStoriesState = "User Stories is required.";
        }
    
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          setErrors({});
          setLoading(true);
        }
        try {
          const testScapeObject = {
            Sprint_Objectives: sprintObjectivesState,
            Backlog_Items: backlogItemState,
            User_Stories: userStoriesState,
            Issue_Resolutions: issueResolutionsState,
            Additional_Objectives: additionalObjectivesState,
          };
          const response = await testScape(testScapeObject);
          navigate("/dashboard/test-plan/output", {
            state: {
              input: testScapeObject,
              output: response,
            },
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
    };

    const handleDownload=()=>{
        setOpenDownload(true);
    }

    return (
        <div style={{ backgroundColor: "#F6F3F2", padding: "20px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CommonAccordion
                    summary={"Test Plan Details"}
                    details={
                        <Box>
                            <Box sx={{ marginBottom: 2, width: "100%" }}>
                            <EditableField
                                label="Sprint Objectives"
                                initialValue={sprintObjectivesState}
                                onSave={setSprintObjectives}
                                error={errors.sprintObjectivesState}
                                
                            />
                            </Box>
                            <Box sx={{ marginBottom: 2, width: "100%" }}>
                            <EditableField
                                label="Backlog Item(s)"
                                initialValue={backlogItemState}
                                onSave={setBacklogItem}
                                error={errors.backlogItemState}
                            />
                            </Box>
                            <Box sx={{ marginBottom: 2, width: "100%" }}>
                            <EditableField
                                label="User Stories"
                                initialValue={userStoriesState
                                }
                                onSave={setUserStories}
                                error={errors.userStoriesState}
                            />
                            </Box>
                            <Box sx={{ marginBottom: 2, width: "100%" }}>
                            <EditableField
                                label="Issue Resolutions"
                                initialValue={issueResolutionsState}
                                onSave={setIssueResolutions}
                            />
                            </Box>
                            <Box sx={{ marginBottom: 2, width: "100%" }}>
                            <EditableField
                                label="Additional Objectives"
                                initialValue={additionalObjectivesState}
                                onSave={setAdditionalObjectives}
                            />
                            </Box>
                        </Box>
                    }
                    buttons={[
                      
                        {
                            label: "Download",
                            onClick: handleDownload,
                            variant: "text",
                            startIcon: <FileDownloadOutlinedIcon />,
                            sx:{textTransform:'none'}
                          },
                        { 
                            label: 'Generate Test Plan', 
                            onClick: handleGenerateTestPlan, 
                            startIcon: <GenerateButton />, 
                            variant: 'contained', 
                            sx: { float: "right" } 
                        },
                    ]}
                   
                    type={"output"}
                />
                <CommonDownload
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        wordData={[{heading: "Test Plan Inputs", type: "subParagraphs", data: {"Sprint Objectives": sprintObjectivesState, "Backlog Item(s)": backlogItemState, "User Stories": userStoriesState, "Issue Resolutions": issueResolutionsState, "Additional Objectives": additionalObjectivesState} }]}
        serviceName={"TestPlan_Input"}
        serviceResponse={{"Sprint Objectives": sprintObjectivesState, "Backlog Item(s)": backlogItemState, "User Stories": userStoriesState, "Issue Resolutions": issueResolutionsState, "Additional Objectives": additionalObjectivesState}}
      />
            </Box>
        </div>
    );
};

export default TestPlanFormDisplay;
