export function flattenArray(formatArray) {
  const formattedArray = formatArray.map((arrayElement) => {
    if (Array.isArray(arrayElement)) {
      return flattenArray(arrayElement);
    } else if (typeof arrayElement === "object" && arrayElement !== null) {
      return flattenObject(arrayElement);
    }
    return arrayElement;
  });

  const breakedFormattedArray = formattedArray.join("\n");
  return breakedFormattedArray;
}

export function flattenObject(formatObject) {
  const formattedObject = Object.entries(formatObject)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}: ${flattenArray(value)}`;
      } else if (typeof value === "object" && value !== null) {
        return `${key}: ${flattenObject(value)}`;
      }
      return `${key}: ${value}`;
    })
    .join("\n");
  return formattedObject;
}
