import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
} from '@mui/material';
import { ReactComponent as GenerateButton } from '../../images/generateButton.svg';
import { exportToCSV } from '../download/qeBotExportToCSV';
import { exportToXLS } from '../download/qeBotExportToExcel';
import { exportToPDF } from '../download/qeBotExportToPdf';
import { exportToWord } from '../download/qeBotExportToWord';
import wordGenerator from '../download/qeBotWordGenerator';

function CommonDownload({ open, onClose, serviceResponse, serviceName, wordData }) { // Accept props
  const [fileFormat, setFileFormat] = useState('.docx');
  // const [testCaseName] = useState('Comprehensive Processing of Incoming Claims');

  const handleDownload = () => {
    // Handle download logic
    switch (fileFormat) {
      case '.csv':
        exportToCSV(serviceResponse, serviceName);
        break;
      case '.xls':
        exportToXLS(serviceResponse, serviceName);
        break;
      case '.pdf':
        exportToPDF(serviceResponse, serviceName);
        break;
      case '.docx':
        wordGenerator(wordData, serviceName);
        break;
      default:
        console.warn('Unsupported file format selected');
    }
    onClose();
  };

  return (
    <>
      {/* The download dialog */}
      <Dialog
        open={open}
        onClose={onClose} // Use the passed onClose function
        PaperProps={{
          sx: {
            borderRadius: '8px', // Blunt edges (rounded corners)
            minWidth: '500px', // Define width of the dialog box
          },
        }}
      >
        <DialogTitle>Download As</DialogTitle>
        <DialogContent>
          {/* Grey "Test Case" title */}
          {/* <Typography variant="subtitle1" sx={{ color: 'grey' }}>
            Test Case
          </Typography> */}

          {/* Non-editable description below the title
          <Typography variant="body1">
            Comprehensive Processing of Incoming Claims
          </Typography> */}

          <Box sx={{ marginTop: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Select file format</InputLabel>
              <Select
                value={fileFormat}
                onChange={(e) => setFileFormat(e.target.value)}
                label="Select file format"
              >
                <MenuItem value=".docx">.docx</MenuItem>
                <MenuItem value=".pdf">.pdf</MenuItem>
                <MenuItem value=".xls">.xls</MenuItem>
                <MenuItem value=".csv">.csv</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ textTransform: 'capitalize' }}>
            Cancel
          </Button>
          <Button
            onClick={handleDownload}
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: '#49A3FF',
              gap: '5px',
              textTransform: 'capitalize'  // This ensures the text is in title case
            }}
          >
            <GenerateButton />
            Download
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default CommonDownload;
