import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Box,
  Input,
  InputAdornment,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Checkbox,
  DialogContent,
  FormControlLabel,
  IconButton,
} from "@mui/material";

import { ReactComponent as UploadIcon } from "../../../images/uploadIcon.svg";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { ReactComponent as ImportIcon } from "../../../images/importIcon.svg";
import { ReactComponent as ConfigureIcon } from "../../../images/parameterIcon.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ReactComponent as LoadIcon } from "../../../images/loadExampleButton.svg";
import {
  fetchExistingJiraFieldsFromScreen,
  fetchJiraIssuesApiCall,
  fetchSpecificIssues,
  freeFlowInvest,
  getJiraConfig,
  investigate,
  refinedUserstories,
} from "../../../services/webServices";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import {
  TestCaseFlowDataContext,
  TestCaseLoaderStateContext,
} from "../../main-pages/TestcaseGenerationPage";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import FullscreenToggle from "../../custom-components/FullScreenToggle";
import CommonAccordion from "../../custom-components/CommonAccordion";
import SessionExpired from "../../popup-components/SessionExpiredPopup";
const StepItem = ({ imgSrc, text, isActive, showArrow }) => (
  <Box display="flex" alignItems="center">
    <img src={imgSrc} alt="" className="step-icon" />
    {showArrow && (
      <ArrowForwardIosIcon
        fontSize="small"
        sx={{ opacity: 0.5, fontSize: "12px" }}
      />
    )}
    <Typography
      variant="body1"
      className={`step-text ${isActive ? "active" : ""}`}
    >
      {text}
    </Typography>
  </Box>
);

const StepsWithDropdown = () => {
  const steps = [
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/911db717332ff445c6c88c44ed82fc3e19bd7216e7cbe68eeb282f2ea6b23256?placeholderIfAbsent=true&apiKey=9384815652264ccaa6717f615f796430",
      text: "Enter Details",
      isActive: true,
      showArrow: true,
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4845d5450893eb5648e1f7dbdbd0dd99793a7f74d5e441c211c448cea6509310?placeholderIfAbsent=true&apiKey=9384815652264ccaa6717f615f796430",
      text: "Evaluate User Stories",
      isActive: false,
      showArrow: false,
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/cd3f0e0e1836fdfc16da42ab1b97402cb9c75181c3a93aff5c063af7a3928a84?placeholderIfAbsent=true&apiKey=9384815652264ccaa6717f615f796430",
      text: "Refine User Stories",
      isActive: false,
      showArrow: false,
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/6e7d946cba76312f3da062d3c5c9dbc707e8efd2e4a3be69dd3a534c93fc86fc?placeholderIfAbsent=true&apiKey=9384815652264ccaa6717f615f796430",
      text: "Generated Use Cases",
      isActive: false,
      showArrow: false,
    },
    {
      imgSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/6cc46303d75f92c8315f090f2f96cd9d41e0892cf4ca39b1345d03692bb0d586?placeholderIfAbsent=true&apiKey=9384815652264ccaa6717f615f796430",
      text: "Generated Test Cases",
      isActive: false,
      showArrow: false,
    },
  ];

  return (
    <Box className="steps-with-dropdown">
      {steps.map((step, index) => (
        <StepItem key={index} {...step} />
      ))}
      <style jsx>{`
        .steps-with-dropdown {
          display: flex;
          align-items: center;
          gap: 8px;
          overflow: hidden;
          color: var(--Schemes-Secondary, #000);
          text-align: center;
          letter-spacing: 0.5px;
          justify-content: start;
          font: 400 16px Manrope, sans-serif;
        }
        .step-icon {
          width: 18px;
          height: 18px;
          object-fit: contain;
          object-position: center;
        }
        .step-text {
          opacity: 0.5;
        }
        .step-text.active {
          opacity: 1;
        }
      `}</style>
    </Box>
  );
};

const defaultParameters = [
  "Independent",
  "Negotiable",
  "Valuable",
  "Estimable",
  "Small",
  "Testable",
];
const UserStoryFormInput = () => {
  const { setTestCaseLoaderState } = useContext(TestCaseLoaderStateContext);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [acceptanceCriteria, setAcceptanceCriteria] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [textFieldError, setTextFieldError] = useState(false);
  const [freeFlowUserStory, setFreeFlowUserStory] = useState("");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    Independent: true,
    Negotiable: true,
    Valuable: true,
    Estimable: true,
    Small: true,
    Testable: true,
  });
  const [reqData, setReqData] = useState({});
  const [reportData, setReportData] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [investData, setInvestData] = useState({
    userStory: "",
    parameters: defaultParameters,
  });
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [sessionExpiredValue, setSessionExpiredValue] = useState(false);

  const handleAccordionChange = (event, expanded) => {
    setIsAccordionOpen(expanded); // Set the button's visibility based on accordion state
  };

  const examples = {
    LoadExample: {
      title: "Add a New Contact",
      description:
        "As a user, I want to add new contact information to the CRM system so that I can manage and store details for my business relationships efficiently.",
      acceptanceCriteria:
        "1. The system should provide a form with fields for: \n- First Name (mandatory)\n- Middle Name (optional)\n- Last Name (mandatory)\n- Company (optional)\n- Email (optional).\n2. Mandatory fields should be validated before saving.\n3. After saving, the new contact should appear in the contact list with accurate details.",
      additionalInfo: "Domain Name: Contact Management \nModule Name: Contacts \nType of User: CRM users (Admin, Sales, Marketing) \nPriority: High",
    },
  };

  const selectLoadExample = (event) => {
    event.stopPropagation();
    setTitle(examples["LoadExample"]["title"]);
    setDescription(examples["LoadExample"]["description"]);
    setAcceptanceCriteria(examples["LoadExample"]["acceptanceCriteria"]);
    setAdditionalInfo(examples["LoadExample"]["additionalInfo"]);
    setTextFieldError(false);
  };

  const setLoading = useContext(LoadingModalContext);
  const {
    setUserStory,
    setEvaluationSummary,
    setRefinedUserStory,
    setUseCase,
    setTestCases,
  } = useContext(TestCaseFlowDataContext);

  const navigate = useNavigate();

  useEffect(() => {
    setUserStory({});
    setEvaluationSummary([]);
    setRefinedUserStory({});
    setUseCase({});
    setTestCases({});
  }, []);

  const isUserStoryFilled = freeFlowUserStory.trim();
  const isDetailsFilled =
    title.trim() && description.trim() && acceptanceCriteria.trim();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSettingsClick = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleFormSubmit = async () => {
    const checkedParameters = Object.keys(selectedOptions).filter(
      (key) => selectedOptions[key] // Only include checked options
    );
    const requestData = {
      Title: title,
      Description: description,
      "Acceptance Criteria": acceptanceCriteria,
      "Additional Information": additionalInfo,
      parameters: checkedParameters,
    };
    setLoading(true);
    setTestCaseLoaderState("Evaluation Summary");
    setUserStory(requestData);
    // setReqData(requestData); ** //not using for now
    try {
      const response = await investigate(requestData);
      const parsedInvestResponse = response.data;
      navigate("/dashboard/generate-testcases/output", {
        state: {
          userStory: requestData,
          evaluationSummary: parsedInvestResponse,
          refinedUserStory: {},
          useCase: {},
          testcases: [],
        },
      });
    } catch (error) {
      setResponseError(error.response.data["Error"] || "An error occurred.");
      console.log(error);
      if (error.response.data.Error == "Your session has expired.") {
        setSessionExpiredValue(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSumbitFreeFlowText = async () => {
    setLoading(true);
    setUserStory(freeFlowUserStory);
    setTestCaseLoaderState("Evaluation Summary");
    const checkedParameters = Object.keys(selectedOptions).filter(
      (key) => selectedOptions[key] // Only include checked options
    );

    const requestData = {
      userStory: freeFlowUserStory,
      parameters: checkedParameters,
    };
    setReqData(requestData);
    try {
      const response = await freeFlowInvest(requestData);
      const parsedInvestResponse = response.data;
      setEvaluationSummary(parsedInvestResponse);
      navigate("/dashboard/generate-testcases/output", {
        state: {
          userStory: freeFlowUserStory,
          evaluationSummary: parsedInvestResponse,
          refinedUserStory: {},
          useCase: {},
          testcases: [],
        },
      });
      // setReportData(parsedInvestResponse); ** //not using for now
    } catch (error) {
      setResponseError(error.response.data["Error"] || "An error occurred.");
      if (error.response.data.Error == "Your session has expired.") {
        setSessionExpiredValue(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const importUserStoriesFormJira = async () => {
    setLoading(true);
    try {
      const userStoriesJiraRequest = await fetchSpecificIssues("Story");
      const importeduserStoriesRawData = userStoriesJiraRequest.data;

      if (importeduserStoriesRawData.length === 0) {
        console.warn("No Story issues found for the project.");
      } else {
        const fetchExistingFieldsApiResponse =
          await fetchExistingJiraFieldsFromScreen();
        const fetchExistingFields = fetchExistingFieldsApiResponse.data;

        const fieldMapping = fetchExistingFields.reduce(
          (fieldsMapper, eachField) => {
            if (eachField.name === "Summary") {
              fieldsMapper["Title"] = eachField.id;
            }
            if (
              eachField.name === "Description" ||
              eachField.name === "Acceptance Criteria" ||
              eachField.name === "Additional Information"
            ) {
              fieldsMapper[eachField.name] = eachField.id;
            }
            return fieldsMapper;
          },
          {}
        );

        // Map to extract Title (summary) and Description from each bug issue
        const userStoryDetails = importeduserStoriesRawData.map(
          (eachStory) => ({
            Title: eachStory.fields?.[fieldMapping["Title"]] || "No Title", // Title of the user story
            Description:
              eachStory.fields?.[fieldMapping["Description"]] ||
              "No Description", // Description of the user story
            "Acceptance Criteria":
              eachStory.fields?.[fieldMapping["Acceptance Criteria"]] ||
              "No Acceptance Criteria", // Acceptance Criteria of the user story
            "Additional Information":
              eachStory.fields?.[fieldMapping["Additional Information"]] ||
              "No Additional Information", // Additional Information for the user story
            key: eachStory?.key,
            id: eachStory?.id,
          })
        );

        setLoading(false);
        navigate("/dashboard/generate-multi/testcases/output", {
          state: {
            userStories: userStoryDetails,
            selectedUserStories: Array(userStoryDetails.length).fill(null),
            multiEvaluationSummary: [],
            multipleRefinedUserStory: [],
            selectedRefinedUserStories: [],
            multipleUseCases: [],
            selectedMultipleUseCases: [],
            multipleTestCases: [],
          },
        });
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data["Error"]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#f6f3f2",
        minHeight: "80vh",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "scroll",
        width: "100%",
        height: "70vh",
        position: "relative",
        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "4px",
          minHeight: "20px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555",
        },
      }}
    >
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Container maxWidth="md">
          <Box
            sx={{
              bgcolor: "transparent",
              marginBottom: 1,
              padding: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box display="flex" alignItems="center" mb={0.1}>
                <AutomateIcon style={{ marginRight: 4 }} />
                {/* Informative text */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                  sx={{ mt: 0.5 }}
                >
                  Automate and create comprehensive, high-quality test cases
                  effortlessly
                </Typography>
              </Box>
              <FullscreenToggle />
            </Box>
          </Box>

          {/* StepsWithDropdown component integrated here */}
          <Card
            sx={{
              bgcolor: "white",
              marginBottom: 1,
              borderRadius: "5px",
              border: "1px solid rgba(0,0,0,0.12)",
            }}
          >
            <form>
              <CardContent sx={{ padding: 1, position: "relative" }}>
                <Box sx={{ position: "relative" }}>
                  <Tooltip title="Evaluation settings" placement="bottom">
                    <IconButton
                      onClick={handleSettingsClick}
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: -5,
                        zIndex: 10,
                        width: 40,
                        height: 25,
                        color: "gray",
                      }}
                    >
                      <ConfigureIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Input
                  fullWidth
                  disableUnderline
                  multiline
                  minRows={5}
                  maxRows={10}
                  margin="normal"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          color: "gray",
                        }}
                      />
                    </InputAdornment>
                  }
                  placeholder="Enter user story Details"
                  value={freeFlowUserStory}
                  onChange={(e) => setFreeFlowUserStory(e.target.value)}
                  autoComplete="off"
                  sx={{
                    color: "black",
                    bgcolor: "transparent",
                    padding: "10px 10px 10px 33px",
                    paddingRight: "50px", // Added padding to the right to ensure the icon doesn't overlap
                    marginTop: "5px", // Adds space from the top
                    zIndex: 1,
                    "&:hover, &:focus": {
                      border: "none",
                    },
                  }}
                />
                <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GenerateButton />}
                    sx={{
                      backgroundColor: "#49A3FF",
                      color: "white",
                      mt: 1,
                      textTransform: "none",
                    }}
                    disabled={!isUserStoryFilled}
                    onClick={handleSumbitFreeFlowText}
                  >
                    Evaluate User Story
                  </Button>
                </Box>
              </CardContent>

              {/* Settings Dialog */}
              <Dialog
                open={settingsOpen}
                onClose={handleSettingsClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                  sx: {
                    width: "600px", // Adjusted width for a wider dialog
                    maxHeight: "90vh", // Prevents scrolling in larger screens
                    borderRadius: "6px", // Adds curved edges
                    padding: 2,
                  },
                }}
              >
                <DialogTitle>Configure Parameters</DialogTitle>
                <Typography
                  variant="body1"
                  sx={{
                    padding: "10px 0", // Space between the title and the description
                    fontWeight: 500, // Optional: makes the text bold
                    color: "#555", // Optional: sets a custom color
                    fontSize: 14,
                    lineHeight: 1,
                    pl: 3,
                  }}
                >
                  Select Parameters to customise and focus the genereted results
                </Typography>
                <DialogContent
                  sx={{
                    padding: "0 16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Checkbox items */}
                  {[
                    {
                      name: "Independent",
                      label: "Independent",
                      description:
                        "User story should be self-contained and not dependent on other stories for completion.",
                    },
                    {
                      name: "Negotiable",
                      label: "Negotiable",
                      description:
                        "User story should be flexible, allowing for discussion and adjustment of its implementation.",
                    },
                    {
                      name: "Valuable",
                      label: "Valuable",
                      description:
                        "User story should clearly provide value to the end user.",
                    },
                    {
                      name: "Estimable",
                      label: "Estimable",
                      description:
                        "User story should be defined well enough that the team can estimate the effort required to implement it.",
                    },
                    {
                      name: "Small",
                      label: "Small",
                      description:
                        "User story should be small enough to be completed within a single iteration or sprint.",
                    },
                    {
                      name: "Testable",
                      label: "Testable",
                      description:
                        "Ensures the user story can be tested effectively.",
                    },
                  ].map((option) => (
                    <Box key={option.name} sx={{ mb: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedOptions[option.name]}
                            onChange={handleCheckboxChange}
                            name={option.name}
                            sx={{
                              // Change the color of the checkbox when checked
                              "&.Mui-checked": {
                                color: "#49A3FF", // Change to any color you prefer (e.g., 'green', '#FF5733')
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "0.9rem" }}
                          >
                            {option.label}
                          </Typography>
                        }
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: "0.8rem",
                          lineHeight: 1.2,
                          textAlign: "left",
                          ml: 4,
                        }}
                      >
                        {option.description}
                      </Typography>
                    </Box>
                  ))}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleSettingsClose}
                    sx={{
                      textTransform: "none",
                      color: "#49A3FF", // Cancel button text color
                      borderColor: "#49A3FF", // Cancel button border color
                      "&:hover": {
                        backgroundColor: "#e6f0ff", // Light background on hover
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSettingsClose}
                    startIcon={<GenerateButton />}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#49A3FF", // Confirm button background color
                      color: "white", // Confirm button text color
                      "&:hover": {
                        backgroundColor: "#3385cc", // Darker background on hover
                      },
                    }}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </Card>

          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{ marginTop: "12px" }}
          >
            Or enter your user story details below
          </Typography>

          <CommonAccordion
            summary={
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
                  Enter User Story Details
                </Typography>

                <Button
                  variant="text"
                  startIcon={<LoadIcon />}
                  sx={{
                    ml: 2,
                    textTransform: "none",
                    position: "relative",
                    top: "0px",
                    right: "-150px",
                  }}
                  onClick={selectLoadExample}
                >
                  Load Demo Example
                </Button>
              </Box>
            }
            details={
              <Box>
                <Box mb={2}>
                  <TextField
                    sx={{ width: "92%" }}
                    placeholder="A brief,descriptive summary of the user story"
                    label="Title*"
                    variant="outlined"
                    multiline
                    rows={4}
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <TextField
                    sx={{ width: "92%" }}
                    placeholder="As a [type of user], I want [an action] so that [a benifit/a reason]"
                    label="Description*"
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <TextField
                    sx={{ width: "92%" }}
                    placeholder="Specific conditions that must be met for the story to be considered complete"
                    label="Acceptance Criteria*"
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                    value={acceptanceCriteria}
                    onChange={(e) => setAcceptanceCriteria(e.target.value)}
                  />
                  <TextField
                    sx={{ width: "92%" }}
                    placeholder="Any extra details, context, or resources that can help in understanding and implementing the user story "
                    label="Additional Information"
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  />
                </Box>
              </Box>
            }
            type={"input"}
            buttons={[
              {
                label: "Upload",
                variant: "text",
                startIcon: <UploadIcon />,
                sx: { textTransform: "none" },
              },
              {
                label: "Import",
                variant: "text",
                startIcon: <ImportIcon />,
                sx: { textTransform: "none" },
                onClick: importUserStoriesFormJira,
              },
              {
                label: "Evaluate User Story",

                variant: "contained",
                sx: {
                  float: "right",
                  textTransform: "none",
                },
                onClick: handleFormSubmit,
                startIcon: <GenerateButton />,
                disabled: !isDetailsFilled,
                color1: isDetailsFilled ? "#49A3FF" : "default",
              },
            ]}
          />
        </Container>
        {sessionExpiredValue && <SessionExpired />}
      </Box>
    </Box>
  );
};

export default UserStoryFormInput;
