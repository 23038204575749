export function arrayOfObjectTransformer(data) {
    // console.log(data)
    const headers = Object.keys(data[0]);
    const values = [];
    data.map((eachObject) => {
        values.push(Object.values(eachObject));
    });

    return { headers, values };
}

export function objectDataTransformer(data) {
    const headers = ["Parameter", "Value"];
    const values = Object.entries(data).map(([key, value]) => [key, value]);

    return { headers, values };
}