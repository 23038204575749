import React, { useContext, useEffect } from "react";
import TestPlanInputForm from "../../input-components/test-plan/TestPlanInputForm";
import { Box } from "@mui/material";
import { TestPlanContext } from "../TestPlanPage";

const TestPlanInputPage=()=>{
    const {setTestPlanInputs, setTestPlanOutputs} = useContext(TestPlanContext);

    useEffect(() => {
        setTestPlanInputs({});
        setTestPlanOutputs({});
    }, []);
    return(
        <Box>
            <TestPlanInputForm/>
        </Box>
    )
};
 export default TestPlanInputPage;