import React, { useContext } from "react";
import BugReportComponent from "../../input-components/bug-report-enhancer/BugReportFormInput";
import { Box } from "@mui/material";
import { BugReportDataContext } from "../BugReportPage";

const BugReportInputPage = () => {
  
  return (
    <Box>
      <BugReportComponent />
    </Box>
  )
};

export default BugReportInputPage;
