import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {ReactComponent as PenIcon} from "../../../images/penIcon.svg";
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from "../../custom-components/CommonDownload";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { ReactComponent as ExportButton } from "../../../images/exportButton.svg";
import AgileTestQuadrantsGraph from "../../custom-components/AgileTestQuadrantsGraph";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";

const TestPlanOuputComponent = ({ }) => {
  const [openDownload, setOpenDownload] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);
  const [quadrantData, setQuadrantData] = useState(null);
  const location = useLocation();
  const { output } = location.state || { input: {}, output: {} };

  const testPlanObject = {
    objectives: output?.["Test plan"]?.["Objective For Sprint"] || "",
    risks: output?.["Test plan"]?.["Risks"] || "",
    mitigation: output?.["Test plan"]?.["Mitigation and Contingency"] || "",
    approach: output?.["Test plan"]?.["Test Approach"] || "",
    roles: output?.["Test plan"]?.["Roles and Responsibilities"] || "",
    timeline: output?.["Test plan"]?.["Test Activities Timeline"],
    tooling: output?.["Test plan"]?.["Tooling and Automation"] || "",
    reporting: output?.["Test plan"]?.["Reporting and Metrics"] || "",
    acceptance: output?.["Test plan"]?.["Acceptance Criteria"] || "",
  };
  const agileQuad = {
    quadrants: output?.["Agile Test Quadrants"] || "",
  }

  const formatTestPlan = (testPlanData) => {
    const formattedTestPlan = {};
    Object.entries(testPlanData).map(([key, value]) => {
      // console.log(typeof value);
      // console.log(value)
      if (Array.isArray(value)) {
        return formattedTestPlan[key] = flattenArray(value);
      }
      if (!!value && typeof value === "object") {
        return formattedTestPlan[key] = flattenObject(value);
      }
      return formattedTestPlan[key] = value;
    });
    // console.log(formattedTestPlan)
    return formattedTestPlan;
  };

  const prepareQuadrantData = () => {
    if (!agileQuad.quadrants) {
      console.error("Agile_Test_Activities_and_Quadrants is missing.");
      return null;
    }

    const agileTestQuadrantsKey = "<strong>AGILE TEST QUADRANTS</strong>";
    let quadrants = agileQuad.quadrants;

    if (typeof quadrants === 'string') {
      try {
        quadrants = JSON.parse(quadrants);
      } catch (error) {
        console.error("Failed to parse AGILE_TEST_QUADRANTS string:", error);
        return null;
      }
    }

    if (!quadrants || typeof quadrants !== 'object') {
      console.error("Agile Test Quadrants data is undefined, null, or not a valid object.");
      return null;
    }

    const quadrantColors = {
      Q1: "#FF9980", // Functional Tests
      Q2: "#FF4D4D", // Performance Tests
      Q3: "#FFB380", // Usability Tests
      Q4: "#FFCCCC", // Integration Tests
    };

    const formattedQuadrants = Object.keys(quadrants).reduce((acc, quadrantKey) => {
      const quadrantData = quadrants[quadrantKey];

      if (quadrantData && typeof quadrantData === 'object') {
        acc[quadrantKey] = {
          title: quadrantData.title || "Untitled",
          items: quadrantData.items || [],
          automation: quadrantData.automation || "None",
          color: quadrantColors[quadrantKey] || "#FFFFFF",
        };
      }

      return acc;
    }, {});

    return formattedQuadrants; // Return the formatted quadrants object
  };

  useEffect(() => {
    const data = prepareQuadrantData();
    setQuadrantData(data);
  }, [output]);

  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const EditableField = ({ label, initialValue, onChange }) => {
    const formatText = (data) => {
      if (Array.isArray(data)) {
        return data.map((item) => {
          if (typeof item === "object") {
            return Object.entries(item)
              .map(([key, val]) => `${key}: ${formatText(val)}`)
              .join("\n");
          }
          return `- ${item}`;
        }).join("\n\n");
      } else if (typeof data === "object" && data !== null) {
        return Object.entries(data)
          .map(([key, val]) => `${key}:\n  ${formatText(val)}`) // Add an extra newline here
          .join("\n\n");
      } else if (typeof data === "string") {
        return data.trim();
      }
      return data;
    };

    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(
      typeof initialValue === "object" ? formatText(initialValue) : initialValue
    );

    useEffect(() => {
      setValue(formatText(initialValue));
    }, [initialValue]);


    const handleSave = () => {
      setIsEditing(false);
      onChange(value); // Save the text value as plain text
    };

    const handleCancel = () => {
      setIsEditing(false);
      setValue(
        typeof initialValue === "object"
          ? formatText(initialValue)
          : initialValue
      );
    };

    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "100%",
          "&:hover .edit-icon": { visibility: "visible" },
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ marginBottom: 1, fontSize: "14px" }}
        >
          {label}
        </Typography>
        {isEditing ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
            <Box>
              <Button
                onClick={handleSave}
                variant="text"
                size="small"
                sx={{ marginRight: 1, color: "#49A3FF", textTransform: "none" }}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                variant="text"
                size="small"
                sx={{ color: "#49A3FF", textTransform: "none" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
              width: "100%",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              {value}
            </Typography>
            <Tooltip title="Click to edit" placement="right" arrow>
            <IconButton
              onClick={() => setIsEditing(true)}
              className="edit-icon"
              sx={{
                //visibility: "hidden",
                flexShrink: 0,
                marginLeft: 1,
              }}
            >
              <PenIcon />
            </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  const labelMapping = {
    objectives: "Sprint Objectives",
    risks: "Risk Analysis",
    mitigation: "Mitigation and Contingency Steps",
    approach: "Test Approach",
    roles: "Roles and Responsibilities",
    timeline: "Timeline Expectation",
    tooling: "Tooling Expectation",
    reporting: "Reporting",
    acceptance: "Acceptance Criteria",
  };


  const accordionContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        padding: "16px",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      {Object.entries(testPlanObject).map(([key, value], index) => (
        <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
          <EditableField
            label={labelMapping[key]}
            initialValue={value}
            onChange={(updatedValue) => (testPlanObject[key] = updatedValue)}
          />
        </Box>
      ))}

      {quadrantData && (
        <Box sx={{ marginTop: -10, alignSelf: "flex-start", width: "100%" }}>
          <AgileTestQuadrantsGraph data={quadrantData} />
        </Box>
      )}

      <Box
        sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
      >
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  const buttonsgroup = [
    {
      label: "Export",
      startIcon: <ExportButton />,
      variant: "text",
      onClick: () => { },
    },
    {
      label: "Download",
      variant: "contained",
      startIcon: <GenerateButton />,
      onClick: () => setOpenDownload(true),
      sx: { float: "right" },
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#F6F3F2",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CommonAccordion
          summary={
            <Typography variant="h6" sx={{ fontSize: "16px" }}>
              Test Plan
            </Typography>
          }
          details={accordionContent}
          buttons={buttonsgroup}
          type={"output"}
          defaultExpanded={true}
        />
        <CommonDownload
          open={openDownload}
          onClose={() => setOpenDownload(false)}
          wordData={[{ heading: "Test Plan", type: "subParagraphs", data: testPlanObject }]}
          serviceName={"TestPlan_Report"}
          serviceResponse={formatTestPlan(testPlanObject)}
        />
      </Box>
    </div>
  );
};

export default TestPlanOuputComponent;
