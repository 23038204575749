import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  Container,
  TextField,
  Divider,
  Grid2,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { ReactComponent as PenIcon } from "../../../images/penIcon.svg";
import { scrutinize } from "../../../services/webServices";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import SessionExpired from "../../popup-components/SessionExpiredPopup";

const BugDetailsForm = ({
  editable,
  onSave,
  onCancel,
  index,
  accordianData,
  setAccordianData,
}) => {
  // Track the current input values
  const [bugTitle, setBugTitle] = useState(accordianData[index]["title"]);
  const [bugDescription, setBugDescription] = useState(
    accordianData[index]["description"]
  );
  const [bugEnvironment, setBugEnvironment] = useState(
    accordianData[index]["environment"]
  );

  const [bugPriority, setBugPriority] = useState(
    accordianData[index]["priority"]
  );

  // Function to handle Save action
  const handleSave = () => {
    const updatedValues = {
      title: bugTitle,
      description: bugDescription,
      environment: bugEnvironment,
      priority: bugPriority,
    };
    setAccordianData((prevState) => {
      const savedData = [...prevState];
      const savedBug = savedData[index];
      savedBug["title"] = bugTitle;
      savedBug["description"] = bugDescription;
      savedBug["environment"] = bugEnvironment;
      savedBug["priority"] = bugPriority;
      savedData[index] = savedBug;
      return savedData;
    });
    onSave(updatedValues);
  };

  // Function to handle Cancel action
  const handleCancel = () => {
    // Revert to original values without saving
    setBugTitle(accordianData[index]["title"]);
    setBugDescription(accordianData[index]["description"]);
    setBugEnvironment(accordianData[index]["environment"]);
    setBugPriority(accordianData[index]["priority"]);
    if (onCancel) onCancel(); // Exit edit mode
    if (onCancel) {
      onCancel(); // Trigger onCancel function if provided
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {!editable && (
        <Grid container spacing={2}>
          {bugTitle && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Title
              </Typography>
              <Typography variant="body2">{bugTitle}</Typography>
            </Grid>
          )}

          {bugDescription && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Description
              </Typography>
              <Typography variant="body2">{bugDescription}</Typography>
            </Grid>
          )}

          {bugEnvironment && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Environment Details
              </Typography>
              <Typography variant="body2">{bugEnvironment}</Typography>
            </Grid>
          )}

          {bugPriority && (
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Priority
              </Typography>
              <Typography variant="body2">{bugPriority}</Typography>
            </Grid>
          )}

          {/* {bugReport.stepsToReproduce && (
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Steps to Reproduce
            </Typography>
            <Typography variant="body2">
              {bugReport.stepsToReproduce}
            </Typography>
          </Grid>
        )}

        {bugReport.expectedResult && (
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Expected Result
            </Typography>
            <Typography variant="body2">{bugReport.expectedResult}</Typography>
          </Grid>
        )}

        {bugReport.actualResult && (
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Actual Result
            </Typography>
            <Typography variant="body2">{bugReport.actualResult}</Typography>
          </Grid>
        )}

        {bugReport.additionalInfo && (
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Additional Information
            </Typography>
            <Typography variant="body2">{bugReport.additionalInfo}</Typography>
          </Grid>
        )}

        {bugReport.impact && (
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Impact
            </Typography>
            <Typography variant="body2">{bugReport.impact}</Typography>
          </Grid>
        )} */}
        </Grid>
      )}
      {editable && (
        <>
          <TextField
            fullWidth
            value={bugTitle}
            label="Title"
            placeholder="Enter the Bug Title"
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            disabled={!editable}
            onChange={(e) => setBugTitle(e.target.value)}
          />
          <TextField
            fullWidth
            value={bugDescription}
            label="Description"
            placeholder="Enter the Bug Description"
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2 }}
            disabled={!editable}
            onChange={(e) => setBugDescription(e.target.value)}
          />
          <TextField
            fullWidth
            label="Environment Details"
            value={bugEnvironment}
            placeholder="Enter the Environment Details"
            variant="outlined"
            multiline
            rows={3}
            sx={{ mb: 2 }}
            disabled={!editable}
            onChange={(e) => setBugEnvironment(e.target.value)}
          />

          <TextField
            fullWidth
            label="Priority"
            value={bugPriority}
            placeholder="Enter the Bug Priority"
            variant="outlined"
            disabled={!editable}
            onChange={(e) => setBugPriority(e.target.value)}
          />
          {editable && (
            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
const itemsPerPage = 10;

const RenderAccordionContent = ({
  items,
  page,
  checkedStates,
  expandedStates,
  handlers,
  setPage,
  showEditIcon = false,
  accordianData,
  setAccordianData,
}) => {
  const [editingStates, setEditingStates] = useState(
    Array(items.length).fill(false)
  );

  // State to hold updated titles
  const [titles, setTitles] = useState(items.map((item) => item.title || ""));

  const currentItems = items.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const isAnySelected = checkedStates.some((checked) => checked);
  const isAnyExpanded = expandedStates.some((expanded) => expanded);

  const handleTitleUpdate = (index, newTitle) => {
    setTitles((prevTitles) => {
      const newTitles = [...prevTitles];
      newTitles[index] = newTitle;
      return newTitles;
    });
  };

  return (
    <AccordionDetails>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Button
            size="small"
            sx={{ textTransform: "none", color: "#49A3FF" }}
            onClick={
              isAnySelected
                ? handlers.handleUnselectAll
                : handlers.handleSelectAll
            }
          >
            {isAnySelected ? "Unselect All" : "Select All"}
          </Button>
          <Button
            size="small"
            sx={{ textTransform: "none", color: "#49A3FF" }}
            onClick={
              isAnyExpanded
                ? handlers.handleCollapseAll
                : handlers.handleExpandAll
            }
          >
            {isAnyExpanded ? "Collapse All" : "Expand All"}
          </Button>
        </Box>
      </Box>

      {currentItems.map((item, index) => {
        const globalIndex = (page - 1) * itemsPerPage + index;

        return (
          <Accordion
            key={globalIndex}
            expanded={expandedStates[globalIndex]}
            onChange={handlers.handleChange(globalIndex)}
            sx={{ width: "100%", mb: 0 }}
          >
            <AccordionSummary
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "0 16px",
                backgroundColor: checkedStates[globalIndex]
                  ? "#d6eaf8"
                  : "white",
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              {expandedStates[globalIndex] ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 1,
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={checkedStates[globalIndex]}
                    onChange={(event) =>
                      handlers.handleCheckboxChange(globalIndex, event)
                    }
                    onClick={(event) => event.stopPropagation()}
                  />
                  <Typography variant="body2">{titles[globalIndex]}</Typography>{" "}
                </Box>

                {/* Use titles state here */}
                {showEditIcon && (
                  <Button
                    variant="text"
                    sx={{ ml: "auto", minWidth: 0, padding: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStates((prev) => {
                        const newEditingStates = [...prev];
                        newEditingStates[globalIndex] =
                          !newEditingStates[globalIndex];
                        return newEditingStates;
                      });
                    }}
                  >
                    <PenIcon />
                  </Button>
                )}
              </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ paddingLeft: "48px" }}>
              <BugDetailsForm
                editable={editingStates[globalIndex]}
                onSave={(updatedValues) => {
                  handleTitleUpdate(globalIndex, updatedValues.title); // Update title in state
                  setEditingStates((prev) => {
                    const newEditingStates = [...prev];
                    newEditingStates[globalIndex] = false;
                    return newEditingStates;
                  });
                }}
                onCancel={() => {
                  setEditingStates((prev) => {
                    const newEditingStates = [...prev];
                    newEditingStates[globalIndex] = false;
                    return newEditingStates;
                  });
                }}
                index={index}
                accordianData={accordianData}
                setAccordianData={setAccordianData}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={Math.ceil(items.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </Box>
    </AccordionDetails>
  );
};

const ImportedInputBugReport = () => {
  // Use navigate hook for routing
  const navigate = useNavigate();
  const setLoading = useContext(LoadingModalContext);
  const location = useLocation();
  const [sessionExpiredValue, setSessionExpiredValue] = useState(false);
  const [bugDetails, setBugDetails] = useState(location.state.bugDetails); // This will contain the data passed via navigate from BugReport Input Page
  const [selectedBugDetails, setSelectedBugDetails] = useState(
    Array(bugDetails.length).fill(null)
  );
  const [enhancedBugDescriptions, setEnhancedBugDescriptions] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [bugDetailsExpanded, setBugDetailsExpanded] = useState(true);

  const [bugDetailsPage, setBugDetailsPage] = useState(1);

  const [bugDetailsCheckedStates, setBugDetailsCheckedStates] = useState(
    selectedBugDetails.map(
      (eachSelectedDetail, bugIndex) =>
        JSON.stringify(eachSelectedDetail) ===
        JSON.stringify(bugDetails[bugIndex])
    )
  );
  const [enhancedCheckedStates, setEnhancedCheckedStates] = useState(
    Array(100).fill(false)
  );
  const [bugDetailsExpandedStates, setBugDetailsExpandedStates] = useState(
    Array(100).fill(false)
  );
  const [enhancedExpandedStates, setEnhancedExpandedStates] = useState(
    Array(100).fill(false)
  );

  // Map the entire object instead of just the title
  const bugDetailsItems = Array.from(
    { length: bugDetails.length },
    (_, index) => bugDetails[index] // This will include both title and description
  );

  const handleEnhanceBugDescription = async () => {
    setLoading(true);
    try {
      const updatedEnhancedBugDescription = [];
      for (const [index, eachBugDescription] of selectedBugDetails.entries()) {
        if (eachBugDescription && Object.keys(eachBugDescription).length > 0) {
          const requestData = {
            bugReportData: eachBugDescription,
            temperature: "0.5",
          };
          const enhanceBugDescriptionResponse = await scrutinize(requestData);
          setEnhancedBugDescriptions((prevState) => {
            const updatingEnhancedBugDescription = [...prevState];
            updatingEnhancedBugDescription[index] =
              enhanceBugDescriptionResponse.data;
            return updatingEnhancedBugDescription;
          });
          updatedEnhancedBugDescription[index] =
            enhanceBugDescriptionResponse.data;
        } else {
          setEnhancedBugDescriptions((prevState) => {
            const updatingEnhancedBugDescription = [...prevState];
            updatingEnhancedBugDescription[index] = null;
            return updatingEnhancedBugDescription;
          });
          updatedEnhancedBugDescription[index] = null;
        }
      }

      navigate("/dashboard/multi-bugreports/output", {
        state: {
          multipleInputs: selectedBugDetails.filter((item) => item !== null),
          multipleOutputs: updatedEnhancedBugDescription.filter(
            (item) => item !== null
          ),
        },
        replace: true,
      });
    } catch (error) {
      console.error(error);
      if (error) {
        setSessionExpiredValue(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const createAccordionHandlers = (
    setCheckedStates,
    setExpandedStates,
    checkedStates,
    expandedStates,
    accordianData,
    setAccordianData,
    selectedAccordianData,
    setSelectedAccordianData
  ) => ({
    handleChange: (index) => (event, isExpanded) => {
      setExpandedStates((prev) => {
        const newStates = [...prev];
        newStates[index] = isExpanded;
        return newStates;
      });
    },

    handleCheckboxChange: (index, event) => {
      event.stopPropagation();
      setCheckedStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = !newStates[index];
        return newStates;
      });
      const updatedSelection = [...selectedAccordianData];
      if (event.target.checked) {
        updatedSelection[index] = accordianData[index];
      } else {
        updatedSelection[index] = null;
      }
      setSelectedAccordianData(updatedSelection);
    },

    handleSelectAll: () => {
      setCheckedStates((prevStates) => {
        const isAnyUnchecked = prevStates.some((state) => !state);
        return Array(prevStates.length).fill(isAnyUnchecked);
      });
      setSelectedAccordianData([...accordianData]);
    },

    handleUnselectAll: () => {
      setCheckedStates((prev) => Array(prev.length).fill(false));
      setSelectedAccordianData(Array(accordianData.length).fill(null));
    },

    handleExpandAll: () => {
      setExpandedStates((prev) => Array(prev.length).fill(true));
    },

    handleCollapseAll: () => {
      setExpandedStates((prev) => Array(prev.length).fill(false));
    },
  });

  const bugDetailsHandlers = createAccordionHandlers(
    setBugDetailsCheckedStates,
    setBugDetailsExpandedStates,
    bugDetailsCheckedStates,
    bugDetailsExpandedStates,
    bugDetails,
    setBugDetails,
    selectedBugDetails,
    setSelectedBugDetails
  );

  return (
    <Box
      className="bug-report-container"
      sx={{
        bgcolor: "#F6F3F2",
        minHeight: "100vh",
        p: 2,
        maxWidth: "600px",
        justifySelf: "center",
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  color: "var(--Schemes-Secondary, #000)",
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <AutomateIcon sx={{ mr: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    Generate detailed and insightful bug reports with ease
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Accordion
              expanded={bugDetailsExpanded}
              onChange={() => setBugDetailsExpanded(!bugDetailsExpanded)}
              sx={{
                mb: 2,
                bgcolor: "white",
                borderRadius: 3,
                boxShadow: 1,
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 16px",
                  "& .MuiAccordionSummary-content": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {bugDetailsExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, ml: 1, fontSize: "0.95rem" }}
                >
                  Bug Details ({bugDetails.length})
                </Typography>
              </AccordionSummary>

              <RenderAccordionContent
                items={bugDetailsItems}
                page={bugDetailsPage}
                checkedStates={bugDetailsCheckedStates}
                expandedStates={bugDetailsExpandedStates}
                handlers={bugDetailsHandlers}
                setPage={setBugDetailsPage}
                showEditIcon={true}
                isEnhanced={false}
                accordianData={bugDetails}
                setAccordianData={setBugDetails}
                selectedAccordianData={selectedBugDetails}
                setSelectedAccordianData={setSelectedBugDetails}
              />

              <Divider sx={{ mt: 2, mb: 1 }} />

              <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button
                  startIcon={<GenerateButton />}
                  variant="contained"
                  sx={{ textTransform: "none", backgroundColor: "#49A3FF" }}
                  onClick={handleEnhanceBugDescription}
                  disabled={
                    !selectedBugDetails.length ||
                    selectedBugDetails.every((item) => item === null) // Disable if all items are null
                  }
                >
                  Enhance Bug Description
                </Button>
              </Box>
            </Accordion>
          </Box>
        </Box>
        {/* Centered Disclaimer */}
      </Container>
      <Box
        component="footer"
        sx={{
          width: "100%",
          maxWidth: "601px",
          backgroundColor: "#f6f3f2",
          textAlign: "center",
          paddingX: "5px",
          position: "fixed",
          bottom: "0",
          zIndex: "99",
          justifySelf: "center",
        }}
      >
        <Typography variant="caption" color="textSecondary">
          Use QMentisAI™ with human supervision, as with all generative AI
          tools.
        </Typography>
      </Box>
      {sessionExpiredValue && <SessionExpired />}
    </Box>
  );
};

export default ImportedInputBugReport;
