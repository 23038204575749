import React, { useContext, useEffect, useState } from "react";

// MUI components
import { Box, IconButton, Tooltip  } from "@mui/material";

// MUI icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { featureFiles, pseudoCode } from "../../../services/webServices";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";

// Icons from project
// import { ReactComponent as ExportIcon } from "../../../images/exportIcon.svg";

// Components and functions from project
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonTable from "../../custom-components/CommonTestCasesTable";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import CommonDownload from "../../custom-components/CommonDownload";
import FeatureFilesCreated from "./CommonUnselectAll";
import Feature from "./FeatureFilesCreated";
import { useLocation, useNavigate } from "react-router-dom";
import { TestCaseFlowDataContext } from "../../main-pages/TestAutomationPage";
import { LoadingModalContext } from "../../main-pages/ServicePage";

const IconComponent = () => (

    <Box sx={{ maxWidth: 14, display: 'flex', flexDirection: 'column' }}>
        <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/74001bbbe35d84ed7dd44adbf23d2273b030df4d38296f54b84768e53b777ae2?placeholderIfAbsent=true&apiKey=61dcb7c26aae4febb0f66efb27027a73"
            alt=""
            style={{ width: '100%', objectFit: 'contain' }}
        />
    </Box>
);

const TestCasesComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { testCaseDetails, setTestCaseDetails } = useContext(TestCaseFlowDataContext);
    const featureFilesFromContext = useContext(TestCaseFlowDataContext).featureFiles;

    const defaultTestCasesExpanded = testCaseDetails.length > 0 && featureFilesFromContext === "";

    const [testCasesData, setTestCasesData] = useState(testCaseDetails || []);

    const [showFeatureFiles, setShowFeatureFiles] = useState(false); // State to control feature files display
    const [featureFilesContent, setFeatureFilesContent] = useState(null); // Store feature files content
    const setLoading = useContext(LoadingModalContext);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);

    const [activeThumb, setActiveThumb] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    
    const handleLike = () => {
        setLiked(!liked);
        if (disliked) setDisliked(false);
      };
    
      const handleDislike = () => {
        setDisliked(!disliked);
        if (liked) setLiked(false);
      };
    
      const handleCopy =
        () => {
          const textToCopy = JSON.stringify(testCasesData, null, 2);
          navigator.clipboard.writeText(textToCopy).then(() => {
            alert("Text copied to clipboard!");
          });
        };
    

    useEffect(() => {
        setTestCasesData(testCaseDetails)
    }, [testCaseDetails]);

    const handleGenerateTestCases = async () => {
        setLoading(true);
        setFeatureFilesContent(null);

        try {
            // Your API call to generate feature files
            const generatedFeatureFileResponse = await featureFiles(testCasesData); // Make sure to define testCasesData
            const generatedFeatureFile = generatedFeatureFileResponse.data;
            // console.log(generatedFeatureFile, "generated feature");
            // setFeatureFilesContent(generatedFeatureFile);
            // setShowFeatureFiles(true); // Show the FeatureFiles component
            navigate("/dashboard/test-automation/output", { state: { ...location.state, featureFilesData: generatedFeatureFile }, replace: true });
            // Update state with the generated content and show the component
        } catch (error) {
            console.error("Error generating feature files:", error);
        } finally {
            setLoading(false);
        }
    };

    // const handlePseudoCode = async () => {
    //     const generatedPseudoCode = [{}]
    //     // setLoading(true);
    //     // setResponseError(null);
    //     // setpsudoCodeGenerated([]);
    //     // setLocatorsGenerated([]);
    //     // setPageClassGenerated([]);
    //     // setStepDefinitionGenerated([]);
    //     // setMavenGenerated({}); 
    //     try {
    //       const generatedPseudoCodeGenerated = await pseudoCode(generatedFeatureFile);
    //       const generatedPseudoCode = generatedPseudoCodeGenerated.data;

    //       // setpsudoCodeGenerated(generatedPseudoCode); // Update with the fetched data
    //     } catch (error) {
    //       // console.log(error);
    //       // setResponseError(error.response.data["Error"] || "An error occurred.");
    //     } 
    //     // finally {
    //     //   setLoading(false);
    //     // }
    //   };
    // Mock test cases output for UI development
    const [userStory, setUserStory] = useState({
        "Title": "Title for userStory from frontend",
        "Description": "Description for userStory from frontend",
        "Acceptance Criteria": "Acceptance Criteria for userStory from frontend",
        "Additional Information": "Additional Information for userStory from frontend"
    })

    const [openDownload, setOpenDownload] = useState(false);

    const handleDownloadClick = () => {
        setOpenDownload(true);
    };

    const handleCopyClick = () => {
        // Handle copy action (you can implement actual copying logic here)
        console.log("Content copied!"); // Placeholder for copy logic
        setIsCopied(true);
        // Reset the copied status after 2 seconds (optional)
        setTimeout(() => setIsCopied(false), 2000);
    };

    // Formatting or flattening the test cases data values(Array or Objects) into strings
    const formattedTestCases = [];
    testCasesData.map((testCase) => {
        const flattenTestCase = {};
        Object.entries(testCase).map(([key, value]) => {
            if (Array.isArray(value)) {
                return (flattenTestCase[key] = flattenArray(value));
            } else if (typeof value === "object" && !!value) {
                return (flattenTestCase[key] = flattenObject(value));
            } else {
                return (flattenTestCase[key] = value);
            }
        });
        return formattedTestCases.push(flattenTestCase);
    });

    // Export functionality handler function
    const handleExport = async () => {
        try {
            const apiData = {
                userStory: userStory,
                testCases: formattedTestCases
            }
            // const createdIssue = await createTestCasesJiraIssue(apiData);
            // console.log(createdIssue);
        } catch (error) {
            console.log(error)
        }
    };

    // Document download handler function
    const handleDownload = () => {
        console.log("Download clicked...");
    };

    const handleTestAutomation = async () => {
        try {
            // const featureFile = await featureFiles(testCasesData);
            // console.log(featureFile)
        } catch (error) {
            console.log(error)
        }
    };

    const handleThumbClick = (thumbType) => {
        // Toggle thumbs based on the thumbType (like or dislike)
        setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
    };


    // Converting flattened test cases data into arrays using it as rows for common table
    const tableDataConverter = (testCasesData) => {
        const headers = Object.keys(testCasesData[0]);
        const values = testCasesData.map((eachTestCase) => {
            return Object.values(eachTestCase);
        });
        return { headers, values };
    };

    // Table rendering using custom component
    const testCasesTable = (
        <Box sx={{ height: "100%" }}>
            <Box
                sx={{
                    maxHeight: "60vh",
                    height: "100%",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                        width: "0px",
                    },
                    scrollbarWidth: "none",
                }}
            >
                <CommonTable
                    {...tableDataConverter(formattedTestCases)}
                    edittable={true}
                    tableData={formattedTestCases}
                    setTableData={setTestCasesData}
                />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2,marginTop:2 }}>
                                <Tooltip title="Like" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("like")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "like" ? (
                                            <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                                        ) : (
                                            <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Thumbs Down Button with Tooltip */}
                                <Tooltip title="Dislike" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("dislike")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#FF4842", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "dislike" ? (
                                            <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                                        ) : (
                                            <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Copy Button with Tooltip */}
                                <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                                    <Box
                                        onClick={handleCopyClick}
                                        sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {isCopied ? (
                                            <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                                        ) : (
                                            <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                                        )}
                                    </Box>
                                </Tooltip>
                        </Box>

        </Box>
    );

    return (
        <Box>
        <>
            <CommonAccordion
                summary={"Test Cases"}
                details={testCasesTable}
                type={"output"}

                buttons={[

                    { label: 'Export', onClick: handleDownloadClick, variant: 'text', startIcon: <IconComponent />, sx: { textTransform: 'none' } },

                    { label: 'Download', onClick: handleDownloadClick, variant: 'text', startIcon: <SaveAltRoundedIcon />, sx: { textTransform: 'none' } },
                    { label: 'Generate Feature Files', onClick: handleGenerateTestCases, startIcon: <GenerateButton />, variant: 'contained', sx: { float: "right" } },

                    // { label: 'GeneratePseduoCode', onClick: handlePseudoCode, startIcon: <SendSharpIcon />, variant: 'contained', sx: { float: "right" } }

                ]}

                defaultExpanded={defaultTestCasesExpanded}
                fullScreenMode={true}

            />
            {/* {showFeatureFiles && (
                <Feature content={featureFilesContent} />
            )} */}
            <CommonDownload open={openDownload} onClose={() => setOpenDownload(false)} 
              serviceResponse={[formattedTestCases]}
              serviceName="TestCases_Report"
              wordData={[{heading: "User Story", type: "text", data: userStory["Title"]}, {heading: "Test Cases", type: "table", data: {...tableDataConverter(formattedTestCases)}}]}
                />


        </>
        </Box>
    );
};

export default TestCasesComponent;
