import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Grid2,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import ExportIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import CommonAccordion from "../../custom-components/CommonAccordion";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import { ReactComponent as PenIcon } from "../../../images/penIcon.svg";
import { useNavigate } from "react-router-dom";
import { scrutinize } from "../../../services/webServices";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CommonDownload from "../../custom-components/CommonDownload";
import FullscreenToggle from "../../custom-components/FullScreenToggle";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ReactComponent as ExportButton } from "../../../images/exportButton.svg";

const BugReportEnhancerPage = ({ bugReportData, enhancedBugReport }) => {
  const [bugReport, setBugReportData] = useState(bugReportData || {});
  const [enhancedReport, setEnhancedReport] = useState(enhancedBugReport || {});
  // console.log("enhancedReport", enhancedReport);

  const [activeThumb, setActiveThumb] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!bugReport || Object.keys(bugReport).length === 0) {
      return navigate("/dashboard/bugreportenhancer/input");
    }
  }, [bugReport]);

  const [openDownload, setOpenDownload] = useState(false);

  const handleDownload = () => {
    console.log("Download clicked...");
  };

  const handleThumbClick = (thumbType) => {
    // Toggle thumbs based on the thumbType (like or dislike)
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    // Handle copy action (you can implement actual copying logic here)
    console.log("Content copied!"); // Placeholder for copy logic
    setIsCopied(true);
    // Reset the copied status after 2 seconds (optional)
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleGenerateTestCases = () => {
    console.log("Generate Test Cases clicked...");
  };

  const [editMode, setEditMode] = useState({
    bugInput: false,
    title: false,
    description: false,
    stepsToReproduce: false,
    expectedVsActual: false,
  });

  const [fields, setFields] = useState(enhancedReport);
  const [tempFields, setTempFields] = useState({ ...fields });

  // Save to localStorage whenever the fields are updated
  // const saveToLocalStorage = (updatedFields) => {
  //   localStorage.setItem("bugReportFields", JSON.stringify(updatedFields));
  // };

  const handleEditClick = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: true }));
    setTempFields((prev) => ({ ...prev, [field]: fields[field] }));
  };

  const handleFieldChange = (field, value) => {
    setTempFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveClick = (field) => {
    const updatedFields = { ...fields, [field]: tempFields[field] };
    setFields(updatedFields);
    setEditMode((prev) => ({ ...prev, [field]: false }));
    // saveToLocalStorage(updatedFields); // Save the changes to localStorage
  };

  const handleCancelClick = (field) => {
    setTempFields((prev) => ({ ...prev, [field]: fields[field] }));
    setEditMode((prev) => ({ ...prev, [field]: false }));
  };

  const renderFieldsFromResponse = (fields) => {
    return Object.keys(fields).map((fieldKey) => {
      // Helper function to format the title
      const formatTitle = (title) => {
        const exceptions = ["for", "and", "of", "in", "on", "at", "to", "by"];
        return title
          .replace(/_/g, " ") // Replace underscores with spaces
          .replace(/Evidences TBD/i, "Evidence") // Replace "Evidences TBD" with "Evidence"
          .split(" ")
          .map((word, index) => {
            if (index === 0 || !exceptions.includes(word.toLowerCase())) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word.toLowerCase();
          })
          .join(" ");
      };

      const transformedTitle = formatTitle(fieldKey);

      return (
        <Grid item xs={12} key={fieldKey}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "&:hover .edit-icon": { display: "flex" },
              width: "100%",
            }}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                width: "300px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {transformedTitle}
            </Typography>
            <Tooltip title="Click to edit" placement="right" arrow>
              <IconButton
                size="small"
                className="edit-icon"
                sx={{
                  alignSelf: "flex-start",
                }}
                onClick={() => handleEditClick(fieldKey)}
              >
                <PenIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          {renderEditableField(fieldKey, Array.isArray(fields[fieldKey]))}
        </Grid>
      );
    });
  };

  const renderEditableField = (field, multiline = false) => {
    const fieldValue = editMode[field] ? tempFields[field] : fields[field];

    // Function to format ideal test cases and other fields
    const formatIdealTestCases = (cases) => {
      if (typeof cases === "object" && !Array.isArray(cases)) {
        return Object.entries(cases)
          .map(([key, value]) => {
            const formattedKey = key.replace(/_/g, " "); // Replace underscores with spaces

            if (key.toLowerCase() === "test_steps" && Array.isArray(value)) {
              // If test_steps is an array, format each step on a new line
              const formattedSteps = value
                .map((step, index) => `${step}`) // Number each step
                .join("\n"); // Join with newline
              return `${formattedKey}:\n${formattedSteps}`; // Return formatted steps with line breaks
            }

            // Handle nested objects (e.g., { Expected, Actual })
            if (typeof value === "object" && !Array.isArray(value)) {
              return `${formattedKey}:\n${Object.entries(value)
                .map(([subKey, subValue]) => `  - ${subKey}: ${subValue}`)
                .join("\n")}`;
            }

            // For other fields, just format normally
            return `${formattedKey}: ${value}`;
          })
          .join("\n");
      }
      return cases;
    };

    // Function to convert objects into a string
    const convertObjectToString = (obj) => {
      return Object.entries(obj)
        .map(([key, val]) => `${key}: ${val}`)
        .join("\n");
    };

    // Prepare the display value
    const displayValue =
      typeof fieldValue === "object" && !Array.isArray(fieldValue)
        ? formatIdealTestCases(fieldValue) // Handle "Ideal Test Cases"
        : Array.isArray(fieldValue)
        ? fieldValue.join("\n") // Convert arrays to newline-separated string
        : fieldValue || "";

    return (
      <Box
        sx={{ position: "relative", "&:hover .edit-icon": { display: "flex" } }}
      >
        {editMode[field] ? (
          <>
            <TextField
              sx={{
                width: "450px", // Custom width for editing
                transition: "width 0.3s ease",
              }}
              variant="outlined"
              multiline // Always enable multiline
              rows={4} // Default number of rows, adjust as needed
              value={displayValue}
              onChange={(e) => {
                handleFieldChange(field, e.target.value);
              }}
              autoFocus
            />
            <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1 }}>
              <Button
                onClick={() => handleSaveClick(field)}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Save
              </Button>
              <Button
                onClick={() => handleCancelClick(field)}
                size="small"
                sx={{ textTransform: "none" }}
              >
                Cancel
              </Button>
            </Box>
          </>
        ) : (
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "pre-line", // Preserve line breaks for strings
              display: "block",
            }}
          >
            {displayValue}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      className="bug-report-container"
      sx={{ bgcolor: "#F6F3F2", height: "100%", p: 2, width: "600px", pb: 5 }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: 600,
            margin: "0 auto",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={2}
          >
            <Box sx={{ display: "flex" }}>
              <AutomateIcon sx={{ mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                Generate detailed and insightful bug reports with ease
              </Typography>
            </Box>

            <FullscreenToggle />
          </Box>

          {/* //Common Accordion for Input Bug Details */}
          <CommonAccordion
            summary={"Input Bug Details"}
            details={
              <Grid2 container spacing={2} mb={2}>
                {typeof bugReport === "object" ? (
                  <>
                    {bugReport.title && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Title
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.title}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.description && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Description
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.description}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.environmentDetails && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Environment Details
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.environmentDetails}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.stepsToReproduce && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Steps to Reproduce
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.stepsToReproduce}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.expectedResult && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Expected Result
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.expectedResult}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.actualResult && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Actual Result
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.actualResult}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.additionalInfo && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Additional Information
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.additionalInfo}
                        </Typography>
                      </Grid>
                    )}

                    {bugReport.impact && (
                      <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                          Impact
                        </Typography>
                        <Typography variant="body2">
                          {bugReport.impact}
                        </Typography>
                      </Grid>
                    )}
                  </>
                ) : (
                  <Typography variant="body2" mb={2}>
                    {bugReport}
                  </Typography>
                )}
              </Grid2>
            }
            type={"output"}
          />

          {/* // Enhance Bug Report Details Output */}
          <CommonAccordion
            defaultExpanded={true}
            summary={"Enhance Bug Description"}
            details={
              <>
                <Box sx={{ px: 1 }}>
                  {" "}
                  <Grid container spacing={2}>
                    {renderFieldsFromResponse(enhancedBugReport)}
                  </Grid>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
                  {/* Thumbs Up Button with Tooltip */}
                  <Tooltip title="Like" arrow>
                    <Box
                      onClick={() => handleThumbClick("like")}
                      sx={{
                        cursor: "pointer",
                        marginRight: 2, // Margin between the buttons
                        "&:hover": {
                          color: "#49A3FF", // Change color on hover
                        },
                      }}
                    >
                      {activeThumb === "like" ? (
                        <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                      ) : (
                        <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                      )}
                    </Box>
                  </Tooltip>

                  {/* Thumbs Down Button with Tooltip */}
                  <Tooltip title="Dislike" arrow>
                    <Box
                      onClick={() => handleThumbClick("dislike")}
                      sx={{
                        cursor: "pointer",
                        marginRight: 2, // Margin between the buttons
                        "&:hover": {
                          color: "#FF4842", // Change color on hover
                        },
                      }}
                    >
                      {activeThumb === "dislike" ? (
                        <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                      ) : (
                        <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                      )}
                    </Box>
                  </Tooltip>

                  {/* Copy Button with Tooltip */}
                  <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                    <Box
                      onClick={handleCopyClick}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "#49A3FF", // Change color on hover
                        },
                      }}
                    >
                      {isCopied ? (
                        <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                      ) : (
                        <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                      )}
                    </Box>
                  </Tooltip>
                </Box>
              </>
            }
            type={"output"}
            buttons={[
              {
                label: "Export",
                onClick: handleDownload,
                variant: "text",
                startIcon: <ExportButton />,
              },
              {
                label: "Download",
                onClick: handleGenerateTestCases,
                startIcon: <GenerateButton />,
                variant: "contained",
                sx: { float: "right" },
                onClick: () => setOpenDownload(true),
              },
            ]}
            sx={{
              "&:hover, &:focus": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                borderRadius: "16px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
          />
          <CommonDownload
            open={openDownload}
            onClose={() => setOpenDownload(false)}
            serviceResponse={enhancedBugReport}
            serviceName="Bug_Report"
            wordData={[
              {
                heading: "Enhanced Bug Reports",
                type: "subParagraphs",
                data: enhancedBugReport,
              },
            ]}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default BugReportEnhancerPage;
