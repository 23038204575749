import React, { useContext, useState } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {ReactComponent as PenIcon} from "../../../images/penIcon.svg"; // Pen icon for editing
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonDownload from "../../custom-components/CommonDownload";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TestCaseFlowDataContext } from "../../main-pages/TestcaseGenerationPage";
import { investigate } from "../../../services/webServices";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";

const UserStoryComponent = () => {
  const [openDownload, setOpenDownload] = useState(false);
  const { userStory, evaluationSummary } = useContext(TestCaseFlowDataContext);
  const setLoading = useContext(LoadingModalContext);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);
  const defaultUserStoryExpanded = Object.keys(userStory).length > 0 && evaluationSummary.length === 0;

  const navigate = useNavigate();
  const location = useLocation();

  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleEvaluateUserStory = async () => {
    try {
      setLoading(true);
      const evaluationSummary = await investigate(userStory);
      navigate("/dashboard/generate-testcases/output", {
        state: { ...location.state, evaluationSummary: evaluationSummary.data },
        replace: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const EditableField = ({ label, initialValue, textProps }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [tempValue, setTempValue] = useState(initialValue);

    const handleSave = (label) => {
      setValue(tempValue); // Save the edited value
      const updatedUserStory = { ...userStory, [label]: tempValue };
      navigate("/dashboard/generate-testcases/output", {
        state: { ...location.state, userStory: updatedUserStory },
        replace: true,
      });
      setIsEditing(false); // Exit edit mode
    };

    const handleCancel = () => {
      setTempValue(value); // Revert to original value
      setIsEditing(false); // Exit edit mode
    };

    return (
      <Box
        sx={{
          "&:hover .edit-icon": { visibility: "visible" },
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ marginBottom: 1, fontSize: "14px" }}
        >
          {label}
        </Typography>
        {isEditing ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name={label}
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
            <Box>
              {/* Save Button */}
              <Button
                onClick={() => handleSave(label)}
                variant="outline"
                size="small"
                sx={{ marginRight: 1, color: "#49A3FF", textTransform: "none" }}
              >
                Save
              </Button>
              {/* Cancel Button */}
              <Button
                onClick={handleCancel}
                variant="outline"
                size="small"
                sx={{ marginRight: 1, color: "#49A3FF", textTransform: "none" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography variant="body1" {...textProps}>
              {value}
            </Typography>
            <IconButton
              onClick={() => setIsEditing(true)}
              className="edit-icon"
             // sx={{ visibility: "hidden" }}
            >
              <PenIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  const accordionContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {typeof userStory === "object" && userStory !== null ? (
        <>
          <Box sx={{  width: "100%" }}>
            <EditableField
              label="Title"
              initialValue={userStory["Title"]}
              // labelProps={{ sx: { marginBottom:  } }}
              textProps={{ sx: { fontSize: "14px" , marginBottom : 3} }}
            />
          </Box>

          <Box sx={{  width: "100%" }}>
            <EditableField
              label="Description"
              initialValue={userStory["Description"]}
              textProps={{ sx: { fontSize: "14px", marginBottom : 3} }}
            />
          </Box>
          <Box sx={{  width: "100%" }}>
            <EditableField
              label="Acceptance Criteria"
              initialValue={userStory["Acceptance Criteria"]}
              textProps={{ sx: { fontSize: "14px" , marginBottom : 3} }}
            />
          </Box>
          {userStory["Additional Information"] && (<Box sx={{ width: "100%" }}>
            <EditableField
              label="Additional Information"
              initialValue={userStory["Additional Information"]}
              textProps={{ sx: { fontSize: "14px" , marginBottom : 3} }}
            />
          </Box>)}
          
        </>
      ) : (
        <Typography>{userStory}</Typography> // Displays userStory as plain text if it's a string
      )}

      <Box
        sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
      >
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  const buttonsgroup = [
    {
      label: "Download",
      variant: "text",
      startIcon: <FileDownloadOutlinedIcon />,
      onClick: () => setOpenDownload(true), // Open CommonDownload dialog
    },
    {
      label: "Evaluate User Story",
      variant: "contained",
      onClick: handleEvaluateUserStory,
      startIcon: <GenerateButton />,
      sx: { float: "right" },
    },
  ];

  return (
    <Box>
      <CommonAccordion
        summary={
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            User Story Details
          </Typography>
        }
        details={accordionContent}
        buttons={buttonsgroup}
        type={"output"}
        defaultExpanded={defaultUserStoryExpanded}
      />
      <CommonDownload
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        serviceResponse={[userStory]}
        serviceName="User_Story"
        wordData={[{ heading: "User Story", type: "subParagraphs", data: userStory }]}
      />
    </Box>
  );
};

export default UserStoryComponent;
