import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// MUI components
import { Box, IconButton, Tooltip } from "@mui/material";

// MUI icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Icons from project
import { ReactComponent as ExportIcon } from "../../../images/exportIcon.svg";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";

// Components and functions from project
import CommonAccordion from "../../custom-components/CommonAccordion";
import CommonTable from "../../custom-components/CommonTestCasesTable";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import {
  createTestCasesJiraIssue,
  featureFiles,
} from "../../../services/webServices";
import CommonDownload from "../../custom-components/CommonDownload";

const TestCasesComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // const [openDownload, setOpenDownload] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const userStory = location.state.userStory || {};
  const [testCasesData, setTestCasesData] = useState(location.state.testcases || []);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  useEffect(() => {
    // console.log(location.state, "state check in testcases page")
    setTestCasesData(location.state.testcases);
  }, [location.state.testcases]);
  const handleDownloadClick = () => {
    setDownloadOpen(true);
  };

  const handleCloseDownload = () => {
    setDownloadOpen(false);
  };

  // Formatting or flattening the test cases data values(Array or Objects) into strings
  const formattedTestCases = [];
  testCasesData.map((testCase) => {
    const flattenTestCase = {};
    Object.entries(testCase).map(([key, value]) => {
      if (Array.isArray(value)) {
        return (flattenTestCase[key] = flattenArray(value));
      } else if (typeof value === "object" && !!value) {
        return (flattenTestCase[key] = flattenObject(value));
      } else {
        return (flattenTestCase[key] = value);
      }
    });
    return formattedTestCases.push(flattenTestCase);
  });

  // Export functionality handler function
  const handleExport = async () => {
    try {
      const apiData = {
        userStory: userStory,
        testCases: formattedTestCases,
      };
      const createdIssue = await createTestCasesJiraIssue(apiData);
      // console.log(createdIssue);
    } catch (error) {
      console.log(error);
    }
  };

  // Document download handler function
  const handleDownload = () => {
    console.log("Download clicked...");
  };
  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleTestAutomation = async () => {
    try {
      navigate("/dashboard/test-automation/output", { state: { testCaseDetails: testCasesData } })
    } catch (error) {
      console.log(error);
    }
  };

  // Converting flattened test cases data into arrays using it as rows for common table
  const tableDataConverter = (testCasesData) => {
    const headers = Object.keys(testCasesData[0]);
    const values = testCasesData.map((eachTestCase) => {
      return Object.values(eachTestCase);
    });
    return { headers, values };
  };

  // Table rendering using custom component
  const testCasesTable = (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          maxHeight: "60vh",
          height: "100%",
          // overflow: "auto",
          // "&::-webkit-scrollbar": {
          //   width: "1px",
          // },
          // scrollbarWidth: "none",
        }}
      >
        <CommonTable
          {...tableDataConverter(formattedTestCases)}
          edittable={true}
          tableData={formattedTestCases}
          setTableData={setTestCasesData}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: 7, marginBottom:2 }}>
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    <Box>
      <CommonAccordion
        summary={"Generated Test Cases"}
        details={testCasesTable}
        type={"output"}
        buttons={[
          {
            label: "Export",
            onClick: handleExport,
            variant: "text",
            startIcon: <ExportIcon />,
            sx: { textTransform: 'none' }
          },
          {
            label: "Download",
            onClick: handleDownloadClick,
            variant: "text",
            startIcon: <FileDownloadOutlinedIcon />,
            sx: { textTransform: 'none' }
          },
          {
            label: "Begin Test Automation",
            onClick: handleTestAutomation,
            variant: "contained",
            startIcon: <GenerateButton />,
            sx: { float: "right", textTransform: 'none' },
          },
        ]}
        fullScreenMode={true}
        defaultExpanded={true}
      />
      <CommonDownload
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        serviceResponse={[formattedTestCases]}
        serviceName="TestCases_Report"
        wordData={[{ heading: "User Story", type: "text", data: (typeof userStory === "string") ? userStory : userStory["Title"] }, { heading: "Test Cases", type: "table", data: { ...tableDataConverter(formattedTestCases) } }]}
      />

    </Box>
  );
};

export default TestCasesComponent;
