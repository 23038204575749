import * as XLSX from "xlsx";
import { serviceResponseTransformation } from "./download-utils/transformDataToExport";
import { generateFilename } from "./download-utils/generateFilenameToExport";

export const exportToCSV = (serviceResponses, filename) => {
  if (!serviceResponses || (Array.isArray(serviceResponses) && serviceResponses.length === 0)) {
    return;
  }

  const responsesArray = Array.isArray(serviceResponses) ? serviceResponses : [serviceResponses];

  const wb = XLSX.utils.book_new();

  responsesArray.forEach((serviceResponse, index) => {
    if (!serviceResponse) {
      return;
    }

    const { headers, values } = serviceResponseTransformation(serviceResponse, filename, "csv") || {};

    if (!headers || !values || headers.length === 0 || values.length === 0) {
      return;
    }

    const ws = XLSX.utils.aoa_to_sheet([headers, ...values]);
    const sheetName = `${filename} - ${index + 1}`;//`ServiceResponse_${index + 1}`;
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  if (wb.SheetNames.length === 0) {
    return;
  }

  const extendedFileName = generateFilename(filename, "xlsx");
  XLSX.writeFile(wb, extendedFileName);
};
