import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, IconButton, Typography, Tooltip, Menu, Slider, Switch, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import DownloadIcon from "@mui/icons-material/Download";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CommonDownload from '../../custom-components/CommonDownload';
import QMentis_logo from "../../../images/QMentis.svg";
import { arrayOfObjectTransformer, objectDataTransformer } from "../../../utils/tableDataGenerator";
import wordGenerator from '../../download/qeBotWordGenerator';

const BusinessProcessGuide = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { businessProcessData } = location.state || {};
    const [textSize, setTextSize] = useState(14);
    const [anchorEl, setAnchorEl] = useState(null);
    const [spaceIncreased, setSpaceIncreased] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const [processedData, setProcessedData] = useState({
        commonDefectsTableData: null,
        appendixTableData: null,
    });
    const [zoomLevel, setZoomLevel] = useState(1);

    useEffect(() => {
        if (businessProcessData) {
            setProcessedData({
                commonDefectsTableData: arrayOfObjectTransformer(businessProcessData.parsedBuinessProcess["List of Common Defects"]),
                appendixTableData: objectDataTransformer(businessProcessData.parsedBuinessProcess["Appendix"]),
            });
        }
    }, [businessProcessData]);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTextSizeChange = (event, newValue) => {
        setTextSize(newValue);
    };

    const handleDownloadClick = () => {
        setDownloadOpen(true);
    };

    const handleCloseDownload = () => {
        setDownloadOpen(false);
    };

    const handleShare = () => {
        console.log('Share clicked');
    };

    const handleSpaceToggle = () => {
        setSpaceIncreased(prev => !prev);
    };

    const handleZoomIn = () => {
        setZoomLevel(prev => Math.min(prev + 0.1, 3));  // Limit zoom in level to 3x
    };

    const handleZoomOut = () => {
        setZoomLevel(prev => Math.max(prev - 0.1, 1));  // Limit zoom out level to 1x (original size)
    };

    const handleDownloadImage = () => {
        const flowchartImage = businessProcessData?.flowchartResponse;
        if (flowchartImage) {
            const link = document.createElement("a");
            link.href = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(flowchartImage)}`;
            link.download = "flowchart.svg";
            link.click();
        }
    };

    const businessProcessGuideCall = async () => {
        try {
            const responseData = businessProcessData;

            await wordGenerator([
                {
                    type: "text",
                    data: responseData.parsedBuinessProcess["Description of the Business Process"],
                    heading: "Business Process Guide"
                },
                {
                    type: "image",
                    data: responseData.flowchartResponse,
                    heading: "Flow Chart"
                },
                {
                    type: "table",
                    data: arrayOfObjectTransformer(responseData.parsedBuinessProcess["List of Common Defects"]),
                    heading: "Common Defects"
                },
                {
                    type: "table",
                    data: objectDataTransformer(responseData.parsedBuinessProcess["Appendix"]),
                    heading: "Appendix"
                }
            ], "BusinessProcessGuide_Report");
        } catch (error) {
            console.error("Error generating document:", error);
        }
    };


    const renderTable = (tableData) => {
        if (!tableData || !tableData.values.length) return null;

        return (
            <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: textSize }}>
                    {tableData.heading}
                </Typography>
                <Table sx={{ borderCollapse: "collapse", width: "100%", mt: 2 }}>
                    <TableHead>
                        <TableRow>
                            {tableData.headers.map((header) => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        border: "1px solid #ccc",
                                        bgcolor: "#f5f5f5",
                                        fontWeight: "bold",
                                        padding: "12px",
                                        textAlign: "left",
                                        fontSize: `${textSize}px`,
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.values.map((row, index) => (
                            <TableRow key={index}>
                                {row.map((cell, cellIndex) => (
                                    <TableCell
                                        key={cellIndex}
                                        sx={{
                                            border: "1px solid #ccc",
                                            padding: "12px",
                                            textAlign: "left",
                                            fontSize: `${textSize}px`,
                                        }}
                                    >
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const textStyle = {
        fontSize: textSize,
        lineHeight: spaceIncreased ? '1.8' : '1.4',
        paddingBottom: spaceIncreased ? '10px' : '5px',
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '792px', margin: '0 auto', p: 2 }}>
            {/* AppBar */}
            <AppBar
                position="static"
                sx={{
                    width: '100%',
                    background: '#FFFFFF',
                    boxShadow: 'none',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2
                }}
            >
                <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <IconButton onClick={handleBackClick} edge="start" aria-label="back" sx={{ color: 'black' }}>
                        <ArrowBackIcon />
                    </IconButton>

                    {/* Right Corner Icons */}
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <Tooltip title="Change Text Size">
                            <IconButton onClick={handleMenuClick} sx={{ color: 'black' }}>
                                <TextFormatIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                            <IconButton onClick={businessProcessGuideCall} sx={{ color: 'black' }}>
                                <ArrowCircleDownIcon />
                            </IconButton>
                        </Tooltip>


                        <Tooltip title="Share">
                            <IconButton onClick={handleShare} sx={{ color: 'black' }}>
                                <ShareOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Text Size Menu */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        sx={{ '& .MuiPaper-root': { width: '277px', height: '196px' } }}
                    >
                        <Box padding={2}>
                            <Typography variant="subtitle1" sx={{ color: 'black' }}>Text Size</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body2" sx={{ color: 'black' }}>AA</Typography>
                                <Slider
                                    value={textSize}
                                    onChange={handleTextSizeChange}
                                    step={1}
                                    marks={[
                                        { value: 10, label: '' },
                                        { value: 12, label: '' },
                                        { value: 14, label: '' },
                                        { value: 16, label: '' },
                                        { value: 18, label: '' },
                                        { value: 20, label: '' },
                                    ]}
                                    min={10}
                                    max={20}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        marginLeft: 2,
                                        marginRight: 2,
                                        width: '110.5px',
                                        height: '16px',
                                        '& .MuiSlider-thumb': { width: '4px', height: '44px', borderRadius: '2px' },
                                        '& .MuiSlider-track': { backgroundColor: '#49A3FF', borderRadius: '16px' },
                                        '& .MuiSlider-rail': { backgroundColor: '#E0E0E0', borderRadius: '16px' },
                                        '& .MuiSlider-mark': { height: '4px', width: '4px', borderRadius: '50%', backgroundColor: '#49A3FF' },
                                        '& .MuiSlider-markActive': { backgroundColor: 'white' },
                                    }}
                                />
                                <Typography variant="body2" sx={{ color: 'black' }}>AA</Typography>
                            </Box>

                            <Box display="flex" alignItems="center" marginTop={2}>
                                <Typography variant="body1" sx={{ color: 'black', marginRight: 1 }}>Increase Spacing</Typography>
                                <Switch checked={spaceIncreased} onChange={handleSpaceToggle} />
                            </Box>
                        </Box>
                    </Menu>
                </Toolbar>
            </AppBar>

            {/* Business Process Guide Content */}
            <Box sx={{ p: 4, backgroundColor: "#FFFFFF" }}>
                {/* Header Section */}
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <img src={QMentis_logo} alt="QEBot.ai Logo" style={{ width: 64, height: 64, marginRight: 16 }} />
                    <Box>
                        <Typography variant="h6" component="span" sx={{ display: "block", fontWeight: 500 }}>
                            QMentisAI™ – Intelligence for Next-Gen Quality.
                        </Typography>
                        <Typography variant="body1" component="span" sx={{ fontWeight: 500 }}>
                            From AICoE, QualiZeal
                        </Typography>
                    </Box>
                </Box>

                {/* Sections */}
                {businessProcessData ? (
                    <>
                        <Section title="Business Process Guide" />
                        <Section title="Description">
                            <Typography variant="body1" sx={textStyle} paragraph>
                                {businessProcessData.parsedBuinessProcess["Description of the Business Process"]}
                            </Typography>
                        </Section>

                        <Section title="Flow Chart">
                            {businessProcessData.flowchartResponse && (
                                <Box sx={{ mt: 2, position: "relative", display: "inline-block" }}>
                                    <img
                                        src={`data:image/svg+xml;charset=UTF-8,${encodeURIComponent(businessProcessData.flowchartResponse)}`}
                                        alt="Flowchart"
                                        loading="lazy"
                                        style={{
                                            maxWidth: "100%",
                                            height: "auto",
                                            transform: `scale(${zoomLevel})`,  // Apply zoom transformation
                                            border: "1px solid #ccc",
                                            borderRadius: "10px",
                                            padding: "5px",
                                        }}
                                    />
                                    <OverlayActions onDownload={handleDownloadImage} onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
                                </Box>
                            )}
                        </Section>

                        <Section title="Common Defects">{renderTable(processedData.commonDefectsTableData)}</Section>
                        <Section title="Appendix Table">{renderTable(processedData.appendixTableData)}</Section>
                    </>
                ) : (
                    <Typography variant="body2">Loading....</Typography>
                )}
            </Box>
        </Box>
    );
};

const Section = ({ title, children }) => (
    <Box sx={{ mt: 4 }}>
        <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{title}</Typography>
        {children}
    </Box>
);

const OverlayActions = ({ onDownload, onZoomIn, onZoomOut }) => (
    <Box
        sx={{
            position: "absolute",
            bottom: 30,
            right: 8,
            display: "flex",
            alignItems: "center",
            gap: 1,
            bgcolor: "rgba(128, 128, 128, 0.8)",
            borderRadius: 1,
            p: 0.5,
        }}
    >
        <Tooltip title="Download">
            <IconButton onClick={onDownload} sx={{ color: "white" }} size="small">
                <DownloadIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Zoom In">
            <IconButton onClick={onZoomIn} sx={{ color: "white" }} size="small">
                <ZoomInIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Zoom Out">
            <IconButton onClick={onZoomOut} sx={{ color: "white" }} size="small">
                <ZoomOutIcon />
            </IconButton>
        </Tooltip>

    </Box>
);

export default BusinessProcessGuide;
