import React, { useContext } from "react";
import BugReportEnhancerPage from "../../output-components/bug-report-enhancer/BugReportEnhancerPage";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { BugReportDataContext } from "../BugReportPage";

const BugReportOutputPage = () => {
  const location = useLocation();
  const { bugReportData, enhancedBugReport } = location.state || {
    bugReportData: {},
    enhancedBugReport: {},
  };
  const { setBugReportData, setEnhancedBugReportData } = useContext(BugReportDataContext);
  setBugReportData(bugReportData);
  setEnhancedBugReportData(enhancedBugReport);
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      <BugReportEnhancerPage
        bugReportData={bugReportData}
        enhancedBugReport={enhancedBugReport}
      />

      <Box
        component="footer"
        sx={{
          width: "100%",
          maxWidth: "601px",
          backgroundColor: "#f6f3f2",
          textAlign: "center",
          paddingX: "5px",
          position: "fixed",
          bottom: "0",
          zIndex: "99"
        }}
      >
        <Typography variant="caption" color="textSecondary">
          Use QMentisAI™ with human supervision, as with all generative AI tools.
        </Typography>
      </Box>
    </div>
  );
};

export default BugReportOutputPage;
