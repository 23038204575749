import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import QMentisBreadCrumb from "../custom-components/QMentisBreadCrumb";
import { Box } from "@mui/material";

export const MultipleBugReportsContext = createContext(null);

const MultiBugReportPage = () => {
  const [multipleBugReports, setMultipleBugReports] = useState([]);
  const [multipeEnhancedBugReports, setMultipleEnhancedBugReports] = useState(
    []
  );

  const breadCrumbs = [
    {
      title: "Input Bug Details",
      status: multipleBugReports.length > 0 ? "Completed" : "In Progress",
    },
    {
      title: "Enhance Bug Descriptions",
      status:
        multipeEnhancedBugReports.length > 0
          ? "Completed"
          : multipleBugReports.length > 0
          ? "In Progress"
          : "Not Started",
    },
  ];
  return (
    <div>
      <Box
          sx={{
            position: "sticky",
            top: "0", // Adjust this value as needed to place the breadcrumb where you want it
            width: "100%",
            zIndex: 1000, // Ensure it stays above other content
            bgcolor: "white", // Background color to prevent transparency
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow for visual separation
          }}
        >
          <QMentisBreadCrumb breadCrumbs={breadCrumbs} sx={{height: "56px"}} />
        </Box>
      <MultipleBugReportsContext.Provider
        value={{
          multipleBugReports,
          setMultipleBugReports,
          multipeEnhancedBugReports,
          setMultipleEnhancedBugReports,
        }}
      >
        <Outlet />
      </MultipleBugReportsContext.Provider>
    </div>
  );
};

export default MultiBugReportPage;
