import React, { useContext, useEffect, useState } from 'react'
import TestCasesComponent from '../../output-components/test-automation/TestCasesComponent'
import FeatureFilesCreated from '../../output-components/test-automation/CommonUnselectAll'
import CodeEditorScreen from '../../output-components/test-automation/PseudoCodeEditorScreen'
import UseCasesComponent from '../../output-components/test-automation/FeatureFilesCreated'
import { TestCaseFlowDataContext } from '../TestAutomationPage'
import Feature from '../../output-components/test-automation/FeatureFilesCreated'
import { useLocation } from "react-router-dom";
import InputForm from '../../output-components/test-automation/PageSource'
import PageClass from '../../output-components/test-automation/PageClass'
import Locators from '../../output-components/test-automation/Locators'
import StepDefinition from '../../output-components/test-automation/StepDefinition'
import { Typography, Box } from '@mui/material'
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import FullscreenToggle from "../../custom-components/FullScreenToggle";
import SetPseudoCodePreferences from '../../output-components/test-automation/SetPseudoCodePreferences'


const TestAutomationOutputPage = () => {
    const location = useLocation();

    const { testCaseDetails, setTestCaseDetails,
        featureFiles, setFeatureFiles,
        pseudoCode, setPseudoCode,
        pageSource, setPageSource,
        locators, setLocators,
        pageClass, setPageClass,
        stepDefinition, setstepDefinition,
        addPreferences, setAddPreferences,
        AutomationCode, setAutomationCode } = useContext(TestCaseFlowDataContext);

    useEffect(() => {
        // console.log("preferenceData", location.state?.testCaseDetails);
        setTestCaseDetails(location.state?.testCaseDetails || []);
        setFeatureFiles(location.state?.featureFilesData || "");
        setPseudoCode(location.state?.generatedPseudoCodeData || "");
        setPageSource(location.state?.pageSource || "");
        setLocators(location.state?.generatedLocators || {})
        setPageClass(location.state?.generatedPageclassData || "");
        setstepDefinition(location.state?.generatedStepDefinitionData || "");
        setAddPreferences(location.state?.preferenceData || false);
    }, [location.state?.testCaseDetails,
    location.state?.featureFilesData,
    location.state?.generatedPseudoCodeData,
    location.state?.generatedLocators,
    location.state?.generatedPageclassData,
    location.state?.generatedStepDefinitionData,
    location.state?.preferenceData
    ])

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: "600px",
                margin: "10px auto",
                "& > *:not(:last-child)": {
                    marginBottom: "16px", // Adds margin between components but not after the last one
                },
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={0.1}
            >
                <Box display="flex" alignItems="center" mb={0.1}>
                    <AutomateIcon style={{ marginRight: 4 }} />
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                        sx={{ mt: 0.5 }}
                    >
                        Generate automated test scripts
                    </Typography>
                </Box>
                <FullscreenToggle />
            </Box>

            {testCaseDetails.length > 0 && <TestCasesComponent />}
            {!!featureFiles && featureFiles !== "" && <Feature />}
            {addPreferences && <SetPseudoCodePreferences />}
            {Object.keys(pseudoCode).length > 0 && <CodeEditorScreen />}
            {Object.keys(pseudoCode).length > 0 && <InputForm />}
            {Object.entries(locators).length > 0 && <Locators />}
            {!!pageClass && pageClass !== "" && <PageClass />}
            {!!stepDefinition && stepDefinition !== "" && <StepDefinition />}


            <Box
                component="footer"
                sx={{
                    py: 2,
                    backgroundColor: "#f6f3f2",
                    textAlign: "center",
                }}
            >
                <Box
                    component="footer"
                    sx={{
                        width: "100%",
                        maxWidth: "601px",
                        backgroundColor: "#f6f3f2",
                        textAlign: "center",
                        paddingX: "5px",
                        position: "fixed",
                        bottom: "0",
                        zIndex: "99"
                    }}
                >
                    <Typography variant="caption" color="textSecondary">
                        Use QMentisAI™ with human supervision, as with all generative AI tools.
                    </Typography>
                </Box>
            </Box>
        </Box>

    )
}

export default TestAutomationOutputPage