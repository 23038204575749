import React, { useState, useEffect } from 'react';
import { Box, AppBar, Toolbar, IconButton, Typography, Tooltip, Menu, Slider, Switch, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import DownloadIcon from "@mui/icons-material/Download";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CommonDownload from '../../custom-components/CommonDownload';
import QMentis_logo from "../../../images/QMentis.svg";
import { arrayOfObjectTransformer, objectDataTransformer } from "../../../utils/tableDataGenerator";
import wordGenerator from '../../download/qeBotWordGenerator';

const MultiBusinessProcessGuide = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { businessProcessData } = location.state || [];

    const [textSize, setTextSize] = useState(14);
    const [anchorEl, setAnchorEl] = useState(null);
    const [spaceIncreased, setSpaceIncreased] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const [processedData, setProcessedData] = useState({
        commonDefectsTableData: null,
        appendixTableData: null,
    });
    const [zoomLevels, setZoomLevels] = useState({});

    // console.log("businessProcessData", businessProcessData);
    useEffect(() => {
        if (businessProcessData && businessProcessData.parsedBusinessProcess) {
            setProcessedData({
                commonDefectsTableData: arrayOfObjectTransformer(businessProcessData.parsedBusinessProcess["List of Common Defects"]),
                appendixTableData: objectDataTransformer(businessProcessData.parsedBusinessProcess["Appendix"]),
            });
        }
    }, [businessProcessData]);


    const handleBackClick = () => {
        navigate(-1);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTextSizeChange = (event, newValue) => {
        setTextSize(newValue);
    };

    const handleDownloadClick = () => {
        setDownloadOpen(true);
    };

    const handleCloseDownload = () => {
        setDownloadOpen(false);
    };

    const handleShare = () => {
        console.log('Share clicked');
    };

    const handleSpaceToggle = () => {
        setSpaceIncreased(prev => !prev);
    };
    const handleZoomIn = (idx) => {
        setZoomLevels(prevZoomLevels => ({
            ...prevZoomLevels,
            [idx]: (prevZoomLevels[idx] || 1) + 0.1,
        }));
    };

    const handleZoomOut = (idx) => {
        setZoomLevels(prevZoomLevels => ({
            ...prevZoomLevels,
            [idx]: (prevZoomLevels[idx] || 1) - 0.1,
        }));
    };


    const handleDownloadImage = (flowchartImage, idx) => {
        if (flowchartImage) {
            const link = document.createElement("a");
            link.href = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(flowchartImage)}`;
            link.download = `flowchart-${idx + 1}.svg`;
            link.click();
        }
    };

    const businessProcessGuideCall = async () => {
        try {
            const allSectionsData = await Promise.all(
                businessProcessData.map(async (useCase, idx) => {
                    if (!useCase || !useCase.parsedBuinessProcess) {
                        return null;
                    }
                    const sections = [
                        {
                            type: "text",
                            data: useCase.parsedBuinessProcess["Description of the Business Process"] || "No description available.",
                            heading: `Business Process Guide - ${idx + 1}`
                        },
                        {
                            type: "image",
                            data: useCase.flowchartResponse,
                            heading: "Flow Chart"
                        },
                        {
                            type: "table",
                            data: arrayOfObjectTransformer(useCase.parsedBuinessProcess["List of Common Defects"] || []),
                            heading: "Common Defects"
                        },
                        {
                            type: "table",
                            data: objectDataTransformer(useCase.parsedBuinessProcess["Appendix"] || {}),
                            heading: "Appendix"
                        }
                    ];

                    return sections;
                })
            );
            const flattenedAllSectionsData = allSectionsData.flat().filter(Boolean);

            await wordGenerator(flattenedAllSectionsData, "BusinessProcessGuide_Report");

        } catch (error) {
            console.error("Error generating documents:", error);
        }
    };

    const renderTable = (tableData) => {
        if (!tableData || !tableData.values || !tableData.values.length) return null;

        return (
            <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: textSize }}>
                    {tableData.heading}
                </Typography>
                <Table sx={{ borderCollapse: "collapse", width: "100%", mt: 2 }}>
                    <TableHead>
                        <TableRow>
                            {tableData.headers.map((header) => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        border: "1px solid #ccc",
                                        bgcolor: "#f5f5f5",
                                        fontWeight: "bold",
                                        padding: "12px",
                                        textAlign: "left",
                                        fontSize: `${textSize}px`,
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.values.map((row, index) => (
                            <TableRow key={index}>
                                {row.map((cell, cellIndex) => (
                                    <TableCell
                                        key={cellIndex}
                                        sx={{
                                            border: "1px solid #ccc",
                                            padding: "12px",
                                            textAlign: "left",
                                            fontSize: `${textSize}px`,
                                        }}
                                    >
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const textStyle = {
        fontSize: textSize,
        lineHeight: spaceIncreased ? '1.8' : '1.4',
        paddingBottom: spaceIncreased ? '10px' : '5px',
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '792px', margin: '0 auto', p: 2 }}>
            {/* AppBar */}
            <AppBar
                position="static"
                sx={{
                    width: '100%',
                    background: '#FFFFFF',
                    boxShadow: 'none',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2
                }}
            >
                <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 0 }}>
                    <IconButton onClick={handleBackClick} edge="start" aria-label="back" sx={{ color: 'black' }}>
                        <ArrowBackIcon />
                    </IconButton>

                    {/* Right Corner Icons */}
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <Tooltip title="Change Text Size">
                            <IconButton onClick={handleMenuClick} sx={{ color: 'black' }}>
                                <TextFormatIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                            <IconButton onClick={businessProcessGuideCall} sx={{ color: 'black' }}>
                                <ArrowCircleDownIcon />
                            </IconButton>
                        </Tooltip>

                        {/* <CommonDownload open={downloadOpen} onClose={handleCloseDownload} /> */}

                        <Tooltip title="Share">
                            <IconButton onClick={handleShare} sx={{ color: 'black' }}>
                                <ShareOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Text Size Menu */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        sx={{ '& .MuiPaper-root': { width: '277px', height: '196px' } }}
                    >
                        <Box padding={2}>
                            <Typography variant="subtitle1" sx={{ color: 'black' }}>Text Size</Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body2" sx={{ color: 'black' }}>AA</Typography>
                                <Slider
                                    value={textSize}
                                    onChange={handleTextSizeChange}
                                    step={1}
                                    marks={[
                                        { value: 10, label: '' },
                                        { value: 12, label: '' },
                                        { value: 14, label: '' },
                                        { value: 16, label: '' },
                                        { value: 18, label: '' },
                                        { value: 20, label: '' },
                                    ]}
                                    min={10}
                                    max={20}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        marginLeft: 2,
                                        marginRight: 2,
                                        width: '110.5px',
                                        height: '16px',
                                        '& .MuiSlider-thumb': { width: '4px', height: '44px', borderRadius: '2px' },
                                        '& .MuiSlider-track': { backgroundColor: '#49A3FF', borderRadius: '16px' },
                                        '& .MuiSlider-rail': { backgroundColor: '#E0E0E0', borderRadius: '16px' },
                                        '& .MuiSlider-mark': { height: '4px', width: '4px', borderRadius: '50%', backgroundColor: '#49A3FF' },
                                        '& .MuiSlider-markActive': { backgroundColor: 'white' },
                                    }}
                                />
                                <Typography variant="body2" sx={{ color: 'black' }}>AA</Typography>
                            </Box>

                            <Box display="flex" alignItems="center" marginTop={2}>
                                <Typography variant="body1" sx={{ color: 'black', marginRight: 1 }}>Increase Spacing</Typography>
                                <Switch checked={spaceIncreased} onChange={handleSpaceToggle} />
                            </Box>
                        </Box>
                    </Menu>
                </Toolbar>
            </AppBar>

            {/* Business Process Guide Content */}
            <Box sx={{ p: 4, backgroundColor: "#FFFFFF" }}>
                {/* Header Section */}
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <img src={QMentis_logo} alt="QEBot.ai Logo" style={{ width: 64, height: 64, marginRight: 16 }} />
                    <Box>
                        <Typography variant="h6" component="span" sx={{ display: "block", fontWeight: 500 }}>
                            QMentisAI™ – Intelligence for Next-Gen Quality.
                        </Typography>
                        <Typography variant="body1" component="span" sx={{ fontWeight: 500 }}>
                            From AICoE, QualiZeal
                        </Typography>
                    </Box>
                </Box>

                {/* Sections for each Use Case */}

                {businessProcessData ? (
                    businessProcessData.map((useCase, idx) => (
                        useCase && useCase.parsedBuinessProcess ? (
                            <Box key={idx} >
                                <Section title={`Business Process Guide - Use Case ${idx + 1}`} />

                                {/* Description Section */}
                                <Section title="Description">
                                    <Typography variant="body1" sx={textStyle} paragraph>
                                        {useCase.parsedBuinessProcess["Description of the Business Process"] || "No description available."}
                                    </Typography>
                                </Section>

                                {/* Flowchart Section */}
                                <Section title="Flow Chart">
                                    {useCase.flowchartResponse && (
                                        <Box sx={{ mt: 2, position: "relative", display: "inline-block" }}>
                                            <img
                                                src={`data:image/svg+xml;charset=UTF-8,${encodeURIComponent(useCase.flowchartResponse)}`}
                                                alt={`Flowchart for Use Case ${idx + 1}`}
                                                loading="lazy"
                                                style={{
                                                    maxWidth: "100%",
                                                    height: "auto",
                                                    transform: `scale(${zoomLevels[idx] || 1})`,
                                                    border: "1px solid #ccc",
                                                    borderRadius: "10px",
                                                    padding: "5px",
                                                }}
                                            />
                                            <OverlayActions
                                                onDownload={() => handleDownloadImage(useCase.flowchartResponse, idx)}
                                                onZoomIn={() => handleZoomIn(idx)}
                                                onZoomOut={() => handleZoomOut(idx)}
                                            />
                                        </Box>
                                    )}
                                </Section>

                                {/* Common Defects Table */}
                                {useCase.parsedBuinessProcess["List of Common Defects"] && (
                                    <Section title="List of Common Defects">
                                        {renderTable({
                                            heading: "Common Defects",
                                            headers: ["Defect", "Occurrence"],
                                            values: useCase.parsedBuinessProcess["List of Common Defects"].map(defect => [
                                                defect.Defect,
                                                defect.Occurrence,
                                            ]),
                                        })}
                                    </Section>
                                )}

                                {/* Appendix Table */}
                                {useCase.parsedBuinessProcess.Appendix && (
                                    <Section title="Appendix">
                                        {renderTable({
                                            heading: "Appendix",
                                            headers: ["Term", "Definition"],
                                            values: Object.entries(useCase.parsedBuinessProcess.Appendix).map(([term, definition]) => [
                                                term,
                                                definition,
                                            ]),
                                        })}
                                    </Section>
                                )}

                            </Box>
                        ) : null
                    ))
                ) : (
                    <Typography variant="body1">No business process data available.</Typography>
                )}


            </Box>
        </Box>
    );
};

const Section = ({ title, children }) => (
    <Box sx={{ mt: 4 }}>
        <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{title}</Typography>
        {children}
    </Box>
);

const OverlayActions = ({ onDownload, onZoomIn, onZoomOut }) => (
    <Box
        sx={{
            position: "absolute",
            bottom: 30,
            right: 8,
            display: "flex",
            alignItems: "center",
            gap: 1,
            bgcolor: "rgba(128, 128, 128, 0.8)",
            borderRadius: 1,
            p: 0.5,
        }}
    >
        <Tooltip title="Download">
            <IconButton onClick={onDownload} sx={{ color: "white" }} size="small">
                <DownloadIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Zoom In">
            <IconButton onClick={onZoomIn} sx={{ color: "white" }} size="small">
                <ZoomInIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Zoom Out">
            <IconButton onClick={onZoomOut} sx={{ color: "white" }} size="small">
                <ZoomOutIcon />
            </IconButton>
        </Tooltip>

    </Box>
);

export default MultiBusinessProcessGuide;
