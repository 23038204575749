import React from 'react';

const AgileTestQuadrantsGraph = ({ data }) => {
  if (!data) return null;

  const quadrantData = data;

  return (
    <svg width="100%" height="600" viewBox="0 0 800 600">
      <text x="400" y="30" textAnchor="middle" fontSize="24" fontWeight="bold">Agile Testing Quadrants</text>
     
      {/* Labels */}
      <text x="400" y="60" textAnchor="middle" fontSize="18">Business Facing</text>
      <text x="400" y="590" textAnchor="middle" fontSize="18">Technology Facing</text>
      <text x="20" y="300" textAnchor="middle" fontSize="18" transform="rotate(-90 20,300)">Supporting the Team</text>
      <text x="780" y="300" textAnchor="middle" fontSize="18" transform="rotate(90 780,300)">Critique Product</text>

      {/* Quadrants */}
      <rect x="50" y="80" width="350" height="220" fill={quadrantData.Q1.color} />
      <rect x="400" y="80" width="350" height="220" fill={quadrantData.Q2.color} />
      <rect x="50" y="300" width="350" height="220" fill={quadrantData.Q3.color} />
      <rect x="400" y="300" width="350" height="220" fill={quadrantData.Q4.color} />

      {/* Quadrant content */}
      {Object.entries(quadrantData).sort((a, b) => {
        const order = { Q2: 0, Q3: 1, Q1: 2, Q4: 3 };
        return order[a[0]] - order[b[0]];
      }).map(([quadrant, info], index) => {
        const x = index % 2 === 0 ? 225 : 575;
        const y = index < 2 ? 190 : 410;
        return (
          <g key={quadrant}>
            <text x={x} y={y - 60} textAnchor="middle" fontSize="18" fontWeight="bold">{info.title}</text>
            {info.items.map((item, i) => (
              <text key={i} x={x} y={y - 30 + i * 20} textAnchor="middle" fontSize="14">{item}</text>
            ))}
          </g>
        );
      })}

      {/* Automation circles */}
      <circle cx="50" cy="80" r="40" fill="#FFB3B3" />
      <circle cx="750" cy="80" r="40" fill="#FFB3B3" />
      <circle cx="50" cy="520" r="40" fill="#FFB3B3" />
      <circle cx="750" cy="520" r="40" fill="#FFB3B3" />

      {/* Automation text */}
      <text x="50" y="85" textAnchor="middle" fontSize="12">
        {quadrantData.Q2.automation.includes('&') ? (
          <>
            {quadrantData.Q2.automation.split(' & ')[0]}
            <tspan x="50" dy="0.8em">&</tspan>
            <tspan x="50" dy="0.8em">{quadrantData.Q2.automation.split(' & ')[1]}</tspan>
          </>
        ) : (
          quadrantData.Q2.automation
        )}
      </text>
      <text x="750" y="85" textAnchor="middle" fontSize="12">
        {quadrantData.Q3.automation.includes('&') ? (
          <>
            {quadrantData.Q3.automation.split(' & ')[0]}
            <tspan x="750" dy="0.8em">&</tspan>
            <tspan x="750" dy="0.8em">{quadrantData.Q3.automation.split(' & ')[1]}</tspan>
          </>
        ) : (
          quadrantData.Q3.automation
        )}
      </text>
      <text x="50" y="525" textAnchor="middle" fontSize="12">
        {quadrantData.Q1.automation.includes('&') ? (
          <>
            {quadrantData.Q1.automation.split(' & ')[0]}
            <tspan x="50" dy="0.8em">&</tspan>
            <tspan x="50" dy="0.8em">{quadrantData.Q1.automation.split(' & ')[1]}</tspan>
          </>
        ) : (
          quadrantData.Q1.automation
        )}
      </text>
      <text x="750" y="525" textAnchor="middle" fontSize="12">
        {quadrantData.Q4.automation.includes(',') ? (
          <>
            {quadrantData.Q4.automation.split(',').map((word, index) => (
              <tspan key={index} x="750" dy={index === 0 ? 0 : '1.2em'}>{word.trim()}</tspan>
            ))}
          </>
        ) : (
          quadrantData.Q4.automation
        )}
      </text>
    </svg>
  );
};

export default AgileTestQuadrantsGraph;
