import React, { useEffect, useState, useMemo, useContext } from "react";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import CommonAccordion from "../../custom-components/CommonAccordion";
import MultiAccordian from "./MultiAccordian";
import { investigate } from "../../../services/webServices";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MultipleTestCasesContext,
  TestCasesLoaderContext,
} from "../../main-pages/MultiTestCaseGenerationPage";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import CommonDownload from "../../custom-components/CommonDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SelectionErrorPopUp from "../../popup-components/SelectionerrorPop";

const MultipleUserStoriesComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { testCaseLoaderState, setTestCaseLoaderState } = useContext(
    TestCasesLoaderContext
  );
  const {
    selectedUserStories,
    setSelectedUserStories,
    multipleEvaluationSummary,
    setMultipleEvaluationSummary,
    setMultipleRefinedUserStory,
    setSelectedRefinedUserStories,
    setMultipleUseCases,
    setSelectedUseCases,
    setMultiTestCases,
  } = useContext(MultipleTestCasesContext);

  const [multipleUserStories, setMultipleUserStories] = useState(
    location.state.userStories
  );

  const defaultUserStoriesExpanded = multipleUserStories.length > 0 && multipleEvaluationSummary.length === 0;

  const [investParameter, setInvestParameters] = useState([
    "Independent",
    "Negotiable",
    "Valuable",
    "Estimable",
    "Small",
    "Testable",
  ]);
  const [multiEvaluationSummary, setMultiEvaluationSummary] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [openDownload, setOpenDownload] = useState(false);
  const [editingValue, setEditingValue] = useState({});
  const [noneSelectionPopOpen, setNoneSelectionPopOpen] = useState(false);

  useEffect(() => {
    setMultipleEvaluationSummary(multiEvaluationSummary);
  }, [multiEvaluationSummary]);

  useEffect(() => {
    navigate("/dashboard/generate-multi/testcases/output", {
      state: {
        ...location.state,
        userStories: multipleUserStories,
        selectedUserStories: selectedUserStories,
      },
      replace: true,
    });
  }, [multipleUserStories]);

  const summaries = useMemo(
    () =>
      multipleUserStories.map((eachUserStory) => {
        return eachUserStory["Title"];
      }),
    [multipleUserStories]
  );

  const userStoriesComponents = multipleUserStories.map(
    (eachUserStory, userStoryIndex) => (
      <Box key={`eachUserStory_${userStoryIndex}`}>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Title
        </Typography>
        {isEditing === userStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Title"
              value={editingValue["Title"]}
              onChange={(e) => handleEdittedValue(e, userStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: 2, fontSize: "14px" }}>
              {eachUserStory["Title"]}
            </Typography>
          </Box>
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Description
        </Typography>
        {isEditing === userStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Description"
              value={editingValue["Description"]}
              onChange={(e) => handleEdittedValue(e, userStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1, }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: 2 }}>
              {eachUserStory["Description"]}
            </Typography>
          </Box>
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Acceptance criteria
        </Typography>
        {isEditing === userStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Acceptance criteria"
              value={editingValue["Acceptance Criteria"]}
              onChange={(e) => handleEdittedValue(e, userStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "14px", marginBottom: 2 }}
              dangerouslySetInnerHTML={{
                __html: eachUserStory["Acceptance Criteria"].toString().replace(/\n/g, "<br>"),
              }} />

            {/* </Typography> */}
          </Box>
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Additional Information
        </Typography>
        {isEditing === userStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Additional Information"
              value={editingValue["Additional Information"]}
              onChange={(e) => handleEdittedValue(e, userStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography variant="body1" sx={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{
                __html: eachUserStory["Additional Information"].toString().replace(/\n/g, "<br>"),
              }} />

          </Box>
        )}
      </Box>
    )
  );

  const handleDownload = () => {
    setOpenDownload(true);
  };

  const handleEdittedValue = (event) => {
    const updatedValue = {
      ...editingValue,
      [event.target.name]: event.target.value,
    };
    setEditingValue(updatedValue);
  };

  const handleMultiEvaluationSummary = async () => {
    try {
      // setLoading(true);
      if(selectedUserStories.every((eachUserStory) => !eachUserStory)) {
        setNoneSelectionPopOpen(true);
        return;
      }
      setTestCaseLoaderState("Evaluation Summary");
      setMultiEvaluationSummary([]);
      setMultipleEvaluationSummary([]);
      setMultipleRefinedUserStory([]);
      setMultipleUseCases([]);
      setMultiTestCases([]);
      const updatedEvaluationSummaries = [];
      for (const [index, eachUserStory] of selectedUserStories.entries()) {
        if (eachUserStory && Object.keys(eachUserStory).length > 0) {
          const requestData = {
            ...eachUserStory,
            parameters: investParameter,
          };
          const evaluationSummary = await investigate(requestData);
          setMultiEvaluationSummary((prevState) => {
            const updatingEvaluationSummaries = [...prevState];
            updatingEvaluationSummaries[index] = evaluationSummary.data;
            return updatingEvaluationSummaries;
          });
          updatedEvaluationSummaries[index] = evaluationSummary.data;
        } else {
          updatedEvaluationSummaries[index] = null;
        }
      }
      navigate("/dashboard/generate-multi/testcases/output", {
        state: {
          userStories: multipleUserStories,
          selectedUserStories: selectedUserStories,
          multiEvaluationSummary: updatedEvaluationSummaries,
          multipleRefinedUserStory: [],
          selectedRefinedUserStories: [],
          multipleUseCases: [],
          selectedMultipleUseCases: [],
          multipleTestCases: [],
        },
        replace: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
      setTestCaseLoaderState(null);
    }
  };

  const createWordData = (multipleUserStoriesData) => {
    const wordData = [];
    multipleUserStoriesData.map((eachUserStory) => {
      if (!!eachUserStory) {
        wordData.push({
          heading: "User Story",
          type: "subParagraphs",
          data: {
            Title: eachUserStory["Title"],
            Description: eachUserStory["Description"],
            "Acceptance Criteria": eachUserStory["Acceptance Criteria"],
            "Additional Information": eachUserStory["Additional Information"],
          },
        });
        wordData.push({ type: "pageBreak" });
        return;
      }
    });
    return wordData;
  };

  const buttons = [
    {
      label: "Download",
      onClick: handleDownload,
      variant: "text",
      startIcon: <FileDownloadOutlinedIcon />,
      sx: { textTransform: "none" },
    },
    {
      label: "Evaluate User Stories",
      variant: "contained",
      startIcon:
        testCaseLoaderState === "Evaluation Summary" ? (
          <CircularProgress size="14px" />
        ) : (
          <GenerateButton />
        ),
      onClick: handleMultiEvaluationSummary,
      sx: { marginLeft: "auto" },
      disabled: !!testCaseLoaderState,
    },
  ];

  return (
    <Box width={"601px"} sx={{ height: "100%" }}>
      <CommonAccordion
        summary={`User Stories (${multipleUserStories.length})`}
        details={
          <Box>
            <Box sx={{ overflow: "hidden", width: "100%" }}>
              <MultiAccordian
                summaries={summaries}
                multiAccoridanDetails={userStoriesComponents}
                multiAccoridanData={multipleUserStories}
                setMultiAccordianData={setMultipleUserStories}
                selectedAccordians={selectedUserStories}
                setSelectedAccordians={setSelectedUserStories}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                editingValue={editingValue}
                setEditingValue={setEditingValue}
                loaderCheck={!!testCaseLoaderState}
              />
            </Box>
          </Box>
        }
        buttons={buttons}
        type={"output"}
        defaultExpanded={defaultUserStoriesExpanded}
      />
      <SelectionErrorPopUp open={noneSelectionPopOpen} onClose={() => setNoneSelectionPopOpen(false)} />
      <CommonDownload
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        serviceResponse={selectedUserStories}
        serviceName="User_Story"
        wordData={createWordData(selectedUserStories)}
      />
    </Box>
  );
};

export default MultipleUserStoriesComponent;
