import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Box,
  InputAdornment,
  Input,
} from "@mui/material";

import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as LoadIcon } from "../../../images/loadExampleButton.svg";
import { useNavigate } from "react-router-dom";
import CommonAccordion from "../../custom-components/CommonAccordion";
import {
  fetchJiraIssuesApiCall,
  freeFlowBugReportEnhance,
  getJiraConfig,
  scrutinize,
  fetchSpecificIssues,
} from "../../../services/webServices";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { BugReportDataContext } from "../../main-pages/BugReportPage";
import { ReactComponent as ImportIcon } from "../../../images/importIcon.svg";
import FullscreenToggle from "../../custom-components/FullScreenToggle";
import SessionExpired from "../../popup-components/SessionExpiredPopup";

const BugReportComponent = () => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  // Free flow bug report variable
  const [bugDetails, setBugDetails] = useState("");
  // State variables for each form field of bug report
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [environmentDetails, setEnvironmentDetails] = useState("");
  const [stepsToReproduce, setStepsToReproduce] = useState("");
  const [expectedResult, setExpectedResult] = useState("");
  const [actualResult, setActualResult] = useState("");
  const [additionalInformation, setAdditionalInforamtion] = useState("");
  const [impact, setImpact] = useState("");
  const [explorationDepth, setExplorationDepth] = useState("");
  const [textFieldError, setTextFieldError] = useState("");
  // State variable for enhance bug report button status (enabled or not)
  const [enhancedBugReportButtonEnabled, setEnhancedBugReportButtonEnabled] =
    useState(false);
  // Getting setLoading method using UseContext to set the loading modal state
  const setLoading = useContext(LoadingModalContext);
  // Getting setBugReportData and setEnhancedBugReportData to change the state variable in BugReportPage
  const { setBugReportData, setEnhancedBugReportData } =
    useContext(BugReportDataContext);

  const [projectId, setProjectId] = useState("");
  const [jiraIssues, setJiraIssues] = useState([]);

  const [sessionExpiredValue, setSessionExpiredValue] = useState(false);
  // Use navigate hook for routing
  const navigate = useNavigate();

  const examples = {
    LoadExample: {
      title: "Incorrect Policy Renewal Date Display",
      description:
        "The policy renewal date displayed on the user's account is incorrect, showing past dates for policies that are still active.",
      environmentDetails:
        "Application Version: 3.5.1\nBrowser: Firefox 91.0\nOS: macOS Catalina\nDevice: Desktop",
      stepsToReproduce:
        "Log into the insurance portal.\nNavigate to the 'My Policies' section.\nReview the renewal dates for active policies.",
      expectedResult:
        "The renewal date should accurately reflect the next renewal date for active policies.",
      actualResult:
        "The renewal date is displayed as a past date, even for policies that are still active.",
      additionalInfo:
        "Users have reported discrepancies in renewal dates across different types of policies.",
      impact:
        "Medium - Users are misinformed about their policy status, potentially leading to missed renewals and lapsed coverage.",
      explorationDepth: "Simple",
    },
  };

  const selectLoadExample = (event) => {
    event.stopPropagation();
    setTitle(examples["LoadExample"]["title"]);
    setDescription(examples["LoadExample"]["description"]);
    setEnvironmentDetails(examples["LoadExample"]["environmentDetails"]);
    setStepsToReproduce(examples["LoadExample"]["stepsToReproduce"]);
    setExpectedResult(examples["LoadExample"]["expectedResult"]);
    setActualResult(examples["LoadExample"]["actualResult"]);
    setAdditionalInforamtion(examples["LoadExample"]["additionalInfo"]);
    setImpact(examples["LoadExample"]["impact"]);
    setExplorationDepth(examples["LoadExample"]["explorationDepth"]);
    setTextFieldError(false);
  };

  useEffect(() => {
    setBugReportData({});
    setEnhancedBugReportData({});
  }, []);

  useEffect(() => {
    // Set to true only if all fields are filled (trimmed)
    const isFormComplete =
      title.trim() &&
      description.trim() &&
      environmentDetails.trim() &&
      stepsToReproduce.trim() &&
      expectedResult.trim() &&
      actualResult.trim();

    if (enhancedBugReportButtonEnabled !== isFormComplete) {
      setEnhancedBugReportButtonEnabled(isFormComplete);
    }
  }, [
    title,
    description,
    expectedResult,
    actualResult,
    environmentDetails,
    stepsToReproduce,
  ]);

  const commonTextFieldStyle = {
    width: "100%",
    mt: 1,
    mb: 2,
  };

  const largerTextFieldStyle = {
    ...commonTextFieldStyle,
    minRows: 8,
  };

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  const handleEnhanceDescription = async () => {
    try {
      setLoading(true);
      const reqData = {
        bugDetails: bugDetails,
      };

      // Awaiting the enhancement response
      const freeFlowEnhancedResponse = await freeFlowBugReportEnhance(reqData);

      // Navigating to the output page with the correct response data
      navigate("/dashboard/bugreportenhancer/output", {
        state: {
          bugReportData: bugDetails,
          enhancedBugReport: freeFlowEnhancedResponse.data, // Assuming freeFlowEnhancedResponse already has `data`
        },
      });
    } catch (error) {
      setLoading(false);
      console.error("Error enhancing bug description:", error);
      setLoading(false);
      if (error.response.data.Error == "Your session has expired.") {
        setSessionExpiredValue(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setExplorationDepth(event.target.value);
  };

  const handleFormSubmit = async () => {
    const bugReportData = {
      title: title,
      description: description,
      environmentDetails: environmentDetails,
      stepsToReproduce: stepsToReproduce,
      expectedResult: expectedResult,
      actualResult: actualResult,
      additionalInfo: additionalInformation,
      impact: impact,
      explorationDepth: explorationDepth,
    };
    setLoading(true);

    try {
      const requestData = {
        bugReportData: bugReportData,
        temperature: explorationDepth,
      };
      const enhancedReport = await scrutinize(requestData);

      navigate("/dashboard/bugreportenhancer/output", {
        state: { bugReportData, enhancedBugReport: enhancedReport.data },
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      if (error.response.data.Error == "Your session has expired.") {
        setSessionExpiredValue(true);
      }
    } finally {
      // setTimeout(() => {
      setLoading(false);
      // }, 10000);
    }
  };

  const handleToNavigateMultiple = async () => {
    // Retrieve the user's email from localStorage
    // const userEmail = localStorage.getItem("email");
    // if (!userEmail) {
    //   console.error("User email is not available");
    //   return;
    // }
    try {
      // Fetch the Jira configuration, which should include the jira_project_key
      const response = await getJiraConfig();
      console.log("Jira config response:", response.data);
      const jiraProjectKey = response.data.jira_project_key; // Correct property name

      if (jiraProjectKey) {
        // Fetch Jira issues using the jiraProjectKey
        await importFromJira(jiraProjectKey);
      } else {
        console.error("Jira Project Key not found in the Jira config.");
      }
    } catch (error) {
      console.error("Failed to fetch Jira configuration:", error);
      setLoading(false);
      // if (error.response.data.error == "Jira configuration not found") {
      //   setSessionExpiredValue(true);
      // } else {
      alert(
        error.response.data?.Error ||
          "Could not process your request, please try again!"
      );
      // }
    }
  };

  //IMPORTING BUGS FROM JIRA
  const importFromJira = async (projectId) => {
    setLoading(true);
    console.log("Attempting to fetch Jira bugs for project:", projectId);
    setProjectId(projectId);

    try {
      const response = await fetchJiraIssuesApiCall(projectId);
      const issues = response?.data?.issues;

      // Add a check to ensure issues is defined and is an array
      if (!Array.isArray(issues)) {
        throw new Error(
          "Failed to retrieve Jira issues or issues array is undefined."
        );
      }

      // Filter Jira issues to only get 'Bug' issues
      const bugIssues = issues.filter(
        (issue) => issue.fields?.issuetype?.name === "Bug"
      );

      if (bugIssues.length === 0) {
        console.warn("No bug issues found for the project.");
        alert("No bug issues found for the project.");
      } else {
        console.log("Jira Bug Issues:", bugIssues);
        // Map to extract Title (summary) and Description from each bug issue
        const bugDetails = bugIssues.map((bug) => ({
          title: bug.fields?.summary || "No Title", // Title of the bug
          description: bug.fields?.description || "No Description", // Description of the bug
          priority: bug.fields?.priority?.name || "No Priority", //Priority of the Bug
          environment: bug.fields?.environment || "NA",
          key: bug.key,
          id: bug.id,
        }));

        setLoading(false);
        navigate("/dashboard/multi-bugreports/input", {
          state: { bugDetails },
        });
      }

      setJiraIssues(bugIssues); // Only set bug issues
    } catch (error) {
      console.error("Failed to fetch Jira bugs:", error.message);
    } finally {
      // setIsLoading(false); // Hide spinner
    }
  };

  const importBugsFormJira = async () => {
    // setLoading(true);
    try {
      const bugsFromJiraRequest = await fetchSpecificIssues("Bug");
      const importedBugsRawData = bugsFromJiraRequest.data;

      // console.log("Bugs Raw Data===>", importedBugsRawData);

      // if (importeduserStoriesRawData.length === 0) {
      //   console.warn("No Story issues found for the project.");
      // } else {
      //   console.log("Jira Bug Issues:", importeduserStoriesRawData);
      //   const fetchExistingFieldsApiResponse =
      //     await fetchExistingJiraFieldsFromScreen();
      //   const fetchExistingFields = fetchExistingFieldsApiResponse.data;

      //   const fieldMapping = fetchExistingFields.reduce(
      //     (fieldsMapper, eachField) => {
      //       console.log(eachField);
      //       if (eachField.name === "Summary") {
      //         fieldsMapper["Title"] = eachField.id;
      //       }
      //       if (
      //         eachField.name === "Description" ||
      //         eachField.name === "Acceptance Criteria" ||
      //         eachField.name === "Additional Information"
      //       ) {
      //         fieldsMapper[eachField.name] = eachField.id;
      //       }
      //       return fieldsMapper;
      //     },
      //     {}
      //   );
      //   console.log(fieldMapping);

      //   // Map to extract Title (summary) and Description from each bug issue
      //   const userStoryDetails = importeduserStoriesRawData.map(
      //     (eachStory) => ({
      //       Title: eachStory.fields?.[fieldMapping["Title"]] || "No Title", // Title of the user story
      //       Description:
      //         eachStory.fields?.[fieldMapping["Description"]] ||
      //         "No Description", // Description of the user story
      //       "Acceptance Criteria":
      //         eachStory.fields?.[fieldMapping["Acceptance Criteria"]] ||
      //         "No Acceptance Criteria", // Acceptance Criteria of the user story
      //       "Additional Information":
      //         eachStory.fields?.[fieldMapping["Additional Information"]] ||
      //         "No Additional Information", // Additional Information for the user story
      //       key: eachStory?.key,
      //       id: eachStory?.id,
      //     })
      //   );

      //   console.log("Jira Story Titles and Descriptions:", userStoryDetails);
      //   setLoading(false);
      //   navigate("/dashboard/generate-multi/testcases/output", {
      //     state: {
      //       userStories: userStoryDetails,
      //       selectedUserStories: [...userStoryDetails],
      //       multiEvaluationSummary: [],
      //       multipleRefinedUserStories: [...userStoryDetails],
      //       selectedRefinedUserStories: [...userStoryDetails],
      //     },
      //   });
      // }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        bgcolor: "#f6f3f2",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "calc(100vh - 40px)",
          paddingTop: "15px",
          transition: "all 0.3s ease",
          overflow: "auto",
          "&::-webkit-scrollbar": { width: "0px" },
          scrollbarWidth: "none",
          mb: 4,
        }}
      >
        {/* Card for the entire Bug Report */}
        <Box
          sx={{
            width: "601px",
            height: "auto",
            boxShadow: 0,
            transition: "all 0.3s ease",
            position: "relative",
            overflow: "hidden",
            bgcolor: "transparent",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            padding: 0,
          }}
        >
          {/* Breadcrumb Navigation */}
          <Box
            sx={{
              bgcolor: "transparent",
              marginBottom: 1,
              padding: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box display="flex" alignItems="center" mb={0.1}>
                <AutomateIcon style={{ marginRight: 4 }} />
                {/* Informative text */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                  sx={{ mt: 0.5 }}
                >
                  Generate detailed and insightful bug reports with ease
                </Typography>
              </Box>
              <FullscreenToggle />
            </Box>
          </Box>

          {/* Card 1: Bug Details Form */}
          <Card
            sx={{
              bgcolor: "white",
              marginBottom: 1,
              borderRadius: "16px",
              border: "1px solid rgba(0,0,0,0.12)",
            }}
          >
            <CardContent sx={{ padding: 1 }}>
              {" "}
              {/* Reduced padding */}
              <Input
                fullWidth
                disableUnderline
                margin="normal"
                multiline
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                placeholder="Enter bug details"
                value={bugDetails}
                onChange={(e) => setBugDetails(e.target.value)}
                sx={{
                  color: "black",
                  bgcolor: "transparent",
                  border: "none",
                  padding: "8px 15px 75px",
                  "&:hover": {
                    border: "none",
                  },
                  "&:focus": {
                    border: "none",
                  },
                }}
              />
              <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GenerateButton />}
                  sx={{
                    mt: 1,
                    textTransform: "none",
                    backgroundColor: "#49A3FF",
                  }}
                  disabled={!bugDetails.trim()}
                  onClick={handleEnhanceDescription}
                >
                  Enhance Bug Description
                </Button>
              </Box>
            </CardContent>
          </Card>

          {/* Informative Text */}
          <Box
            sx={{
              bgcolor: "transparent",
              marginBottom: 1,
              padding: 1,
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              gutterBottom
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.25px",
              }}
            >
              Or enter bug report details below
            </Typography>
          </Box>

          <CommonAccordion
            summary={
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
                  Bug Details
                </Typography>

                <Button
                  variant="text"
                  startIcon={<LoadIcon />} // Add LoadIcon here
                  sx={{
                    ml: 2,
                    textTransform: "none",
                    position: "relative",
                    top: "0px",
                    right: "-260px",
                  }}
                  onClick={selectLoadExample}
                >
                  Load Demo Example
                </Button>
              </Box>
            }
            type={"input"}
            details={
              <CardContent sx={{ padding: 0 }}>
                <Box
                  sx={{
                    // width: "530px",
                    // ml: "18px",
                    // maxHeight: "600px",
                    // overflowY: "auto",
                    // overflowX: "hidden",
                    paddingRight: "18px",
                  }}
                >
                  <TextField
                    label="Title*"
                    placeholder="Provide a brief and descriptive title for the bug"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    multiline
                    sx={commonTextFieldStyle}
                  />
                  <TextField
                    label="Description*"
                    placeholder="Explain the issue in detail, including the relevant context"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                    sx={largerTextFieldStyle}
                  />
                  <TextField
                    label="Environment Details"
                    placeholder="Specify the environment where the bug was found (e.g., operating system, browser, device)"
                    value={environmentDetails}
                    onChange={(e) => setEnvironmentDetails(e.target.value)}
                    margin="normal"
                    multiline
                    sx={commonTextFieldStyle}
                  />
                  <TextField
                    label="Steps to Reproduce*"
                    placeholder="List the step by step instructions to replicate the issue"
                    value={stepsToReproduce}
                    onChange={(e) => setStepsToReproduce(e.target.value)}
                    margin="normal"
                    multiline
                    rows={4}
                    sx={largerTextFieldStyle}
                  />
                  <TextField
                    label="Expected Result*"
                    placeholder="Describe the desired behaviour or outcome that should occur when following the reproduction steps"
                    value={expectedResult}
                    onChange={(e) => setExpectedResult(e.target.value)}
                    margin="normal"
                    multiline
                    sx={commonTextFieldStyle}
                  />
                  <TextField
                    label="Actual Result*"
                    placeholder="Describe the actual behaviour or outcome that occurs when following the reproduction steps"
                    value={actualResult}
                    onChange={(e) => setActualResult(e.target.value)}
                    multiline
                    sx={commonTextFieldStyle}
                  />
                  <TextField
                    label="Additional Information"
                    placeholder="Include any other relevant details such as error messages,logs"
                    value={additionalInformation}
                    onChange={(e) => setAdditionalInforamtion(e.target.value)}
                    margin="normal"
                    multiline
                    sx={commonTextFieldStyle}
                  />
                  <TextField
                    label="Impact"
                    placeholder="Include the severity or impact of the bug on the system or users"
                    value={impact}
                    onChange={(e) => setImpact(e.target.value)}
                    margin="normal"
                    multiline
                    sx={commonTextFieldStyle}
                  />

                  {/* <Select
                    value={explorationDepth}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span
                            style={{
                              color: "gray",
                            }}
                          >
                            Exploration Depth*
                          </span>
                        );
                      }
                      return selected;
                    }}
                    sx={{
                      width: "528px",
                      marginTop: 1,
                      marginBottom: 2,
                      marginLeft: "3px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select...
                    </MenuItem>
                    <MenuItem value="Simple">Simple</MenuItem>
                    <MenuItem value="Normal">Normal</MenuItem>
                    <MenuItem value="Deep">Deep</MenuItem>
                  </Select> */}
                  {/* //Exploration Depth */}
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Exploration Depth*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={explorationDepth}
                      label="Exploration Depth*"
                      onChange={handleChange}
                    >
                      <MenuItem value="Simple">Simple</MenuItem>
                      <MenuItem value="Normal">Normal</MenuItem>
                      <MenuItem value="Deep">Deep</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                {/* <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} /> */}
              </CardContent>
            }
            buttons={[
              {
                label: "Add More",
                variant: "text",
                startIcon: <AddIcon />,
              },
              {
                label: "Import",
                variant: "text",
                startIcon: <ImportIcon />,
                onClick: handleToNavigateMultiple,
              },
              {
                label: "Enhance Bug Description",
                startIcon: <GenerateButton />,
                variant: "contained",
                sx: { float: "right" },
                disabled: !enhancedBugReportButtonEnabled,
                onClick: handleFormSubmit,
              },
            ]}
            sx={{
              "&:hover, &:focus": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                borderRadius: "16px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
          />
        </Box>
      </Box>
      {sessionExpiredValue && <SessionExpired />}
    </Box>
  );
};

export default BugReportComponent;
