import { Box, TextField, Typography, Button } from "@mui/material";
import CommonAccordion from "../../custom-components/CommonAccordion";
import { ReactComponent as ImportButton } from "../../../images/importButton.svg";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import { ReactComponent as LoadIcon } from "../../../images/loadExampleButton.svg";
import { useState, useContext } from "react";
import { testScape } from "../../../services/webServices";
import { useNavigate } from "react-router-dom";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import FullscreenToggle from "../../custom-components/FullScreenToggle";

const TestPlanInputForm = () => {
  const navigate = useNavigate();
  const [sprintObjectives, setSprintObjectives] = useState("");
  const [backlogItem, setBacklogItem] = useState("");
  const [userStories, setUserStories] = useState("");
  const [issueResolutions, setIssueResolutions] = useState("");
  const [additionalObjectives, setAdditionalObjectives] = useState("");
  const [errors, setErrors] = useState({});
  const [accordionOpen, setAccordionOpen] = useState(true);
  const setLoading = useContext(LoadingModalContext);

  const handleGenerateTestPlan = async () => {
    let newErrors = {};
    if (!sprintObjectives.trim()) {
      newErrors.sprintObjectives = "Sprint Objectives is required.";
    }
    if (!backlogItem.trim()) {
      newErrors.backlogItem = "Backlog Item is required.";
    }
    if (!userStories.trim()) {
      newErrors.userStories = "User Stories is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setLoading(true);
    }
    setLoading(true);
    try {
      const testScapeObject = {
        Sprint_Objectives: sprintObjectives,
        Backlog_Items: backlogItem,
        User_Stories: userStories,
        Issue_Resolutions: issueResolutions,
        Additional_Objectives: additionalObjectives,
      };
      const response = await testScape(testScapeObject);
      navigate("/dashboard/test-plan/output", {
        state: {
          input: testScapeObject,
          output: response,
        },
      });
      // console.log("Response====>", response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    return (
      sprintObjectives.trim() !== "" &&
      backlogItem.trim() !== "" &&
      userStories.trim() !== ""
    );
  };

  const examples = {
    LoadExample: {
      sprintObjectives:
        "- Enhance the product search functionality on the retail website.\n- Implement filtering options by price, brand, and ratings.\n- Fix the issue with incorrect inventory counts displayed on product pages.",
      backlogItem:
        "1. As a customer, I want to filter products by price, so that I can find items within my budget.\n2. As a customer, I want to filter products by brand, so that I can view products from my preferred brands.\n3. As a customer, I want to filter products by ratings, so that I can see the most highly-rated items first.\n4. Fix the bug where the inventory count on the product page does not update after a purchase.",
      userStories:
        "1. User Story 1: As a shopper, I want to quickly find products by typing keywords into the search bar, so that I can easily locate the items I am interested in.\n2. User Story 2: As a shopper, I want to view accurate inventory levels on product pages, so that I can make informed purchasing decisions.",
      issueResolutions:
        "1. The inventory count issue is caused by a synchronization problem between the inventory management system and the front-end display. A new API will be introduced to update inventory counts in real-time.",
      additionalObjectives:
        "- Ensure the product search functionality supports partial keyword matches.\n- Improve the loading speed of filtered search results.",
    },
  };

  const selectLoadExample = (event) => {
    event.stopPropagation();
    setSprintObjectives(examples["LoadExample"]["sprintObjectives"]);
    setBacklogItem(examples["LoadExample"]["backlogItem"]);
    setUserStories(examples["LoadExample"]["userStories"]);
    setIssueResolutions(examples["LoadExample"]["issueResolutions"]);
    setAdditionalObjectives(examples["LoadExample"]["additionalObjectives"]);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F3F2",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            width: "601px",
            height: "auto",
            boxShadow: 0,
            transition: "all 0.3s ease",
            position: "relative",
            overflow: "hidden",
            bgcolor: "transparent",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            padding: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center", // Ensure vertical alignment is consistent
              // width: "100%", // Ensure the outer Box spans the full width
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                width: "100%", // This can be removed if you want to avoid inner Box taking full width
                textAlign: "left",
                mb: 0.1,
              }}
            >
              <AutomateIcon style={{ marginRight: 6 }} />
              <Typography
                variant="body2"
                color="text.secondary"
                gutterBottom
                sx={{ mt: 0.5 }}
              >
                Generate a comprehensive test plan
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end", // Aligns the FullscreenToggle button at the end vertically
              }}
            >
              <FullscreenToggle />
            </Box>
          </Box>
        </Box>

        <CommonAccordion
          summary={
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
                Test Plan Details
              </Typography>

              <Button
                variant="text"
                startIcon={<LoadIcon />} // Add LoadIcon here
                sx={{
                  ml: 2,
                  textTransform: "none",
                  position: "relative",
                  top: "0px",
                  right: "-220px",
                }}
                onClick={selectLoadExample}
              >
                Load Demo Example
              </Button>
            </Box>
          }
          details={
            <Box>
              <Box mb={2}>
                <TextField
                  sx={{ width: "92%" }}
                  placeholder="Define the key deliverables and goals for this sprint"
                  label="Sprint Objectives*"
                  variant="outlined"
                  multiline
                  rows={4}
                  margin="normal"
                  value={sprintObjectives}
                  onChange={(e) => setSprintObjectives(e.target.value)}
                  error={Boolean(errors.sprintObjectives)}
                  helperText={errors.sprintObjectives}
                />
                <TextField
                  sx={{ width: "92%" }}
                  placeholder="Identify the priortized tasks or features for this sprint"
                  label="Backlog Item(s)*"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={backlogItem}
                  onChange={(e) => setBacklogItem(e.target.value)}
                  error={Boolean(errors.backlogItem)}
                  helperText={errors.backlogItem}
                />
                <TextField
                  sx={{ width: "92%" }}
                  placeholder="Detail the user requirements and acceptance criteria"
                  label="User Stories*"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={userStories}
                  onChange={(e) => setUserStories(e.target.value)}
                  error={Boolean(errors.userStories)}
                  helperText={errors.userStories}
                />
                <TextField
                  sx={{ width: "92%" }}
                  placeholder="Outline the defects or issues to be addressed"
                  label="Issue Resolutions"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={issueResolutions}
                  onChange={(e) => setIssueResolutions(e.target.value)}
                />
                <TextField
                  sx={{ width: "92%" }}
                  placeholder="Specify any supplementary goals or intiatives"
                  label="Additional Objectives"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={additionalObjectives}
                  onChange={(e) => setAdditionalObjectives(e.target.value)}
                />
              </Box>
            </Box>
          }
          type={"input"}
          buttons={[
            {
              label: "Import",
              variant: "text",
              startIcon: <ImportButton />,
              sx: { textTransform: "none" },
            },
            {
              label: "Generate Test Plan",
              onClick: handleGenerateTestPlan,
              startIcon: <GenerateButton />,
              variant: "contained",
              sx: {
                float: "right",
                textTransform: "none",
                backgroundColor: isFormValid() ? "#49A3FF" : "grey",
                color: isFormValid() ? "white" : "black",
                "&:hover": {
                  backgroundColor: isFormValid() ? "#49A3FF" : "darkgrey",
                },
              },
              disabled: !isFormValid(), // Disable button if form is not valid
            },
          ]}
        />
      </Box>
    </div>
  );
};

export default TestPlanInputForm;
