import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import QMentisBreadCrumb from "../custom-components/QMentisBreadCrumb";

export const TestCaseFlowDataContext = createContext(null);
export const TestCaseLoaderStateContext = createContext(null);

const TestcaseGenerationPage = () => {
  const [userStory, setUserStory] = useState({});
  const [evaluationSummary, setEvaluationSummary] = useState([]);
  const [refinedUserStory, setRefinedUserStory] = useState({});
  const [useCase, setUseCase] = useState({});
  const [testcases, setTestCases] = useState([]);

  const [testCaseLoaderState, setTestCaseLoaderState] = useState(null);

  const breadCrumbs = [
    {
      title: "Input Story Details",
      status: Object.keys(userStory).length > 0 ? "Completed" : "In Progress",
    },
    {
      title: "Evaluate User Story",
      status:
        Object.keys(evaluationSummary).length > 0
          ? "Completed"
          : Object.keys(userStory).length > 0 &&
            testCaseLoaderState === "Evaluation Summary"
          ? "In Progress"
          : "Not Started",
    },
    {
      title: "Refine User Story",
      status:
        Object.keys(refinedUserStory).length > 0
          ? "Completed"
          : Object.keys(evaluationSummary).length > 0 &&
            testCaseLoaderState === "Refined User Story"
          ? "In Progress"
          : "Not Started",
    },
    {
      title: "Generate Use Cases",
      status:
        Object.keys(useCase).length > 0
          ? "Completed"
          : Object.keys(refinedUserStory).length > 0 &&
            testCaseLoaderState === "Use Case"
          ? "In Progress"
          : "Not Started",
    },
    {
      title: "Generate Test Cases",
      status:
        testcases.length > 0
          ? "Completed"
          : Object.keys(useCase).length > 0 &&
            testCaseLoaderState === "Test Cases"
          ? "In Progress"
          : "Not Started",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Box sx={{ maxHeight: "100%", width: "100%" }}>
        {/* Fixed breadcrumb where you want it */}
        <Box
          sx={{
            position: "sticky",
            top: "0", // Adjust this value as needed to place the breadcrumb where you want it
            width: "100%",
            zIndex: 1000, // Ensure it stays above other content
            bgcolor: "white", // Background color to prevent transparency
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional shadow for visual separation
          }}
        >
          <QMentisBreadCrumb breadCrumbs={breadCrumbs} sx={{height: "56px"}}/>
        </Box>

        {/* Add padding-top to avoid overlap with the fixed breadcrumb */}
        <Container
          sx={{
            width: "100%",
          }}
        >
          <TestCaseLoaderStateContext.Provider
            value={{ testCaseLoaderState, setTestCaseLoaderState }}
          >
            <TestCaseFlowDataContext.Provider
              value={{
                userStory,
                setUserStory,
                evaluationSummary,
                setEvaluationSummary,
                refinedUserStory,
                setRefinedUserStory,
                useCase,
                setUseCase,
                testcases,
                setTestCases,
              }}
            >
              <Outlet />
            </TestCaseFlowDataContext.Provider>
          </TestCaseLoaderStateContext.Provider>
        </Container>
      </Box>
    </Box>
  );
};

export default TestcaseGenerationPage;
