import React, { useContext, useEffect } from "react";
import ImportedInputBugReport from "../../input-components/bug-report-enhancer/ImportedInputBugReport";
import { useLocation } from "react-router-dom";
import { MultipleBugReportsContext } from "../MultiBugReportPage";

const MultiBugReportsInputPage = () => {
  const location = useLocation();
  const { bugDetails } = location.state;
  const { setMultipleBugReports } = useContext(MultipleBugReportsContext);
  useEffect(() => {
    setMultipleBugReports(bugDetails);
  }, [bugDetails]);
  return (
    <div>
      <ImportedInputBugReport />
    </div>
  );
};

export default MultiBugReportsInputPage;
