import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// MUI components
import { Box, IconButton, Tooltip } from "@mui/material";

// MUI icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Components and functions from project
import CommonAccordion from "../../custom-components/CommonAccordion";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import { refinedUserstory } from "../../../services/webServices";
import CommonDownload from "../../custom-components/CommonDownload";
import CommonEvaluationTable from "../../custom-components/CommonEvaluationTable";
import {
  TestCaseFlowDataContext,
  TestCaseLoaderStateContext,
} from "../../main-pages/TestcaseGenerationPage";
import { LoadingModalContext } from "../../main-pages/ServicePage";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";

const EvaluateSummary = () => {
  // Mock test cases output for UI development

  const [evaluationSummaryDat, setEvaluationSummaryData] = useState([
    {
      Parameter: "Independent",
      Rating: "3",
      Explanation: "Some Explanation",
      Recommendation: "Some Recommendations",
    },
    {
      Parameter: "Negotiable",
      Rating: "3.5",
      Explanation: "Some Explanation",
      Recommendation: "Some Recommendations",
    },
    {
      Parameter: "Valuable",
      Rating: "2",
      Explanation: "Some Explanation",
      Recommendation: "Some Recommendations",
    },
    {
      Parameter: "Estimable",
      Rating: "5",
      Explanation: "Some Explanation",
      Recommendation: "Some Recommendations",
    },
    {
      Parameter: "Small",
      Rating: "4.8",
      Explanation: "Some Explanation",
      Recommendation: "Some Recommendations",
    },
    {
      Parameter: "Testable",
      Rating: "3",
      Explanation: "Some Explanation",
      Recommendation: "Some Recommendations",
    },
  ]);
  const [openDownload, setOpenDownload] = useState(false);

  const setLoading = useContext(LoadingModalContext);
  const { setTestCaseLoaderState } = useContext(TestCaseLoaderStateContext);
  const { setRefinedUserStory, setUseCase, setTestCases } = useContext(
    TestCaseFlowDataContext
  );

  const location = useLocation();
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [activeThumb, setActiveThumb] = useState(null);

  const { refinedUserStory } = useContext(TestCaseFlowDataContext);

  const evaluationSummaryData = location.state["evaluationSummary"];
  const userStory = location.state["userStory"];

  const defaultEvalutaionSummaryExpanded = evaluationSummaryData.length > 0 && Object.keys(refinedUserStory).length === 0;
  // Formatting or flattening the test cases data values(Array or Objects) into strings
  const formattedEvaluationSummary = [];
  evaluationSummaryData.map((evaluationSummary) => {
    const flattenEvaluationSummary = {};
    Object.entries(evaluationSummary).map(([key, value]) => {
      if (Array.isArray(value)) {
        return (flattenEvaluationSummary[key] = flattenArray(value));
      } else if (typeof value === "object" && !!value) {
        return (flattenEvaluationSummary[key] = flattenObject(value));
      } else {
        return (flattenEvaluationSummary[key] = value);
      }
    });
    return formattedEvaluationSummary.push(flattenEvaluationSummary);
  });
  const handleThumbClick = (thumbType) => {
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Content to be copied");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  // Document download handler function
  const handleDownload = () => {
    setOpenDownload(true);
  };

  const handleRefinedUserStory = async () => {
    setLoading(true);
    setRefinedUserStory({});
    setUseCase({});
    setTestCases([]);
    setTestCaseLoaderState("Refined User Story");

    try {
      const requestData = {
        EvaluationSummary: evaluationSummaryData,
        UserStory: userStory,
      };
      const refinedUserStory = await refinedUserstory(requestData);
      navigate("/dashboard/generate-testcases/output", {
        state: {
          ...location.state,
          refinedUserStory: refinedUserStory.data["refinedUserStory"],
          useCase: {},
          testcases: [],
        },
        replace: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableDataConverter = (data) => {
    const headers = ["Parameter", ...data.map((item) => item.Parameter)]; // Empty cell for row labels + headers

    // Extract the rows (keys) excluding the 'Parameter' key
    const rowLabels = Object.keys(data[0]).filter((key) => key !== "Parameter");

    // Extract values for each row key across all parameters
    const values = rowLabels.map(
      (rowKey) => [rowKey, ...data.map((item) => item[rowKey])] // Include row label as the first element
    );

    return { headers, values };
  };

  // Table rendering using custom component
  const evaluationSummaryTable = (
    <Box sx={{ height: "100%" }}>
      <Box
        sx={{
          maxHeight: "60vh",
          height: "100%",
          // overflow: "auto",
          // "&::-webkit-scrollbar": {
          //   width: "0px",
          // },
          // scrollbarWidth: "none",
        }}
      >
        <CommonEvaluationTable
          {...tableDataConverter(formattedEvaluationSummary)}
          edittable={true}
          tableData={formattedEvaluationSummary}
          setTableData={setEvaluationSummaryData}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 6,
          marginBottom: 2,
        }}
      >
        {/* Thumbs Up Button with Tooltip */}
        <Tooltip title="Like" arrow>
          <Box
            onClick={() => handleThumbClick("like")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {activeThumb === "like" ? (
              <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
            ) : (
              <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
            )}
          </Box>
        </Tooltip>

        {/* Thumbs Down Button with Tooltip */}
        <Tooltip title="Dislike" arrow>
          <Box
            onClick={() => handleThumbClick("dislike")}
            sx={{
              cursor: "pointer",
              marginRight: 2, // Margin between the buttons
              "&:hover": {
                color: "#FF4842", // Change color on hover
              },
            }}
          >
            {activeThumb === "dislike" ? (
              <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
            ) : (
              <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
            )}
          </Box>
        </Tooltip>

        {/* Copy Button with Tooltip */}
        <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
          <Box
            onClick={handleCopyClick}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#49A3FF", // Change color on hover
              },
            }}
          >
            {isCopied ? (
              <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
            ) : (
              <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
            )}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );

  return (
    <Box>
      <CommonAccordion
        summary={"Evaluation Summary"}
        details={evaluationSummaryTable}
        type={"output"}
        buttons={[
          {
            label: "Download",
            onClick: handleDownload,
            variant: "text",
            startIcon: <FileDownloadOutlinedIcon />,
            sx: { textTransform: "none" },
          },
          {
            label: "Refine User Story",
            onClick: handleRefinedUserStory,
            variant: "contained",
            startIcon: <GenerateButton />,
            sx: { float: "right", textTransform: "none" },
          },
        ]}
        fullScreenMode={true}
        defaultExpanded={defaultEvalutaionSummaryExpanded}
      />
      <CommonDownload
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        serviceResponse={[formattedEvaluationSummary]}
        serviceName="Evaluation_Summary_Report"
        wordData={[
          { heading: "User Story", type: "text", data: userStory["Title"] },
          {
            heading: "Evaluation Summary",
            type: "table",
            data: { ...tableDataConverter(formattedEvaluationSummary) },
          },
        ]}
      />
    </Box>
  );
};

export default EvaluateSummary;
