const base_url = {
  /***** LOCAL DEVELOPMENT ******/
  // base_url: "http://localhost:5000",
  // frontendUrl: "http://localhost:3000",

  /**** DEV URLS ****/
  // base_url: "https://dev.qmentisai.ai/api",
  // frontendUrl: "https://dev.qmentisai.ai",

  /***** PRODUCTION URLS ******/
  base_url: "https://qmentisai.ai/api",
  frontendUrl: "https://qmentisai.ai",

  /**** Test URLS ****/
  // base_url: "https://testing.qebot.ai/api",
  // frontendUrl: "https://testing.qebot.ai",
};
export default base_url;
