import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as QMentisAILogo } from "../../images/QMentis.svg"; // Adjust the path as necessary
import "../../App.css"; // Ensure this contains the rotate-animation CSS

function ProgressWithLogo() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={3}
      borderRadius={1}
      bgcolor="background.paper"
      boxShadow={3}
    >
      <Box className="rotate-animation">
        <QMentisAILogo
          width="100"
          height="100"
          display="block"
          position="relative"
        />
      </Box>
      <Typography variant="h4" color="textPrimary" gutterBottom>
        QMentisAI™ - Intelligence for Next-Gen Quality
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Getting you a quality response...
      </Typography>
    </Box>
  );
}

export default ProgressWithLogo;
