import * as XLSX from "xlsx";
import { serviceResponseTransformation } from "./download-utils/transformDataToExport";
import { generateFilename } from "./download-utils/generateFilenameToExport";

export const exportToXLS = (serviceResponses, filename) => {
  if (!serviceResponses) {
    return;
  }

  const wb = XLSX.utils.book_new();

  const wrapTextStyle = {
    alignment: { wrapText: true },
  };

  if (Array.isArray(serviceResponses)) {
    serviceResponses.forEach((serviceResponse, index) => {
      if (!serviceResponse) {
        return;
      }

      const { headers, values } = serviceResponseTransformation(serviceResponse, filename, "xls");

      if (!headers || !values) {
        return;
      }

      const formattedServiceResponse = [headers, ...values];
      const ws = XLSX.utils.aoa_to_sheet(formattedServiceResponse);

      for (const row of ws["!rows"] || []) {
        row.style = wrapTextStyle;
      }

      const sheetName = `${filename} - ${index + 1}`;//`ServiceResponse ${index + 1}`;
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });
  } else {
    if (!serviceResponses) {
      return;
    }

    const { headers, values } = serviceResponseTransformation(serviceResponses, filename, "xls");

    if (!headers || !values) {
      return;
    }

    const formattedServiceResponse = [headers, ...values];

    const ws = XLSX.utils.aoa_to_sheet(formattedServiceResponse);
    for (const row of ws["!rows"] || []) {
      row.style = wrapTextStyle;
    }
    XLSX.utils.book_append_sheet(wb, ws, "Data");
  }
  const extendedFileName = generateFilename(filename, "xls");
  XLSX.writeFile(wb, extendedFileName);
};
