import React, { useState, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonAccordion from "../../custom-components/CommonAccordion"; // Import your custom accordion component
import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import CommonDownload from "../../custom-components/CommonDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import { MultipleTestCasesContext, TestCasesLoaderContext } from "../../main-pages/MultiTestCaseGenerationPage";
import MultiAccordian from "./MultiAccordian";
import { refinedUserstory, userStoryToUseCase } from "../../../services/webServices";
import { flattenArray, flattenObject } from "../../../utils/dataFlatteners";
import SelectionErrorPopUp from "../../popup-components/SelectionerrorPop";

const MultipleRefinedUserStory = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { testCaseLoaderState, setTestCaseLoaderState } = useContext(TestCasesLoaderContext);
  const {
    multipleRefinedUserStory,
    multipleUseCases,
    setMultipleUseCases,
    setMultiTestCases
  } = useContext(MultipleTestCasesContext);

  const selectedRefinedUserStoriesFromContext = useContext(MultipleTestCasesContext).selectedRefinedUserStories;

  const [multipleRefinedUserStories, setMultipleRefinedUserStories] = useState(multipleRefinedUserStory);
  const [selectedRefinedUserStories, setSelectedRefinedUserStories] = useState(selectedRefinedUserStoriesFromContext);
  const [editingValue, setEditingValue] = useState({});
  // const [openDownload, setOpenDownload]= useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const defaultMultipleRefinedUserStoriesExpanded = multipleRefinedUserStory.length > 0 && multipleUseCases.length === 0;
  const [noneRefinedUserStoriesSelected, setNoneRefinedUserStoriesSelected] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const handleDownloadClick = () => {
    setDownloadOpen(true);
  };

  const handleCloseDownload = () => {
    setDownloadOpen(false);
  };

  useEffect(() => {
    setMultipleRefinedUserStories([...multipleRefinedUserStory]);
    setSelectedRefinedUserStories([...selectedRefinedUserStoriesFromContext]);
  }, [multipleRefinedUserStory, selectedRefinedUserStoriesFromContext]);

  const activeRefinedUserStories = [];
  multipleRefinedUserStories.map((eachUserStory, index) => {
    if (!!eachUserStory) {
      activeRefinedUserStories.push({[index]: eachUserStory});
    }
  });

  const summaries = useMemo(
    () =>
      multipleRefinedUserStories.map((eachUserStory) => {
        return eachUserStory?.Title || null;
      }),
    [multipleRefinedUserStories]
  );

  const refinedUserStoriesComponents = multipleRefinedUserStories.map(
    (eachUserStory, refinedUserStoryIndex) => {
      if (!eachUserStory) {
        return null;
      }
      
      return (
      
      <Box key={`eachUserStory_${refinedUserStoryIndex}`}>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Title
        </Typography>
        {isEditing === refinedUserStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Title"
              value={editingValue["Title"]}
              onChange={(e) => handleEdittedValue(e, refinedUserStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography variant="body1" sx={{ marginBottom: 2, fontSize: "14px" }}>
              {eachUserStory["Title"]}
            </Typography>
          </Box>
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Description
        </Typography>
        {isEditing === refinedUserStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Description"
              value={editingValue["Description"]}
              onChange={(e) => handleEdittedValue(e, refinedUserStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginBottom: 2, fontSize: "14px" }}
              dangerouslySetInnerHTML={{
                __html: eachUserStory["Description"].replace(/\n/g, '<br />')
              }}
            />
          </Box>
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Acceptance Criteria
        </Typography>
        {isEditing === refinedUserStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Acceptance Criteria"
              value={editingValue["Acceptance Criteria"]}
              onChange={(e) => handleEdittedValue(e, refinedUserStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginBottom: 2, fontSize: "14px" }}
              dangerouslySetInnerHTML={{
                __html: eachUserStory["Acceptance Criteria"].replace(/\n/g, '<br />')
              }}
            />
          </Box>
        )}
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: "14px" }}
        >
          Additional Information
        </Typography>
        {isEditing === refinedUserStoryIndex ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              name="Additional Information"
              value={editingValue["Additional Information"]}
              onChange={(e) => handleEdittedValue(e, refinedUserStoryIndex)}
              sx={{ marginTop: 1, marginRight: 1 }}
              inputProps={{ style: { fontSize: "14px" } }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { backgroundColor: "white" },
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "14px" }}
              dangerouslySetInnerHTML={{
                __html: eachUserStory["Additional Information"].replace(/\n/g, '<br />')
              }}
            />
          </Box>
        )}
      </Box>
    )}
);


  const handleEdittedValue = (event) => {
    const updatedValue = {
      ...editingValue,
      [event.target.name]: event.target.value,
    };
    setEditingValue(updatedValue);
  };

  // const handleDownload=()=>{
  //   setOpenDownload(true);
  // }

  const handleMultiUseCases = async () => {
    try {
      if (selectedRefinedUserStories.every((eachUserStory) => !eachUserStory)){
        setNoneRefinedUserStoriesSelected(true);
        return;
      }
      setTestCaseLoaderState("Use Case");
      setMultipleUseCases([]);
      setMultiTestCases([]);
      const updatedUseCases = [];
      for (const [
        index,
        eachUserStory,
      ] of selectedRefinedUserStories.entries()) {
        if (eachUserStory && Object.keys(eachUserStory).length > 0) {
          const requestData = {
            ...eachUserStory,
          };
          const useCases = await userStoryToUseCase(requestData);
          const formattedUseCase = {};
          Object.entries(useCases.data).map(([key,value]) => {
            if (Array.isArray(value)) {
              return formattedUseCase[key] = flattenArray(value);
            }
            if (typeof value === "object" && !!value) {
              return formattedUseCase[key] = flattenObject(value);
            }
            return formattedUseCase[key] = value; 
          })
          setMultipleUseCases((prevState) => {
            const updatingUseCases = [...prevState];
            updatingUseCases[index] = formattedUseCase;
            return updatingUseCases;
          });
          updatedUseCases[index] = formattedUseCase;
        } else {
          updatedUseCases[index] = null;
        }
      }
      navigate("/dashboard/generate-multi/testcases/output", {
        state: {
          ...location.state,
          multipleRefinedUserStory: multipleRefinedUserStories,
          selectedRefinedUserStories: selectedRefinedUserStories,
          multipleUseCases: updatedUseCases,
          selectedMultipleUseCases: Array(updatedUseCases.length).fill(null),
          multipleTestCases: [],
          selectedMultipleTestCases: []
        },
        replace: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setTestCaseLoaderState(null);
    }
  };

  const createWordData = (multipleRefinedUserStoriesData) => {
    const wordData = [];
    multipleRefinedUserStoriesData.map((eachUserStory) => {
      if (!!eachUserStory) {
        wordData.push({
          heading: "Refined User Story",
          type: "subParagraphs",
          data: eachUserStory,
        });
        wordData.push({ type: "pageBreak" });
        return;
      }
    });
    return wordData;
  };

  const buttons = [
    {
      label: "Download",
      variant: "text",
      startIcon: <FileDownloadOutlinedIcon />,
      onClick: handleDownloadClick,
    },
    {
      label: "Generate Use Cases",
      variant: "contained",
      startIcon: (testCaseLoaderState === "Use Case" ? <CircularProgress size="14px" /> : <GenerateButton />),
      onClick: handleMultiUseCases,
      sx: { float: "right" },
      disabled: !!testCaseLoaderState
    },
  ];

  return (
    <div style={{ backgroundColor: "#F6F3F2" }}>
      <Box width={"601px"} sx={{ height: "100%" }}>
        <CommonAccordion
          summary={`Refined User Stories (${activeRefinedUserStories.length})`}
          details={
            <Box>
              <Box sx={{ overflow: "hidden", width: "100%" }}>
                <MultiAccordian
                  summaries={summaries}
                  multiAccoridanDetails={refinedUserStoriesComponents}
                  multiAccoridanData={multipleRefinedUserStories}
                  setMultiAccordianData={setMultipleRefinedUserStories}
                  selectedAccordians={selectedRefinedUserStories}
                  setSelectedAccordians={setSelectedRefinedUserStories}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  editingValue={editingValue}
                  setEditingValue={setEditingValue}
                  loaderCheck={!!testCaseLoaderState}
                />
              </Box>
            </Box>
          }
          buttons={buttons}
          type={"output"}
          defaultExpanded={defaultMultipleRefinedUserStoriesExpanded}
        />
        <CommonDownload
        open={downloadOpen}
        onClose={handleCloseDownload}
        serviceResponse={multipleRefinedUserStories}
        serviceName="Refined_User_Story"
        wordData={createWordData(selectedRefinedUserStories)}
      />
      <SelectionErrorPopUp open={noneRefinedUserStoriesSelected} onClose={() => setNoneRefinedUserStoriesSelected(false)} />
      </Box>
    </div>
  );
};

export default MultipleRefinedUserStory;
