import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import QMentisBreadCrumb from "../custom-components/QMentisBreadCrumb";

export const BugReportDataContext = createContext(null);

const BugReportPage = () => {
  const [bugReportData, setBugReportData] = useState({});
  const [enhancedBugReportData, setEnhancedBugReportData] = useState({});

  const breadCrumbs = [
    {
      title: "Input Bug Details",
      status:
        Object.keys(bugReportData).length > 0 ? "Completed" : "In Progress",
    },
    {
      title: "Enhance Bug Description",
      status:
        Object.keys(enhancedBugReportData).length > 0
          ? "Completed"
          : Object.keys(bugReportData).length > 0
          ? "In Progress"
          : "Not Started",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Box sx={{ maxHeight: "100%", width: "100%" }}>
        {/* BreadCrumb section */}
        <Box
          sx={{
            position: "fixed", // Fix it at the desired position
            // top: 0, // Adjust this if needed
            width: "100%",
            zIndex: 1000, // Make sure it's above other content
            bgcolor: "white", // Add a background color to prevent overlap issues
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for separation
          }}
        >
          <QMentisBreadCrumb breadCrumbs={breadCrumbs} sx={{height: "56px"}} />
        </Box>

        {/* Main content section */}
        <Box
          sx={{
            paddingTop: "50px", // Add padding equivalent to the height of the fixed BreadCrumb
          }}
        >
          <BugReportDataContext.Provider
            value={{ setBugReportData, setEnhancedBugReportData }}
          >
            <Outlet />
          </BugReportDataContext.Provider>
        </Box>
      </Box>
    </Box>
  );
};

export default BugReportPage;
