import jsPDF from "jspdf";
import "jspdf-autotable";
import { serviceResponseTransformation } from "./download-utils/transformDataToExport";
import { generateFilename } from "./download-utils/generateFilenameToExport";

export const exportToPDF = (serviceResponses, filename) => {
  const doc = new jsPDF();
  if (!Array.isArray(serviceResponses)) {
    serviceResponses = [serviceResponses];
  }

  let firstPage = true;
  serviceResponses.forEach((serviceResponse, index) => {
    if (!serviceResponse) {
      return;
    }

    const { headers, values } = serviceResponseTransformation(serviceResponse, filename, "pdf") || {};

    if (!headers || !values || values.length === 0) {
      return;
    }

    if (!firstPage) {
      doc.addPage();
    }

    doc.autoTable({
      head: [[`${filename} - ${index + 1}`]],
      body: [],
      theme: "plain",
      styles: { fontStyle: "bold", halign: "center", fontSize: 14 },
    });

    doc.autoTable({
      head: [headers],
      body: values,
    });

    firstPage = false;
  });

  const extendedFileName = generateFilename(filename, "pdf");
  doc.save(extendedFileName);
};
