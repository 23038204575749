import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import {ReactComponent as SendButton} from '../../images/generateButton.svg';

function ConfirmHomePage({ open, onClose, onConfirm }) {
  const navigate=useNavigate();
  const handleHomePage = () => {
    if (onConfirm) {
      navigate("/landingpage");
    }
    onClose(); // Close the dialog after confirming logout
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          minWidth: '400px',
          padding: '20px',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '20px',
          paddingBottom: '10px',
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        Are you sure?
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: '20px' }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: '16px',
            fontFamily: 'Roboto, sans-serif',
            color: '#333',
          }}
        >
          You will lose all the information entered as well as the outputs.
        </Typography>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'flex-end', padding: '10px 24px' }}>
        <Button
          onClick={onClose}
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            fontFamily: 'Roboto, sans-serif',
            color: '#49A3FF',
            marginRight: '10px',
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleHomePage}
          variant="contained"
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            fontFamily: 'Roboto, sans-serif',
            color: 'white',
            backgroundColor: '#49A3FF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', 
            padding: '5px 15px',
            gap:'7px',
          }}
        >
          <SendButton sx={{ marginRight: '8px' }} />
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmHomePage;