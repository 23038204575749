import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Tooltip,
  Popover,
  MenuItem,
  ListItemIcon,
  Icon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as TestPlanIcon } from "../../images/testPlanIcon.svg";
import { ReactComponent as TestDesignIcon } from "../../images/testDesignIcon.svg";
import { ReactComponent as TestStrategyIcon } from "../../images/testStrategyIcon.svg";
import { ReactComponent as BugReportIcon } from "../../images/bugReportIcon.svg";
import { ReactComponent as UserStoryIcon } from "../../images/userStoryIcon.svg";
import { ReactComponent as RiskAssessmentIcon } from "../../images/riskAssessmentIcon.svg";
import { ReactComponent as CoverageIcon } from "../../images/coverageAnalysisIcon.svg";
import { ReactComponent as TestDataIcon } from "../../images/testDataIcon.svg";
import { ReactComponent as TestAutoIcon } from "../../images/testAutoIcon.svg";
import { ReactComponent as ScriptIcon } from "../../images/scriptMaintenanceIcon.svg";
import { ReactComponent as TestMetricsIcon } from "../../images/testMetricsIcon.svg";
import { ReactComponent as SummaryIcon } from "../../images/summaryReportsIcon.svg";
import { ReactComponent as SecurityIcon } from "../../images/securityIcon.svg";
import { ReactComponent as PerformanceTestingIcon } from "../../images/performanceTestingIcon.svg";
import { ReactComponent as APITestingICon } from "../../images/APITestingIcon.svg";
import { ReactComponent as ETLTestingIcon } from "../../images/ETLTestingIcon.svg";
import { ReactComponent as PromptIcon } from "../../images/promptLib.svg";
import { logout } from "../../services/webServices";
import ConfirmLogout from "../popup-components/ConfirmLogout";
import { fetchDisplayName } from "../../services/webServices";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import QMentisImage from "../../images/QMentis.svg";
import ConfirmHomePage from "../popup-components/ConfirmHomePage";

// Create a mapping for active index to path
const pathToIndexMap = {
  "/dashboard/test-plan/input": 2,
  "/dashboard/test-plan/output": 2,
  "/dashboard/generate-multi/testcases/output": 5,
  "/dashboard/generate-testcases/input": 5,
  "/dashboard/generate-testcases/output": 5,
  "/dashboard/multi-bugreports/input": 10,
  "/dashboard/multi-bugreports/output": 10,
  "/dashboard/bugreportenhancer/input": 10,
  "/dashboard/bugreportenhancer/output": 10,
  "/dashboard/test-automation": 8,
  "/dashboard/test-automation/input": 8,
  "/dashboard/test-automation/output": 8,
  // Add other paths as needed
};

const NavigationRailIcons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const iconRefs = useRef({}); // Store refs for icons
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayUserName, setUserDisplayName] = useState("");

  const handleLogoClick = () => {
    // Open ConfirmHomePage component before navigating
    setIsConfirmDialogOpen(true); // Assuming this state is defined in ConfirmHomePage
    setActiveIndex(0);
  };

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handleConfirmHomePage = () => {
    // Handle confirmation logic, e.g., navigate to homepage
    navigate("/landingpage");
    setIsConfirmDialogOpen(false);
  };

  const handleHoverOpen = (event) => {
    // setActiveIndex(0);
    setAnchorEl(event.currentTarget);
  };

  const handleHoverClose = () => {
    setAnchorEl(null);
  };

  const handleAccountManagementClick = () => {
    navigate("/dashboard/jira-settings");
    handleHoverClose();
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
    handleHoverClose();
  };

  useEffect(() => {
    const fetchAndSetDisplayName = async () => {
      try {
        // const username = localStorage.getItem("email"); // Retrieve username from localStorage

        // if (!username) return; // If username is not found in localStorage, exit early

        // if (username) {
        const response = await fetchDisplayName(); // Fetch display name from server
        if (response && response.data && response.data.displayName) {
          setUserDisplayName(response.data.displayName);
        } else {
          console.error("Display name not found in response");
        }
        // }
      } catch (error) {
        console.error("Error fetching display name:", error);
      }
    };

    fetchAndSetDisplayName();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const newIndex = pathToIndexMap[currentPath];
    setActiveIndex(newIndex);

    // Scroll to the corresponding icon
    if (iconRefs.current[newIndex]) {
      iconRefs.current[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [location]);

  const handleSignOut = async () => {
    await logout();
    navigate("/");
  };

  const handleIconClick = (index) => {
    setActiveIndex(index);

    // Redirect based on the clicked index
    // if (index === 1) {
    //   navigate("/login");
    // } else if (index === 2) {
    //   navigate("/test-plan");
    // } else
    if (index === 5) {
      navigate("/dashboard/generate-testcases/input");
    } else if (index === 10) {
      navigate("/dashboard/bugreportenhancer/input");
    } else if (index === 2) {
      navigate("/dashboard/test-plan/input");
    } else if (index === 8) {
      navigate("/dashboard/test-automation");
    }
  };

  const handleCloseDialog = () => {
    setOpenLogoutDialog(false); // Close the dialog
  };

  return (
    <AppBar
      sx={{
        width: isExpanded ? 220 : 80,
        height: "100vh",
        bgcolor: "white",
        alignItems: "center",
        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.2)",
        transition: "width 0.3s",
        position: "relative",
      }}
    >
      <Toolbar
        sx={{
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          p: 0,
          // position: "relative",
        }}
      >
        {/* Fixed Icons Container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 16,
            left: 18,
          }}
        >
          {/* Fixed Logo */}
          <Tooltip title="Go to Home" placement="right" arrow>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="logo"
              onClick={handleLogoClick}
              sx={{ color: "black", mb: 1, position: "relative", left: "10px" }}
            >
              <img src={QMentisImage} alt="Logo" width="40" height="40" />
            </IconButton>
          </Tooltip>

          {/* Fixed Menu Icon */}

          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              color: "black",
              borderRadius: 2,
              left: "3px",
              "&:hover": {
                bgcolor: "#0094fb",
              },
              "&.Mui-selected": {
                bgcolor: "#0094fb",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Container for other icons */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: isExpanded ? "flex-start" : "center",
            p: 0,
            marginTop: "130px",
            flexGrow: 1,
            left: -2,
            position: "relative",
            // Restrict height to maintain the number of visible icons
            direction: "ltr",
            maxHeight: "calc(7 * 60px)", // Set height for 7 icons (assuming each icon is ~60px tall)
            overflowY: "auto",
            marginBottom: "45px",
            scrollbarWidth: "thin", // Firefox support
            "&::-webkit-scrollbar": {
              width: "6px", // Thin scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#0094fb", // Scrollbar thumb color
              borderRadius: "2px", // Rounded scrollbar thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", // Scrollbar track color
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "column" }, // Use row on small screens, column otherwise
              gap: 2,
              flexWrap: "wrap", // Allow wrapping for small screens
              justifyContent: isExpanded ? "flex-start" : "center",
            }}
          >
            {/* Test Strategy Icon */}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="test strategy"
                onClick={() => handleIconClick(1)}
                sx={{
                  bgcolor: activeIndex === 1 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <TestStrategyIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 1 ? "inherit" : "black",
                  }}
                >
                  Test Strategy
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Test Strategy" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="test strategy"
                  onClick={() => handleIconClick(1)}
                  sx={{
                    bgcolor: activeIndex === 1 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <TestStrategyIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/* Test Plan Icon */}
            {isExpanded ? (
              <IconButton
                ref={(el) => (iconRefs.current[2] = el)} // Store ref for index 2
                color="inherit"
                aria-label="test plan"
                onClick={() => handleIconClick(2)}
                sx={{
                  bgcolor: activeIndex === 2 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <TestPlanIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 2 ? "inherit" : "black",
                  }}
                >
                  Test Plan
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Test Plan" placement="right" arrow>
                <IconButton
                  ref={(el) => (iconRefs.current[2] = el)} // Store ref for index 2
                  color="inherit"
                  aria-label="test plan"
                  onClick={() => handleIconClick(2)}
                  sx={{
                    bgcolor: activeIndex === 2 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <TestPlanIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*User Story Icon */}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="user story"
                onClick={() => handleIconClick(3)}
                sx={{
                  bgcolor: activeIndex === 3 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <UserStoryIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 3 ? "inherit" : "black",
                  }}
                >
                  User Story
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="User Story" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="user story"
                  onClick={() => handleIconClick(3)}
                  sx={{
                    bgcolor: activeIndex === 3 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <UserStoryIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Risk Assessment */}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="test cases"
                onClick={() => handleIconClick(4)}
                sx={{
                  bgcolor: activeIndex === 4 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <RiskAssessmentIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 4 ? "inherit" : "black",
                  }}
                >
                  Risk Assessment
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Risk Assessment" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="test cases"
                  onClick={() => handleIconClick(4)}
                  sx={{
                    bgcolor: activeIndex === 4 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <RiskAssessmentIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/* Test Design Icon */}
            {isExpanded ? (
              <IconButton
                ref={(el) => (iconRefs.current[5] = el)}
                color="inherit"
                aria-label="test design"
                onClick={() => handleIconClick(5)}
                sx={{
                  bgcolor: activeIndex === 5 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <TestDesignIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 5 ? "inherit" : "black",
                  }}
                >
                  Test Design
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Test Design" placement="right" arrow>
                <IconButton
                  ref={(el) => (iconRefs.current[5] = el)}
                  color="inherit"
                  aria-label="test design"
                  onClick={() => handleIconClick(5)}
                  sx={{
                    bgcolor: activeIndex === 5 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <TestDesignIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Coverage Analysis Icon*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="coverage analysis"
                onClick={() => handleIconClick(6)}
                sx={{
                  bgcolor: activeIndex === 6 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <CoverageIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 6 ? "inherit" : "black",
                  }}
                >
                  Coverage Analysis
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Coverage Analysis" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="coverage analysis"
                  onClick={() => handleIconClick(6)}
                  sx={{
                    bgcolor: activeIndex === 6 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <CoverageIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Test Data creation*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="test data creation"
                onClick={() => handleIconClick(7)}
                sx={{
                  bgcolor: activeIndex === 7 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <TestDataIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 7 ? "inherit" : "black",
                  }}
                >
                  Test Data Creation
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Test Data Creation" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="test data creation"
                  onClick={() => handleIconClick(7)}
                  sx={{
                    bgcolor: activeIndex === 7 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <TestDataIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Test Automation*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="test automation"
                onClick={() => handleIconClick(8)}
                sx={{
                  bgcolor: activeIndex === 8 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <TestAutoIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 8 ? "inherit" : "black",
                  }}
                >
                  Test Automation
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Test Automation" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="test automation"
                  onClick={() => handleIconClick(8)}
                  sx={{
                    bgcolor: activeIndex === 8 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <TestAutoIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Script Maintenance*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="script maintenance"
                onClick={() => handleIconClick(9)}
                sx={{
                  bgcolor: activeIndex === 9 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <ScriptIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 9 ? "inherit" : "black",
                  }}
                >
                  Script Maintenance
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Script Maintenance" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="script maintenance"
                  onClick={() => handleIconClick(9)}
                  sx={{
                    bgcolor: activeIndex === 9 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <ScriptIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/* Bug Report Icon */}
            {isExpanded ? (
              <IconButton
                ref={(el) => (iconRefs.current[10] = el)}
                color="inherit"
                aria-label="bug report"
                onClick={() => handleIconClick(10)}
                sx={{
                  bgcolor: activeIndex === 10 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <BugReportIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 10 ? "inherit" : "black",
                  }}
                >
                  Bug Reporting
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Bug Reporting" placement="right" arrow>
                <IconButton
                  ref={(el) => (iconRefs.current[10] = el)}
                  color="inherit"
                  aria-label="bug report"
                  onClick={() => handleIconClick(10)}
                  sx={{
                    bgcolor: activeIndex === 10 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <BugReportIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Test Metrics Icon */}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="test metrics"
                onClick={() => handleIconClick(11)}
                sx={{
                  bgcolor: activeIndex === 11 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <TestMetricsIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 11 ? "inherit" : "black",
                  }}
                >
                  Test Metrics
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Test Metrics" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="test metrics"
                  onClick={() => handleIconClick(11)}
                  sx={{
                    bgcolor: activeIndex === 11 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <TestMetricsIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Summary Reports*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="summary reports"
                onClick={() => handleIconClick(12)}
                sx={{
                  bgcolor: activeIndex === 12 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <SummaryIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 12 ? "inherit" : "black",
                  }}
                >
                  Summary Reports
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Summary Reports" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="summary reports"
                  onClick={() => handleIconClick(12)}
                  sx={{
                    bgcolor: activeIndex === 12 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <SummaryIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Security Testing*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="security testing"
                onClick={() => handleIconClick(13)}
                sx={{
                  bgcolor: activeIndex === 13 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <SecurityIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 13 ? "inherit" : "black",
                  }}
                >
                  Security Testing
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Security Testing" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="security testing"
                  onClick={() => handleIconClick(13)}
                  sx={{
                    bgcolor: activeIndex === 13 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <SecurityIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Performance Testing*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="performance testing"
                onClick={() => handleIconClick(14)}
                sx={{
                  bgcolor: activeIndex === 14 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <PerformanceTestingIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 14 ? "inherit" : "black",
                  }}
                >
                  Performance Testing
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Performance Testing" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="performance testing"
                  onClick={() => handleIconClick(14)}
                  sx={{
                    bgcolor: activeIndex === 14 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <PerformanceTestingIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*API Testing Icon*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="api testing"
                onClick={() => handleIconClick(15)}
                sx={{
                  bgcolor: activeIndex === 15 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <APITestingICon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 15 ? "inherit" : "black",
                  }}
                >
                  API Testing
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="API Testing" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="api testing"
                  onClick={() => handleIconClick(15)}
                  sx={{
                    bgcolor: activeIndex === 15 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <APITestingICon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*ETL Testing*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="etl testing"
                onClick={() => handleIconClick(16)}
                sx={{
                  bgcolor: activeIndex === 16 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <ETLTestingIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 16 ? "inherit" : "black",
                  }}
                >
                  ETL Testing
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="ETL Testing" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="etl testing"
                  onClick={() => handleIconClick(16)}
                  sx={{
                    bgcolor: activeIndex === 16 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <ETLTestingIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}

            {/*Prompt Lib Icon*/}
            {isExpanded ? (
              <IconButton
                color="inherit"
                aria-label="prompt library"
                onClick={() => handleIconClick(17)}
                sx={{
                  bgcolor: activeIndex === 17 ? "#0094fb" : "transparent",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: "#0094fb",
                  },
                  justifyContent: "flex-start",
                }}
              >
                <PromptIcon width={24} height={24} />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.85rem",
                    ml: 1,
                    whiteSpace: "nowrap",
                    color: activeIndex === 17 ? "inherit" : "black",
                  }}
                >
                  Prompt Library
                </Typography>
              </IconButton>
            ) : (
              <Tooltip title="Prompt Library" placement="right" arrow>
                <IconButton
                  color="inherit"
                  aria-label="prompt library"
                  onClick={() => handleIconClick(17)}
                  sx={{
                    bgcolor: activeIndex === 17 ? "#0094fb" : "transparent",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#0094fb",
                    },
                    justifyContent: "flex-start",
                  }}
                >
                  <PromptIcon width={24} height={24} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        {/* Fixed Profile Icon */}
        <Tooltip title="Profile" placement="right" arrow>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="logout"
            sx={{
              color: "black",
              mb: 1,
              position: "absolute",
              left: "35px",
              bottom: "5px",
            }}
            onClick={(e) => handleHoverOpen(e)}
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4fa7e9a53a0b37a2cf751f8cc044de6188e2a7a534f07ab29fdb0b31d1ca99df?placeholderIfAbsent=true&apiKey=9a2f454d373e439aa0de2a182c9950a9"
              alt="Jira Settings"
              style={{ width: 25, height: 25 }}
            />
          </IconButton>
        </Tooltip>

        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleHoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            onMouseEnter: handleHoverOpen,
            onMouseLeave: handleHoverClose,
          }}
        >
          <MenuItem onClick={handleHoverClose}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <PersonOutlineOutlinedIcon sx={{ marginRight: 1 }} />{" "}
              {/* Add margin for spacing */}
              <span>{displayUserName}</span>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={handleAccountManagementClick}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <SettingsOutlinedIcon sx={{ marginRight: 1 }} />
              <Typography variant="body1">Settings</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleLogoutClick}>
            <ListItemIcon>
              <LogoutOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Logout</Typography>
          </MenuItem>
        </Popover>
        {/* </Tooltip> */}
        <ConfirmHomePage
          open={isConfirmDialogOpen} // Pass the open state
          onClose={() => setIsConfirmDialogOpen(false)} // Pass the close handler
          onConfirm={handleConfirmHomePage} // Pass the confirm handler
        />
      </Toolbar>
      <ConfirmLogout
        open={openLogoutDialog} // Pass the open state
        onClose={handleCloseDialog} // Pass the close handler
        onConfirm={handleSignOut} // Pass the confirm handler
      />
    </AppBar>
  );
};

export default NavigationRailIcons;
