import React, { useContext, useEffect } from "react";
import TestCasesComponent from "../../output-components/testcase-generation/TestCasesComponent";
import { useLocation } from "react-router-dom";
import { TestCaseFlowDataContext } from "../TestcaseGenerationPage";
import UserStoryComponent from "../../output-components/testcase-generation/UserStoryComponent";
import EvaluateSummary from "../../output-components/testcase-generation/EvaluationSummaryComponent";
import { Box, Typography } from "@mui/material";
import RefinedUserStoryComponent from "../../output-components/testcase-generation/RefinedUserStoryComponent";
import UseCasesComponent from "../../output-components/testcase-generation/UseCasesComponent";
import { ReactComponent as AutomateIcon } from "../../../images/automateIcon.svg";
import FullscreenToggle from "../../custom-components/FullScreenToggle";

const TestCasesOutputPage = () => {
  const location = useLocation();
  const { userStory, evaluationSummary, refinedUserStory, useCase, testcases } =
    location.state || {
      userStory: {},
      evaluationSummary: [],
      refinedUserStory: {},
      useCase: {},
      testcases: [],
    };
  const {
    setUserStory,
    setEvaluationSummary,
    setRefinedUserStory,
    setUseCase,
    setTestCases,
  } = useContext(TestCaseFlowDataContext);

  useEffect(() => {
    setUserStory(userStory);
    setEvaluationSummary(evaluationSummary);
    setRefinedUserStory(refinedUserStory);
    setUseCase(useCase);
    setTestCases(testcases);
  }, [userStory, evaluationSummary, refinedUserStory, useCase, testcases]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "10px auto",
        "& > *:not(:last-child)": {
          marginBottom: "16px", // Adds margin between components but not after the last one
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={0.1}
      >
        <Box display="flex" alignItems="center" mb={0.1}>
          <AutomateIcon style={{ marginRight: 4 }} />
          {/* Informative text */}
          <Typography
            variant="body2"
            color="text.secondary"
            gutterBottom
            sx={{ mt: 0.5 }}
          >
            Automate and create comprehensive, high-quality test cases
            effortlessly
          </Typography>
        </Box>
        <FullscreenToggle />
      </Box>
      {Object.keys(userStory).length > 0 && <UserStoryComponent />}
      {evaluationSummary.length > 0 && <EvaluateSummary />}
      {Object.keys(refinedUserStory).length > 0 && (
        <RefinedUserStoryComponent />
      )}
      {Object.keys(useCase).length > 0 && <UseCasesComponent />}
      {testcases.length > 0 && <TestCasesComponent />}
      {/* <TestCasesComponent /> */}
      <Box
        component="footer"
        sx={{
          width: "100%",
          maxWidth: "601px",
          backgroundColor: "#f6f3f2",
          textAlign: "center",
          paddingX: "5px",
          position: "fixed",
          bottom: "0",
          zIndex: "99"
        }}
      >
        <Typography variant="caption" color="textSecondary">
          Use QMentisAI™ with human supervision, as with all generative AI tools.
        </Typography>
      </Box>
    </Box>
  );
};

export default TestCasesOutputPage;
