import React, { useEffect, useState } from "react";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const LocatorsTable = ({ jsonData, setJsonData }) => {
    const [data, setData] = useState(jsonData || {});
    const [editableRow, setEditableRow] = useState("");
    const [editedPrimary, setEditedPrimary] = useState([]);
    const [editedSecondary, setEditedSecondary] = useState([]);

    useEffect(() => {
        setData(jsonData);
    }, [jsonData]);

    const handleEditClick = (parameter) => {
        const { primary, secondary } = data[parameter];
        setEditableRow(parameter);
        setEditedPrimary(primary);
        setEditedSecondary(secondary);
    };

    const handleSaveClick = (parameter) => {
        const updatedData = {
            ...data,
            [parameter]: {
                primary: editedPrimary,
                secondary: editedSecondary,
            },
        };
        setJsonData(updatedData);
        setData(updatedData);
        setEditableRow("");
    };

    const handleCancelClick = () => {
        setEditableRow("");
    };

    return (
        <Box padding={3}>
            <TableContainer sx={{ boxShadow: 1, padding: "10px" }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#0095ff", color: "#ffffff" }}>
                            <TableCell style={{ fontWeight: "bold" }}>Parameter</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Primary Locators</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Secondary Locators</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Object.entries(data).map(([parameter, locators], index) => (
                            <TableRow
                                key={parameter}
                                style={{
                                    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
                                }}
                            >
                                <TableCell>{parameter}</TableCell>

                                {/* Primary Locators */}
                                <TableCell>
                                    {editableRow === parameter ? (
                                        <TextField
                                            value={editedPrimary.join("\n")}
                                            onChange={(e) =>
                                                setEditedPrimary(e.target.value.split("\n"))
                                            }
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            style={{ color: "#333" }}
                                            dangerouslySetInnerHTML={{
                                                __html: locators.primary.join("<br>"),
                                            }}
                                        />
                                    )}
                                </TableCell>

                                {/* Secondary Locators */}
                                <TableCell>
                                    {editableRow === parameter ? (
                                        <TextField
                                            value={editedSecondary.join("\n")}
                                            onChange={(e) =>
                                                setEditedSecondary(e.target.value.split("\n"))
                                            }
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            style={{ color: "#333" }}
                                            dangerouslySetInnerHTML={{
                                                __html: locators.secondary.join("<br>"),
                                            }}
                                        />
                                    )}
                                </TableCell>

                                <TableCell>
                                    {editableRow === parameter ? (
                                        <>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleSaveClick(parameter)}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={handleCancelClick}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleEditClick(parameter)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default LocatorsTable;
