import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, Container, Pagination, Tooltip, Checkbox, Divider } from '@mui/material';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import CommonAccordion from '../../custom-components/CommonAccordion';
import CommonDownload from '../../custom-components/CommonDownload';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { featureFiles, pseudoCode } from '../../../services/webServices'
import { TestCaseFlowDataContext } from '../../main-pages/TestAutomationPage';
import { useLocation, useNavigate } from "react-router-dom";
import CodeEditor from './CodeEditor';
import { LoadingModalContext } from "../../main-pages/ServicePage";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";


const FeatureFile = ({ title, isChecked, isExpanded, onCheckboxToggle, onExpandToggle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px',
        backgroundColor: isChecked ? '#b3d1ff' : 'white',
        marginLeft: '-10px',
        marginRight: '-40px',
        marginBottom: '2px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onExpandToggle}> {/* Use the prop to toggle expand/collapse */}
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
        <Checkbox
          checked={isChecked}
          onChange={onCheckboxToggle}
        />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </Box>
      {isExpanded && (
        <>
          <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.1)', my: 2 }} />
        </>
      )}
    </Box>
  );
};

// const LikeDislikeComponent = () => {
//   const [liked, setLiked] = useState(false);
//   const [disliked, setDisliked] = useState(false);

//   const handleLike = () => {
//     setLiked(prev => !prev);
//     if (disliked) {
//       setDisliked(false);
//     }
//   };

//   const handleDislike = () => {
//     setDisliked(prev => !prev);
//     if (liked) {
//       setLiked(false);
//     }
//   };

//   const handleCopy = () => {

//     console.log("Content copied!");
//   };

//   return (
//     <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
//       <IconButton onClick={handleLike}>
//         <ThumbUpIcon sx={{ color: liked ? 'blue' : 'inherit' }} />
//       </IconButton>
//       <IconButton onClick={handleDislike}>
//         <ThumbDownIcon sx={{ color: disliked ? 'red' : 'inherit' }} />
//       </IconButton>
//       <IconButton onClick={handleCopy}>
//         <ContentCopyIcon />
//       </IconButton>
//     </Box>
//   );
// };



const IconComponent = () => (

  <Box sx={{ maxWidth: 14, display: 'flex', flexDirection: 'column' }}>
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/74001bbbe35d84ed7dd44adbf23d2273b030df4d38296f54b84768e53b777ae2?placeholderIfAbsent=true&apiKey=61dcb7c26aae4febb0f66efb27027a73"
      alt=""
      style={{ width: '100%', objectFit: 'contain' }}
    />
  </Box>
);


const Feature = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { featureFiles, addPreferences } = useContext(TestCaseFlowDataContext);
  const [featureFilesData, setFeatiuresFiles] = useState(featureFiles);
  // console.log("featureFilesData====>", featureFilesData)
  const [downloadOpen, setDownloadOpen] = useState(false);
  const setLoading = useContext(LoadingModalContext);
  // const { setShowPreferencesCompleted } = useContext(TestCaseFlowDataContext);

  const [showPreferences, setShowPreferences] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const [activeThumb, setActiveThumb] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const defaultFeatureFilesExpanded = featureFiles !== "" && !!featureFiles && !addPreferences;

  useEffect(() => {
    setFeatiuresFiles(featureFiles);
  }, [featureFiles]);

  const handleDownloadClick = () => {
    setDownloadOpen(true);
  };

  const handleCloseDownload = () => {
    setDownloadOpen(false);
  };

  const handlePreferencesClick = () => {
    navigate("/dashboard/test-automation/output", { state: { ...location.state, featureFilesData: featureFilesData, preferenceData: true }, replace: true })
  };


  const handleDownload = () => {
    console.log("Download clicked...");
  };
  const handleLike = () => {
    setLiked(!liked);
    if (disliked) setDisliked(false);
  };

  const handleDislike = () => {
    setDisliked(!disliked);
    if (liked) setLiked(false);
  };

  const handleCopy =
    () => {
      const textToCopy = JSON.stringify(featureFilesData, null, 2);
      navigator.clipboard.writeText(textToCopy).then(() => {
        alert("Text copied to clipboard!");
      });
    };

    const handleCopyClick = () => {
      // Handle copy action (you can implement actual copying logic here)
      console.log("Content copied!"); // Placeholder for copy logic
      setIsCopied(true);
      // Reset the copied status after 2 seconds (optional)
      setTimeout(() => setIsCopied(false), 2000);
  };

  const handleThumbClick = (thumbType) => {
    // Toggle thumbs based on the thumbType (like or dislike)
    setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
};



  return (
<Box>
    
      <CommonAccordion
        summary={"Feature File"}
        details={
          <Box sx={{ backgroundColor: '#fff', borderRadius: 1, p: 2, marginTop: '-15px' }}>

            <CodeEditor codeContent={featureFilesData} setCodeContent={setFeatiuresFiles} />

            <Container maxWidth={false} disableGutters>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                maxWidth="557px"

              >

<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2,marginTop:2 }}>
                                <Tooltip title="Like" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("like")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "like" ? (
                                            <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                                        ) : (
                                            <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Thumbs Down Button with Tooltip */}
                                <Tooltip title="Dislike" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("dislike")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#FF4842", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "dislike" ? (
                                            <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                                        ) : (
                                            <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Copy Button with Tooltip */}
                                <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                                    <Box
                                        onClick={handleCopyClick}
                                        sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {isCopied ? (
                                            <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                                        ) : (
                                            <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                                        )}
                                    </Box>
                                </Tooltip>
                        </Box>

              </Box>
            </Container>
          </Box>
        }

        buttons={[

          { label: 'Export', variant: 'text', startIcon: <IconComponent />, sx: { textTransform: 'none' } },
          { label: 'Download', onClick: handleDownloadClick, variant: 'text', startIcon: <SaveAltRoundedIcon />, sx: { textTransform: 'none' } },
          { label: 'Select Framework', onClick: handlePreferencesClick, startIcon: <GenerateButton />, variant: 'contained', sx: { float: "right" } }

        ]}

        type={"output"}
        fullScreenMode={true}
        defaultExpanded={defaultFeatureFilesExpanded}
      />
      {/* {showPreferences && <SetPseudoCodePreferences />} */}

      <CommonDownload open={downloadOpen} onClose={handleCloseDownload}
         serviceResponse={featureFilesData}
         serviceName="Feature_File_data"
         wordData={[{heading: "Feature File", type: "text", data: featureFilesData}]}
       />
  
    </Box>
  );
};

export default Feature;
