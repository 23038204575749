import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Divider,
    Typography,
    Button,
    Checkbox,
    IconButton,
    useMediaQuery,
    useTheme,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Container,
    Tooltip
} from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import CodeEditorNew from './BlackScreenNew';
import CommonAccordion from "../../custom-components/CommonAccordion"
import { ReactComponent as ExportIcon } from '../../../images/exportIcon.svg';
import LikeDislikeComponent from './CommonLikeDislikeComponent';
import { TestCaseFlowDataContext } from '../../main-pages/TestAutomationPage';
import { useLocation, useNavigate } from 'react-router-dom';
import CodeEditor from './CodeEditor';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import CommonDownload from '../../custom-components/CommonDownload';
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { ReactComponent as GenerateButton } from "../../../images/generateButton.svg";
import SetPseudoCodePreferences from './SetPseudoCodePreferences';


//import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
const PAGE_SIZE = 10; // Number of items per page

const CodeEditorScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {pseudoCode, setPseudoCode, locators} = useContext(TestCaseFlowDataContext);
    console.log(pseudoCode, "\n\n--------pseudo code-----------\n\n")
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState('panel4');
    const [automationCodeItems, setAutomationCodeItems] = useState([]);
    const [checkedStates, setCheckedStates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAllChecked, setIsAllChecked] = useState(false);  // For tracking "Select All/Unselect All"
    const [isAllExpanded, setIsAllExpanded] = useState(false);  // For tracking "Expand All/Collapse All"
    const [downloadOpen, setDownloadOpen] = useState(false);

    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [activeThumb, setActiveThumb] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const defaultPseudoCodeExpanded = pseudoCode !== "" && !!locators && Object.keys(locators).length === 0;
    // useEffect(() => {
    //     // This simulates a dynamic array of objects received from backend
    //     const fetchData = async () => {
            // const data = [
            //     { id: 1, description: 'User logs in with valid credentials' },
            //     { id: 2, description: 'Comprehensive Processing of Incoming Claims' },
            //     { id: 3, description: 'Validates Payment Gateway' },
            //     { id: 4, description: 'Logout Process' },
            //     { id: 5, description: 'Process Payment Refund' },
            //     { id: 6, description: 'User Updates Profile' },
            //     { id: 7, description: 'View User Dashboard' },
            //     { id: 8, description: 'Check Notification Settings' },
            //     { id: 9, description: 'User Changes Password' },
            //     { id: 10, description: 'Process Batch Orders' },
            //     { id: 11, description: 'Generate Monthly Report' },
            //     { id: 12, description: 'Validate Email Address' },
            //     { id: 13, description: 'System Health Check' },
            //     { id: 27, description: 'Update Terms and Conditions' },
            //     { id: 28, description: 'User Search Functionality' },
            //     { id: 29, description: 'Invoice Generation' },
            //     { id: 30, description: 'Security Vulnerability Scan' },
            //     { id: 31, description: 'Role-Based Access Check' },
            //     { id: 32, description: 'Data Encryption Validation' },
            //     { id: 33, description: 'Update Email Templates' },
            //     { id: 34, description: 'Notification Preference Update' },
            //     { id: 35, description: 'Update System Configuration' },
            //     { id: 36, description: 'Subscription Renewal Process' },
            //     { id: 37, description: 'Real-Time Data Monitoring' },
            //     { id: 38, description: 'Order Fulfillment Verification' },
            //     { id: 39, description: 'User Group Management' },
            //     { id: 40, description: 'Customer Support Ticket Review' }
            // ];
        //     setAutomationCodeItems(data);
        //     setCheckedStates(data.map(() => false)); // Initialize all unchecked
        //     setExpanded(data.map(() => false));
        //     setLoading(false); // Simulate loading done
        // };

    //     fetchData();
    // }, []);
    const pageCount = Math.ceil(automationCodeItems.length / PAGE_SIZE);


   
 
    const handleCheckboxChange = (index, event) => {
        event.stopPropagation();
        const actualIndex = (currentPage - 1) * PAGE_SIZE + index;

        setCheckedStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[actualIndex] = !newStates[actualIndex];
            return newStates;
        });
    };

    const handleSelectAll = () => {
        if (isAllChecked) {
            // Unselect all
            setCheckedStates(checkedStates.map(() => false));
        } else {
            // Select all
            setCheckedStates(checkedStates.map(() => true));
        }
        setIsAllChecked(!isAllChecked);
    };

    // Toggle Expand All/Collapse All
    const handleExpandAll = () => {
        if (isAllExpanded) {
            setExpanded(expanded.map(() => false));  // Collapse all
        } else {
            setExpanded(expanded.map(() => true));  // Expand all
        }
        setIsAllExpanded(!isAllExpanded);
    };
    const handlePageSource = async () => {
        // const featureFileGenerated = [{}]
        // setLoading(true);
        // setResponseError(null);
        // setpsudoCodeGenerated([]);
        // setLocatorsGenerated([]);
        // setPageClassGenerated([]);
        // setStepDefinitionGenerated([]);
        // setMavenGenerated({}); 
        try {
        //   const generatedPageSourceGenerated = await pageSource(generatedPseudoCodeData);
        //   const generatedPageSource = generatedPageSource.data;
        //   console.log("generatedPageSource", generatedPageSource);
          // console.log("TYPE generatedPseudoCode", typeof generatedPseudoCode);
    
        //   console.log("doing")
          navigate("/dashboard/test-automation/output", { state: { ...location.state, generatedPageSource: true }, replace: true });
          // setpsudoCodeGenerated(generatedPseudoCode); // Update with the fetched data
        } catch (error) {
          console.log(error);
          // setResponseError(error.response.data["Error"] || "An error occurred.");
        }
        // finally {
        //   setLoading(false);
        // }
      };

    
      const handleDownloadClick = () => {
        setDownloadOpen(true);
      };
    
      const handleCloseDownload = () => {
        setDownloadOpen(false);
      };



    const displayedItems = automationCodeItems.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
    );


    // Handle page change
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const IconComponent = () => (

        <Box sx={{ maxWidth: 14, display: 'flex', flexDirection: 'column' }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/74001bbbe35d84ed7dd44adbf23d2273b030df4d38296f54b84768e53b777ae2?placeholderIfAbsent=true&apiKey=61dcb7c26aae4febb0f66efb27027a73"
            alt=""
            style={{ width: '100%', objectFit: 'contain' }}
          />
        </Box>
      );

      const handleDownload = () => {
        console.log("Download clicked...");
      };
      const handleLike = () => {
        setLiked(!liked);
        if (disliked) setDisliked(false);
      };
    
      const handleDislike = () => {
        setDisliked(!disliked);
        if (liked) setLiked(false);
      };
    
      const handleCopy =
        () => {
          const textToCopy = JSON.stringify(pseudoCode, null, 2);
          navigator.clipboard.writeText(textToCopy).then(() => {
            alert("Text copied to clipboard!");
          });
        };

        const handleCopyClick = () => {
          // Handle copy action (you can implement actual copying logic here)
          console.log("Content copied!"); // Placeholder for copy logic
          setIsCopied(true);
          // Reset the copied status after 2 seconds (optional)
          setTimeout(() => setIsCopied(false), 2000);
      };

      const handleThumbClick = (thumbType) => {
        // Toggle thumbs based on the thumbType (like or dislike)
        setActiveThumb((prev) => (prev === thumbType ? null : thumbType));
    };
        
    return (


        // <Box className="bug-report-container" sx={{ bgcolor: '#F6F3F2', p: 2 }}>
        // <Container maxWidth="lg">
        // <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: 600, margin: '0 auto' }}>
      

            <Box>
            <CommonAccordion
                summary={"Pseudo Code"}
                details={
                    <>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-start', mb: 2 }}> 
                            {/* <Button size="small" sx={{ textTransform: 'none' }} onClick={handleUnselectAll} >Unselect All</Button>
                            <Button size="small" sx={{ textTransform: 'none' }}>Expand All</Button> 
                            <Button size="small" sx={{ textTransform: 'none' }} onClick={handleSelectAll}>
                                {isAllChecked ? "Unselect All" : "Select All"}
                            </Button>
                            <Button size="small" sx={{ textTransform: 'none' }} onClick={handleExpandAll}>
                                {isAllExpanded ? "Collapse All" : "Expand All"}
                            </Button>
                        </Box>

                         {displayedItems.map((codeItem, codeIndex) => (
                            <Accordion
                                key={codeIndex}
                                // defaultExpanded={false}
                                // sx={{
                                //     width: '100%',
                                //     maxWidth: 600,
                                //     backgroundColor: checkedStates[(currentPage - 1) * PAGE_SIZE + codeIndex] ? 'lightblue' : 'white', // Highlight if checkbox is checked
                                // }}

                                expanded={expanded[(currentPage - 1) * PAGE_SIZE + codeIndex]}
                                onChange={() =>
                                    setExpanded((prev) => {
                                        const newExpanded = [...prev];
                                        newExpanded[(currentPage - 1) * PAGE_SIZE + codeIndex] = !newExpanded[(currentPage - 1) * PAGE_SIZE + codeIndex];
                                        return newExpanded;
                                    })
                                }
                                sx={{
                                    width: '100%',
                                    maxWidth: 600,
                                    backgroundColor: checkedStates[(currentPage - 1) * PAGE_SIZE + codeIndex] ? 'lightblue' : 'white'
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
                                    aria-controls={`panel${codeIndex}-content`}
                                    id={`panel${codeIndex}-header`}
                                    sx={{ flexDirection: 'row-reverse' }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            size="small"
                                            checked={checkedStates[(currentPage - 1) * PAGE_SIZE + codeIndex] || false}
                                            onChange={(event) => handleCheckboxChange(codeIndex, event)} // Handle checkbox click without expanding accordion
                                            onClick={(event) => event.stopPropagation()} // Ensure checkbox click doesn't expand accordion
                                        />
                                        <Typography variant="body2">{codeItem.description}</Typography> {/* Render the description 
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CodeEditorNew />
                                </AccordionDetails>
                            </Accordion>
                        ))} */}
                        {/* <CodeEditorNew /> */}
                        <CodeEditor codeContent={pseudoCode} setCodeContent={setPseudoCode}/>

                        {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Pagination count={pageCount}
                                page={currentPage}
                                onChange={handlePageChange}
                                size="small"
                            />
                        </Box> */}
                       <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2,marginTop:2 }}>
                                <Tooltip title="Like" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("like")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "like" ? (
                                            <ThumbUpIcon sx={{ fontSize: "24px" }} /> // Filled thumbs up when clicked
                                        ) : (
                                            <ThumbUpOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs up by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Thumbs Down Button with Tooltip */}
                                <Tooltip title="Dislike" arrow>
                                    <Box
                                        onClick={() => handleThumbClick("dislike")}
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: 2, // Margin between the buttons
                                            "&:hover": {
                                                color: "#FF4842", // Change color on hover
                                            },
                                        }}
                                    >
                                        {activeThumb === "dislike" ? (
                                            <ThumbDownIcon sx={{ fontSize: "24px" }} /> // Filled thumbs down when clicked
                                        ) : (
                                            <ThumbDownOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined thumbs down by default
                                        )}
                                    </Box>
                                </Tooltip>

                                {/* Copy Button with Tooltip */}
                                <Tooltip title={isCopied ? "Copied!" : "Copy"} arrow>
                                    <Box
                                        onClick={handleCopyClick}
                                        sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: "#49A3FF", // Change color on hover
                                            },
                                        }}
                                    >
                                        {isCopied ? (
                                            <ContentCopyIcon sx={{ fontSize: "24px" }} /> // Filled copy icon when copied
                                        ) : (
                                            <ContentCopyOutlinedIcon sx={{ fontSize: "24px" }} /> // Outlined copy icon by default
                                        )}
                                    </Box>
                                </Tooltip>
                        </Box>
                        

                    </>
                }

                buttons={[

                    { label: 'Export', variant: 'text', startIcon: <IconComponent />, sx: { textTransform: 'none' } },
                    { label: 'Download', onClick: handleDownloadClick, variant: 'text', startIcon: <SaveAltRoundedIcon />, sx: { textTransform: 'none' } }
          
                  ]}
          
                fullScreenMode={true}
                type={"output"}
                defaultExpanded={defaultPseudoCodeExpanded}

            >

            </CommonAccordion>
                      <CommonDownload open={downloadOpen} onClose={handleCloseDownload}
                         serviceResponse={pseudoCode}
                         serviceName="PseudoCode_data"
                         wordData={[{heading: "Pseudo Code", type: "text", data: pseudoCode}]}
                      
                      />
                      </Box>
 
        
    );
};

export default CodeEditorScreen;
