export const generateFilename = (prefix, extension) => {
    const now = new Date();
    const pad = (number) => (number < 10 ? "0" : "") + number;
    const day = pad(now.getDate());
    const month = pad(now.getMonth() + 1);
    const year = now.getFullYear().toString().slice(-2);
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());
    const timestamp = `${day}_${month}_${year}_${hours}_${minutes}_${seconds}`;
    return `${prefix}_${timestamp}.${extension}`;
  };