import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid2,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import NavigationRail from "../common-components/NavigationRail";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UserStoryIcon } from "../../images/userStoryMain.svg";
import { ReactComponent as TestStrategyIcon } from "../../images/testStrategyMain.svg"
import { ReactComponent as TestPlanIcon } from "../../images/testPlanMain.svg";
import { ReactComponent as TestDesignIcon } from "../../images/testDesignMain.svg";
import { ReactComponent as TestAutoIcon } from "../../images/testAutomationMain.svg";
import { ReactComponent as BugReportIcon } from "../../images/bugReportingMain.svg";
import { ReactComponent as RightArrow } from "../../images/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../images/leftArrow.svg";
import { ReactComponent as RiskIcon } from "../../images/riskAssessmentMain.svg";
import { ReactComponent as CoverageIcon } from "../../images/coverageMain.svg";
import { ReactComponent as CreationIcon } from "../../images/creationMain.svg";
import { ReactComponent as ScriptIcon } from "../../images/scriptMain.svg";
import { ReactComponent as TestMetricsIcon } from "../../images/testMetricsMain.svg";
import { ReactComponent as SummaryReportsIcon } from "../../images/summaryReportsMain.svg";
import { ReactComponent as SecurityTestingIcon } from "../../images/securityTestingMain.svg"
import { ReactComponent as APITestingIcon } from "../../images/APITesingMain.svg";
import { ReactComponent as PromptLibIcon } from "../../images/promptLibMain.svg";
import { ReactComponent as PerformanceTestingIcon } from "../../images/performanceTestingMain.svg";
import { ReactComponent as ETLIcon } from "../../images/ETLMain.svg";

// Gradient Typography Styling
const GradientTypography = styled(Typography)(({ theme }) => ({
  background: "linear-gradient(94deg, #0074ff 11.09%, #e24e5f 99.55%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

// ToolCard Component
const ToolCard = ({
  icon,
  title,
  description,
  hasStartButton,
  onClickNavigator,
}) => (
  <Card
    sx={{
      bgcolor: "#F6F3F2",
      width: { xs: "100%", sm: "150px" }, // Full width on mobile, 170px on larger screens
      height: "150px", // Fixed height
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      p: 2,
      boxShadow: "none",
      border: "none",
      "&:hover": {
        bgcolor: "white", // Dark white on hover
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
      },
      cursor: "pointer",
      transition: "background-color 0.3s, box-shadow 0.3s",
    }}
    onClick={onClickNavigator}
  >
    <div>
      {icon}</div>
    <CardContent sx={{ textAlign: "left", flexGrow: 1, p: 0 }}>
      <Typography
        variant="h6"
        component="h3"
        sx={{ fontWeight: 600, mb: 1, fontSize: "14px", marginTop: 2 }}
      >
        {title}
      </Typography>
      <Divider sx={{ bgcolor: "#4D4D4D" }} />

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ fontSize: "12px", mb: 1.5, marginTop: 2 }}
      >
        {description}
      </Typography>
      {/* {hasStartButton && isSelected && (
        <Button
          variant="text"
          onClick={onClickNavigator}
          sx={{
            color: "#0094fb",
            fontWeight: 700,
            fontSize: "12px",
            padding: "4px 8px",
            mt: "auto",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Start
        </Button>
      )} */}
    </CardContent>
  </Card>
);

// Main LandingPage Component
const LandingPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleCardClick = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index);
  };
  const navigate = useNavigate();

  // Tools Data
  const toolsData = [
    {
      icon: <TestStrategyIcon />,
      title: "Test Strategy",
      description: "Create a master plan for success",
      // hasStartButton: true,
      // onClickNavigator: () => navigate("/dashboard/test-startegy"),
    },
    {
      icon: <TestPlanIcon />,
      title: "Test Plan",
      description: "Craft a detailed roadmap",
      // hasStartButton: true,
      onClickNavigator: () => navigate("/dashboard/test-plan/input"),
    },
    {
      icon: <UserStoryIcon />,
      title: "User Story",
      description: "Refine user stories",
      onClickNavigator: () => console.log("User Story clicked"),
    },
    {
      icon: <RiskIcon />,
      title: "Risk Assessment",
      description: "Evaluate risks",
      onClickNavigator: () => console.log("Risk Assessment clicked"),
    },
    {
      icon: <TestDesignIcon />,
      title: "Test Design",
      description: "Design with purpose",
      //hasStartButton: true,
      onClickNavigator: () => navigate("/dashboard/generate-testcases/input"),
    },
    {
      icon: <CoverageIcon />,
      title: "Coverage Analysis",
      description: "Maximize test coverage",
      onClickNavigator: () => console.log("Coverage Analysis clicked"),
    },
    {
      icon: <CreationIcon />,
      title: "Test Data Creation",
      description: "Generate comprehensive test data",
      onClickNavigator: () => console.log("Test Data Creation clicked"),
    },
    {
      icon: <TestAutoIcon />,
      title: "Test Automation",
      description: "Create scripts with ease",
      //hasStartButton: true,
      onClickNavigator: () => navigate("/dashboard/test-automation"),
    },
    {
      icon: <ScriptIcon />,
      title: "Script Maintenance",
      description: "Near real-time script updates",
      //hasStartButton: true,
      onClickNavigator: () => console.log("Script Maintenance clicked"),
    },
    {
      icon: <BugReportIcon />,
      title: "Bug Reporting",
      description: "Enhance bug reports",
      //hasStartButton: true,
      onClickNavigator: () => navigate("/dashboard/bugreportenhancer/input"),
    },
    {
      icon: <TestMetricsIcon />,
      title: "Test Metrics",
      description: "Generate better insights",
      //hasStartButton: true,
      onClickNavigator: () => console.log("Test Metrics clicked"),
    },
    {
      icon: <SummaryReportsIcon />,
      title: "Summary Reports",
      description: "Publish results",
      //hasStartButton: true,
      onClickNavigator: () => console.log("Summary Reports clicked"),
    },
    {
      icon: <SecurityTestingIcon />,
      title: "Security Testing",
      description: "Fortify with confidence",
      //hasStartButton: true,
      onClickNavigator: () => console.log("Security Testing clicked"),
    },
    {
      icon: <PerformanceTestingIcon />,
      title: "Performance Testing",
      description: "Accelerate on the path to success",
      //hasStartButton: true,
      onClickNavigator: () => console.log("Performance Testing clicked"),
    },
    {
      icon: <APITestingIcon />,
      title: "API Testing",
      description: "Connect and validate",
      //hasStartButton: true,
      onClickNavigator: () => console.log("API Testing clicked"),
    },
    {
      icon: <ETLIcon />,
      title: "ETL Testing",
      description: "Verify the data flows",
      //hasStartButton: true,
      onClickNavigator: () => console.log("ETL Testing clicked"),
    },
    {
      icon: <PromptLibIcon />,
      title: "Prompt Library",
      description: "Re-use, explore and enhance",
      //hasStartButton: true,
      onClickNavigator: () => console.log("Prompt Library clicked"),
    },

  ];
  const handleRightArrowClick = () => {
    if (startIndex < toolsData.length - 5) {
      setStartIndex(startIndex + 5);  // Move to the next page
      setCurrentPage(currentPage + 1); // Update the page number
    }
  };

  const handleLeftArrowClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 5);  // Move to the previous page
      setCurrentPage(currentPage - 1); // Update the page number
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "#F6F3F2",
      }}
    >
      <Grid2
        container
        spacing={2}
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* NavigationRail Section */}
        <Grid2
          item
          xs={12}
          md={2}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <NavigationRail />
        </Grid2>

        {/* Main Content Section */}
        <Grid2
          item
          xs={12}
          md={10}
          sx={{
            p: { xs: 2, md: 4 },
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Header Section */}
          <Box
            sx={{
              textAlign: "center",
              mb: 4,
              mx: "auto",
              maxWidth: "lg",
              width: "526px"
            }}
          >
            <GradientTypography
              variant="h3"
              component="h1"
              sx={{
                mb: 2,
                fontWeight: 400,
                fontSize: { xs: "32px", md: "57px" },
                lineHeight: "64px",
              }}
            >
              Your Partner for <br /> Intelligent QE
            </GradientTypography>
            <Typography
              variant="body1"
              sx={{
                mb: 3,
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              An all-in-one AI solution for seamless software testing — <br /> from user stories to test cases, automation, reports, and non-functional testing.
            </Typography>
          </Box>

          {/* Divider */}
          {/* <Divider sx={{ mb: 4, height: '1px', bgcolor: 'grey', width: '60%' }} /> */}

          {/*Tools Section*/}
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              mb: 4,
              fontSize: { xs: "12px", md: "14px" },
              position: "relative",
              top: "-15px",
            }}
          >
            <b>What can I help with? </b>
          </Typography>

          {/* Tool Cards Section */}
          <Grid2 container spacing={2} justifyContent="flex-start" sx={{ paddingLeft: 'auto', paddingRight: '0px' }}>
            <Grid2 item xs={12} sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, minmax(170px, 1fr))', gap: '20px', marginRight: '-70px' }}>
              {toolsData.slice(startIndex, startIndex + 5).map((tool, index) => (
                <Grid2 item xs={12} sm={6} md={4} lg={2.4} key={tool.title}>
                  <ToolCard
                    icon={tool.icon}
                    title={tool.title}
                    description={tool.description}
                    hasStartButton={tool.hasStartButton}
                    onClickNavigator={tool.onClickNavigator}
                    sx={{
                      width: '210px',           // Set the width of the ToolCard
                      height: '207px',          // Set the height of the ToolCard
                      opacity: 0,               // Initially set opacity to 0
                      gap: '0px',
                      transition: 'box-shadow 0.3s ease-in-out',  // Smooth transition for the shadow
                      '&:hover': {
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',  // Add shadow on hover
                        transform: 'scale(1.05)',  // Optional: add a slight scale effect for hover
                      },
                      '&.selected': {  // If selected, you can apply a different shadow or effect
                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  />
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
          {/* Left and Right Arrow Buttons */}
          {startIndex > 0 && (
            <Button
              onClick={handleLeftArrowClick}
              sx={{
                position: 'absolute',
                left: '85px',         // Position it 20px from the left
                top: '65%',           // Position it vertically centered (50% from the top)
                transform: 'translateY(-50%)', // Corrected vertical centering
                zIndex: 1000,         // Ensure it's on top of other elements
              }}
            >
              <LeftArrow />
            </Button>

          )}
          {startIndex < toolsData.length - 5 && (
            <Button
              onClick={handleRightArrowClick}
              sx={{
                position: 'absolute',
                right: '60px',
                bottom: '30%',
                transform: 'translateX(50%)',
              }}
            >
              <RightArrow />
            </Button>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default LandingPage;
